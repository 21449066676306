import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { I18n } from "../../utilities/i18n/i18n";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')
const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fifa = require('../game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/r6 150x150.webp')
const roblox = require('../game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../game-navbar/assets/images/KingOfGlory 150x150.webp')


const noImageFound = require('./Assets/no image found.png')
const noImageFoundPlayer = require('../postmatches-component/assets/author.webp')
const css = `
<style>

matches-component .img-circle-container-game-logo-widget{
  /*position: absolute;*/
  /*left: 0;*/
  /*right: 0;*/
  /*top:-5px;*/
  /*margin: 0 auto;*/
}
matches-component .card{
         background-color: transparent;
         border: none;
}

 matches-component .winner-left {
    background-color: #005307;
    filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
    border-radius: 0 15px 15px 0;
}
 matches-component .winner-right {
    background-color: #005307;
    filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
    border-radius: 15px 0 0 15px;
}
 matches-component .nav .nav-item , .nav .nav-link {
background=#EA121259 !important;
color:#ffffff !important;
}
 matches-component .nav .activestatus{
background-color: #ff282859 !important;
color:#ffffff !important;
}
matches-component .dropdown-menu .active{
background-color: #ff282859 !important;
color:#ffffff !important;
}

#current-date{
    background-color: #ff282859 !important;
    font-weight: 500;
    color:#ffffff !important;
}

 .dropdown-menu{
    color: #ffffff;
    font-weight: 500;
    background-color: #181c24;
    < !--#181c24-- >
}

 matches-component .nav .disabled{
color:#707070 !important;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
matches-component .img-circle-container-game-logo-widget {
 width: 0.6rem;
 height: 0.6rem;
  top:2px;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.6rem;
 max-height: 0.6rem;
 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1.1rem;
 height: 1.1rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }
  matches-component .img-circle-container-widget {

   width: 1rem;  /* Adjust the width as needed */
    height: 1rem; /* Ensure the height is the same as the width for a perfect circle */
}
 matches-component .team-name-resize-widget{
         font-size: 0.6rem;
         font-weight: 800;
         color:#ffffff;
}
matches-component .nav .nav-item {
    font-size: 0.5rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.5rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-resize {
         font-size: 0.5rem;
         font-weight: 400;
    }
    matches-component .score-resize-widget{
         font-size: 0.55rem;  
         font-weight: 800;
         color: white ;
}
         matches-component .text-resize-tournament{
         font-size: 0.45rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.35rem;
         font-weight: 200;
         
    }
 
    
   matches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
    }
    
         matches-component .img-circle-container {

   width: 1.5rem;  /* Adjust the width as needed */
    height: 1.5rem; /* Ensure the height is the same as the width for a perfect circle */
}
 
          matches-component .img-circle-container img{

   max-width: 1rem;
   max-height: 1rem;
}
 
     matches-component .team-name-resize{
         font-size: 0.55rem;
         font-weight: 500;
         color: white ;
        
}

 
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }
     matches-component .text-resize-badge{
         font-size: 0.5rem;
         font-weight: 700;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
matches-component .img-circle-container-game-logo-widget {
 width: 0.6rem;
 height: 0.6rem;
  top:2px;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.6rem;
 max-height: 0.6rem;
 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }
    matches-component .img-circle-container-widget {

   width: 1.1rem;  /* Adjust the width as needed */
    height: 1.1rem; /* Ensure the height is the same as the width for a perfect circle */
}
   matches-component .img-circle-container-widget img {

   max-width: 1.1rem;  
    max-height: 1.1rem; 
}
     matches-component .team-name-resize-widget{
         font-size: 0.6rem;
         font-weight: 500;
         color:#ffffff;
}
  matches-component .text-resize-badge{
         font-size: 0.35rem;
         font-weight: 700;
    }
    matches-component .nav .nav-item {
    font-size: 0.55rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.55rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-resize {
         font-size: 0.55rem;
         font-weight: 400;
    }
    matches-component .score-resize-widget{
         font-size: 0.6rem;  
         font-weight: 700;
         color: white ;
}
         matches-component .text-resize-tournament{
         font-size: 0.5rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.4rem;
         font-weight: 200;
         
    }
    
    
    matches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
    }
    
         matches-component .img-circle-container {

   width: 1.5rem;  /* Adjust the width as needed */
    height: 1.5rem; /* Ensure the height is the same as the width for a perfect circle */
}
         matches-component .img-circle-container img {

   max-width: 1.5rem;
   max-height: 1.5rem;
}
 
 
     matches-component .team-name-resize{
         font-size: 0.6rem;
         font-weight: 500;
         color: white ;
        
}

 
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
matches-component .img-circle-container-game-logo-widget {
 width: 0.6rem;
 height: 0.6rem;
  top:3px;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.6rem;
 max-height: 0.6rem;
 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1.3rem;
 height: 1.3rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1.2rem;
 max-height: 1.2rem;
 }
  matches-component .img-circle-container-widget {

   width: 1.1rem;  /* Adjust the width as needed */
    height: 1.1rem; /* Ensure the height is the same as the width for a perfect circle */
     
}
 matches-component .img-circle-container-widget img {

   max-width: 1.1rem;  
    max-height: 1.1rem; 
}
     matches-component .team-name-resize-widget{
         font-size: 0.45rem;
         font-weight: 600;
         color:#ffffff;
         
}
  matches-component .text-resize-badge{
         font-size: 0.35rem;
         font-weight: 500;
    }
    
        matches-component .nav .nav-item {
    font-size: 0.6rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.6rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-resize {
         font-size: 0.6rem;
         font-weight: 400;
    }
    matches-component .score-resize-widget{
         font-size: 0.6rem;  
         font-weight: 700;
         color: white ;
}
         matches-component .text-resize-tournament{
         font-size: 0.55rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.45rem;
         font-weight: 200;
         
    }
   
    
    matches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
    }
    
         matches-component .img-circle-container {

   width: 1.5rem;  /* Adjust the width as needed */
    height: 1.5rem; /* Ensure the height is the same as the width for a perfect circle */
}
 
          matches-component .img-circle-container img{

   max-width: 1.5rem;
   max-height: 1.5rem;
}
 
     matches-component .team-name-resize{
         font-size: 0.65rem;
         font-weight: 500;
         color: white ;
        
}

 
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
matches-component .img-circle-container-game-logo-widget {
 width: 0.7rem;
 height: 0.7rem;
 top:0;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.7rem;
 max-height: 0.7rem;
 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1.4rem;
 height: 1.4rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1.3rem;
 max-height: 1.3rem;
 }
  matches-component .img-circle-container-widget {

   width: 1.1rem;  /* Adjust the width as needed */
    height: 1.1rem; /* Ensure the height is the same as the width for a perfect circle */
}
 matches-component .img-circle-container-widget img {

   max-width: 1.1rem;  
    max-height: 1.1rem; 
}
     matches-component .team-name-resize-widget{
         font-size: 0.6rem;
         font-weight: 800;
         color:#ffffff;
         /*margin: auto !important;*/
}
  matches-component .text-resize-badge{
         font-size: 0.5rem;
         font-weight: 800;
    }
        matches-component .nav .nav-item {
    font-size: 0.65rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.65rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-resize {
         font-size: 0.65rem;
         font-weight: 400;
    }
    matches-component .score-resize-widget{
        font-size: 0.7rem;  
         font-weight: 800;
         color: white ;
}
         matches-component .text-resize-tournament{
         font-size: 0.6rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.5rem;
         font-weight: 200;
         
    }
  
    
    matches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
    }
    
         matches-component .img-circle-container {

   width: 2rem;  /* Adjust the width as needed */
    height: 2rem; /* Ensure the height is the same as the width for a perfect circle */
}
 
        matches-component .img-circle-container img{

   max-width: 1.8rem;
   max-height: 1.8rem;
}
 
     matches-component .team-name-resize{
         font-size: 0.7rem;
         font-weight: 500;
         color: white ;
        
}

   matches-component .img-circle-container-widget-game-logo{
   width: 1rem;
   height: 1rem;
   }
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  matches-component .img-circle-container-widget {

   width: 1.6rem;  /* Adjust the width as needed */
    height: 1.6rem; /* Ensure the height is the same as the width for a perfect circle */
}
 matches-component .img-circle-container-widget img {

   max-width: 1.6rem;  
    max-height: 1.6rem; 
}
     matches-component .team-name-resize-widget{
         font-size: 0.9rem;
         font-weight: 600;
         color:#ffffff;
         margin:5% !important;
}
  matches-component .text-resize-badge{
         font-size: 0.6rem;
         font-weight: 800;
    }

    matches-component .nav .nav-item {
    font-size: 0.7rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.7rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-resize {
         font-size: 0.7rem;
         font-weight: 400;
    }
         matches-component .text-resize-tournament{
         font-size: 0.65rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.55rem;
         font-weight: 200;
         
    }
matches-component .img-circle-container-game-logo-widget {
 width: 1rem;
 height: 1rem;
 top:0;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.8rem;
 max-height: 0.8rem;

 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1.5rem;
 height: 1.5rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1.4rem;
 max-height: 1.4rem;
 }
   matches-component .img-circle-container-widget-game-logo{
   width: 1.5rem;
   height: 1.5rem;
   }
         matches-component .img-circle-container {

   width: 3rem;  /* Adjust the width as needed */
    height: 3rem; /* Ensure the height is the same as the width for a perfect circle */
}
         matches-component .img-circle-container img {

   max-width: 2.5rem;
   max-height: 2.5rem;
}
 
 
     matches-component .team-name-resize{
         font-size: 0.8rem;
         font-weight: 500;
         color: white ;
        
}

matches-component .score-resize-widget{
font-size: 0.8rem;  
         font-weight: 800;
         color: white ;
}
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }


}
 </style>
`

export class MatchesComponent extends BaseComponent {

    initCallback() {

        if (window.location.href.includes('matches')) {
            console.log('The URL includes "matches".');

        }
        RouterAssistant.checkForSingleContainer('matches-component', MatchesComponent.initiator);
        window.MatchesComponent = MatchesComponent;
        document.head.insertAdjacentHTML("beforeend", css);
    }

    static globalDate = new Date();
    static userLanguage = window.navigator.language;
    static boolCheck = false;
    static statusStatic = '';


    static initiator() {
        MatchesComponent.ShowStateMenuOrNot();
        MatchesComponent.addNavStatusListeners();
        MatchesComponent.getMatches('fromMatchesComponent', () => {
            MatchesComponent.checkUrlToActivateFilters(); // this exists for initial run if prompted from a url and not a redirect
            if (document.querySelectorAll('#upcomingmatches-html').length !== 0) {
                console.debug('I am running the matches component call back!');
                console.debug(MatchesComponent.globalDate);
                MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate, MatchesComponent.boolCheck);
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                        upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                        } else {
                            document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: '' });
                        }
                        if (!window.location.href.includes('matches')) {
                            const showMoreButton = '<button id="show-more-button" class="btn color-dark text-resize-badge dark-animation" style="color#ffffff;font-weight: bold;">View All</button>';
                            document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                            document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                        }
                    } else {
                        upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                        } else {
                            document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                        }
                    }

                } else if (!window.location.href.includes('matches')) {
                    const showMoreButton = '<button id="show-more-button" class="btn color-dark text-resize-badge dark-animation" style="color#ffffff;font-weight: bold;">View All</button>';
                    document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                    document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                }
                MatchesComponent.redirectToMatches();
            } else {
                console.debug('The matches component call back is supposed to run but I am not on the correct page!!!')
            }
        });
        GameNavbar.subscribeToGameFilterChanges('fromMatchesComponent', (eventId) => {
            if (document.querySelectorAll('matches-component').length !== 0) // if our component exists on the page
            {
                MatchesComponent.updateMatches(eventId, MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate, MatchesComponent.boolCheck, document.querySelector('matches-component '));
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                        upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                        } else {
                            document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: '' });
                        }
                        if (!window.location.href.includes('matches')) {
                            const showMoreButton = '<button id="show-more-button" class="btn color-dark text-resize-badge dark-animation" style="color#ffffff;font-weight: bold;">View All</button>';
                            document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                            document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                        }
                    } else {
                        upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                        } else {
                            document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                        }
                    }

                } else if (!window.location.href.includes('matches')) {
                    const showMoreButton = '<button id="show-more-button" class="btn color-dark text-resize-badge dark-animation" style="color#ffffff;font-weight: bold;">View All</button>';
                    document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                    document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                }
            }
            MatchesComponent.redirectToMatches();
        })

    }

    static addNavStatusListeners() {
        const navItems = document.querySelectorAll('matches-component .nav-link');
        navItems.forEach(item => {
            item.addEventListener('click', (event) => {
                MatchesComponent.changeUrlAccordingToFilters(event);
                const gameId = GameNavbar.getCurrentlyActivatedGameIfAny();
                MatchesComponent.updateMatches(gameId, MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate, MatchesComponent.boolCheck);
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerHTML.trim() || upcomingMatchesDiv.innerHTML === '') {
                    if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                        upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyStateFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                        } else {
                            document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyStateFilterMessage', { game: '' });
                        }
                        if (!window.location.href.includes('matches')) {
                            const showMoreButton = '<button id="show-more-button" class="btn color-dark text-resize-badge dark-animation" style="color#ffffff;font-weight: bold;">View All</button>';
                            document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                            document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                        }
                    } else {
                        upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                        } else {
                            document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                        }
                    }
                }
            });
        });
    }

    static getCurrentlyActivatedStatusIfAny() {
        return RouterAssistant.getCurrentVariableValue('status')
    }

    static changeUrlAccordingToFilters(event) {
        const statusName = event.target.getAttribute('alt');
        console.debug('I am changing the url according to the filters');
        RouterAssistant.addOrUpdateCurrentPageVariable("status", statusName);
        MatchesComponent.checkUrlToActivateFilters();
    }

    static checkUrlToActivateFilters() {
        const currentActiveStatusFilter = RouterAssistant.getCurrentVariableValue('status')
        if (currentActiveStatusFilter) {
            const activeElement = document.querySelector(`[alt="${currentActiveStatusFilter}"]`);
            if (activeElement) {
                const currentlyActiveElement = document.querySelector('.activestatus');
                if (currentlyActiveElement) {
                    currentlyActiveElement.classList.remove('activestatus');
                }
                activeElement.classList.add('activestatus');
            } else {
                console.debug("No matching status filter found");
            }
        } else {
            console.debug("No status filter in URL");
        }
    }


    static getMatches(callbackName, callback) {
        if (window.getMatchesEventListeners === undefined) {
            window.getMatchesEventListeners = {}
            window.getMatchesEventListeners[callbackName] = callback
            let dateWithTimeSet = new Date(MatchesComponent.globalDate);
            dateWithTimeSet.setHours(4, 0, 0, 0);
            if (window.savedDateMatches === undefined) {
                window.savedDateMatches = { [dateWithTimeSet]: 'OK' };
            } else {
                window.savedDateMatches[dateWithTimeSet] = 'OK';
            }
            FirebaseAssistant.getMatches(querySnapshot => {
                console.debug('running initial firebase getMatches() query snapshot for TODAYs matches')
                console.debug('imrunning', new Date());
                querySnapshot.forEach(single => {
                    const data = single.data();
                    if (window.savedMatches === undefined) {
                        window.savedMatches = { [data.id]: data }
                    } else {
                        window.savedMatches[data.id] = data; // see here
                    }
                });
                Object.keys(window.getMatchesEventListeners).forEach(eventListenerName => {
                    window.getMatchesEventListeners[eventListenerName]()
                })
            });
            FirebaseAssistant.getLiveMatches(querySnapshot => {
                console.debug('running initial firebase getMatches() query snapshot for LIVE matches')
                console.debug('imrunning', new Date());
                querySnapshot.forEach(single => {
                    const data = single.data();
                    window.savedMatches[data.id] = data; // see here
                });
                Object.keys(window.getMatchesEventListeners).forEach(eventListenerName => {
                    window.getMatchesEventListeners[eventListenerName]()
                })
            });
        } else if (window.savedMatches === undefined) {
            setTimeout(() => {
                MatchesComponent.getMatches(callbackName, callback)
            }, 50)
        } else {
            window.getMatchesEventListeners[callbackName] = callback
            callback()
        }
    }

    static updateMatches(gameId, statusId, dateparam, boolParam) {
        console.debug('here', gameId, statusId, dateparam, boolParam)
        if (!statusId) {
            statusId = 'running';
        }
        dateparam = new Date(dateparam.setHours(4, 0, 0, 0));
        MatchesComponent.globalDate.setTime(dateparam.getTime());
        if (!window.location.href.includes('matches')) {
            MatchesComponent.boolCheck = false;
            boolParam = MatchesComponent.boolCheck;
            console.debug(boolParam)
        }
        if (boolParam === false || statusId === 'running') {
            console.debug('back to undefined CHECK')
            dateparam = new Date();
            dateparam = new Date(dateparam.setHours(4, 0, 0, 0));
            MatchesComponent.globalDate.setTime(dateparam.getTime());
        }
        console.debug(dateparam)
        console.debug('running update matches')
        let counter = 0;

        document.querySelector('#upcomingmatches-html').innerHTML = ''

        const sortedMatchIds = Object.keys(window.savedMatches).sort((a, b) => {
            const dateA = new Date(window.savedMatches[a].begin_at ? window.savedMatches[a].begin_at : window.savedMatches[a].scheduled_at);
            const dateB = new Date(window.savedMatches[b].begin_at ? window.savedMatches[b].begin_at : window.savedMatches[b].scheduled_at);
            return dateA - dateB
        })
        for (let i = 0; i < sortedMatchIds.length; i++) {
            const matchCode = sortedMatchIds[i]
            const data = window.savedMatches[matchCode]

            const dateObject = new Date(dateparam);
            const startOfDay = new Date(dateObject.setHours(0, 0, 0, 0));
            const endOfDay = new Date(dateObject.setHours(23, 59, 59, 999));
            const matchDate = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
            if (window.location.href.includes('matches')) {
                if (dateObject && (matchDate < startOfDay || matchDate > endOfDay)) {
                    continue;
                }
            }

            data.predictions = data.predictions ? data.predictions : ''; //TO BE DELETED
            // with the above line we are setting the predictions to an empty string if it is undefined temporarily
            // this is to avoid the error that is thrown when the predictions are not present in the data and also
            // to be able to give value on window.savedMatches through chrome console and test the code
            const currentGameId = data.videogame.id; // see here
            const gameStatus = data.status;
            // const matchid = data.id;

            // Create a mapping of gameIds to their corresponding currentGameId
            const gameIdMapping = {
                'csgo': 3,
                'dota2': 4,
                'lol': 1,
                'easports': 25,
                'valorant': 26,
                'cod': 23,
                'overwatch': 14,
                'pubg': 20,
                'r6': 24,
                'rocketleague': 22,
                'starcraft': [29, 30],
                'kingofglory': 27,
                'hearthstone': 999,
                'fortnite': 999,
                'fifa': 999,
                'roblox': 999,
                'minecraft': 999,
                'wow': 999,
                'apex': 999,
                'mlegends': 34
            };
            const gameIdImg = {
                '3': csgologo,
                '4': dota2,
                '1': lol,
                '25': ea,
                '26': valorant,
                '23': cod,
                '14': overwatch,
                '20': pubg,
                '24': r6,
                '22': rocketleague,
                '29': startcraft,
                '30': startcraft,
                '27': kingofglory,
                '34': mlegends

            };
            // If a gameId is provided and it doesn't match the current game's id, skip this match
            if (gameId && gameIdMapping[gameId] !== undefined) {
                if (Array.isArray(gameIdMapping[gameId])) {
                    if (!gameIdMapping[gameId].includes(currentGameId)) {
                        continue;
                    }
                } else {
                    if (gameIdMapping[gameId] !== currentGameId) {
                        continue;
                    }
                }
            }

            let now = new Date();
            now = new Date(now.setHours(4, 0, 0, 0));

            // console.debug(statusId)
            // If a statusId is provided and it doesn't match the current game's status, skip this match
            if (statusId !== 'all-tab') {
                if (statusId && statusId === "predictions" && data && data.predictions && data.predictions.length > 0) {
                    // console.debug('Prediction Match')
                } else if (statusId && statusId !== gameStatus) {
                    // console.debug('Match with different status')
                    continue;
                } else {
                    // console.debug('Match with the selected status')
                }
            }
            if (!data.opponents || data.opponents.length < 2) {
                // console.debug('\tError: Invalid opponents data', data.id);
                continue;
            } // if no opponents due to tourney format ongoing, skip the match

            const team1name = data.opponents[0].opponent.name;
            const team1acronym = (data.opponents[0].opponent.acronym ? data.opponents[0].opponent.acronym : data.opponents[0].opponent.name).substring(0, 6);
            const team1id = data.opponents[0].opponent.id;
            let team1img = undefined;
            if (data.winner_type === "Team") {
                team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : noImageFound
            } else {
                team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : noImageFoundPlayer
            }





            const team2name = data.opponents[1].opponent.name;
            const team2acronym = (data.opponents[1].opponent.acronym ? data.opponents[1].opponent.acronym : data.opponents[1].opponent.name).substring(0, 6);
            const team2id = data.opponents[1].opponent.id;
            let team2img = undefined;
            if (data.winner_type === "Team") {
                team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : noImageFound
            } else {
                team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : noImageFoundPlayer
            }
            const winnerId = data.winner && data.winner.id ? data.winner.id : data.winner_id;


            const tournamentName = `${data.league.name} ${data.serie.full_name}`;
            const tournamentLogo = data.league.image_url;
            const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid mx-auto d-block " >` : '';
            const gameLogoHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="tournament logo" class="img-fluid mx-auto d-block  " >`;
            const gameLogoWidgetHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="game logo" class="img-fluid mx-auto d-block " >`;

            const matchTypeRaw = data.match_type;
            const numberOfGames = data.number_of_games;
            let matchTypeFinal = ``;
            if (matchTypeRaw === 'best_of' && (numberOfGames !== 2 && numberOfGames !== 1)) {
                matchTypeFinal = 'best_of_X';
            } else if (matchTypeRaw === 'best_of' && numberOfGames === 1) {
                matchTypeFinal = 'single_game';
            } else if (matchTypeRaw === 'best_of' && numberOfGames === 2) {
                matchTypeFinal = 'best_of_2';
            } else if (matchTypeRaw === 'all_games_played') {
                matchTypeFinal = 'all_games_played';
            } else if (matchTypeRaw === 'first_to') {
                matchTypeFinal = 'first_to_X';
            } else {
                matchTypeFinal = 'general_drop_to';
            }
            const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
            const matchTypefull = `${matchType} ${data.number_of_games.toString()}`;

            const score = `${data.results[0].score.toString()} - ${data.results[1].score.toString()}`;
            const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
            const date = `${dateRaw.toLocaleDateString(MatchesComponent.userLanguage, {
                weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                day: 'numeric',   // numeric day of the month
                month: 'short'    // "short" for abbreviated month name (e.g., Feb)
            })} ${dateRaw.toLocaleTimeString(MatchesComponent.userLanguage, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })}`;


            const countdownElementIdmatches = `countdownmatches-${matchCode}`;
            const countdownElementIdhome = `countdownhome-${matchCode}`;
            const matchElementId = `${matchCode}`;
            const matchElementIdmatches = `matchfrommatches-${matchCode}`;
            const SerieElement = `${matchCode}seriefrommatches-${data.serie_id}`;
            const matchElementIdhome = `matchfromhome-${matchCode}`;
            const matchName = data.name ? `${data.name.replace(/\s/g, "_").replace(/match/gi, "").replace(/&/g, "")}` : 'unknown';

            const team1Element = document.getElementById(`match-${matchCode}-team-${team1id}`);
            const team2Element = document.getElementById(`match-${matchCode}-team-${team2id}`);
            // If winnerId exists and it matches team1id or team2id, add a CSS class to the corresponding team
            if (winnerId) {
                if (winnerId === team1id && team1Element) {
                    team1Element.classList.add('winner-left');
                } else if (winnerId === team2id && team2Element) {
                    team2Element.classList.add('winner-right');
                }
            }

            let countdown = `${countdownElementIdmatches}`;
            let countdownhome = `${countdownElementIdhome}`;


            const modeElement = document.querySelector('[data-mode]');
            let mode;
            if (modeElement) {
                mode = modeElement.getAttribute('data-mode');
                const matchesCardinalityThreshold = ((window.innerHeight > window.innerWidth) && window.location.href.includes('home')) ? 2 : 10;

                if (mode === 'matchesPage') {
                    const matchHtml = MatchesComponent.getMatchHtml(mode, matchElementIdmatches, matchElementIdhome, team1img, team1acronym, gameLogoHTML, gameLogoWidgetHTML, countdown, countdownhome, team2acronym, team2img, team1name, team2name, tournamentLogoHTML, tournamentName, matchTypefull, score, date, winnerId, team1id, team2id, SerieElement, matchCode, matchTypeFinal, numberOfGames, matchName);
                    document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', matchHtml);
                } else if (mode === 'homePage') {
                    if (counter < matchesCardinalityThreshold) {
                        const matchHtml = MatchesComponent.getMatchHtml(mode, matchElementIdmatches, matchElementIdhome, team1img, team1acronym, gameLogoHTML, gameLogoWidgetHTML, countdown, countdownhome, team2acronym, team2img, team1name, team2name, tournamentLogoHTML, tournamentName, matchTypefull, score, date, winnerId, team1id, team2id, SerieElement, matchCode, matchTypeFinal, numberOfGames, matchName);
                        document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', matchHtml);
                        counter++;
                    } else if (counter === matchesCardinalityThreshold) {
                        counter++;
                    }
                }
            }

            const matchTypeElement = document.querySelector(`#${matchElementIdmatches} .matchTypeFromMatches`);
            if (mode === 'matchesPage') {
                if (matchTypeElement.innerHTML === 'all_games_played') {
                    matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
                } else if (matchTypeElement.innerHTML === 'best_of_X') {
                    let gamesNumber;
                    if (window.translateTo === 'el') {
                        gamesNumber = Math.floor(numberOfGames / 2) + 1;
                    } else {
                        gamesNumber = numberOfGames;
                    }
                    matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
                } else if (matchTypeElement.innerHTML === 'first_to_X') {
                    matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
                } else if (matchTypeElement.innerHTML === 'best_of_2') {
                    matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
                } else if (matchTypeElement.innerHTML === 'single_game') {
                    matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
                } else {
                    matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
                }
            }

            counter = MatchesComponent.startCountdown(dateRaw, countdownElementIdmatches, countdownElementIdhome, matchElementIdmatches, matchElementIdhome, gameStatus, mode, counter, data.streams_list);
            MatchesComponent.redirectToMatchPage(mode, matchElementIdmatches, matchElementIdhome, matchName);
            MatchesComponent.redirectToSeriePage(SerieElement);

        }

        let now = new Date();
        now = new Date(now.setHours(4, 0, 0, 0));
        if (MatchesComponent.globalDate.getTime() > now.getTime() && document.querySelectorAll('[data-mode="matchesPage"]').length !== 0) {
            console.debug('start of day is greater than now');
            document.querySelector('#finished').classList.add('disabled');
            document.querySelector('#all-tab').classList.add('disabled');
            document.querySelector('#not_started').classList.remove('disabled');

        }
        if (MatchesComponent.globalDate.getTime() < now.getTime() && document.querySelectorAll('[data-mode="matchesPage"]').length !== 0) {
            console.debug('end of day is less than now');
            document.querySelector('#not_started').classList.add('disabled');
            document.querySelector('#all-tab').classList.add('disabled');
            document.querySelector('#finished').classList.remove('disabled');

        }
        if (MatchesComponent.globalDate.getTime() === now.getTime() && document.querySelectorAll('[data-mode="matchesPage"]').length !== 0) {
            console.debug('start of day is equal to now');
            document.querySelector('#not_started').classList.remove('disabled');
            document.querySelector('#finished').classList.remove('disabled');
            document.querySelector('#all-tab').classList.remove('disabled');
        }
        if (statusId === 'running' && dateparam.getTime() === now.getTime() && document.querySelectorAll('[data-mode="matchesPage"]').length !== 0) {
            const activeElement = document.querySelector('[data-date].active');
            console.debug('activeElement', activeElement);
            if (activeElement) {
                activeElement.classList.remove('active');
            }
            document.querySelector('.theToday').classList.add('active');
            document.querySelector('#current-date').innerText = document.querySelector('.theToday').innerText;

        }
        let element = document.getElementById('placeholder-matches-widget');
        if (element) {
            element.style.display = "none"; // deactivate placeholder upcoming matches
        }
        let element2 = document.getElementById('placeholder-matches-big');
        if (element2) {
            element2.style.display = "none"; // deactivate placeholder  matches
        }
        MatchesComponent.redirectToMatches();
    }

    static removeHotMatchFromMatches(matchId) {
        if (document.querySelectorAll('[data-mode="homePage"]').length !== 0) //only when in home mode
        {
            if (document.getElementById('show-more-button') === undefined) // wait until matches are done loading :)
                setTimeout(() => { MatchesComponent.removeHotMatchFromMatches(matchId) }, 50)
            else if (document.querySelectorAll(`#matchfromhome-${matchId}`).length !== 0)
                document.getElementById(`matchfromhome-${matchId}`).style.display = 'none'
        }
    }

    static getMatchHtml(mode, matchElementIdmatches, matchElementIdhome, team1img, team1acronym, gameLogoHTML, gameLogoWidgetHTML, countdown, countdownhome, team2acronym, team2img, team1name, team2name, tournamentLogoHTML, tournamentName, matchTypefull, score, date, winnerId, team1id, team2id, SerieElement, matchCode, matchTypeFinal, numberOfGames, matchName) {
        const alteredMatchElementId = matchElementIdmatches.replace('matchfrommatches-', '');
        const alteredMatchElementIdHome = matchElementIdhome.replace('matchfromhome-', '');
        if (mode === 'matchesPage') {
            return /*html*/ `

                <a href="${window.location.origin}/s_match?match=${alteredMatchElementId}&name=${matchName}" id="${matchElementIdmatches}" class="row  mt-3 rounded text-white color-lightdark p-0 clickable">
                
                <div class="col-12 p-0 d-flex justify-content-between rounded-top mb-2 color-dark">
                <div class="text-start d-flex p-1  clickable d-flex justify-content-center align-items-center align-content-center"  id="${SerieElement}">
                <div class="img-circle-container-game-logo d-flex align-items-center">
                ${gameLogoHTML} 
                </div>
                <span class="m-0 mx-2 d-flex align-items-center text-resize-tournament">${tournamentName}</span>
                <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                ${tournamentLogoHTML}
                </div>
                </div>
                <div class="text-end  text-resize-date mt-1 me-2 d-flex justify-content-center align-content-center align-items-center">
                <i class="bi bi-clock"></i><span class="ms-1"> ${date}</span>
                </div>

                </div>
  
                 
                <div class="col-5 d-flex justify-content-end align-items-center ${winnerId === team1id ? 'winner-left' : ''}" id="match-${matchCode}-team-${team1id}">
                <span class="team-name-resize mx-2 my-0 ">${team1name}</span>
                <div class="img-circle-container d-flex justify-content-center flex-column m-2">
                <img src="${team1img}" alt="" class="img-fluid mx-auto d-block" >
                </div>
                </div>
    
                <div class="col-2 gay-bg d-flex flex-column align-items-center justify-content-around">
                <p class="score-resize m-0">${score}</p>
                
                </div>
    
                <div class="col-5 d-flex justify-content-start align-items-center ${winnerId === team2id ? 'winner-right' : ''}" id="match-${matchCode}-team-${team2id}">
                 <div class="img-circle-container d-flex justify-content-center flex-column m-2">
                <img src="${team2img}" alt="" class="img-fluid mx-auto d-block" >
                 </div>
                <span class="team-name-resize mx-2 my-0 ">${team2name}</span>
                </div>
               
                
                    <div class="col-4 p-0 m-0 mt-2">
                    </div>
                    <div class="col-4 text-center  p-0 m-0 mt-2">
                     <span class="my-2 badge bg-secondary text-resize-badge matchTypeFromMatches">${matchTypeFinal}</span>
                    </div>
                    <div class="col-4 text-end  p-0 m-0 pe-2 mt-2">
                    <div id="${countdown}"></div>
                    </div>
                 
            
                </a>
                </div>

                </div>
            `;
        } else if (mode === 'homePage') {
            return /*html*/ `
                <a href="${window.location.origin}/s_match?match=${alteredMatchElementIdHome}&name=${matchName}" id="${matchElementIdhome}" >
                    <div class="container">
                     
                      
                            <!-- Match Item -->
                            
                           
                            <div class="row d-flex align-items-center align-content-center p-0 my-3 color-dark rounded clickable h-100 position-relative" style="background-color: #1e252e !important;">
                            
                            <div class="col-12 d-flex justify-content-between align-items-start align-content-start p-2 mb-1 color-dark rounded-top">
                            <div class="img-circle-container-game-logo-widget d-flex align-items-center justify-content-center align-content-center">
                              ${gameLogoWidgetHTML}
                                 </div>
                                 <div id="${countdownhome}" class="d-flex align-items-center justify-content-center align-content-center" ></div>
                            </div>
                                 
                         
                             <div class="col-5 p-2 p-md-1  d-flex flex-row-reverse justify-content-start align-content-center align-items-center flex-md-column hotmatch-component .img-circle-container imgjustify-content-md-center text-center align-items-md-center">
                                <div class=" img-circle-container-widget d-flex justify-content-center flex-column">
                                <img src="${team1img}" alt="" class="img-fluid mx-auto d-block" >
                                </div>
                                <span class="team-name team-name-resize-widget me-2 me-md-0">${team1name}</span>
                             </div>
                             <div class="col-2 p-2 p-md-1 text-center d-flex justify-content-center align-items-center align-content-center ">
                           <span class="score-resize-widget">${score}</span>
                                
                             </div>
                             <div class="col-5 p-2 p-md-1 d-flex flex-row-reverse justify-content-end align-content-center align-items-center flex-md-column-reverse justify-content-md-center text-center align-items-md-center">
                                <span class="team-name team-name-resize-widget ms-2 ms-md-0">${team2name}</span>
                                 <div class=" img-circle-container-widget d-flex justify-content-center flex-column   ">
                                <img src="${team2img}" alt="" class="img-fluid mx-auto d-block" >
                                </div>
                             </div>
                                </div>
                           
                    </div>
                    </a>
            `;

        } else {
            return '';
        }
    }


    static redirectToMatchPage(mode, matchElementIdmatches, matchElementIdhome, matchName) {
        const elementmatches = document.getElementById(matchElementIdmatches);
        const elementhome = document.getElementById(matchElementIdhome);

        if (mode === 'matchesPage' && elementmatches) {
            const alteredMatchElementId = matchElementIdmatches.replace('matchfrommatches-', '');
            elementmatches.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                RouterAssistant.redirect('s_match', {
                    match: alteredMatchElementId,
                    name: matchName
                })
            }))
        } else if (mode === 'homePage' && elementhome) {
            const alteredMatchElementId = matchElementIdhome.replace('matchfromhome-', '');
            elementhome.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                RouterAssistant.redirect('s_match', {
                    match: alteredMatchElementId,
                    name: matchName
                })
            }))
        }
    }

    static redirectToSeriePage(serieElementId) {

        const element = document.getElementById(serieElementId);
        const alteredSerieElementId = serieElementId.replace(/.*seriefrommatches-/, '');
        if (window.location.href.includes('matches') && element && !element.listenerAdded) {
            element.addEventListener('click', function (evt) {
                evt.stopPropagation();
                RouterAssistant.redirect('s_tournament', {
                    tournament: alteredSerieElementId
                })
            })
            element.listenerAdded = true;
        }

    }

    static redirectToMatches() {
        const showMoreButton = document.getElementById('show-more-button');
        if (showMoreButton) {
            showMoreButton.addEventListener('click', function (evt) {
                RouterAssistant.redirect("matches", { game: GameNavbar.getCurrentlyActivatedGameIfAny(), status: 'running' });
            })
        }
    }


    static startCountdown(gameStartTime, countdownElementIdmatches, countdownElementIdhome, matchElementIdmatches, matchElementIdhome, gameStatus, mode, counter, streams_list) {
        const now = new Date();
        const diffInMilliseconds = gameStartTime - now;
        const diffInMinutes = Math.round(diffInMilliseconds / 1000 / 60);

        const element = document.getElementById(countdownElementIdmatches);
        const elementhome = document.getElementById(countdownElementIdhome);
        const matchElement = document.getElementById(matchElementIdmatches);
        const matchElementTwo = document.getElementById(matchElementIdhome);
        if (mode === 'matchesPage') {
            // innerHTML = I18n.translateString("hotmatchTranslations.badgeHot"));
            if (element && diffInMinutes <= 60 && diffInMinutes > 0) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchUpcoming", { minutes: diffInMinutes });
            } else if (element && (diffInMinutes <= 0 && gameStatus === 'running')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchLive");
            } else if (element && (diffInMinutes <= 0 && diffInMinutes >= -180 && gameStatus === 'not_started')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchAboutTo");
            } else if (element && (gameStatus === 'finished')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchFinished");
            } else if (element && (gameStatus === 'postponed')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchPostponed");
            } else if (element && (gameStatus === 'canceled')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchCanceled");
            } else if (matchElement && diffInMinutes < -180) {
                matchElement.style.display = 'none';
            }

            if (element && matchElement.querySelector('.countdownBadge') && streams_list && streams_list.length > 0 && gameStatus !== 'finished' && gameStatus !== 'postponed' && gameStatus !== 'canceled') {
                matchElement.querySelector('.countdownBadge').innerHTML = '<i class="me-1 bi bi-cast"></i>' + matchElement.querySelector('.countdownBadge').innerHTML;
            }
        } else if (mode === 'homePage') {
            if (elementhome && diffInMinutes > 0) {
                if (diffInMinutes > 1440) {
                    const days = Math.floor(diffInMinutes / 1440);
                    const hours = Math.floor((diffInMinutes % 1440) / 60);
                    const minutes = diffInMinutes % 60;
                    elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchDHM", { days: days, hours: hours, minutes: minutes });
                } else if (diffInMinutes > 60) {
                    const hours = Math.floor(diffInMinutes / 60);
                    const minutes = diffInMinutes % 60;
                    elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchHM", { hours: hours, minutes: minutes });
                } else if (diffInMinutes < 60) {
                    elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchM", { minutes: diffInMinutes });
                }

            } else if (elementhome && (diffInMinutes <= 0 && gameStatus === 'running')) {
                elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchLive");
            } else if (elementhome && (diffInMinutes <= 0 && diffInMinutes >= -30 && gameStatus === 'not_started')) {
                elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchAboutTo");
            } else if (elementhome && (diffInMinutes < 30 && gameStatus === 'not_started')) {
                matchElementTwo.style.display = 'none';
                counter--;
            }
            else if (elementhome && ((gameStatus === 'finished') || (gameStatus === 'postponed') || (gameStatus === 'canceled'))) {
                matchElementTwo.style.display = 'none';
                counter--;
            }

            if (elementhome && matchElementTwo.querySelector('.countdownBadge') && streams_list && streams_list.length > 0 && gameStatus !== 'finished' && gameStatus !== 'postponed' && gameStatus !== 'canceled') {
                matchElementTwo.querySelector('.countdownBadge').innerHTML = '<i class="me-1 bi bi-cast"></i>' + matchElementTwo.querySelector('.countdownBadge').innerHTML;
            }
        }

        return counter;
    }

    static generateDates() {
        const dates = [];
        const currentDate = new Date();

        // Subtract 7 days from the current date
        currentDate.setDate(currentDate.getDate() - 4);

        // Generate 15 dates
        for (let i = 0; i < 11; i++) {
            // Add a day to the current date
            currentDate.setDate(currentDate.getDate() + 1);
            const formattedDate = new Date(currentDate.getTime());
            dates.push(formattedDate);
        }

        return dates;
    }

    static ShowStateMenuOrNot() {
        const dates = MatchesComponent.generateDates();
        // console.debug(dates);
        if (window.location.href.includes('matches')) {
            document.querySelector('matches-component .stateMenu').innerHTML = /*html*/`
        <div class="container p-0">
        <div class="row">
            <ul class="col-sm-12 col-md-8 nav nav-pills style-nav-pills d-flex rounded justify-content-start">
                <li class="nav-item">
                    <a class="nav-link activestatus" id="running" alt="running" data-toggle="pill">LIVE</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="not_started" alt="not_started" data-toggle="pill">UPCOMING</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link  " id="predictions" alt="predictions" data-toggle="pill">PREDICTIONS</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" id="finished" alt="finished" data-toggle="pill">FINISHED</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="all-tab" alt="all-tab" data-toggle="pill" >ALL</a>
                </li>
            </ul>
            <div class="col-sm-12 col-md-4 date-btn-group d-flex justify-content-end">
                <button class="btn btn-outline-secondary dropdown-toggle text-resize-dropdown" data-bs-toggle="dropdown" aria-expanded="false" id="current-date">
                </button>

                <ul class="dropdown-menu" id="date-dropdown">
                    <!-- Dynamically generated date list items will go here -->
                </ul>

                </div>
            </div>
            <div id="upcomingmatches-html"></div>
        </div>
    `;
            document.getElementById('running').innerText = I18n.translateString("matchTranslations.pillLive");
            document.getElementById('not_started').innerText = I18n.translateString("matchTranslations.pillUpcoming");
            document.getElementById('predictions').innerText = I18n.translateString("matchTranslations.pillPredictions");
            document.getElementById('finished').innerText = I18n.translateString("matchTranslations.pillFinished");
            document.getElementById('all-tab').innerText = I18n.translateString("matchTranslations.pillAll");
            for (let i = 0; i < dates.length; i++) {
                let date = dates[i];
                date = new Date(date.setHours(4, 0, 0, 0));
                let now = new Date();
                now = new Date(now.setHours(4, 0, 0, 0));

                const dateFormated = date.toLocaleString(MatchesComponent.userLanguage, { weekday: 'short', day: '2-digit', month: 'short' }).toUpperCase();
                const dateItem = document.createElement('li');
                dateItem.classList.add('dropdown-item', 'text-resize-dropdown');
                let dateFinal = 'ATR' + date.toISOString().split('T')[0];
                if (date.getTime() === now.getTime()) {
                    dateItem.classList.add('theToday');
                }
                dateItem.setAttribute('data-date', dateFinal);
                dateItem.innerText = dateFormated;
                const currentDate = new Date();
                const formattedCurrentDate = currentDate.toLocaleString(MatchesComponent.userLanguage, { weekday: 'short', day: '2-digit', month: 'short' }).toUpperCase();
                if (dateFormated === formattedCurrentDate) {
                    dateItem.classList.add('active');
                    document.getElementById('current-date').innerText = dateFormated;
                }
                document.getElementById('date-dropdown').appendChild(dateItem);
                document.querySelectorAll('[data-date]').forEach(function (element) {

                    if (element && !element.listenerAdded) {
                        let procelement = element.getAttribute('data-date');
                        procelement = procelement.replace('ATR', '');

                        MatchesComponent.theDateListener(element, procelement);
                    }
                    element.listenerAdded = true;

                });

            }

        } else {

            document.querySelector('matches-component .stateMenu').innerHTML =  /*html*/ `

                <div class="container p-0 my-0 my-xl-5 mobileOnlyCss">
            <ul class="nav nav-pills style-nav-pills ">
                <li class="nav-item "  style="display: none;">
                    <a class="nav-link" id="all-tab" data-toggle="pill" ></a>
                </li>
            </ul>
            <div id="upcomingmatches-html"></div>
        </div >
                `;

        }

    }

    static theDateListener(element, procelement) {
        element.addEventListener('click', function () {
            console.debug('element', element)
            console.debug('procelement', procelement)
            const activeElement = document.querySelector('[data-date].active');
            console.debug('activeElement', activeElement);
            if (activeElement) {
                activeElement.classList.remove('active');
            }
            element.classList.add('active');
            document.getElementById('current-date').innerText = element.innerText;
            console.debug(new Date(procelement))
            MatchesComponent.globalDate = new Date(procelement);
            MatchesComponent.globalDate = new Date(MatchesComponent.globalDate.setHours(4, 0, 0, 0));
            const dateObject = new Date(procelement);
            const startOfDay = new Date(dateObject.setHours(0, 0, 0, 0));
            const endOfDay = new Date(dateObject.setHours(23, 59, 59, 999));
            const matchesForSelectedDate = Object.values(window.savedMatches || {}).filter(match => {
                const matchDate = new Date(match.begin_at ? match.begin_at : match.scheduled_at);
                return matchDate >= startOfDay && matchDate < endOfDay;
            });
            let now = new Date();
            now = new Date(now.setHours(4, 0, 0, 0));

            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search);
            if (MatchesComponent.globalDate.getTime() > now.getTime()) {
                MatchesComponent.statusStatic = 'not_started';
                document.querySelectorAll('.activestatus').forEach(function (element) {
                    element.classList.remove('activestatus');
                });
                document.querySelector('#not_started').classList.add('activestatus');

                params.set('status', 'not_started');
                url.search = params.toString();
                history.pushState({}, '', url.toString());
            }
            if (MatchesComponent.globalDate.getTime() < now.getTime()) {
                document.querySelectorAll('.activestatus').forEach(function (element) {
                    element.classList.remove('activestatus');
                });
                MatchesComponent.statusStatic = 'finished';
                document.querySelector('#finished').classList.add('activestatus');

                params.set('status', 'finished');
                url.search = params.toString();
                history.pushState({}, '', url.toString());
            }
            if (MatchesComponent.globalDate.getTime() === now.getTime()) {
                document.querySelectorAll('.activestatus').forEach(function (element) {
                    element.classList.remove('activestatus');
                });
                MatchesComponent.statusStatic = 'running';
                document.querySelector('#running').classList.add('activestatus');

                params.set('status', 'running');
                url.search = params.toString();
                history.pushState({}, '', url.toString());
            }
            if (window.savedDateMatches && window.savedDateMatches[MatchesComponent.globalDate]) {
                console.debug('CHECK UPDATING FROM WINDOW.SAVEDMATCHES')
                console.debug('element', element)

                // MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate, MatchesComponent.boolCheck = true);
                MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.statusStatic, MatchesComponent.globalDate, MatchesComponent.boolCheck = true);
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                        upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                        } else {
                            document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: '' });
                        }
                        if (!window.location.href.includes('matches')) {
                            const showMoreButton = '<button id="show-more-button" class="btn color-dark text-resize-badge dark-animation" style="color#ffffff;font-weight: bold;">View All</button>';
                            document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                            document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                        }
                    } else {
                        upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                        } else {
                            document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                        }
                    }

                }
            } else {
                if (window.location.href.includes('matches')) {
                    console.debug('CHECK UPDATING FROM FIREBASE')
                    MatchesComponent.boolCheck = true;
                    if (window.savedDateMatches === undefined) {
                        window.savedDateMatches = { [MatchesComponent.globalDate]: 'OK' };
                    } else if (window.savedDateMatches[MatchesComponent.globalDate] === undefined) {
                        window.savedDateMatches[MatchesComponent.globalDate] = 'OK';
                    }
                    FirebaseAssistant.getMatches(querySnapshot => {
                        console.debug('running firebase getMatches() query snapshot with a set date from user click');
                        console.debug('imrunning', new Date());

                        querySnapshot.forEach(single => {
                            const data = single.data();
                            if (window.savedMatches === undefined) {
                                window.savedMatches = { [data.id]: data };
                            } else {
                                window.savedMatches[data.id] = data; // see here
                            }
                        });
                        // Object.keys(window.getMatchesEventListeners).forEach(eventListenerName => {
                        //     window.getMatchesEventListeners[eventListenerName]()
                        // })
                    }, MatchesComponent.globalDate);
                    document.querySelector('#placeholder-matches-big').style.display = '';
                    setTimeout(() => {
                        MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.statusStatic, MatchesComponent.globalDate, MatchesComponent.boolCheck = true);
                    }, 500);


                    setTimeout(() => {
                        const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                        if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                            if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                                upcomingMatchesDiv.innerHTML = /*html*/`
                                <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                                </div>
                                `;
                                if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                                    document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                                } else {
                                    document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: '' });
                                }
                                if (!window.location.href.includes('matches')) {
                                    const showMoreButton = '<button id="show-more-button" class="btn color-dark text-resize-badge dark-animation" style="color#ffffff;font-weight: bold;">View All</button>';
                                    document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                                    document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                                }
                            } else {
                                upcomingMatchesDiv.innerHTML = /*html*/`
                                <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                                </div>
                                `;
                                if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                                    document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                                } else {
                                    document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                                }
                            }

                        }
                    }, 501);
                }
            }

        });
    }



}


MatchesComponent.template =   /*html*/ `

        <div class="stateMenu text-center">
        </div>
                `;

MatchesComponent.reg('matches-component');

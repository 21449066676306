import { BaseComponent } from "@symbiotejs/symbiote";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { AuthorsComponent } from "../author-component/author-component";
import { I18n } from "../../utilities/i18n/i18n";
import { GameNavbar } from "../game-navbar/game-navbar";

const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/rainbow six 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')
const test = require('./assets/test.png')


const css = `
<style>
/* News Carousel Container */
news-carousel .container {
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Card Styles */
news-carousel .card {
    border: none;
    background-color: #1E252E;
    color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

news-carousel .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.6);
}

/* Image Wrapper */
news-carousel .image-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

news-carousel .image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

news-carousel .image-wrapper:hover img {
    transform: scale(1.1);
}

/* Game Logo */
news-carousel .newsItemSingle .img-game-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 5px;
    object-fit: contain;
    z-index: 10;
}



/* Card Body */
news-carousel .card-body {
    padding: 20px;
    border-radius: 0 0 10px 10px;
    background-color: #1E252E;
    height: 110px;
}

/* Author and Date */
news-carousel .author-link {
    color: #B22A23; /* Author name in red */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Ensure date is white even if nested */
news-carousel .news-date {
    color: #ccc; /* Override any previous grey color */
    font-size: 0.75rem;
}

/* Hover Effects */
news-carousel .card:hover .card-title  {
    color: #B22A23; /* Title changes to red on hover */
}

news-carousel .card-title  {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Truncate to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* News Meta */
news-carousel .news-meta {
    font-size: 0.9rem;
    opacity: 0.8;
}

news-carousel .author {
    color: #B22A23;
}

/* Responsive Adjustments */
@media only screen and (max-width: 600px) { /* Extra Small Devices (Phones) */
    /* Adjust container padding */
    

    /* Reduce card body padding and height */
    news-carousel .card-body {
        padding: 8px;
        height: 60px; /* Allow height to adjust based on content */
    }

    /* Reduce game logo size */
    news-carousel .newsItemSingle .img-game-logo {
        width: 20px;
        height: 20px;
    }


    /* Adjust card title font size */
    news-carousel .card-title {
        font-size: 0.8rem;
    }

    /* Stack author and date vertically */
    news-carousel .author-link {
        flex-direction: column;
        align-items: flex-start;
    }

    /* Adjust date margin */
    news-carousel .news-date {
        margin-left: 0;
        margin-top: 5px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 767.98px) { /* Small Devices (Landscape Phones) */
    /* Adjust container padding */
    

    /* Adjust card body padding and height */
    news-carousel .card-body {
        padding: 18px;
        height: auto;
    }



    /* Adjust card title font size */
    news-carousel .card-title {
        font-size: 0.9rem;
    }

    /* Adjust game logo size */
    news-carousel .newsItemSingle .img-game-logo {
        width: 30px;
        height: 30px;
    }

    /* Maintain horizontal layout for author and date */
    news-carousel .author-link {
        flex-direction: row;
    }

    /* Adjust date margin */
    news-carousel .news-date {
        margin-left: 5px;
        margin-top: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) { /* Medium Devices (Tablets) */
    /* Adjust container padding */
   

    /* Adjust card body padding and height */
    news-carousel .card-body {
        padding: 20px;
        height: 120px;
    }


   news-carousel .news-date{
   font-size: 0.55rem;
   }

    /* Adjust card title font size */
    news-carousel .card-title {
        font-size: 0.9rem;
    }

    /* Maintain game logo size */
    news-carousel .newsItemSingle .img-game-logo {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) { /* Large Devices (Desktops) */
    /* Adjust container padding */
    

    /* Adjust card body padding and height */
    news-carousel .card-body {
        padding: 22px;
        height: 120px;
    }



    /* Adjust card title font size */
    news-carousel .card-title {
        font-size: 1rem;
    }

    /* Adjust game logo size */
    news-carousel .newsItemSingle .img-game-logo {
        width: 45px;
        height: 45px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399.98px) { /* Extra Large Devices (Large Desktops) */
    /* Adjust container padding */
    
    /* Adjust card body padding and height */
    news-carousel .card-body {
        padding: 10px;
        height: 100px;
    }


   news-carousel .news-date{
   font-size: 0.65rem;
   }

    /* Adjust card title font size */
    news-carousel .card-title {
        font-size: 0.9rem;
    }

    /* Adjust game logo size */
    news-carousel .newsItemSingle .img-game-logo {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (min-width: 1400px) { /* Extra Extra Large Devices (Very Large Screens) */
    /* Adjust container padding */
   

    /* Adjust card body padding and height */
    news-carousel .card-body {
        padding: 12px;
        height: 105px;
    }

   news-carousel .news-date{
   font-size: 0.8rem;
   }


    /* Adjust card title font size */
    news-carousel .card-title {
        font-size: 1rem;
    }

    /* Adjust game logo size */
    news-carousel .newsItemSingle .img-game-logo {
        width: 30px;
        height: 30px;
    }
}
</style>



`

export class NewsCarousel extends BaseComponent {

    static gameImageMapping = {
        'lol': lol,
        'valorant': valorant,
        'fortnite': fortnite,
        'csgo': csgologo,
        'dota2': dota2,
        'r6': r6,
        'apex': apex,
        'cod': cod,
        'rocketleague': rocketleague,
        'overwatch': overwatch,
        'easports': ea,
        'pubg': pubg,
        'starcraft': startcraft,
        'hearthstone': hearthstone,
        'mlegends': mlegends,
    };

    initCallback() {
        RouterAssistant.checkForSingleContainer('news-carousel', NewsCarousel.initiator);
        document.querySelectorAll('.newsTitle').forEach((el) => {
            el.innerHTML = I18n.translateString('newsTranslations.newsTitle');
        });
        document.querySelector('.show-more-news-button').insertAdjacentHTML('beforeend', I18n.translateString('newsTranslations.newsSeeAll'));
        // Get the container
        // const container = document.getElementById('NewsCarouselItemsContainer');
        //
        // if (container) {
        // 	const scrollSpeed = 1.5;
        // 	const touchpadThreshold = 60;
        //
        // 	container.addEventListener('wheel', (event) => {
        // 		event.preventDefault();
        //
        // 		if (Math.abs(event.deltaY) < touchpadThreshold) {
        // 			// console.debug('Scrolling with touchpad');
        // 			container.style.scrollBehavior = 'auto';
        // 		} else {
        // 			// console.debug('Scrolling with mouse wheel');
        // 			container.style.scrollBehavior = 'smooth';
        // 		}
        //
        // 		container.scrollLeft += event.deltaY * scrollSpeed;
        // 	});
        // }
    }

    static initiator() {
        document.head.insertAdjacentHTML("beforeend", css);
        AuthorsComponent.getAuthors('fromNewsCarousel', () => {
            console.debug('Authors loaded from news carousel');
            NewsCarousel.getNews(() => {
                NewsCarousel.populateNewsCarousel(GameNavbar.getCurrentlyActivatedGameIfAny());
                if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                    document.getElementById('noNewsPlaceholder').innerHTML = I18n.translateString('newsTranslations.noRecentNewsMsg', { newsGame: document.querySelector('.gameNameActive').innerText });
                } else {
                    document.getElementById('noNewsPlaceholder').innerHTML = I18n.translateString('newsTranslations.noRecentNewsMsg', { newsGame: '' });
                }
            });
            GameNavbar.subscribeToGameFilterChanges('fromNewsCarousel', (activeGameFilterId) => {
                if (document.querySelectorAll('news-carousel').length !== 0) {
                    NewsCarousel.populateNewsCarousel(activeGameFilterId);
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        document.getElementById('noNewsPlaceholder').innerHTML = I18n.translateString('newsTranslations.noRecentNewsMsg', { newsGame: document.querySelector('.gameNameActive').innerText });
                    } else {
                        document.getElementById('noNewsPlaceholder').innerHTML = I18n.translateString('newsTranslations.noRecentNewsMsg', { newsGame: '' });
                    }
                }
            });
        });
    }

    static redirectToNewsPost(postId) {
        RouterAssistant.redirect('s_news', {
            newsPostId: postId
        })
    }

    static populateNewsCarousel(activeGameFilterId) {
        console.debug(activeGameFilterId);
        if (document.querySelectorAll('news-carousel').length !== 0) {
            document.getElementById('noNewsPlaceholder').style.display = 'none';
            const container = document.getElementById('NewsCarouselItemsContainer');
            document.getElementById('placeholder-news-carousel').style.display = "none"; // Hide the placeholder news

            container.innerHTML = '';
            let count = 0; // Initialize counter

            for (const [NewsPostId, NewsPostData] of Object.entries(window.savedNews)) {
                // Check if the active game filter is applied
                if (activeGameFilterId !== null && activeGameFilterId !== 'null' && NewsPostData.game_tag !== activeGameFilterId) continue;

                // Check if the news post is public and not a page
                if (NewsPostData.public_draft !== 'Public' || NewsPostData.is_page) continue;

                // Limit to 6 articles
                if (count >= 6) break; // Exit the loop after 6 articles

                const greeklishUrl = NewsPostData.public_url_greeklish;
                const writtenAtTimestamp = NewsPostData.writtenAt;
                const writtenAtDate = new Date(writtenAtTimestamp.seconds * 1000 + writtenAtTimestamp.nanoseconds / 1000000);
                const formattedDate = writtenAtDate.toLocaleString(I18n.getUsersPreferredLanguage(), {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });
                // Assign the author's temporary data
                Object.entries(window.savedAuthors).forEach(([authorName, authorData]) => {
                    if (authorName.toLowerCase() === NewsPostData.linkedAuthor.toLowerCase()) {
                        NewsPostData.tempAuthor = authorName;
                        NewsPostData.tempAuthorPhoto = authorData.photo;
                    }
                });

                container.insertAdjacentHTML('beforeend', /*html*/`
	<article class="col-6 col-lg-6 col-xl-4">
    <a href="${window.location.origin}/s_news?article=${greeklishUrl}" 
       class="newsItemSingle card card-full hover-a mb-4" 
       data-newsid="${NewsPostId}" 
       data-newsgameid="${NewsPostData.game_tag}">
       
        <!-- Game Logo -->
        <img class="img-game-logo" 
             src="${NewsCarousel.gameImageMapping[NewsPostData.game_tag]}" 
             alt="Game Logo">

        <!-- Image Wrapper -->
        <div class="ratio_230-129 image-wrapper">
            <img width="230" height="129" 
                 src="${NewsPostData.article_photo}" 
                 class="img-fluid lazy" 
                 alt="${NewsPostData.titles[I18n.getUsersPreferredLanguage()]}">
        </div>
        
        <!-- Card Body -->
        <div class="card-body">
            <div class="card-text mb-2 text-muted small">
                <span class="fw-bold d-none d-sm-inline-flex me-1">
                    <div rel="author" class="author-link">
                        ${NewsPostData.tempAuthor ? NewsPostData.tempAuthor : 'Unknown'} 
                        <time class="news-date ms-1" datetime="${formattedDate}">
                            ${formattedDate}
                        </time>
                    </div>
                </span>
            </div>
            <h2 class="card-title h6">
                ${NewsPostData.titles[I18n.getUsersPreferredLanguage()]}
            </h2>
        </div>
    </a>
</article>
     `); // Increment the counter after inserting an article
                count++;
                // Add event listeners to the newly inserted article
                const insertedElement = document.querySelector(`[data-newsid="${NewsPostId}"]`);
                const articleImage = insertedElement.querySelector('.image-wrapper img'); // Corrected selector
                insertedElement.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                    RouterAssistant.redirect('s_news', {
                        article: greeklishUrl
                    });
                }));
                insertedElement.addEventListener('mouseenter', (evt) => {
                    articleImage.classList.add('zoom');
                    articleImage.classList.remove('unzoom');
                }, false);
                insertedElement.addEventListener('mouseleave', (evt) => {
                    articleImage.classList.remove('zoom');
                    articleImage.classList.add('unzoom');
                }, false);
            }

            // Display the placeholder if no articles are found
            if (document.querySelectorAll('.newsItemSingle').length === 0) {
                document.getElementById('noNewsPlaceholder').style.display = '';
            }
        }
        // console.log(window.savedNews)
        NewsCarousel.redirectToNews();
    }

    static getNews(callback) {
        if (window.savedNews === undefined) {
            FirebaseAssistant.getNews(NewsThatCameBack => {
                const tempObject = {}
                NewsThatCameBack.forEach(NewsEntity => {
                    tempObject[NewsEntity.id] = NewsEntity.data();
                });
                window.savedNews = tempObject
                callback()
            });
        } else {
            callback()
        }
    }

    static redirectToNews() {
        const showMoreButton = document.querySelector('.show-more-news-button');
        if (showMoreButton) {
            showMoreButton.addEventListener('click', function (evt) {
                RouterAssistant.redirect("news");
            })
        }
    }
}


NewsCarousel.template = /*html*/`

    

<div class="ht-component container mt-4">
        <div class="ht-section-header d-flex justify-content-between align-items-center mb-4">
            <h2 class="ht-section-title newsTitle"></h2>
            <button class="btn btn-outline-secondary btn-sm ht-btn-back show-more-news-button"  aria-label="See All">
                <i class="bi bi-arrow-left"></i>
            </button>
        </div>
        <!-- placeholder Start -->
<div id="placeholder-news-carousel">
   <div class="row flex-nowrap overflow-hidden mt-0 mt-sm-0 mt-md-1 mt-lg-2 mt-xl-2 mt-xxl-2   mx-1 mx-sm-1 mx-md-1 mx-lg-1 mx-xl-1 mx-xxl-1 mx-1 w-100" style="height: 15rem;" >
                      <div class="col-xxl-5 col-xl-5 col-md-6 col-sm-5 col-5  placeholder-wave color-dark rounded d-flex align-items-end">
                      <span class="placeholder col-12 rounded mb-2"></span>
                       </div>
                        <div class="col-xxl-5 col-xl-5 col-md-6 col-sm-5 col-5 mx-2 placeholder-wave color-dark rounded d-flex align-items-end">
                        <span class="placeholder col-12 rounded mb-2"></span>
                         </div>
                 			 <div class="col-xxl-5 col-xl-5 col-md-6 col-sm-5 col-5  placeholder-wave color-dark rounded d-flex align-items-end">
                 			 <span class="placeholder col-12 rounded mb-2"></span>
                 			  </div>
                  </div>
</div>
      <!-- placeholder end -->       
    <div id="NewsCarouselItemsContainer" class="row">
    </div>
    <div id="noNewsPlaceholder" class="my-5" style="display:none; text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
      </div>
 

</div>
`;

NewsCarousel.reg('news-carousel'); // components should always be 2 words seperated with a dash

import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";

const noAuthorImg = require('../postmatches-component/assets/author.webp');



const css = `
<style>
authors-component .article-box:hover {
  cursor: pointer;
}

authors-component .ratio {
  position: relative;
  overflow: hidden;
}

authors-component .zoom {
  transition: transform 0.3s ease-in-out;
}

authors-component .zoom {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}
authors-component .unzoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
authors-component .img-info {
  position: relative;
  margin: 0;
  border-radius: 0;
  padding: 0;
}

authors-component .img-info p {
  margin: .5rem;
}

authors-component .text-cut {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.social-icons a {
  color: white;
  margin-left: 10px;
}
.carousel-control-prev, .carousel-control-next {
  width: 5%;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  filter: unset !important;
}

@media only screen and (max-width: 600px) {
authors-component .author-photo-container{
background-color: #161b22 !important;
}
authors-component .img-circle-container-author-photo{

width: 20%;
height: auto;
}
authors-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}
authors-component .img-circle-container-game-logo {
    width: 0.7rem;
    height: 0.7rem;
  }
  authors-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  authors-component .card-body {
    height: 50px !important;
  }
  authors-component .carousel-item .article-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: white;
    
  }
  authors-component .text-resize-authors-name {
    font-size: 0.8rem;
    font-weight: 800;
    color: #ffffff;
  }
  authors-component .text-resize-title-article {
    font-size: 0.6rem;
    font-weight: 500;
    
  }
  authors-component .text-resize-page-title {
    font-weight: 600;
    font-size: 1rem;
    color: #fff;
    text-align: center;
  }
}

@media only screen and (min-width: 600px) {
authors-component .author-photo-container{
background-color: #161b22 !important;
}
authors-component .img-circle-container-author-photo{

width: 20%;
height: auto;
}
authors-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}
 authors-component .img-circle-container-game-logo {
    width: 1rem;
    height: 1rem;
  }
  authors-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  authors-component .card-body {
    height: 70px !important;
  }
  authors-component .carousel-item .article-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
  }
  authors-component .text-resize-authors-name {
    font-size: 1rem;
    font-weight: 800;
    color: #ffffff;
  }
  authors-component .text-resize-title-article {
    font-size: 0.7rem;
    font-weight: 500;
  }
  authors-component .text-resize-page-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }
  authors-component .carousel-item .article-box {
    height: 250px;
  }
}

@media only screen and (min-width: 768px) {
authors-component .author-photo-container{
background-color: #161b22 !important;
}
authors-component .img-circle-container-author-photo{

width: 50%;
height: auto;
}
authors-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}
authors-component .img-circle-container-game-logo {
    width: 1rem;
    height: 1rem;
  }
  authors-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  authors-component .card-body {
    height: 70px !important;
  }
  authors-component .carousel-item .article-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
  }
  authors-component .text-resize-authors-name {
    font-size: 1rem;
    font-weight: 800;
    color: #ffffff;
  }
  authors-component .text-resize-title-article {
    font-size: 1rem;
    font-weight: 600;
  }
  authors-component .text-resize-page-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }
  authors-component .carousel-item .article-box {
    height: 250px;
  }
}

@media only screen and (min-width: 992px) {
authors-component .author-photo-container{
background-color: transparent !important;
}
authors-component .img-circle-container-author-photo{

width:100%;
height: auto;
}
authors-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}

authors-component .img-circle-container-game-logo {
    width: 1rem;
    height: 1rem;
  }
  authors-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  authors-component .text-resize-authors-name {
    font-size: 1rem;
    font-weight: 800;
    color: #ffffff;
  }
  authors-component .text-resize-title-article {
    font-size: 0.7rem;
    font-weight: 600;
    color: #ffffff;
  }
  authors-component .carousel-item .article-box {
    height: 200px;
  }
}

@media (min-width: 1200px) {
authors-component .author-photo-container{
background-color: transparent !important;
}
authors-component .img-circle-container-author-photo{

width:100%;
height: auto;
}
authors-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}

  authors-component .img-circle-container-game-logo {
    width: 1rem;
    height: 1rem;
  }
  authors-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  authors-component .card-body {
    height: 70px !important;
  }
  authors-component .carousel-item .article-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
  }
  authors-component .text-resize-authors-name {
    font-size: 1rem;
    font-weight: 800;
    color: #ffffff;
  }
  authors-component .text-resize-title-article {
    font-size: 1rem;
    font-weight: 600;
  }
  authors-component .text-resize-page-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }
  authors-component .carousel-item .article-box {
    height: 250px;
  }
}
</style>
`

export class AuthorsComponent extends BaseComponent {

  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css);
    RouterAssistant.checkForSingleContainer('authors-component', AuthorsComponent.initiator)
    document.querySelector('.titleAuthors').innerText = I18n.translateString("authorsTranslations.title");
  }

  static getAuthors(callbackName, callback) {
    if (window.getAuthorsEventListeners === undefined) {
      window.getAuthorsEventListeners = {}
      window.getAuthorsEventListeners[callbackName] = callback
      FirebaseAssistant.getAllAuthors(querySnapshot => {
        console.debug('running initial firebase getAllAuthors() query snapshot')
        querySnapshot.forEach(single => {
          const data = single.data();
          if (window.savedAuthors === undefined) {
            window.savedAuthors = { [data.name]: data }
          } else {
            window.savedAuthors[data.name] = data; // see here
          }
        });
        Object.keys(window.getAuthorsEventListeners).forEach(eventListenerName => {
          window.getAuthorsEventListeners[eventListenerName]()
        })
      });
    } else if (window.savedAuthors === undefined) {
      setTimeout(() => {
        AuthorsComponent.getAuthors(callbackName, callback)
      }, 50)
    } else {
      window.getAuthorsEventListeners[callbackName] = callback
      callback()
    }
  }

  static initiator() {
    if (window.savedNews === undefined) {
      NewsCarousel.getNews(() => {
        console.debug('Got the news cause we didnt have them beforehand');
      });
    }
    AuthorsComponent.getAuthors('fromAuthorsComponent', () => {
      if (document.querySelectorAll('#authors-container').length !== 0) {
        console.debug('I am running the authors component call back!');

        AuthorsComponent.updateAuthors();
      } else {
        console.debug('The authors component call back is supposed to run but I am not on the correct page!!!')
      }
    });

  }

  static updateAuthors() {
    const authorsContainer = document.getElementById('authors-container');
    authorsContainer.innerHTML = '';
    let html = '';
    Object.keys(window.savedAuthors).forEach(entry => {
      let author = window.savedAuthors[entry];
      const authorName = author.name;
      const authorBio = author.bio;
      const authorFullName = author.fullName;
      const authorSocials = author.socials;
      const authorPhoto = (author.photo === "" || author.photo === "N/A") ? noAuthorImg : author.photo;
      author.newsPosts = [];

      for (const [NewsPostId, NewsPostData] of Object.entries(window.savedNews)) {
        if (NewsPostData.public_draft !== 'Public' || NewsPostData.is_page) continue;
        if (authorName.toLowerCase() === NewsPostData.linkedAuthor.toLowerCase() && NewsPostData.public_draft === 'Public') {
          NewsPostData.tempID = NewsPostData.public_url_greeklish;
          author.newsPosts.push(NewsPostData);
        }
      }

      let newshtml = '';
      let newshtmlMobile = '';
      author.newsPosts.forEach((NewsPostData, index) => {
        if (index % 3 === 0) {
          if (index !== 0) {
            newshtml += /*html*/`</div></div>`; // Close previous carousel item
          }
          newshtml += /*html*/`<div class="carousel-item ${index === 0 ? 'active' : ''}"><div class="row">`; // Start new carousel item
        }
        newshtml += /*html*/`
          <div class="col-lg-4 col-md-4 col-6  ">
            <div class="article-box" data-authornewsid="${NewsPostData.tempID}" data-newsgameid="${NewsPostData.game_tag}">
                <div class="">

                    <div class="ratio ratio-16x9 position-relative">
                        <img class="card-img-top unzoom img-fluid rounded-top" src="${NewsPostData.article_photo}" alt="Card image cap">
                        <div class="img-circle-container-game-logo d-flex align-items-center">
                            <img class="img-game-logo position-absolute img-fluid mx-auto d-block" src="${NewsCarousel.gameImageMapping[NewsPostData.game_tag]}" alt="gamelogo">
                        </div>  
                    </div>
                    <div class="card-body color-dark rounded-bottom caption-mobile p-1 p-sm-1 p-md-2 p-lg-2">
                        <p class="card-text text-start text-cut text-resize-title-article">${NewsPostData.titles[I18n.getUsersPreferredLanguage()]}</p>
                    </div>
                </div>
            </div>
          </div>
  `;
      });

      author.newsPosts.forEach((NewsPostData, index) => {
        if (index % 2 === 0) {
          if (index !== 0) {
            newshtmlMobile += /*html*/`</div></div>`; // Close previous carousel item
          }
          newshtmlMobile += /*html*/`<div class="carousel-item ${index === 0 ? 'active' : ''}"><div class="row">`; // Start new carousel item
        }
        newshtmlMobile += /*html*/`
        <div class="col-lg-4 col-md-4 col-6 ">
            <div class="article-box" data-authornewsid="${NewsPostData.tempID}" data-newsgameid="${NewsPostData.game_tag}">
                <div class="">

                    <div class="ratio ratio-16x9 position-relative">
                        <img class="card-img-top unzoom img-fluid rounded-top" src="${NewsPostData.article_photo}" alt="Card image cap">
                        <div class="img-circle-container-game-logo d-flex align-items-center">
                            <img class="img-game-logo position-absolute img-fluid mx-auto d-block" src="${NewsCarousel.gameImageMapping[NewsPostData.game_tag]}" alt="gamelogo">
                        </div>  
                    </div>
                    <div class="card-body color-dark rounded-bottom caption-mobile p-1 p-sm-1 p-md-2 p-lg-2">
                        <p class="card-text text-start text-cut text-resize-title-article">${NewsPostData.titles[I18n.getUsersPreferredLanguage()]}</p>
                    </div>
                </div>
            </div>
        </div>
    `;
      });

      newshtml += /*html*/`</div></div></div>`;
      newshtmlMobile += /*html*/`</div></div></div>`;

      // Add carousel controls
      newshtml += /*html*/`
      <button class="carousel-control-prev position-absolute top-50 start-0 translate-middle-y" type="button" data-bs-target="#articleCarousel-${entry}" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next position-absolute top-50 end-0 translate-middle-y" type="button" data-bs-target="#articleCarousel-${entry}" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
      </button>
  `;

      // Add carousel controls
      newshtmlMobile += /*html*/`
      <button class="carousel-control-prev position-absolute top-50 start-0 translate-middle-y" type="button" data-bs-target="#articleCarouselM-${entry}" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next position-absolute top-50 end-0 translate-middle-y" type="button" data-bs-target="#articleCarouselM-${entry}" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
      </button>
  `;
      html += /*html*/ `
      
          <div class="author-card row w-100 mt-5 color-lightdark rounded">
              <div class="col-12 d-inline-flex justify-content-start align-items-center w-100 p-0 my-3">
                  <h5 class="mb-0 ms-3 text-resize-authors-name">${authorFullName}(${authorName})</h5>
                  <div class="social-icons ms-2">
                      ${!authorSocials.instagram || authorSocials.instagram === "N/A" ? '' : `<a href="${authorSocials.instagram}" target="_blank" rel="noopener noreferrer"><i class="bi bi-instagram"></i></a>`}
                      ${!authorSocials.discord || authorSocials.discord === "N/A" ? '' : `<a href="${authorSocials.discord}" target="_blank" rel="noopener noreferrer"><i class="bi bi-discord"></i></a>`}
                      ${!authorSocials.twitter || authorSocials.twitter === "N/A" ? '' : `<a href="${authorSocials.twitter}" target="_blank" rel="noopener noreferrer"><i class="bi bi-twitter"></i></a>`}
                      ${!authorSocials.facebook || authorSocials.facebook === "N/A" ? '' : `<a href="${authorSocials.facebook}" target="_blank" rel="noopener noreferrer"><i class="bi bi-facebook"></i></a>`}
                  </div>
              </div>
              <div class="col-lg-2 col-md-12 col-12 d-flex flex-column align-items-center justify-content-end author-photo-container p-0">
                <div class="img-circle-container-author-photo d-flex align-items-center">
                  <img src="${authorPhoto}" alt="Author Photo" class="img-fluid mx-auto d-block">
                  </div>
              </div>
                          <div class="col-lg-10 col-md-12 col-12 pb-1 p-1 d-none d-md-block rounded-bottom">
                <div class="position-relative">
                    <div id="articleCarousel-${entry}" class="carousel slide mt-3" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            ${newshtml}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 col-md-12 col-12 pb-1 p-1 d-block color-lightdark w-100 d-block d-md-none rounded-bottom ">
                <div class="position-relative">
                    <div id="articleCarouselM-${entry}" class="carousel slide mt-3" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            ${newshtmlMobile}
                        </div>
                    </div>
                </div>
            </div>
          </div>
      
  `;
    });
    document.querySelector('#authors-container').innerHTML = html;
    // Add event listeners to all elements with the data-newsid attribute
    document.querySelectorAll('[data-authornewsid]').forEach(insertedElement => {
      const articleImage = insertedElement.querySelector('.card-img-top'); // Target the specific image
      insertedElement.addEventListener('click', function () {
        RouterAssistant.redirect('s_news', {
          article: insertedElement.getAttribute('data-authornewsid')
        });
      });
      insertedElement.addEventListener('mouseenter', (evt) => {
        articleImage.classList.add('zoom');
        articleImage.classList.remove('unzoom');
      }, false);
      insertedElement.addEventListener('mouseleave', (evt) => {
        articleImage.classList.remove('zoom');
        articleImage.classList.add('unzoom');
      }, false);
    });
  }

}

AuthorsComponent.template = /*html*/ `
<!--<div class="row" id="authors-container">-->
<!--</div>-->
<div class="container p-0 my-5">
 <div class="text-resize-page-title">
        <h1 class="titleAuthors"></h1>
        </div>
  <div class="container-fluid d-flex flex-column justify-content-center align-content-center align-items-center" id="authors-container"></div>
  </div>
</div>
`;

AuthorsComponent.reg('authors-component');

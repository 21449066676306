export const englishTranslation =
{
    "metaTags": {
        "description": "Unlock the Ultimate eSports Experience! Livestreams, Articles, Predictions, & More! Join the Discord Fun Now!"
    },
    "homePage": {
        "latestAnalyses": 'LATEST ΑΙ ANALYSES'
    },
    "gamerShowcase": {
        "showGamer": "Show Gamer's profile",
        "modalHeader": "Profile of",
    },
    "networkGuard": {
        "title": "Oh Dear!",
        "description": "Slow or no internet connection.",
    },
    "navMainMenu": {
        "Home": "Home",
        "Tournaments": "Tournaments",
        "Matches": "Matches",
        "Giveaways": "Giveaways",
        "Casinos": "Bookmakers",
        "AboutUs": "About us",
        "Streamers": "Streamers",
        "News": "News",
        "Predictions": "Predictions",
        "navDesktopTitle": "MyEsports",
    },
    "hotmatchTranslations": {
        "playerNationalities": "Players from {{countries}} compete",
        "playerAges": "The youngest player is {{Age}} years old",
        "viewers": "{{maxviewers}} watching now!",
        "alternativeTimeLineHours": "Game {{RGN}} ongoing. Started {{hourz}} hour(s) and {{minutez}} minute(s) ago",
        "alternativeTimeLineMinutes": "Game {{RGN}} ongoing. Started {{minutez}} minute(s) ago",
        "badgeHot": `<i class="me-2 bi bi-fire"></i>HOT`,
        "noLiveMsg": `<h2>Hang tight, action is just around the corner!</h2><p>Looks like the {{hotGame}} action is taking a breather.</p>`,
    },
    "matchTranslations": {
        "matchMatchUpcoming": `<span class="my-2 badge bg-primary text-resize-badge countdownBadge">{{minutes}} mins left</span>`,
        "matchMatchLive": `<span class="my-2 badge bg-danger text-resize-badge live-animation countdownBadge"><i class="me-2 bi bi-broadcast"></i>LIVE</span>`,
        "matchMatchFinished": `<span class="my-2 badge bg-secondary text-resize-badge countdownBadge">Finished</span>`,
        "matchMatchPostponed": `<span class="my-2 badge bg-warning text-resize-badge countdownBadge">Postponed</span>`,
        "matchMatchCanceled": `<span class="my-2 badge bg-warning text-resize-badge countdownBadge">Canceled</span>`,
        "matchMatchAboutTo": `<span class="my-2 badge bg-primary text-resize-badge countdownBadge">About to start</span>`,
        "homeMatchDHM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{days}}d {{hours}}h {{minutes}}m</span>`,
        "homeMatchHM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{hours}}h {{minutes}}m</span>`,
        "homeMatchM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{minutes}}m</span>`,
        "homeMatchLive": `<span class="countdownBadge"><i class="bi bi-broadcast"></i>LIVE</span>`,
        "homeMatchAboutTo": `<span class="m-0 badge bg-secondary text-resize-badge">Starting</span>`,
        "pillLive": "LIVE",
        "pillUpcoming": "UPCOMING",
        "pillFinished": "FINISHED",
        "pillPredictions": "PREDICTIONS",
        "pillAll": "ALL",
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"d></i> FAVORITES`,
        "bestofX": "Best of {{gamesNumber}}",
        "bestof1": "Best of 1",
        "bestof2": "Best of 2",
        "allGamesPlayed": "{{gamesNumber}} games",
        "firstToX": "First to {{gamesNumber}}",
        "generalDrop": "{{matchType}}",
        "showMoreMatches": "View More",
        "emptyGameFilterMessage": "Νo available {{game}} matches at the moment.",
        "emptyStateFilterMessage": "Νo available {{game}} matches with the selected filters.",
        "emptyPredictionFilterMessage": "No available {{predGame}} predictions for the selected date.",
        "emptyAnalysisFilterMessage": "No available {{analysisGame}} analyses for the selected date.",
        "searchPlaceholder": "Search for specific tournaments or teams...",
    },
    "postMatchTranslations": {
        "aiTranslationTitle": "<i class=\"bi bi-graph-up\"></i>AI-Powered Match Analysis",
        "tipTranslationTitle": "<i class=\"bi bi-lightbulb\"></i>Tips from the Experts",
        "team1players": "Players",
        "team2players": "Players",
        "ageLabel": "Age: ",
        "positionLabel": "Position: ",
        "h2hNoRecentMsg": "No recent matches between these teams",
        "matchHistory": "Match History",
        "h2hPill": "Head to Head",
        "badgeWon": "W",
        "badgeLost": "L",
        "badgeDraw": "D",
        "noVideoMessage": "No video available for this match",
        "analysis": "Our 'PlayPulse' AI is currently analysing the data of this match.  Come back in approximately 30 minutes to read the analysis!",
        "whatDoesAiThink": "AI-Powered Insights on This Match:",
        "aiAnalysis": "AI ANALYSIS",
        "betNow": "Bet Now",
        "oddsDraw": "Draw",
        "predictToWin": "to win",
        "predictDraw": "Draw",
        "predictExactScore": "Exact score",
        "predictOverUnder": "games",
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>No Recent Articles</h2>
      <p>Unfortunately, there are no {{newsGame}} recent articles.</p>`,
        "newsTitle": "ARTICLES",
        "writtenAt": "Written at",
        "footerArticle": "🔥 Enjoyed the Article? 🔥",
        "footerArticleFollow": "Don't miss out! Follow us on social media for more exciting updates!",
        "newsSeeAll": "View All",
        "seeMore": "See More...",
        "goBack": "Go back to News",
        "tournamentNews": "Tournaments",
        "rosterNews": "Rosters",
        "gameNews": "General",
    },
    "footer": {
        "about": "Welcome to MyEsports, your comprehensive destination for all eSports enthusiasts. Born from a deep love and passion for the gaming world, we've created a space where the eSports community can find everything: from live broadcasts of matches and events to predictions, analyses, and articles for all kinds of games.",
        "followUs": "Follow Us",
        "usefulLinks": "Useful Links",
        "contactUs": "Contact Us",
        "aboutUs": "About Us",
        "eula": "Terms of Use",
        "privacy": "Privacy Policy",
        "faq": "Frequently Asked Questions",
        "Authors": "Authors",
    },
    "tournamentsTranslations": {
        "noDateMsg": "Ending date TBD",
        "tier": "Tier",
        "emptyTournamentFilterMessage": "No available {{tourGame}} tournaments with the selected status filters.",
        "pillOngoing": "ONGOING",
        "pillUpcoming": "UPCOMING",
        "pillFinished": "FINISHED",
        "pillAll": "ALL",
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>Ongoing</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">Finished</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{days}} days</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{days}} day</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{hours}}h {{minutes}}m</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{minutes}}m</span>`,
        "noPrizePoolAvailable": "N/A",
        "euroPrizePool": "€",
        "usdPrizePool": "$",
        "teamsCompete": "Τeams compete",
        "hotTournamentsTitle": "HOT TOURNAMENTS",
        "hotTournamentFixedLabel": `ONGOING`,
        "noHotTournamentsMsg": `<h2>No Live Tournaments Currently</h2><p>Unfortunately, there are no {{hTgame}} live tournaments currently.</p>`,
        "noHotTournamentsButton": "View All",
        "noHotTournamentsButton2": "View All",
        "clickHotTournaments": "Click to view more information.",
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Teams Compete: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `Players Compete: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `Prizepool: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Prizepool: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Prizepool: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": "Tier:",
        "winnerPost": "Tournament Winner:",
        "startPost": "Start Date:",
        "endPost": "End Date:",
        "noDateMsgPost": "Ending date TBD",
        "ladderWins": "Wins",
        "ladderLosses": "Losses",
        "ladderTies": "Ties",
        "ladderGamesWon": "Games Won",
        "ladderGamesLost": "Games Lost",
        "ladderTieBreaker": "Tiebreakers",
        "upperBracket": "Upper Bracket",
        "lowerBracket": "Lower Bracket",
        "grandFinal": "Grand Final",
        "semiFinal": "Semi Final",
        "quarterFinal": "Quarter Final",
        "roundBrackets": "Round",
        "matchBrackets": "Match",
        "laddersMatchesTitle": "Matches",
        "week": "Week",
        "liveWeek": "LIVE",

    },
    "predictionsTranslations": {
        "predTitle": "PREDICTIONS",
        "comingSoon": "Coming soon",
        "today": "Today",
        "title": "Esports Predictions",
        "subtitle": "At MyEsports, we study each match closely to bring you the best betting tips in esports. Our experts analyze the teams, players, and strategies to help you make the best betting decisions.",
        "time": "Time",
        "date": "Date",
        "match": "Match",
        "tournament": "Tournament",
        "tipster": "Tipster",
        "authorsPrediction": "Prediction from ",
        "betButton1": "Place your bet now on ",
        "odd": "@",
        "noPredictionForThisDate": "No prediction for this date yet, come back later.",
        "statsAvgOdds": "AVG Odds",
        "statsAvgOddsAuthorPage": `AVG Odds <i class="bi bi-info-circle"></i>`,
        "statsEarn": "Earned",
        "statsEarnAuthorPage": `Earned <i class="bi bi-info-circle"></i>`,
        "statsBets": "Bets",
        "statsOpenBets": "Open Bets",
        "authStats": "Prediction Statistics",
        "articles": "Articles",
        "last10": "Recent predictions",
        "authBio": "Biography",
        "bioComing": "Biography coming soon",
        "showMorePredictions": "Show More",
        "EarnExplain": "The profits are calculated based on the predictions that have been closed. Each tipster starts from 0, with each correct prediction adding the earned odds x1 to their total profit. For example, in a correct prediction with odds of 1.80, the tipster's profit increases by 0.80. In the case of an incorrect prediction, 1.00 is subtracted from the total profit.",
        "WinrateExplain": "The win rate is calculated based on the predictions that have been closed. It is the percentage of correct predictions out of the total number of predictions made.",
        "RoiExplain": "The ROI (Return on Investment) is calculated based on the tipster's profits in relation to their total bets. Essentially, the ROI represents the tipster's net profit. For example, if they have placed 50 bets with one unit each and the ROI is 20%, then they have won 10 units.",
        "AvgOddsExplain": "The average odds are calculated based on the odds of the predictions that have been closed. It is the sum of the odds divided by the total number of predictions.",
        "plainPrediction": "Prediction",
    },
    "banners": {
        "desktopLeft": 'en_desktop_left',
        "desktopRight": 'en_desktop_right',
    },
    "streamerTranslations": {
        "cardFollowers": "Followers",
        "cardInfo": `{{user}} is {{status}} at the moment.`,
        "cardViewers": "Viewers!",
        "detailsInformation": "Streamer Information",
        "detailsName": "Full Name",
        "detailsNationality": "Nationality",
        "detailsAge": "Age",
        "detailsGame": "Games",
        "detailsRole": "Role",
        "detailsTeam": "Team",
        "detailsSignature": "Signature Hero",
        "accordionHowTo": "<strong>-How can I become a streamer on MyEsports?</strong>",
        "accordionHowToReply": `To become a streamer on MyEsports, you can apply by joining our <a href="${window.globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord server</a> and contacting our team or fill this <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">form</a>.`,
        "accordionEarn": `<strong>-What do I earn by becoming a streamer on MyEsports?</strong>`,
        "accordionEarnReply": `Being presented on MyEsports will give you the opportunity to increase your audience and grow your channel. You will also have the chance to be featured in our articles and social media.`,
        "accordionGraphics": `<strong>-Will I receive graphics for my channel?</strong>`,
        "accordionGraphicsReply": `Yes, we will provide you with graphics for your channel, as well as support and guidance on how to use them and improve your streaming experience.`,
        "accordionHowToStream": `<strong>-I am new to streaming, can I still join? How do i start?</strong>`,
        "accordionHowToStreamReply": `Of course! We welcome streamers of all levels. You can start by joining our <a href="${window.globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord server</a> and contacting our team for more information.`,
        "accordionViewers": `<strong>-How many viewers do I need to have to join?</strong>`,
        "accordionViewersReply": `We welcome streamers of all sizes. Whether you have 1 or 10,000 viewers, you are welcome to join MyEsports!`,
    },
    "authorsTranslations": {
        "title": 'Discover the MyEsports Author Team',
    },

    "bookmakersPostTranslations": {
        "odds": "Odds",
        "varietyOfEsports": "Variety of Esports",
        "liveSupport": "Live Support",
        "liveStreaming": "Live Streaming",
        "payments": "Payments",
        "fullReview": "Full Review",
        "esports": "Esports",
        "register": "BET NOW"
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": "Full Review",
        "register": "BET NOW"
    },
    "bookmakersSkinList": {
        "widgetTitle": "Skin Platforms",
        "widgetButton": "View All",
        "playNow": "Play now",
        "introSection": `<h1 class="title-section">Top Esports Betting Sites to Join in 2024: Discover the Best Esports Bookmaker Skins</h1>
            <p>
            With the explosive growth of esports, leading betting skins have risen to meet demand, offering extensive markets for popular games like Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends, and Call of Duty. These games bring a continuous stream of thrilling tournaments and leagues, providing bettors with diverse options – from straightforward match winners to game-specific bets.            </p>
            <p>
            At <a href=\"https://myesports.gg\" class=\"text-a-style\">myesports.gg</a>, we’ve carefully reviewed a range of top esports betting skin platforms to help you find the perfect skin for your betting needs.            </p>`,
        "sectionTitle": "Where to Bet on Esports: Best Esports Betting Skins & Bonuses",
        "pleaseBetResponsibly": "Please Bet Responsibly",
        "reviewMethodology": `
                <h2 class="title-section">Our Review Methodology</h2>
        <p>
        At <a class=\"text-a-style\" href=\"https://myesports.gg\">myesports.gg</a>, our mission is straightforward – to guide you in selecting the ideal bookmaker skin for your esports betting. We evaluate each skin based on ease of use, variety of esports markets, and security features to ensure your safety when depositing or withdrawing funds.
        </p>
        <p>
        We also assess licensing to verify if the bookmaker skin operates legally in your region. Only those that meet our high standards receive our seal of approval. We feature exclusive esports bonuses and promotions to further enhance your betting experience.        </p>
        <p>
        Our review criteria span multiple areas, including user-friendliness, market offerings, and payment methods. Each bookmaker skin is thoroughly evaluated across eight main categories, covering everything from design and functionality to customer service. Check our in-depth reviews to explore each bookmaker skin’s performance in these critical areas.        </p>
        `,
        "review": "Review",
        "betNow": "BET NOW",
        "noBookmakerSkinsMsg": `<h1>No skins platforms available for your region and language.</h1>`,
    },
    "bookmakersList": {
        "widgetTitle": "Βοοkmakers",
        "widgetButton": "View All",
        "playNow": "Play now",
        "introSection": `<h1 class="title-section">Top Esports Betting Sites to Join in 2024: Discover the Best Esports Bookmakers</h1>
            <p>
            With the explosive growth of esports, leading betting sites have risen to meet demand, offering extensive markets for popular games like Counter- Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends, and Call of Duty.These games bring a continuous stream of thrilling tournaments and leagues, providing bettors with diverse options – from straightforward match winners to game - specific bets.
            </p>
            <p>
            At <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, we’ve carefully reviewed a range of top esports betting platforms to help you find the perfect site for your betting needs.
            </p>`,
        "sectionTitle": "Where to Bet on Esports: Best Esports Betting Sites & Bonuses",
        "pleaseBetResponsibly": "Please Bet Responsibly",
        "reviewMethodology": `
                <h2 class="title-section">Our Review Methodology</h2>
        <p>
            At <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, our mission is straightforward – to guide you in selecting the ideal bookmaker for your esports betting. We evaluate each site based on ease of use, variety of esports markets, and security features to ensure your safety when depositing or withdrawing funds.
        </p>
        <p>
            We also assess licensing to verify if the bookmaker operates legally in your region. Only those that meet our high standards receive our seal of approval. We feature exclusive esports bonuses and promotions to further enhance your betting experience.
        </p>
        <p>
            Our review criteria span multiple areas, including user-friendliness, market offerings, and payment methods. Each bookmaker is thoroughly evaluated across eight main categories, covering everything from design and functionality to customer service. Check our in-depth reviews to explore each bookmaker’s performance in these critical areas.
        </p>
        `,
        "methodologyTitle": "Our Review Methodology",
        "methodologyParagraph1": "At <a class=\"text-a-style\" href=\"https://myesports.gg\">myesports.gg</a>, our mission is straightforward – to guide you in selecting the ideal bookmaker for your esports betting. We evaluate each site based on ease of use, variety of esports markets, and security features to ensure your safety when depositing or withdrawing funds.",
        "methodologyParagraph2": "We also assess licensing to verify if the bookmaker operates legally in your region. Only those that meet our high standards receive our seal of approval. We feature exclusive esports bonuses and promotions to further enhance your betting experience.",
        "methodologyParagraph3": "Our review criteria span multiple areas, including user-friendliness, market offerings, and payment methods. Each bookmaker is thoroughly evaluated across eight main categories, covering everything from design and functionality to customer service. Check our in-depth reviews to explore each bookmaker’s performance in these critical areas.",
        "review": "Review",
        "betNow": "BET NOW",
        "noBookmakersMsg": `<h1>No bookmakers available for your region and language.</h1>`,

    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>Oops! It looks like you're using an ad blocker.</strong></p>
        <p>You're missing out on some great content we have for you. It's not ads, but helpful features. Please consider disabling your ad blocker.</p>
        <button id="close-button">Got it!</button>`,
    },
}

import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { MatchesComponent } from "../matches-component/matches-component";
import { PostMatchesComponent } from "../postmatches-component/postmatches-component";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";
import { match } from "assert";
import { I18n } from "../../utilities/i18n/i18n";
import { doc } from "firebase/firestore";


const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fifa = require('../game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/rainbow six 150x150.png')
const roblox = require('../game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../game-navbar/assets/images/KingOfGlory 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')

const css = `
<style>




hotmatch-component .container {
  position: relative; /* This makes the positioning of child elements relative to this container */
}

hotmatch-component .game-logo {
  position: absolute; /* This will take the element out of the normal document flow */
  top: 0; /* Aligns the top edge of the element with the top edge of its nearest positioned parent */
  left: 0; /* Aligns the left edge of the element with the left edge of its nearest positioned parent */
  z-index: 10; /* This ensures that the logo stays on top of other elements */
}

/* Add this style to ensure the logo is responsive and doesn't overflow */
hotmatch-component .game-logo img {
 
  width: 1rem; /* Keeps image aspect ratio */
  height: 1rem; /* Keeps image aspect ratio */
}


hotmatch-component .game-logo-resize{
max-width: 1.5rem;
max-height: 1.5rem;
}
hotmatch-component .img-circle-container {

   width: 4rem;  /* Adjust the width as needed */
    height: 4rem; /* Ensure the height is the same as the width for a perfect circle */
}
hotmatch-component .img-circle-container img{
    max-width: 4rem;
    max-height: 4rem;
    
    }
 hotmatch-component .circle {
  padding: 13px 20px;
  border-radius: 50%;
  background-color: #B22A23;
  color: #fff;
  max-height: 50px;
  z-index: 2;
}

hotmatch-component .how-it-works.row .col-2 {
  align-self: stretch;
}
/*.how-it-works.row .col-2::after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  border-left: 3px solid #B22A23;*/
/*  z-index: 1;*/
/*}*/
hotmatch-component .how-it-works.row .col-2.bottom::after {
  height: 50%;
  left: 50%;
  top: 50%;
}
hotmatch-component .how-it-works.row .col-2.full::after {
  height: 100%;
  left: calc(50% - 3px);
}
hotmatch-component .how-it-works.row .col-2.top::after {
  height: 50%;
  left: 50%;
  top: 0;
}


hotmatch-component .timeline div {
  padding: 0;
  height: 40px;
}
hotmatch-component .timeline hr {
  border-top: 3px solid #B22A23;
  margin: 0;
  top: 17px;
  position: relative;
}
hotmatch-component .timeline .col-2 {
  display: flex;
  overflow: hidden;
}
hotmatch-component .timeline .corner {
  border: 3px solid #B22A23;
  width: 100%;
  position: relative;
  border-radius: 15px;
}
hotmatch-component .timeline .top-right {
  left: 50%;
  top: -50%;
}
hotmatch-component .timeline .left-bottom {
  left: -50%;
  top: calc(50% - 3px);
}
hotmatch-component .timeline .top-left {
  left: -50%;
  top: -50%;
}
hotmatch-component .timeline .right-bottom {
  left: 50%;
  top: calc(50% - 3px);
}

 hotmatch-component .text-resize{
 font-size: 0.8rem;
 }
 hotmatch-component .text-resize-bigger{
 font-size: 0.8rem;
 }
.img-resize{
width: 100px;
height: 100px;
object-fit: cover;
}
@media (min-width: 1200px) {
    .responsiveHotText {
        font-size: 1.1rem;

    }
    .score-resize {
        font-size: 1.5rem;
}
    .responsiveHotImg {
      max-width: 4rem !important;
      max-height: 4rem !important;
}
 hotmatch-component .card-text, hotmatch-component i{
 font-size: 0.7rem;
 margin-bottom:1rem;
 }
 hotmatch-component .card{
 background-color: transparent;
 border: none;
 }
.team-hot-resize{
font-size: 1.3rem;
margin:0;
}
}
@media (max-width:  776px) {
 hotmatch-component .text-resize{
 font-size: 0.4rem;
 }
    .responsiveHot {
        font-size: 1.1rem;
}
      .score-resize {
        margin-top: 0;
        font-size: 0.5rem;
}
 .responsiveHotImg {
      max-width: 25% !important;
      max-height: 25% !important;
}
.team-hot-resize{
font-size: 0.6rem;
font-weight: 600;
margin:0;
}

hotmatch-component .img-circle-container{
    width: 1.5rem;
    height: 1.5rem;
    
    }
    
hotmatch-component .img-circle-container img{
    max-width: 1.5rem;
    max-height: 1.5rem;
    
    }
    
    hotmatch-component .game-logo-resize{
max-width: 0.7rem;
max-height: 0.7rem;
}
}

@media only screen and (max-width: 600px) {
hotmatch-component .img-circle-container-tournament-logo {
 width: 1.1rem;
 height: 1.1rem;
 }
hotmatch-component  .img-circle-container-tournament-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }

}

@media only screen and (min-width: 600px) {
hotmatch-component .img-circle-container-tournament-logo {
 width: 1rem;
 height: 1rem;
 }
hotmatch-component  .img-circle-container-tournament-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }

}

@media only screen and (min-width: 768px) {
hotmatch-component .img-circle-container-tournament-logo {
 width: 1.3rem;
 height: 1.3rem;
 }
hotmatch-component  .img-circle-container-tournament-logo img{
 max-width: 1.2rem;
 max-height: 1.2rem;
 }

}

@media only screen and (min-width: 992px) {
hotmatch-component .img-circle-container-tournament-logo {
 width: 1.4rem;
 height: 1.4rem;
 }
hotmatch-component  .img-circle-container-tournament-logo img{
 max-width: 1.3rem;
 max-height: 1.3rem;
 }

}

@media (min-width: 1200px) { 
#timelineInnerHome{
        transform:translate(-50%, -50%) scale(0.45) !important;
                min-width: 19rem;

    }
hotmatch-component .img-circle-container-tournament-logo {
 width: 1.5rem;
 height: 1.5rem;
 }
hotmatch-component  .img-circle-container-tournament-logo img{
 max-width: 1.4rem;
 max-height: 1.4rem;
 }
}

@media (min-width: 1300px) {
#timelineInnerHome{
        transform:translate(-50%, -50%) scale(0.50) !important;
                min-width: 20rem;
    }
 }
@media (min-width: 1400px) { 
#timelineInnerHome{
        transform:translate(-50%, -50%) scale(0.55) !important;
                min-width: 22rem;
    }
 }
@media (min-width: 1500px) { 
#timelineInnerHome{
        transform:translate(-50%, -50%) scale(0.60) !important;
                min-width: 22rem;

    }
 }
     @media (min-width: 1580px) {
#timelineInnerHome{
        transform:translate(-50%, -50%) scale(0.65) !important;
                min-width: 20rem;
    }
 }
 @media (min-width: 1700px) {
#timelineInnerHome{
        transform:translate(-50%, -50%) scale(0.7) !important;
                min-width: 22rem;
    }
 }
 @media (min-width: 1920px) { 
#timelineInnerHome{
        transform:translate(-50%, -50%) scale(0.8) !important;
                min-width: unset;
    }
 }
    </style>
`

export class HotmatchComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('hotmatch-component', HotmatchComponent.initiator);
    document.head.insertAdjacentHTML("beforeend", css);
    window.addEventListener('resize', HotmatchComponent.resizetoTwitchHeight);

  }

  static initiator() {

    MatchesComponent.getMatches('fromHotmatchComponent', () => {
      // document.getElementById('placeholder').style.display = "none";
      if (document.querySelectorAll('#hotmatch-component').length !== 0) {
        console.debug('I am running the hotmatch component call back!')
        HotmatchComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny())
      } else {
        console.debug('The hotmatch component call back is supposed to run but I am not on the correct page!!!')
      }
    });
    GameNavbar.subscribeToGameFilterChanges('fromHotmatchComponent', (eventId) => {
      if (document.querySelectorAll('hotmatch-component').length !== 0) // if our component exists on the page
        HotmatchComponent.updateMatches(eventId);
    })
  }

  static updateMatches(gameId) {
    console.debug('running update matches of hotmatch component')

    let topStream = '';
    let topStreamViewers = -1
    let topStreamViewers_aggregate = {}
    let currentLanguage = '';
    let foundEnStream = false;
    let matchDictionary = {}
    let othermatchDictionary = {}
    for (let matchCode of Object.keys(window.savedMatches)) {
      const data = window.savedMatches[matchCode]
      const currentGameId = data.videogame.id;
      // Create a mapping of gameIds to their corresponding currentGameId
      const gameIdMapping = {
        'csgo': 3,
        'dota2': 4,
        'lol': 1,
        'easports': 25,
        'valorant': 26,
        'cod': 23,
        'overwatch': 14,
        'pubg': 20,
        'r6': 24,
        'rocketleague': 22,
        'starcraft': [29, 30],
        'hearthstone': 999,
        'fortnite': 999,
        'fifa': 999,
        'roblox': 999,
        'minecraft': 999,
        'wow': 999,
        'apex': 999,
        'kingofglory': 27,
        'mlegends': 34,

      };

      // If a gameId is provided and it doesn't match the current game's id, skip this match
      if (gameId && gameIdMapping[gameId] !== undefined) {
        if (Array.isArray(gameIdMapping[gameId])) {
          if (!gameIdMapping[gameId].includes(currentGameId)) {
            continue; // This will now work
          }
        } else {
          if (gameIdMapping[gameId] !== currentGameId) {
            continue; // This will now work
          }
        }
      }


      if (!currentGameId || data.status !== 'running' || data.streams_list.length === 0) continue;
      // console.debug(`looking at match ${matchCode}`)
      let twitchStreams = []
      let temp_topStreamViewers_aggregate = 0
      for (let i = 0; i < data.streams_list.length; i++) {
        let stream = data.streams_list[i];
        let streamId = stream.raw_url.split('/').pop();
        // console.debug(stream.raw_url);
        if (stream.viewer_count)
          temp_topStreamViewers_aggregate += stream.viewer_count;
        if ((stream.official === true) && (stream.raw_url !== undefined && stream.raw_url !== null) && stream.raw_url.includes('twitch')) {
          matchDictionary[streamId] = {
            matchCode: matchCode,
            official: true
          }
          twitchStreams.push(streamId)
          // console.debug('I am an twitch stream');
          // console.debug(stream.viewer_count);
          if ((stream.language === 'en' || stream.language === 'uk') && stream.viewer_count) {
            if (currentLanguage !== 'en') {
              topStreamViewers = stream.viewer_count;
              topStream = stream.raw_url;
              currentLanguage = stream.language;
            } else if (currentLanguage === 'en' && stream.viewer_count > topStreamViewers) {
              topStreamViewers = stream.viewer_count;
              topStream = stream.raw_url;
              currentLanguage = stream.language;
            }
            foundEnStream = true;
          } else if (foundEnStream === false && stream.viewer_count && (stream.viewer_count > topStreamViewers)) {
            topStreamViewers = stream.viewer_count;
            topStream = stream.raw_url;
            currentLanguage = stream.language;
          }
          else if (foundEnStream === false && (!stream.viewer_count && topStreamViewers === -1)) {
            topStreamViewers = 1;
            topStream = stream.raw_url;
          }
        } else if ((stream.raw_url !== undefined && stream.raw_url !== null) && stream.raw_url.includes('twitch')) {
          if (!matchDictionary[streamId] || !matchDictionary[streamId].official) {
            matchDictionary[streamId] = {
              matchCode: matchCode,
              official: false
            }
          }
          twitchStreams.push(streamId)
          // console.debug('I am an twitch stream ');
          if (stream.language === 'en' && stream.viewer_count) {
            if (currentLanguage !== 'en') {
              topStreamViewers = stream.viewer_count;
              topStream = stream.raw_url;
              currentLanguage = stream.language;
            } else if (currentLanguage === 'en' && stream.viewer_count > topStreamViewers) {
              topStreamViewers = stream.viewer_count;
              topStream = stream.raw_url;
              currentLanguage = stream.language;
            }
            foundEnStream = true;
          } else if (foundEnStream === false && stream.viewer_count && (stream.viewer_count > topStreamViewers)) {
            topStreamViewers = stream.viewer_count;
            topStream = stream.raw_url;
            currentLanguage = stream.language;
          }
          else if (foundEnStream === false && (!stream.viewer_count && topStreamViewers === -1)) {
            topStreamViewers = 1;
            topStream = stream.raw_url;
          }
        }
        else if ((stream.raw_url !== undefined && stream.raw_url !== null) && (stream.raw_url.includes('youtube')) && twitchStreams.length <= 0) {
          if (stream.embed_url && stream.language === 'en') {
            othermatchDictionary[stream.embed_url] = matchCode;
          } else if (stream.embed_url && othermatchDictionary[stream.embed_url] === undefined) {
            othermatchDictionary[stream.embed_url] = matchCode;
          }
          // console.debug('I am a youtube stream ');
        }
        else if ((stream.raw_url !== undefined && stream.raw_url !== null) && (stream.raw_url.includes('afreecatv')) && twitchStreams.length <= 0) {
          if (othermatchDictionary[stream.embed_url] === undefined && stream.embed_url && stream.language === 'en') {
            othermatchDictionary[stream.embed_url] = matchCode;
          } else if (stream.embed_url && othermatchDictionary[stream.embed_url] === undefined) {
            othermatchDictionary[stream.embed_url] = matchCode;
          }
          // console.debug('I am an afreecatv stream ');
        }
        // else if ((stream.raw_url !== undefined && stream.raw_url !== null) && (stream.raw_url.includes('bilibili')) && twitchStreams.length <= 0) {
        //   if (stream.raw_url) {
        //     othermatchDictionary[stream.raw_url] = matchCode;
        //   }
        //   console.debug('I am an bilibili stream ');
        // }
      }
      topStreamViewers_aggregate[matchCode] = temp_topStreamViewers_aggregate
    }

    // console.debug(topStream)
    // console.debug(topStreamViewers)
    // console.debug(topStreamViewers_aggregate)
    // console.debug(topStream.split('/').pop())
    // console.debug(matchDictionary[topStream.split('/').pop()])

    let firstValue = Object.values(othermatchDictionary)[0];
    let alternativetopMatchembed = Object.keys(othermatchDictionary)[0];
    let theMatch = matchDictionary[topStream.split('/').pop()]?.matchCode;

    const promoStreamer = PostMatchesComponent.PromoVideo(true, theMatch);
    if (promoStreamer) {
      const { promoMatchId, userLogin, promoViewers } = promoStreamer;
      // console.debug(promoStreamer)
      // console.debug(promoMatchId)
      // console.debug(userLogin)
      theMatch = promoMatchId;
      topStream = `https://player.twitch.tv/${userLogin}`;
      if (topStreamViewers_aggregate[theMatch] === undefined) {
        topStreamViewers_aggregate[theMatch] = promoViewers;
      }
    }
    // console.debug(theMatch)
    const topMatch = theMatch ? window.savedMatches[theMatch] : undefined;
    const alternativetopMatch = window.savedMatches[firstValue]
    // console.debug(topMatch)
    // console.debug(alternativetopMatch)
    if (topMatch !== undefined) {
      MatchesComponent.removeHotMatchFromMatches(topMatch.id)
      const matchElementId = `hotmatchelementid-${topMatch.id}`
      const matchName = topMatch.opponents && topMatch.opponents[0].opponent.acronym && topMatch.opponents[1].opponent.acronym ?
        `${topMatch.opponents[0].opponent.acronym.replace(/\s/g, "_").replace(/&/g, "")}_vs_${topMatch.opponents[1].opponent.acronym.replace(/\s/g, "_").replace(/&/g, "")}`
        : topMatch.opponents && topMatch.opponents[0].opponent.name && topMatch.opponents[1].opponent.name ?
          `${topMatch.opponents[0].opponent.name.replace(/\s/g, "_").replace(/&/g, "")}_vs_${topMatch.opponents[1].opponent.name.replace(/\s/g, "_").replace(/&/g, "")}`
          : topMatch.name ? `${topMatch.name.replace(/\s/g, "_").replace(/match/gi, "").replace(/&/g, "")}` : 'unknown';
      const serieElementId = `hotserieId-${topMatch.serie_id}`

      const gameIdImg = {
        '3': csgologo,
        '4': dota2,
        '1': lol,
        '25': ea,
        '26': valorant,
        '23': cod,
        '14': overwatch,
        '20': pubg,
        '24': r6,
        '22': rocketleague,
        '29': startcraft,
        '30': startcraft,
        '27': kingofglory,
        '34': mlegends

      };
      const gameLogoHTML = `<img src="${gameIdImg[topMatch.videogame.id]}" alt="tournament logo" class="img-fluid object-fit-scale  game-logo-resize" >`;



      const tournamentlogo = topMatch.league.image_url;
      const tournamentLogoHTML = tournamentlogo ? `<img src="${tournamentlogo}" alt="tournament logo" class="img-fluid mx-auto d-block " >` : ''
      const tournamentname = `${topMatch.league.name} ${topMatch.serie.full_name}`;

      const team1name = topMatch.opponents[0].opponent.name ? topMatch.opponents[0].opponent.name : topMatch.opponents[0].opponent.acronym
      const team2name = topMatch.opponents[1].opponent.name ? topMatch.opponents[1].opponent.name : topMatch.opponents[1].opponent.acronym
      const team1logo = topMatch.opponents[0].opponent.image_url ? topMatch.opponents[0].opponent.image_url : ''
      const team2logo = topMatch.opponents[1].opponent.image_url ? topMatch.opponents[1].opponent.image_url : ''
      const matchTypeRaw = topMatch.match_type;
      const numberOfGames = topMatch.number_of_games;
      let matchTypeFinal = ``;
      if (matchTypeRaw === 'best_of' && (numberOfGames !== 2 && numberOfGames !== 1)) {
        matchTypeFinal = 'best_of_X';
      } else if (matchTypeRaw === 'best_of' && numberOfGames === 1) {
        matchTypeFinal = 'single_game';
      } else if (matchTypeRaw === 'best_of' && numberOfGames === 2) {
        matchTypeFinal = 'best_of_2';
      } else if (matchTypeRaw === 'all_games_played') {
        matchTypeFinal = 'all_games_played';
      } else if (matchTypeRaw === 'first_to') {
        matchTypeFinal = 'first_to_X';
      } else {
        matchTypeFinal = 'general_drop_to';
      }
      const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
      const matchTypefull = `${matchType} ${topMatch.number_of_games.toString()}`;


      const score = `${topMatch.results[0].score.toString()} - ${topMatch.results[1].score.toString()}`;
      const topchannelName = topStream.split('/').pop();
      const topStreamEmbed = `https://player.twitch.tv/?channel=${topchannelName}&parent=${window.location.hostname}`;

      console.debug(topStreamEmbed);
      const matchHtml = /*html*/ `
<!--
       <div class="game-logo d-inline d-sm-inline d-md-none ms-1 mt-1">
    ${gameLogoHTML}
  </div>
  -->
        <div class="container p-0 text-white my-0 my-sm-0 my-md-0 my-lg-0 my-xl-0 my-xxl-0">
  

  <a href="${window.location.origin}/s_match?match=${topMatch.id}" class="row mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0 rounded-top color-lightdark clickable" id="${matchElementId}" >
    <div class="col-12 p-0 d-flex justify-content-between color-dark rounded-top">
      <div class="text-start d-flex align-items-center p-1 clickable "  id="${serieElementId}">
                
                ${gameLogoHTML} 
                <span class="m-0 mx-1 mx-lg-2 d-flex align-items-center text-resize">${tournamentname}</span>
                  <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                ${tournamentLogoHTML}
                </div>
                </div>
      <div class="d-flex align-items-center">
        <span  id="spicyInfo_1" class="badge bg-danger text-resize d-inline d-sm-inline d-lg-none red-animation" style="margin-right: 0.3rem;margin-top: 0.3rem;"></span>
        <span class="red-animation badge text-resize m-2 d-none d-sm-none d-lg-inline badgeTranslation"></span>
      </div>
    </div>
    <div class="row color-lightdark my-0 my-sm-0 my-md-4 my-lg-4 my-xl-4 my-xxl-4 p-0 m-0">
    <div class="col-5 d-flex justify-content-end align-items-center p-0 clickable">
      <p class="team-hot-resize mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-2 mx-xxl-2 my-0   ">${team1name}</p>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
               <img src="${team1logo}" alt="" class="img-fluid mx-auto d-block ">
                </div>
     
    </div>
    <div class="col-2 d-flex justify-content-center align-items-center text-center p-0 clickable">
      <span class="score-resize m-0" id="hotScoreId">${score}</span>
    </div>
    <div class="col-5 d-flex justify-content-start align-items-center p-0 clickable ">
        <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
               <img src="${team2logo}" alt="" class="img-fluid mx-auto d-block ">
                </div>
      <p class="team-hot-resize mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-2 mx-xxl-2 my-0  ">${team2name}</p>
    </div>
    <div class="col-12 text-center d-flex h-auto justify-content-center align-items-center  p-0 m-0 m-0">
                     <span class="my-2 badge bg-secondary text-resize d-none d-sm-none d-lg-inline matchTypeFromHM">${matchTypeFinal}</span>
                     
                    </div>
</div>
  </a>
  
<div  class="row color-lightdark rounded-bottom">

    <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7 d-flex  justify-content-center my-2 ">
        <div  class="ratio ratio-16x9" >
        
            <iframe id="twitch-home"
                src="${topStreamEmbed}"
                width="100%" 
                height="100%" 
                allowfullscreen="true">
            </iframe>
        </div>
    </div>
    <div class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-5 col-xxl-5 d-none d-xl-flex  my-2 ">
                    <div id="timeline-home" class="scrollbar w-100">
  <div id="timelineInnerHome" class="container  " style="position: relative;top: 50%;left: 50%;">
<!--    <p class="pb-3  text-center mb-1 ">Did You know?</p>-->
    <!--first section-->
    <div class="row align-items-center how-it-works d-flex ">
      <div class="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold"><i class="bi bi-people-fill"></i></div>
      </div>
      <div class="col-10">
        <p class="fs-5"></p>
        <p id="spicyInfo_1" class="fs-6 text-white-50" style="margin: 0rem !important;"></p>
      </div>
    </div>
    <!--path between 1-2-->
    <div class="row timeline">
      <div class="col-2">
        <div class="corner top-right"></div>
      </div>
      <div class="col-8">
        <hr/>
      </div>
      <div class="col-2">
        <div class="corner left-bottom"></div>
      </div>
    </div>
    <!--second section-->
    <div class="row align-items-center justify-content-end how-it-works d-flex">
      <div class="col-10 ">
        <p id="spicyInfoNumberTwo" class="fs-6 text-white-50 text-end" style="margin: 0rem !important;"></p>
      </div>
      <div class="col-2 text-center full d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold"><i class="bi bi-globe-americas"></i></div>
      </div>
    </div>
    <!--path between 2-3-->
    <div id="hotMatchInfoThirdsectionPart1" class="row timeline">
      <div class="col-2">
        <div class="corner right-bottom"></div>
      </div>
      <div class="col-8">
        <hr/>
      </div>
      <div class="col-2">
        <div class="corner top-left"></div>
      </div>
    </div>
    <!--third section-->
    <div id="hotMatchInfoThirdsectionPart2" class="row align-items-center how-it-works d-flex">
      <div class="col-2 text-center top d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold"><i class="bi bi-cake2"></i></div>
      </div>
      <div class="col-10">
         <p id="spicyInfoNumberThree" class="fs-6 text-white-50" style="margin: 0rem !important;"></p>
      </div>
    </div>
   
  </div>
</div>
    </div>
</div>
`;
      if (document.getElementById('twitch-home') !== null) {
        const iframe = document.getElementById('twitch-home');
        if (iframe.src !== topStreamEmbed) {
          console.debug('I found the iframe and its different, so im updating the hotmatch component!')
          document.getElementById('hotmatch-component').innerHTML = '';
          document.querySelector('#hotmatch-component').insertAdjacentHTML('beforeend', matchHtml);
          const matchTypeElement = document.querySelector(`.matchTypeFromHM`);
          console.debug(matchTypeElement.innerHTML);
          if (matchTypeElement.innerHTML === 'all_games_played') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
          } else if (matchTypeElement.innerHTML === 'best_of_X') {
            let gamesNumber;
            if (window.translateTo === 'el') {
              gamesNumber = Math.floor(numberOfGames / 2) + 1;
            } else {
              gamesNumber = numberOfGames;
            }
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
          } else if (matchTypeElement.innerHTML === 'first_to_X') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
          } else if (matchTypeElement.innerHTML === 'best_of_2') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
          } else if (matchTypeElement.innerHTML === 'single_game') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
          } else {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
          }
        } else {
          console.debug('I am not changing the iframe src cause its the same,im just updating the score!')
          document.getElementById('hotScoreId').innerText = score;
          document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.viewers", { maxviewers: topStreamViewers_aggregate[topMatch.id] }));
        }
      }
      else {
        console.debug('I am not able to find the iframe so im creating the hotmatch for the first time')
        document.querySelector('#hotmatch-component').innerHTML = '';
        document.querySelector('#hotmatch-component').insertAdjacentHTML('beforeend', matchHtml);
        const matchTypeElement = document.querySelector(`.matchTypeFromHM`);
        console.debug(matchTypeElement.innerHTML);
        if (matchTypeElement.innerHTML === 'all_games_played') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
        } else if (matchTypeElement.innerHTML === 'best_of_X') {
          let gamesNumber;
          if (window.translateTo === 'el') {
            gamesNumber = Math.floor(numberOfGames / 2) + 1;
          } else {
            gamesNumber = numberOfGames;
          }
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
        } else if (matchTypeElement.innerHTML === 'first_to_X') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
        } else if (matchTypeElement.innerHTML === 'best_of_2') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
        } else if (matchTypeElement.innerHTML === 'single_game') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
        } else {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
        }
      }

      document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.viewers", { maxviewers: topStreamViewers_aggregate[topMatch.id] }));
      document.querySelectorAll('.badgeTranslation').forEach(el => el.innerHTML = I18n.translateString("hotmatchTranslations.badgeHot"));
      HotmatchComponent.resizetoTwitchHeight()
      HotmatchComponent.redirectToMatchPage(matchElementId, matchName);
      HotmatchComponent.redirectToSeriePage(serieElementId);
      const tournamentId = topMatch.tournament_id || topMatch.tournament.id;
      if (topMatch.opponents[0].type === 'Team' && topMatch.opponents[1].type === 'Team') {
        PostMatchesComponent.getRostersData(tournamentId.toString(), topMatch.opponents[0].opponent.id, topMatch.opponents[1].opponent.id).then((response) => {
          const teamData1 = response.rosterId1;
          const teamData2 = response.rosterId2;
          let nationalities = []
          let maxAge = 9999999
          let userLanguage = window.navigator.language.split("-")[0];
          let regionNames = new Intl.DisplayNames([userLanguage], { type: 'region' });

          teamData1.players.forEach(player => {
            if (player.age !== null && player.age < maxAge) maxAge = player.age
            if (player.nationality !== null && nationalities.indexOf(regionNames.of(player.nationality)) === -1)
              nationalities.push(regionNames.of(player.nationality))
          })
          teamData2.players.forEach(player => {
            if (player.age !== null && player.age < maxAge) maxAge = player.age
            if (player.nationality !== null && nationalities.indexOf(regionNames.of(player.nationality)) === -1)
              nationalities.push(regionNames.of(player.nationality))
          })
          document.getElementById('spicyInfoNumberTwo').innerText = I18n.translateString("hotmatchTranslations.playerNationalities", { countries: nationalities.join(', ') });
          if (maxAge !== 9999999) {
            document.getElementById('spicyInfoNumberThree').innerText = I18n.translateString("hotmatchTranslations.playerAges", { Age: maxAge });
            document.getElementById('hotMatchInfoThirdsectionPart1').style.display = ''
            document.getElementById('hotMatchInfoThirdsectionPart2').style.display = ''
          }
          else {
            document.getElementById('hotMatchInfoThirdsectionPart1').setAttribute('style', 'display: none !important');
            document.getElementById('hotMatchInfoThirdsectionPart2').setAttribute('style', 'display: none !important');
          }
        })
      } else if (topMatch.opponents[0].type === 'Player' && topMatch.opponents[1].type === 'Player') {
        let nationalities = []
        let maxAge = 9999999
        let userLanguage = window.navigator.language.split("-")[0];
        let regionNames = new Intl.DisplayNames([userLanguage], { type: 'region' });
        topMatch.opponents.forEach(player => {
          if (player.opponent.age !== null && player.opponent.age < maxAge) maxAge = player.opponent.age
          if (player.opponent.nationality !== null && nationalities.indexOf(regionNames.of(player.opponent.nationality)) === -1)
            nationalities.push(regionNames.of(player.opponent.nationality))
        })
        let beginAt = new Date(topMatch.begin_at);
        let now = new Date();
        let diffMs = now - beginAt; // difference in milliseconds
        let diffSecs = Math.floor(diffMs / 1000); // convert to seconds
        let diffMins = Math.floor(diffSecs / 60); // convert to minutes
        let diffHrs = Math.floor(diffMins / 60); // convert to hours
        let runningGameNumber = topMatch.games.findIndex(game => game.status === "running") + 1;
        if (runningGameNumber === 0) { runningGameNumber = topMatch.games.findIndex(game => game.status === "not_started") + 1; }

        if (diffHrs > 0) {
          // if it's been more than an hour, display hours and minutes
          let remainingMins = diffMins % 60;
          document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineHours", { RGN: runningGameNumber, hourz: diffHrs, minutez: remainingMins }));
        } else {
          let remainingMins = diffMins % 60;
          console.debug('I am running the else statement')
          document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineMinutes", { RGN: runningGameNumber, minutez: remainingMins }));
        }
        document.getElementById('spicyInfoNumberTwo').innerText = I18n.translateString("hotmatchTranslations.playerNationalities", { countries: nationalities.join(', ') });
        if (maxAge !== 9999999) {
          document.getElementById('spicyInfoNumberThree').innerText = I18n.translateString("hotmatchTranslations.playerAges", { Age: maxAge }); document.getElementById('hotMatchInfoThirdsectionPart1').style.display = ''
          document.getElementById('hotMatchInfoThirdsectionPart2').style.display = ''
        }
        else {
          document.getElementById('hotMatchInfoThirdsectionPart1').setAttribute('style', 'display: none !important');
          document.getElementById('hotMatchInfoThirdsectionPart2').setAttribute('style', 'display: none !important');
        }
      }
    } else if (alternativetopMatch !== undefined && topMatch === undefined) {
      MatchesComponent.removeHotMatchFromMatches(alternativetopMatch.id)
      const matchElementId = `hotmatchelementid-${alternativetopMatch.id}`
      const serieElementId = `hotserieId-${alternativetopMatch.serie_id}`
      const matchName = alternativetopMatch.opponents && alternativetopMatch.opponents[0].opponent.acronym && alternativetopMatch.opponents[1].opponent.acronym ?
        `${alternativetopMatch.opponents[0].opponent.acronym.replace(/\s/g, "_").replace(/&/g, "")}_vs_${alternativetopMatch.opponents[1].opponent.acronym.replace(/\s/g, "_").replace(/&/g, "")}`
        : alternativetopMatch.opponents && alternativetopMatch.opponents[0].opponent.name && alternativetopMatch.opponents[1].opponent.name ?
          `${alternativetopMatch.opponents[0].opponent.name.replace(/\s/g, "_").replace(/&/g, "")}_vs_${alternativetopMatch.opponents[1].opponent.name.replace(/\s/g, "_").replace(/&/g, "")}`
          : alternativetopMatch.name ? `${alternativetopMatch.name.replace(/\s/g, "_").replace(/match/gi, "").replace(/&/g, "")}` : 'unknown';

      const gameIdImg = {
        '3': csgologo,
        '4': dota2,
        '1': lol,
        '25': ea,
        '26': valorant,
        '23': cod,
        '14': overwatch,
        '20': pubg,
        '24': r6,
        '22': rocketleague,
        '29': startcraft,
        '30': startcraft,
        '27': kingofglory,
        '34': mlegends

      };
      const gameLogoHTML = `<img src="${gameIdImg[alternativetopMatch.videogame.id]}" alt="tournament logo" class="img-fluid object-fit-scale  " style="max-width: 1.5rem; max-height: 1.5rem;">`;



      const tournamentlogo = alternativetopMatch.league.image_url;
      const tournamentLogoHTML = tournamentlogo ? `<img src="${tournamentlogo}" alt="tournament logo" class="img-fluid object-fit-scale ms-2 " style="max-width: 1.5rem;max-height: 1.5rem;">` : '';
      const tournamentname = `${alternativetopMatch.league.name} ${alternativetopMatch.serie.full_name}`;

      const team1name = alternativetopMatch.opponents[0].opponent.name ? alternativetopMatch.opponents[0].opponent.name : alternativetopMatch.opponents[0].opponent.acronym
      const team2name = alternativetopMatch.opponents[1].opponent.name ? alternativetopMatch.opponents[1].opponent.name : alternativetopMatch.opponents[1].opponent.acronym
      const team1logo = alternativetopMatch.opponents[0].opponent.image_url ? alternativetopMatch.opponents[0].opponent.image_url : ''
      const team2logo = alternativetopMatch.opponents[1].opponent.image_url ? alternativetopMatch.opponents[1].opponent.image_url : ''

      const score = `${alternativetopMatch.results[0].score.toString()} - ${alternativetopMatch.results[1].score.toString()}`;
      const topStreamEmbed = `${alternativetopMatchembed}`;
      const matchTypeRaw = alternativetopMatch.match_type;
      const numberOfGames = alternativetopMatch.number_of_games;
      let matchTypeFinal = ``;
      if (matchTypeRaw === 'best_of' && (numberOfGames !== 2 && numberOfGames !== 1)) {
        matchTypeFinal = 'best_of_X';
      } else if (matchTypeRaw === 'best_of' && numberOfGames === 1) {
        matchTypeFinal = 'single_game';
      } else if (matchTypeRaw === 'best_of' && numberOfGames === 2) {
        matchTypeFinal = 'best_of_2';
      } else if (matchTypeRaw === 'all_games_played') {
        matchTypeFinal = 'all_games_played';
      } else if (matchTypeRaw === 'first_to') {
        matchTypeFinal = 'first_to_X';
      } else {
        matchTypeFinal = 'general_drop_to';
      }
      const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
      const matchTypefull = `${matchType} ${alternativetopMatch.number_of_games.toString()}`;

      // let iframeaddition = '';
      // console.debug(topStreamEmbed);
      // if(topStreamEmbed.includes('bilibili')){
      //   iframeaddition = `scrolling="no" border="0" frameborder="no" framespacing="0"`;
      // }

      const matchHtml = /*html*/ `
 <div class="game-logo d-inline d-sm-inline d-md-none ms-1 mt-1">
    ${gameLogoHTML}
  </div>
        <div class="container p-0 text-white my-0 my-sm-0 my-md-0 mb-lg-2 mb-xl-5 mb-xxl-5">

  <a href="${window.location.origin}/s_match?match=${alternativetopMatch.id}" class="row mt-0 mt-sm-0 mt-md-0 mt-lg-2 mt-xl-2 mt-xxl-2 rounded-top color-dark clickable" id="${matchElementId}" >
    <div class="col-12 p-0 d-flex justify-content-between ">
      <div class="text-start d-flex align-items-center p-1 clickable " style="background-color:#0f2b46;border-bottom-right-radius: 8px;" id="${serieElementId}">
                
                ${gameLogoHTML} 
                <span class="m-0 mx-2 d-flex align-items-center text-resize">${tournamentname}</span>
                ${tournamentLogoHTML}
                </div>
      <div class="d-flex align-items-center"> 
         <span  id="spicyInfo_1M" class="badge bg-danger text-resize d-inline d-sm-inline d-lg-none red-animation" style="margin-right: 0.3rem;margin-top: 0.3rem;"></span>
          <span class="red-animation badge text-resize m-2 d-none d-sm-none d-lg-inline badgeTranslation" ></span>
      </div>
    </div>
    <div class="row my-0 my-sm-0 my-md-4 my-lg-4 my-xl-4 my-xxl-4 p-0 m-0">
    <div class="col-5 d-flex justify-content-end align-items-center p-0 clickable">
      <p class="team-hot-resize mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-2 mx-xxl-2 my-0   ">${team1name}</p>
      <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
               <img src="${team1logo}" alt="" class="img-fluid mx-auto d-block ">
                </div>
     
    </div>
    <div class="col-2 align-self-center text-center p-0 clickable">
      <span class="score-resize m-0" id="hotScoreId">${score}</span>
    </div>
    <div class="col-5 d-flex justify-content-start align-items-center p-0 clickable ">
        <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
               <img src="${team2logo}" alt="" class="img-fluid mx-auto d-block ">
                </div>
      <p class="team-hot-resize mx-0 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-2 mx-xxl-2 my-0  ">${team2name}</p>
    </div>
    <div class="col-12 text-center d-flex h-auto justify-content-center align-items-center  p-0 m-0 m-0">
                     <span class="my-2 badge bg-secondary text-resize d-none d-sm-none d-lg-inline matchTypeFromHM">${matchTypeFinal}</span>
                     
                    </div>
</div>
  </a>
  
<div  class="row color-dark rounded-bottom">

    <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7 d-flex  justify-content-center my-2 ">
        <div  class="ratio ratio-16x9" >
        
            <iframe id="twitch-home"
                src="${topStreamEmbed}"
                width="100%" 
                height="100%" 
                allowfullscreen="true">
            </iframe>
        </div>
    </div>
     <div class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-5 col-xxl-5 d-none d-xl-flex  my-2 ">
                    <div id="timeline-home" class="scrollbar w-100">
  <div id="timelineInnerHome" class="container  " style="position: relative;top: 50%;left: 50%;transform: translate(-50%,-50%)">
<!--    <p class="pb-3  text-center mb-1 ">Did You know?</p>-->
    <!--first section-->
    <div class="row align-items-center how-it-works d-flex ">
      <div class="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold"><i class="bi bi-people-fill"></i></div>
      </div>
      <div class="col-10">
        <p class="fs-5"></p>
        <p id="spicyInfo_1D" class="fs-6 text-white-50" style="margin: 0rem !important;"></p>
      </div>
    </div>
    <!--path between 1-2-->
    <div class="row timeline">
      <div class="col-2">
        <div class="corner top-right"></div>
      </div>
      <div class="col-8">
        <hr/>
      </div>
      <div class="col-2">
        <div class="corner left-bottom"></div>
      </div>
    </div>
    <!--second section-->
    <div class="row align-items-center justify-content-end how-it-works d-flex">
      <div class="col-10 ">
        <p id="spicyInfoNumberTwo" class="fs-6 text-white-50 text-end" style="margin: 0rem !important;"></p>
      </div>
      <div class="col-2 text-center full d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold"><i class="bi bi-globe-americas"></i></div>
      </div>
    </div>
    <!--path between 2-3-->
    <div id="hotMatchInfoThirdsectionPart1" class="row timeline">
      <div class="col-2">
        <div class="corner right-bottom"></div>
      </div>
      <div class="col-8">
        <hr/>
      </div>
      <div class="col-2">
        <div class="corner top-left"></div>
      </div>
    </div>
    <!--third section-->
    <div id="hotMatchInfoThirdsectionPart2" class="row align-items-center how-it-works d-flex">
      <div class="col-2 text-center top d-inline-flex justify-content-center align-items-center">
        <div class="circle font-weight-bold"><i class="bi bi-cake2"></i></div>
      </div>
      <div class="col-10">
         <p id="spicyInfoNumberThree" class="fs-6 text-white-50" style="margin: 0rem !important;"></p>
      </div>
    </div>
   
  </div>
</div>
    </div>
</div>
`;
      if (document.getElementById('twitch-home') !== null) {
        const iframe = document.getElementById('twitch-home');
        if (iframe.src !== topStreamEmbed) {
          console.debug('I found the iframe and its different, so im updating the hotmatch component!')
          document.getElementById('hotmatch-component').innerHTML = '';
          document.querySelector('#hotmatch-component').insertAdjacentHTML('beforeend', matchHtml);
          const matchTypeElement = document.querySelector(`.matchTypeFromHM`);
          if (matchTypeElement.innerHTML === 'all_games_played') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
          } else if (matchTypeElement.innerHTML === 'best_of_X') {
            let gamesNumber;
            if (window.translateTo === 'el') {
              gamesNumber = Math.floor(numberOfGames / 2) + 1;
            } else {
              gamesNumber = numberOfGames;
            }
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
          } else if (matchTypeElement.innerHTML === 'first_to_X') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
          } else if (matchTypeElement.innerHTML === 'single_game') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
          } else if (matchTypeElement.innerHTML === 'best_of_2') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
          } else {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
          }
        } else {
          console.debug('I am not changing the iframe src cause its the same,im just updating the score!')
          document.getElementById('hotScoreId').innerText = score;
          let beginAt = new Date(alternativetopMatch.begin_at);
          let now = new Date();
          let diffMs = now - beginAt; // difference in milliseconds
          let diffSecs = Math.floor(diffMs / 1000); // convert to seconds
          let diffMins = Math.floor(diffSecs / 60); // convert to minutes
          let diffHrs = Math.floor(diffMins / 60); // convert to hours
          let runningGameNumber = alternativetopMatch.games.findIndex(game => game.status === "running") + 1;
          if (runningGameNumber === 0) { runningGameNumber = alternativetopMatch.games.findIndex(game => game.status === "not_started") + 1; }

          if (diffHrs > 0) {
            // if it's been more than an hour, display hours and minutes
            let remainingMins = diffMins % 60;
            document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineHours", { RGN: runningGameNumber, hourz: diffHrs, minutez: remainingMins }));
          } else {
            let remainingMins = diffMins % 60;
            document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineMinutes", { RGN: runningGameNumber, minutez: remainingMins }));
          }

        }
      } else {
        console.debug('I am not able to find the iframe so im creating the hotmatch for the first time')
        document.querySelector('#hotmatch-component').innerHTML = '';
        document.querySelector('#hotmatch-component').insertAdjacentHTML('beforeend', matchHtml);
        const matchTypeElement = document.querySelector(`.matchTypeFromHM`);
        if (matchTypeElement.innerHTML === 'all_games_played') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
        } else if (matchTypeElement.innerHTML === 'best_of_X') {
          let gamesNumber;
          if (window.translateTo === 'el') {
            gamesNumber = Math.floor(numberOfGames / 2) + 1;
          } else {
            gamesNumber = numberOfGames;
          }
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
        } else if (matchTypeElement.innerHTML === 'first_to_X') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
        } else if (matchTypeElement.innerHTML === 'best_of_2') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
        } else if (matchTypeElement.innerHTML === 'single_game') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
        } else {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
        }
      }


      document.querySelectorAll('.badgeTranslation').forEach(el => el.innerHTML = I18n.translateString("hotmatchTranslations.badgeHot"));
      HotmatchComponent.resizetoTwitchHeight()
      HotmatchComponent.redirectToMatchPage(matchElementId, matchName);
      HotmatchComponent.redirectToSeriePage(serieElementId);
      const tournamentId = alternativetopMatch.tournament_id || alternativetopMatch.tournament.id;
      if (alternativetopMatch.opponents[0].type === 'Team' && alternativetopMatch.opponents[1].type === 'Team') {
        PostMatchesComponent.getRostersData(tournamentId.toString(), alternativetopMatch.opponents[0].opponent.id, alternativetopMatch.opponents[1].opponent.id).then((response) => {
          const teamData1 = response.rosterId1;
          const teamData2 = response.rosterId2;
          let nationalities = []
          let maxAge = 9999999
          let userLanguage = window.navigator.language.split("-")[0];
          let regionNames = new Intl.DisplayNames([userLanguage], { type: 'region' });
          teamData1.players.forEach(player => {
            if (player.age !== null && player.age < maxAge) maxAge = player.age
            if (player.nationality !== null && nationalities.indexOf(regionNames.of(player.nationality)) === -1)
              nationalities.push(regionNames.of(player.nationality))
          })
          teamData2.players.forEach(player => {
            if (player.age !== null && player.age < maxAge) maxAge = player.age
            if (player.nationality !== null && nationalities.indexOf(regionNames.of(player.nationality)) === -1)
              nationalities.push(regionNames.of(player.nationality))
          })
          let beginAt = new Date(alternativetopMatch.begin_at);
          let now = new Date();
          let diffMs = now - beginAt; // difference in milliseconds
          let diffSecs = Math.floor(diffMs / 1000); // convert to seconds
          let diffMins = Math.floor(diffSecs / 60); // convert to minutes
          let diffHrs = Math.floor(diffMins / 60); // convert to hours
          let runningGameNumber = alternativetopMatch.games.findIndex(game => game.status === "running") + 1;
          if (runningGameNumber === 0) { runningGameNumber = alternativetopMatch.games.findIndex(game => game.status === "not_started") + 1; }

          if (diffHrs > 0) {
            // if it's been more than an hour, display hours and minutes
            let remainingMins = diffMins % 60;
            document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineHours", { RGN: runningGameNumber, hourz: diffHrs, minutez: remainingMins }));
          } else {
            let remainingMins = diffMins % 60;
            document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineMinutes", { RGN: runningGameNumber, minutez: remainingMins }));
          }

          document.getElementById('spicyInfoNumberTwo').innerText = I18n.translateString("hotmatchTranslations.playerNationalities", { countries: nationalities.join(', ') });
          if (maxAge !== 9999999) {
            document.getElementById('spicyInfoNumberThree').innerText = I18n.translateString("hotmatchTranslations.playerAges", { Age: maxAge }); document.getElementById('hotMatchInfoThirdsectionPart1').style.display = ''
            document.getElementById('hotMatchInfoThirdsectionPart2').style.display = ''
          }
          else {
            document.getElementById('hotMatchInfoThirdsectionPart1').setAttribute('style', 'display: none !important');
            document.getElementById('hotMatchInfoThirdsectionPart2').setAttribute('style', 'display: none !important');
          }
        })
      } else if (alternativetopMatch.opponents[0].type === 'Player' && alternativetopMatch.opponents[1].type === 'Player') {
        let nationalities = []
        let maxAge = 9999999
        let userLanguage = window.navigator.language.split("-")[0];
        let regionNames = new Intl.DisplayNames([userLanguage], { type: 'region' });
        alternativetopMatch.opponents.forEach(player => {
          if (player.opponent.age !== null && player.opponent.age < maxAge) maxAge = player.opponent.age
          if (player.opponent.nationality !== null && nationalities.indexOf(regionNames.of(player.opponent.nationality)) === -1)
            nationalities.push(regionNames.of(player.opponent.nationality))
        })
        let beginAt = new Date(alternativetopMatch.begin_at);
        let now = new Date();
        let diffMs = now - beginAt; // difference in milliseconds
        let diffSecs = Math.floor(diffMs / 1000); // convert to seconds
        let diffMins = Math.floor(diffSecs / 60); // convert to minutes
        let diffHrs = Math.floor(diffMins / 60); // convert to hours
        let runningGameNumber = alternativetopMatch.games.findIndex(game => game.status === "running") + 1;
        if (runningGameNumber === 0) { runningGameNumber = alternativetopMatch.games.findIndex(game => game.status === "not_started") + 1; }

        if (diffHrs > 0) {
          // if it's been more than an hour, display hours and minutes
          let remainingMins = diffMins % 60;
          document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineHours", { RGN: runningGameNumber, hourz: diffHrs, minutez: remainingMins }));
        } else {
          let remainingMins = diffMins % 60;
          console.debug('I am running the else statement')
          document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineMinutes", { RGN: runningGameNumber, minutez: remainingMins }));
        }
        document.getElementById('spicyInfoNumberTwo').innerText = I18n.translateString("hotmatchTranslations.playerNationalities", { countries: nationalities.join(', ') });
        if (maxAge !== 9999999) {
          document.getElementById('spicyInfoNumberThree').innerText = I18n.translateString("hotmatchTranslations.playerAges", { Age: maxAge }); document.getElementById('hotMatchInfoThirdsectionPart1').style.display = ''
          document.getElementById('hotMatchInfoThirdsectionPart2').style.display = ''
        }
        else {
          document.getElementById('hotMatchInfoThirdsectionPart1').setAttribute('style', 'display: none !important');
          document.getElementById('hotMatchInfoThirdsectionPart2').setAttribute('style', 'display: none !important');
        }
      }
    } else {
      document.querySelector('#hotmatch-component').innerHTML = '';
      document.querySelector('#hotmatch-component').insertAdjacentHTML('beforeend', /*html*/`
      <div class="my-5 noLiveMsgHM" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
      </div>
      `);
      if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
        document.querySelector('.noLiveMsgHM').innerHTML = I18n.translateString("hotmatchTranslations.noLiveMsg", { hotGame: document.querySelector('.gameNameActive').innerText });
      } else {
        document.querySelector('.noLiveMsgHM').innerHTML = I18n.translateString("hotmatchTranslations.noLiveMsg", { hotGame: '' });
      }
    }


    document.getElementById('placeholder').style.display = "none";
  }

  static resizetoTwitchHeight() {
    const twitchIframe = document.getElementById('twitch-home');
    if (twitchIframe) {
      const width = twitchIframe.offsetWidth;
      const height = width * (9 / 16);
      twitchIframe.style.height = `${height}px`;
      console.debug('I am running the resizetoTwitchHeight function and the height is', height);
      // Now, apply this height to the timeline
      const timelineHome = document.getElementById('timeline-home');
      if (timelineHome) {
        timelineHome.style.height = `${height}px`; // Set the computed height
        timelineHome.style.overflow = 'hidden';
      } else {
        console.debug('Timeline element not found');
      }
    }
    else {
      console.debug('Twitch iframe element not found');
    }

  }


  static redirectToMatchPage(matchElementId, matchName) {
    const element = document.getElementById(matchElementId);
    const alteredMatchElementId = matchElementId.replace('hotmatchelementid-', '');
    if (element && !element.listenerAdded) {
      element.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
        RouterAssistant.redirect('s_match', {
          match: alteredMatchElementId,
          htname: matchName
        })
      }))
      element.listenerAdded = true;
    }
  }

  static redirectToSeriePage(serieElementId) {

    const element = document.getElementById(serieElementId);
    const alteredSerieElementId = serieElementId.replace('hotserieId-', '');

    if (element && !element.listenerAdded) {
      element.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
        evt.stopPropagation();
        RouterAssistant.redirect('s_tournament', {
          tournament: alteredSerieElementId
        })
      }))
      element.listenerAdded = true;
    }
  }





}


HotmatchComponent.template = /*html*/ `
<div class="container p-0 text-white my-0 my-sm-0 my-md-0 my-lg-2 my-xl-5 my-xxl-5 color-dark " id="placeholder">
  <div class="row mt-0 mt-sm-0 mt-md-0 mt-lg-2 mt-xl-5 mt-xxl-5 rounded-top color-dark">
    <div class="col-12 p-0 d-flex justify-content-between bcolor-dark text-white placeholder-glow">
      <div class="d-flex p-1 rounded-start bg-secondary placeholder ">
        <div class="placeholder bg-secondary  me-2" style="width: 2rem; height: 1.5rem;"></div>
        <span class="placeholder bg-secondary  me-2" style="width: 100px; height: 1rem;"></span>
        <div class="placeholder bg-secondary " style="width: 1.5rem; height: 1.5rem;"></div>
      </div>
      <div class="text-end placeholder-glow">
        <span class="badge bg-danger placeholder" style="width: 3.5rem; height: 1.5rem;">&nbsp;</span>
      </div>
    </div>
    <div class="row my-4  d-flex justify-content-center placeholder-glow p-0">
      <div class="col-5 bg-secondary rounded placeholder w-75 p-0 " style="height: 5rem;">
      </div>
    </div>
  </div>
  <div class="row color-dark rounded-bottom">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7 d-flex justify-content-center my-2 color-dark">
      <div class="ratio ratio-16x9 placeholder-glow">
        <div class="placeholder bg-secondary w-100 rounded" ></div>
      </div>
    </div>
    <div class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-5 col-xxl-5 d-none  d-xl-flex my-2 color-dark">
      <div class="w-100 placeholder-glow ">
        <span class="placeholder bg-secondary  w-100 rounded" style="height:100%;"></span>
      </div>
    </div>
  </div>
</div>

<div id="hotmatch-component"> </div>
`;

HotmatchComponent.reg('hotmatch-component');

import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { I18n } from "../../utilities/i18n/i18n";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";
import { PredictionsHomecomponent } from "../predictions-homecomponent/predictions-homecomponent";
import { PredictionComponent } from "../predictions-component/predictions-component";
import { doc } from "firebase/firestore";
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')
const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fifa = require('../game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/r6 150x150.webp')
const roblox = require('../game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../game-navbar/assets/images/KingOfGlory 150x150.webp')
const wildrift = require('../game-navbar/assets/images/wild rift 150x150.jpg')


const noImageFound = require('./Assets/no image found.png')
const noImageFoundPlayer = require('../postmatches-component/assets/author.webp')
const css = `
<style>

/* filters styles */
#searchBar::-webkit-input-placeholder {
  font-size: calc(0.15rem + 0.45vw);
}
#searchBar:-ms-input-placeholder {
  font-size: calc(0.15rem + 0.45vw);
}
#searchBar::placeholder {
  font-size: calc(0.15rem + 0.45vw);
}
#searchBar.search-input {
    font-size: 10px !important;
    width: calc(1rem + 13vw);
}
#searchBar.search-input:focus {
    border-color: #ff2828;
    box-shadow: 0 0 0 0.2rem rgba(255, 40, 40, 0.25);
}
matches-component .search-input-mobile {
    font-size: 10px !important;
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}
    matches-component .search-input-mobile2 {
    font-size: 10px !important;
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}
matches-component .search-input-mobile:focus {
    font-size: 10px !important;
    border-color: #ff2828;
    box-shadow: 0 0 0 0.2rem rgba(255, 40, 40, 0.25);
}
matches-component .filters-dropdown {
    position: relative;
    
}
matches-component .filters-dropdown-content {
  position: absolute;
  top: 40px; /* Adjust this value based on the height of your dropdown button */
  z-index: 111;
  border-radius: 8px;
  width: 18rem;
} 
matches-component  .filters-dropdown-content .filter-option {
    padding: 10px; /* Adds some space around each link for better readability */
    text-decoration: none; /* Removes the underline from the links */
    font-weight: 700;
    color: #fff; /* Assuming you want white text for visibility */
    background-color: #181c24; /* Background color to match your theme */
    border-bottom: 1px solid #36393f; /* Adds a subtle divider between options */
}
matches-component  .filter-tournament, .filter-teams {
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.1rem;
    padding-top: 3px;
    border: solid 1px #21262d;
}
matches-component  .filter-odds {
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: 0.1rem;
    padding-top: 3px;
    border: solid 1px #21262d;
}
matches-component .filter-odds-checkbox, .filter-tournament-checkbox, .filter-teams-checkbox{
    font-size: 1rem;
}
matches-component .filter-label{
    padding-top: 3px;
    }
matches-component .filter-tournaments-count, .filter-teams-count, .filter-providers-count {
            font-size: 0.7rem;
            font-weight: 600;
            padding: 3px 4px;
            background-color: rgba(255, 40, 40, 0.59);
            color:rgb(255, 255, 255);
            max-width: 30px;
            border-radius: 20px;
            text-align: center;
            position: relative;
            overflow: hidden;
            transition: background-color 0.3s, color 0.3s;
        }
matches-component .filters-general-count{
            font-size: 0.7rem;
            font-weight: 600;
            padding: 5px 8px;
            background-color: rgba(255, 40, 40, 0.59);
            color:rgb(255, 255, 255);
            max-width: 30px;
            border-radius: 20px;
            text-align: center;
            position: relative;
            overflow: hidden;
            transition: background-color 0.3s, color 0.3s;
        }

/*end of filters styles */

matches-component .nav .nav-item {
    padding-left: 5px;
    padding-right: 5px;
    }
matches-component .style-nav-pills .nav-link {
    background-color: #181c24;
    border-radius: 30px;
}
/* Custom Scrollable Datepicker */
matches-component        .date-selector-matches-container {
            background-color: inherit;
            position: relative;
            margin-bottom: 10px;
            overflow: auto; /* Hide overflow to use transform for scrolling */
            white-space: nowrap; /* Prevent line breaks */

        }
matches-component        .table-responsive2 {
        margin-top: 30px;
}
matches-component        .date-content-matches {
    display: inline-flex;
    transition: transform 0.3s ease; /* Smooth transition for transform */
}

matches-component        .date-selector-matches {
            display: flex;
            justify-content: center;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: none;
            -ms-overflow-style: none;
            padding: 10px 40px; /* Increased padding to accommodate arrows */
            background-color: inherit;
            border-radius: 8px;
            box-shadow: 0 4px 12px rgba(0,0,0,0.5);
            align-items: center;
        }
matches-component        .date-selector-matches::-webkit-scrollbar {
            display: none;
        }
matches-component       .date-box-matches {
            background-color: #21262d;
            border-radius: 15px;
            padding: 12px 20px;
            margin: 0 10px;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s, color 0.3s;
            text-align: center;
            flex-shrink: 0;
            font-size: 0.9rem;
            user-select: none;
            min-width: 100px;
            white-space: nowrap;
            overflow: auto;
            text-overflow: ellipsis;
        }
matches-component        .date-box-matches:hover {
            background-color: #30363d;
            transform: translateY(-3px);
        }
matches-component        .date-box-matches.active {
            background-color: #00d1b2;
            color: #1e1e1e;
            transform: translateY(-5px);
            box-shadow: 0 4px 6px rgba(0,0,0,0.3);
        }
matches-component        .date-box-matches .day-number-matches {
            font-size: 1.2rem;
            font-weight: 600;
            display: block;
        }
matches-component        .date-box-matches .day-name-matches {
            font-size: 0.85rem;
            display: block;
        }
        /* Scroll Arrow Styles */
matches-component        .scroll-arrow-matches {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(24, 28, 33, 0.7);
            border: none;
            color: #c9d1d9;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 3;
            transition: background-color 0.3s, color 0.3s;
        }
matches-component        .scroll-arrow-matches:hover {
            background-color: rgba(24, 28, 33, 0.9);
            color: #58a6ff;
        }
matches-component        .scroll-left-matches {
            left: 9px;
        }
matches-component        .scroll-right-matches {
            right: 9px;
        }
            
        /* Match Item */
        .match-item-widget {
            background-color: #1E252E;
            border: none; /* Clean look */
            border-radius: 8px;
            padding: 15px;
            margin-bottom: 15px;
            text-decoration: none;
            color: inherit;
            transition: background-color 0.3s, transform 0.3s;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .match-item-widget:hover {
            background-color: #3A4B5C;
            transform: translateY(-3px); /* Pronounced hover effect */
        }

        /* Match Header */
        .match-header-widget {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            
            padding:3px;
             
        }
        .match-header-widget .game-logo-widget {
            width: 28px; /* Enhanced visibility */
            height: 28px;
            object-fit: contain;
           
        }
         .text-resize-tournament-widget{
         font-size:0.7rem;
         }
        /* Live Badge */
         .live-badge-widget {
        background-color: #28A745; /* Vibrant green */
        animation: pulse-widget 2s infinite;
        }
         .text-resize-badge-widget {
            display: flex;
            align-items: center;     
            color: #fff;
            padding: 4px 8px;
            border-radius: 8px;
            font-size: 0.75rem;
            font-weight: 700;
            text-transform: uppercase;        
            transition: transform 0.3s;
        }
         .text-resize-badge-widget i {
            margin-right: 4px;
            font-size: 1rem;
        }
         .text-resize-badge-widget:hover {
            transform: scale(1.05);
        }

        /* Pulse Animation */
        @keyframes pulse-widget {
            0% {
                box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
            }
            70% {
                box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
            }
            100% {
                box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
            }
        }

        /* Teams and Odds */
        .teams-odds-widget {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }
        .team-widget {
            text-align: center;
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .team-widget img {
            width: 50px;
            height: 50px;
            margin-bottom: 8px;
            object-fit: contain;
            transition: transform 0.3s;
            loading: lazy; /* Performance optimization */
        }
        .team-widget img:hover {
            transform: scale(1.1);
        }
        .team-name-widget {
            font-size: 0.8rem;
            font-weight: 600;
            color: #FFFFFF;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 5px;
            max-width: 100%; /* Prevent overflow */
            cursor: pointer; /* Indicate interactivity */
        }
        .team-odd-widget {
            background-color:rgba(0, 0, 0, 0.4);
            color: rgb(255, 255, 255);
            font-size: calc(0.4rem + 0.45vw);
            padding: calc(0.05rem + 0.2vw) calc(0.2rem + 0.4vw);
            border-radius: 4px;
            font-weight: 700;
            transition: background-color 0.3s;
            text-align: center;
        }
        .team-odd-widget:hover {
            background-color: rgba(0, 0, 0, 0.64);
        }

matches-component .new-odds-badge {
            background-color:rgba(0, 0, 0, 0.62);
            color: rgb(255, 255, 255);
            font-size: calc(0.4rem + 0.45vw);
            padding: calc(0.05rem + 0.2vw) calc(0.2rem + 0.4vw);
            border-radius: 4px;
            font-weight: 700;
            transition: background-color 0.3s;
            text-align: center;
        }
matches-component .new-odds-badge-placeholder {
            color: transparent;
            font-size: calc(0.4rem + 0.45vw);
            padding: calc(0.05rem + 0.2vw) calc(0.2rem + 0.4vw);
            font-weight: 700;
            text-align: center;
        }

        /* Score */
        .score-widget {
            width: 20%;
            font-size: 1.2rem;
            font-weight: 700;
            color: #ffffff;
            text-align: center;
            margin: 10px 0;
            flex-shrink: 0;
        }

        /* Bet Now Button (Retained from your code, uncommented if needed)
        .bet-now-widget {
            text-align: center;
            width: 100%;
        }
        .bet-now-widget .btn-widget {
            background-color: #28A745;
            border: none;
            color: #FFFFFF;
            font-size: 1rem;
            font-weight: 700;
            padding: 12px 0; 
            border-radius: 6px;
            cursor: pointer;
            transition: background-color 0.3s, box-shadow 0.3s;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .bet-now-widget .btn-widget i {
            margin-right: 8px;
            font-size: 1.2rem;
        }
        .bet-now-widget .btn-widget:hover {
            background-color: #218838;
            box-shadow: 0 6px 18px rgba(40, 168, 56, 0.3);
        }
        */

        /* Show More Button */
        .show-more-widget {
            text-align: center;
            margin-top: 5px;
        }
        .show-more-widget {
            background-color: #FF4655; /* Button background */
            border: none;
            color: #FFFFFF;
            font-size: 0.9rem;
            font-weight: 700;
            padding: 10px 20px; /* Enhanced padding for touch */
            border-radius: 6px;
            cursor: pointer;
            transition: background-color 0.3s, box-shadow 0.3s;
        }
        .show-more-widget:hover {
            background-color: #D73648; /* Hover state */
            box-shadow: 0 6px 18px rgba(215, 54, 72, 0.3);
        }
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
.nav-link .bi-star-fill {
    margin-right: 0.5rem; /* Space between icon and text */
    color: gold;          /* Gold color for the star */
    font-size: 0.66rem;      /* Adjust size as needed */
    vertical-align: middle; /* Aligns the icon vertically with the text */
}
/* Remove default margin from Bootstrap's form-check-input */
.form-check {
padding-left: 0 !important;
}
.form-check .form-check-input {
    margin-left: 0 !important;
   
}

.star-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    cursor: pointer;
    margin-left: 0.5rem;
}

.star-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    cursor: pointer;
    z-index: 2;
}

.star-icon {
    font-size: 1.5rem;
    line-height: 1;
    color: gray;
    transition: color 0.3s ease, transform 0.2s ease;
}

.star-checkbox:checked + .star-icon {
    color: gold;
     
}

.star-label:hover .star-icon {
    transform: scale(1.05);
    color: orange;
}

matches-component .img-circle-container-game-logo-widget{
  /*position: absolute;*/
  /*left: 0;*/
  /*right: 0;*/
  /*top:-5px;*/
  /*margin: 0 auto;*/
}
matches-component .card{
         background-color: transparent;
         border: none;
}

 matches-component .winner-left {
    background-color: #005307;
    filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
    border-radius: 0 15px 15px 0;
}
    matches-component .winner-left2 {
    background-color: #005307;
    filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
    border-radius: 10px 10px 10px 10px;
}
 matches-component .winner-right {
    background-color: #005307;
    filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
    border-radius: 15px 0 0 15px;
}
        matches-component .winner-right2 {
    background-color: #005307;
    filter: drop-shadow(#0D1117 0rem 0.4rem 4.8px);
    border-radius: 10px 10px 10px 10px;
}
 matches-component .nav .nav-item , .nav .nav-link {
background=#EA121259 !important;
color:#ffffff !important;
}
 matches-component .nav .activestatus{
background-color:rgba(255, 40, 40, 0.59) !important;
color:#ffffff !important;
}
matches-component .dropdown-menu .active{
background-color: #ff282859 !important;
color:#ffffff !important;
}

#current-date{
    background-color: #ff282859 !important;
    font-weight: 500;
    color:#ffffff !important;
}

 .dropdown-menu{
    color: #ffffff;
    font-weight: 500;
    background-color: #181c24;
    < !--#181c24-- >
}

 matches-component .nav .disabled{
color:#707070 !important;
}

matches-component .hidden-important {
    display: none !important;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    matches-component .nav  {
    justify-content: space-between !important;
    padding-left: 1.6vw !important;
    padding-right: 1.6vw !important;
    }
        matches-component       .date-box-matches {
            padding: 0.35rem 0.5rem;
            min-width: 3rem;
        }
        matches-component        .date-box-matches .day-number-matches {
            font-size: 0.65rem;

        }
    matches-component        .date-box-matches .day-name-matches {
            font-size: 0.47rem;
        }

    /* Match Item */
  matches-component  .match-item-widget {
        padding: 0;
        margin-bottom: 10px;
    }

    /* Match Header */
  matches-component  .match-header-widget {
        
    }
    
   matches-component .match-header-widget .game-logo-widget {
        width: 20px;
        height: 20px;
        margin-bottom: 8px;
    }
    .text-resize-tournament-widget{
         font-size:0.55rem;
         
         }

    /* Live Badge */
   matches-component .text-resize-badge-widget {
        font-size: 0.6rem;
        padding: 2px 4px;
        
    }
    matches-component .text-resize-badge-widget i {
        margin-right: 3px;
        font-size: 0.6rem;
    }

    /* Teams and Odds */
   matches-component .teams-odds-widget {
      margin-bottom: 0px;
    }
   matches-component .team-widget {
        width: 100%;
        margin-bottom: 10px;
    }
  matches-component  .team-widget img {
        width: 35px;
        height: 35px;
        margin-bottom: 6px;
    }
   matches-component .team-name-widget {
        font-size: 0.65rem;
    }
   matches-component .team-odd-widget {
        font-size: 0.6rem;
        padding: 2px 4px;
        width: 20%;
    }

    /* Score */
    matches-component.score-widget {
        font-size: 0.85rem;
        margin: 8px 0;
    }

    /* Show More Button */
   matches-component .show-more-widget {
        padding: 4px 8px;
        font-size: 0.65rem;
        }
        
        
        
        
        
        
        
        
        
        
        
        
        
        
matches-component .star-icon {
    font-size: 1rem;
   
}
matches-component .img-circle-container-game-logo-widget {
 width: 0.6rem;
 height: 0.6rem;
  top:2px;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.6rem;
 max-height: 0.6rem;
 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1.1rem;
 height: 1.1rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }
  matches-component .img-circle-container-widget {

   width: 1rem;  /* Adjust the width as needed */
    height: 1rem; /* Ensure the height is the same as the width for a perfect circle */
}
 matches-component .team-name-resize-widget{
         font-size: 0.6rem;
         font-weight: 800;
         color:#ffffff;
}

matches-component .nav .nav-item {
    font-size: 0.55rem;
    font-weight: 700;
    padding-right: 2px;
    padding-left: 2px;
    }
matches-component .text-resize-dropdown{
    font-size: 0.5rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-matches {
         font-size: 0.5rem;
         font-weight: 400;
    }

         matches-component .text-resize-tournament{
         font-size: 0.45rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.35rem;
         font-weight: 200;
         
    }
 
    
   matches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
    }
    
         matches-component .img-circle-container {
    
   width: 1.5rem;  /* Adjust the width as needed */
    height: 1.5rem; /* Ensure the height is the same as the width for a perfect circle */
}
 
          matches-component .img-circle-container img{

   max-width: 1rem;
   max-height: 1rem;
}
 
     matches-component .team-name-resize{
         font-size: 0.55rem;
         font-weight: 500;
         color: white ;
        
}

 
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }
     matches-component .text-resize-badge{
         font-size: 0.5rem;
         font-weight: 700;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
 /* Widget Container */
matches-component .nav  {
    justify-content: space-between !important;
    padding-left: 1.6vw !important;
    padding-right: 1.6vw !important;
    }


  /* Match Item */
  matches-component  .match-item-widget {
        padding: 0;
        margin-bottom:0;
    }

    /* Match Header */
  matches-component  .match-header-widget {
        
    }
    
   matches-component .match-header-widget .game-logo-widget {
        width: 20px;
        height: 20px;
        margin-bottom: 8px;
    }
    .text-resize-tournament-widget{
         font-size:0.55rem;
         
         }

    /* Live Badge */
   matches-component .text-resize-badge-widget {
        font-size: 0.6rem;
        padding: 2px 4px;
        
    }
    matches-component .text-resize-badge-widget i {
        margin-right: 3px;
        font-size: 0.6rem;
    }

    /* Teams and Odds */
   matches-component .teams-odds-widget {
      
    }
   matches-component .team-widget {
        width: 100%;
        margin-bottom: 10px;
    }
  matches-component  .team-widget img {
        width: 17px;
        height: 17px;
        margin-bottom: 4px;
    }
   matches-component .team-name-widget {
        font-size: 0.4rem;
    }
   matches-component .team-odd-widget {
        font-size: 0.35rem;
        padding: 2px 4px;
        width: 20%;
    }

    /* Score */
 matches-component   .score-widget {
        font-size: 0.7rem;
        margin: 0 0;
    }

    /* Show More Button */
   matches-component .show-more-widget {
        padding: 4px 8px;
        font-size: 0.65rem;
        }
        
    
    
    
    
    
    
    
    
    
    
    
matches-component .star-icon {
    font-size: 1.1rem;
   
}
matches-component .img-circle-container-game-logo-widget {
 width: 0.6rem;
 height: 0.6rem;
  top:2px;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.6rem;
 max-height: 0.6rem;
 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }
    matches-component .img-circle-container-widget {

   width: 1.1rem;  /* Adjust the width as needed */
    height: 1.1rem; /* Ensure the height is the same as the width for a perfect circle */
}
   matches-component .img-circle-container-widget img {

   max-width: 1.1rem;  
    max-height: 1.1rem; 
}
     matches-component .team-name-resize-widget{
         font-size: 0.6rem;
         font-weight: 500;
         color:#ffffff;
}
  matches-component .text-resize-badge{
         font-size: 0.35rem;
         font-weight: 700;
    }
    matches-component .nav .nav-item {
    font-size: 0.55rem;
    font-weight: 700;
    padding-right: 4px;
    padding-left: 4px;
    }
matches-component .text-resize-dropdown{
    font-size: 0.55rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-matches {
         font-size: 0.55rem;
         font-weight: 400;
    }

         matches-component .text-resize-tournament{
         font-size: 0.5rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.4rem;
         font-weight: 200;
         
    }
    
    
    matches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
    }
    
         matches-component .img-circle-container {

   width: 1.5rem;  /* Adjust the width as needed */
    height: 1.5rem; /* Ensure the height is the same as the width for a perfect circle */
}
         matches-component .img-circle-container img {

   max-width: 1.5rem;
   max-height: 1.5rem;
}
 
 
     matches-component .team-name-resize{
         font-size: 0.6rem;
         font-weight: 500;
         color: white ;
        
}

 
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 823px) {
  
 matches-component .nav  {
    justify-content: space-between !important;
    padding-left: 1.6vw !important;
    padding-right: 1.6vw !important;
    }

   /* Match Item */
  matches-component  .match-item-widget {
        padding: 0;
        margin-bottom: 10px;
    }

    /* Match Header */
  matches-component  .match-header-widget {
        
    }
    
   matches-component .match-header-widget .game-logo-widget {
        width: 22px;
        height: 22px;
        margin-bottom: 0;
    }
    .text-resize-tournament-widget{
         font-size:0.6rem;
         
         }

    /* Live Badge */
   matches-component .text-resize-badge-widget {
        font-size: 0.65rem;
        padding: 2px 4px;
        
    }
    matches-component .text-resize-badge-widget i {
        margin-right: 3px;
        font-size: 0.65rem;
    }

    /* Teams and Odds */
   matches-component .teams-odds-widget {

    } 

   matches-component .team-widget {
        width: 100%;
        margin-bottom: 10px;
    }
  matches-component  .team-widget img {
        width: 19px;
        height: 19px;
        margin-bottom: 4px;
    }
   matches-component .team-name-widget {
        font-size: 0.5rem;
    }
   matches-component .team-odd-widget {
        font-size: 0.42rem;
        padding: 2px 4px;
        width: 20%;
    }

    /* Score */
 matches-component   .score-widget {
        font-size: 0.7rem;
        margin: 0 0;
    }

    /* Show More Button */
   matches-component .show-more-widget {
        padding: 4px 8px;
        font-size: 0.75rem;
        }
        
    
    
    
    
    
    
matches-component .star-icon {
    font-size: 1.1rem;
   
}
matches-component .img-circle-container-game-logo-widget {
 width: 0.6rem;
 height: 0.6rem;
  top:3px;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.6rem;
 max-height: 0.6rem;
 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1.3rem;
 height: 1.3rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1.2rem;
 max-height: 1.2rem;
 }
  matches-component .img-circle-container-widget {

   width: 1.1rem;  /* Adjust the width as needed */
    height: 1.1rem; /* Ensure the height is the same as the width for a perfect circle */
     
}
 matches-component .img-circle-container-widget img {

   max-width: 1.1rem;  
    max-height: 1.1rem; 
}
     matches-component .team-name-resize-widget{
         font-size: 0.45rem;
         font-weight: 600;
         color:#ffffff;
         
}
  matches-component .text-resize-badge{
         font-size: 0.35rem;
         font-weight: 500;
    }
    
        matches-component .nav .nav-item {
    font-size: 0.6rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.6rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-matches {
         font-size: 0.6rem;
         font-weight: 400;
    }

         matches-component .text-resize-tournament{
         font-size: 0.55rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.45rem;
         font-weight: 200;
         
    }
   
    
    matches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
    }
    
         matches-component .img-circle-container {

   width: 1.5rem;  /* Adjust the width as needed */
    height: 1.5rem; /* Ensure the height is the same as the width for a perfect circle */
}
 
          matches-component .img-circle-container img{

   max-width: 1.5rem;
   max-height: 1.5rem;
}
 
     matches-component .team-name-resize{
         font-size: 0.65rem;
         font-weight: 500;
         color: white ;
        
}

 
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }

}
@media only screen and (min-width: 824px) and (max-width: 1199px) {

 matches-component .nav  {
    justify-content: space-between !important;
    padding-left: 1.6vw !important;
    padding-right: 1.6vw !important;
    }

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 824px) and (max-width: 1399px) {


    /* Match Item */
  matches-component  .match-item-widget {
        padding: 0;
    }

    /* Match Header */
  matches-component  .match-header-widget .game-logo-widget {
        width: 20px;
        height: 20px;
    }
.text-resize-tournament-widget{
         font-size:0.5rem;
         max-width: calc(2rem + 6vw);
         }
    /* Live Badge */
  matches-component  .text-resize-badge-widget {
        font-size: 0.4rem;
        padding: 2px 4px;
    }
 matches-component   .text-resize-badge-widget i {
        margin-right: 2px;
        font-size: 0.4rem;
    }

    /* Teams and Odds */
  matches-component  .team-widget img {
        width: 21px;
        height: 21px;
    }
  matches-component  .team-name-widget {
        font-size: 0.6rem;
    }
  matches-component  .team-odd-widget {
        font-size: 0.51rem;
        padding: 2px 4px;
        width: 30%;
    }

    /* Score */
 matches-component   .score-widget {
        font-size: 0.7rem;
        margin: 0 0;
    }

    /* Show More Button */
  matches-component  .show-more-widget {
        padding: 7px 14px;
        font-size: 0.7rem;
    }
    
    
    
    
    
    
    
    
matches-component .img-circle-container-game-logo-widget {
 width: 0.7rem;
 height: 0.7rem;
 top:0;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.7rem;
 max-height: 0.7rem;
 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1.4rem;
 height: 1.4rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1.3rem;
 max-height: 1.3rem;
 }
  matches-component .img-circle-container-widget {

   width: 1.1rem;  /* Adjust the width as needed */
    height: 1.1rem; /* Ensure the height is the same as the width for a perfect circle */
}
 matches-component .img-circle-container-widget img {

   max-width: 1.1rem;  
    max-height: 1.1rem; 
}
     matches-component .team-name-resize-widget{
         font-size: 0.6rem;
         font-weight: 800;
         color:#ffffff;
         /*margin: auto !important;*/
}
  matches-component .text-resize-badge{
         font-size: 0.5rem;
         font-weight: 800;
    }
        matches-component .nav .nav-item {
    font-size: 0.65rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.65rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-matches {
         font-size: 0.65rem;
         font-weight: 400;
    }

         matches-component .text-resize-tournament{
         font-size: 0.6rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.5rem;
         font-weight: 200;
         
    }
  
    
    matches-component .tournament-logo-resize{
    max-width: 0.8rem;
    max-height: 0.8rem;
    }
    
         matches-component .img-circle-container {

   width: 2rem;  /* Adjust the width as needed */
    height: 2rem; /* Ensure the height is the same as the width for a perfect circle */
}
 
        matches-component .img-circle-container img{

   max-width: 1.8rem;
   max-height: 1.8rem;
}
 
     matches-component .team-name-resize{
         font-size: 0.7rem;
         font-weight: 500;
         color: white ;
        
}

   matches-component .img-circle-container-widget-game-logo{
   width: 1rem;
   height: 1rem;
   }
  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
 
    /* Match Item */
   matches-component .match-item-widget {
        padding: 0;
        margin-bottom: 18px;
    }

    /* Match Header */
  matches-component  .match-header-widget .game-logo-widget {
        width: 24px;
        height: 24px;
    }
.text-resize-tournament-widget{
         font-size:0.6rem;
         max-width: 110px;
         }
    /* Live Badge */
   matches-component .text-resize-badge-widget {
        font-size: 0.5rem;
        padding: 4px 8px;
    }
  matches-component  .text-resize-badge-widget i {
        
        font-size: 0.5rem;
    }

    /* Teams and Odds */
  matches-component  .team-widget img {
        width: 30px;
        height: 30px;
    }
  matches-component  .team-name-widget {
        font-size: 0.8rem;
    }
  matches-component  .team-odd-widget {
        font-size: 0.7rem;
        padding: 4px 8px;
        width: 30%;
    }

    /* Score */
  matches-component  .score-widget {
        font-size: 1.1rem;
        margin: 12px 0;
    }

    /* Show More Button */
   matches-component .show-more-widget {
        padding: 8px 22px;
        font-size: 0.9rem;
    }
    
    
    
    
    
    
    
    
    

matches-component .star-icon {
    font-size: 1.2rem;
   
}
  matches-component .img-circle-container-widget {

   width: 1.6rem;  /* Adjust the width as needed */
    height: 1.6rem; /* Ensure the height is the same as the width for a perfect circle */
}
 matches-component .img-circle-container-widget img {

   max-width: 1.6rem;  
    max-height: 1.6rem; 
}
     matches-component .team-name-resize-widget{
         font-size: 0.9rem;
         font-weight: 600;
         color:#ffffff;
         margin:5% !important;
}
  matches-component .text-resize-badge{
         font-size: 0.6rem;
         font-weight: 800;
    }

    matches-component .nav .nav-item {
    font-size: 0.7rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.7rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-matches {
         font-size: 0.7rem;
         font-weight: 400;
    }
         matches-component .text-resize-tournament{
         font-size: 0.65rem;
         font-weight: 300;
    }
 
          matches-component .text-resize-date{
         font-size: 0.55rem;
         font-weight: 200;
         
    }
matches-component .img-circle-container-game-logo-widget {
 width: 1rem;
 height: 1rem;
 top:0;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.8rem;
 max-height: 0.8rem;

 }   
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }   
 matches-component .img-circle-container-tournament-logo {
 width: 1.5rem;
 height: 1.5rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1.4rem;
 max-height: 1.4rem;
 }
   matches-component .img-circle-container-widget-game-logo{
   width: 1.5rem;
   height: 1.5rem;
   }
         matches-component .img-circle-container {

   width: 2.2rem;  /* Adjust the width as needed */
    height: 2.2rem; /* Ensure the height is the same as the width for a perfect circle */
}
         matches-component .img-circle-container img {

   max-width: 2rem;
   max-height: 2rem;
}
 
 
     matches-component .team-name-resize{
         font-size: 0.8rem;
         font-weight: 500;
         color: white ;
        
}


  
matches-component .mobileOnlyCss{
    margin-top:0!important;
    }


}

@media only screen and (min-width: 1499px) and (max-width: 1699px) {

    /* Match Item */
   matches-component .match-item-widget {
        padding: 0;
        margin-bottom: 18px;
    }

    /* Match Header */
  matches-component  .match-header-widget .game-logo-widget {
        width: 24px;
        height: 24px;
    }
.text-resize-tournament-widget{
         font-size:0.6rem;
         max-width: 160px;
         }
    /* Live Badge */
   matches-component .text-resize-badge-widget {
        font-size: 0.6rem;
        padding: 4px 8px;
    }
  matches-component  .text-resize-badge-widget i {

        font-size: 0.7rem;
    }

    /* Teams and Odds */
  matches-component  .team-widget img {
        width: 30px;
        height: 30px;
    }
  matches-component  .team-name-widget {
        font-size: 0.8rem;
    }
  matches-component  .team-odd-widget {
        font-size: 0.7rem;
        padding: 4px 8px;
        width: 30%;
    }

    /* Score */
  matches-component  .score-widget {
        font-size: 1.1rem;
        margin: 12px 0;
    }

    /* Show More Button */
   matches-component .show-more-widget {
        padding: 8px 22px;
        font-size: 0.9rem;
    }










matches-component .star-icon {
    font-size: 1.2rem;

}
  matches-component .img-circle-container-widget {

   width: 1.6rem;  /* Adjust the width as needed */
    height: 1.6rem; /* Ensure the height is the same as the width for a perfect circle */
}
 matches-component .img-circle-container-widget img {

   max-width: 1.6rem;
    max-height: 1.6rem;
}
     matches-component .team-name-resize-widget{
         font-size: 0.9rem;
         font-weight: 600;
         color:#ffffff;
         margin:5% !important;
}
  matches-component .text-resize-badge{
         font-size: 0.6rem;
         font-weight: 800;
    }

    matches-component .nav .nav-item {
    font-size: 0.7rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.7rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-matches {
         font-size: 0.8rem;
         font-weight: 400;
    }
         matches-component .text-resize-tournament{
         font-size: 0.65rem;
         font-weight: 300;
    }

          matches-component .text-resize-date{
         font-size: 0.55rem;
         font-weight: 200;

    }
matches-component .img-circle-container-game-logo-widget {
 width: 1rem;
 height: 1rem;
 top:0;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.8rem;
 max-height: 0.8rem;

 }
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }
 matches-component .img-circle-container-tournament-logo {
 width: 1.5rem;
 height: 1.5rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1.4rem;
 max-height: 1.4rem;
 }
   matches-component .img-circle-container-widget-game-logo{
   width: 1.5rem;
   height: 1.5rem;
   }
         matches-component .img-circle-container {

   width: 2.2rem;  /* Adjust the width as needed */
    height: 2.2rem; /* Ensure the height is the same as the width for a perfect circle */
}
         matches-component .img-circle-container img {

   max-width: 2rem;
   max-height: 2rem;
}


     matches-component .team-name-resize{
         font-size: 0.8rem;
         font-weight: 500;
         color: white ;

}

matches-component .mobileOnlyCss{
    margin-top:0!important;
    }


}
    @media only screen and (min-width: 1700px) {

    /* Match Item */
   matches-component .match-item-widget {
        padding: 0;
        margin-bottom: 18px;
    }

    /* Match Header */
  matches-component  .match-header-widget .game-logo-widget {
        width: 24px;
        height: 24px;
    }
.text-resize-tournament-widget{
         font-size:0.65rem;
         max-width: 225px;
         }
    /* Live Badge */
   matches-component .text-resize-badge-widget {
        font-size: 0.6rem;
        padding: 4px 8px;
    }
  matches-component  .text-resize-badge-widget i {

        font-size: 0.7rem;
    }

    /* Teams and Odds */
  matches-component  .team-widget img {
        width: 30px;
        height: 30px;
    }
  matches-component  .team-name-widget {
        font-size: 0.8rem;
    }
  matches-component  .team-odd-widget {
        font-size: 0.7rem;
        padding: 4px 8px;
        width: 30%;
    }


    /* Score */
  matches-component  .score-widget {
        font-size: 1.1rem;
        margin: 12px 0;
    }

    /* Show More Button */
   matches-component .show-more-widget {
        padding: 8px 22px;
        font-size: 0.9rem;
    }










matches-component .star-icon {
    font-size: 1.2rem;

}
  matches-component .img-circle-container-widget {

   width: 1.6rem;  /* Adjust the width as needed */
    height: 1.6rem; /* Ensure the height is the same as the width for a perfect circle */
}
 matches-component .img-circle-container-widget img {

   max-width: 1.6rem;
    max-height: 1.6rem;
}
     matches-component .team-name-resize-widget{
         font-size: 0.9rem;
         font-weight: 600;
         color:#ffffff;
         margin:5% !important;
}
  matches-component .text-resize-badge{
         font-size: 0.6rem;
         font-weight: 800;
    }

    matches-component .nav .nav-item {
    font-size: 0.7rem;
    font-weight: 700;
    }
matches-component .text-resize-dropdown{
    font-size: 0.7rem;
    font-weight: 700 !important;
    border: none !important;
}
     matches-component .score-matches {
         font-size: 0.9rem;
         font-weight: 400;
    }
         matches-component .text-resize-tournament{
         font-size: 0.65rem;
         font-weight: 300;
    }

          matches-component .text-resize-date{
         font-size: 0.55rem;
         font-weight: 200;

    }
matches-component .img-circle-container-game-logo-widget {
 width: 1rem;
 height: 1rem;
 top:0;
 }
 matches-component  .img-circle-container-game-logo-widget img{
 max-width: 0.8rem;
 max-height: 0.8rem;

 }
 matches-component .img-circle-container-game-logo {
 width: 1rem;
 height: 1rem;
 }
 matches-component  .img-circle-container-game-logo img{
 max-width: 1rem;
 max-height: 1rem;
 }
 matches-component .img-circle-container-tournament-logo {
 width: 1.5rem;
 height: 1.5rem;
 }
 matches-component  .img-circle-container-tournament-logo img{
 max-width: 1.4rem;
 max-height: 1.4rem;
 }
   matches-component .img-circle-container-widget-game-logo{
   width: 1.5rem;
   height: 1.5rem;
   }
         matches-component .img-circle-container {

   width: 3rem;  /* Adjust the width as needed */
    height: 3rem; /* Ensure the height is the same as the width for a perfect circle */
}
         matches-component .img-circle-container img {

   max-width: 2.5rem;
   max-height: 2.5rem;
}


     matches-component .team-name-resize{
         font-size: 0.8rem;
         font-weight: 500;
         color: white ;

}

matches-component .mobileOnlyCss{
    margin-top:0!important;
    }


}
 </style>
`

export class MatchesComponent extends BaseComponent {

    static checkboxClickHandler(event) {
        event.stopPropagation();
        let checkbox = event.target;
        const favourites = JSON.parse(localStorage.getItem('myEsportsfavourites')) || {};
        favourites[checkbox.id] = checkbox.checked;
        localStorage.setItem('myEsportsfavourites', JSON.stringify(favourites));
    }


    initCallback() {

        if (window.location.href.includes('matches')) {
            console.log('The URL includes "matches".');
        }
        RouterAssistant.checkForSingleContainer('matches-component', MatchesComponent.initiator);
        window.MatchesComponent = MatchesComponent;
        document.head.insertAdjacentHTML("beforeend", css);

    }
    static favCheckbox;
    static globalDate = new Date();
    static userLanguage = window.navigator.language;
    static boolCheck = false;
    static statusStatic = '';
    static currentTranslateX = 0;
    static searchQuery = '';


    static initiator() {


        MatchesComponent.ShowStateMenuOrNot();
        MatchesComponent.addNavStatusListeners();
        MatchesComponent.addFilterListeners();

        MatchesComponent.getMatches('fromMatchesComponent', () => {
            MatchesComponent.checkUrlToActivateFilters(); // this exists for initial run if prompted from a url and not a redirect
            if (document.querySelectorAll('#upcomingmatches-html').length !== 0) {
                console.debug('I am running the matches component call back!');
                console.debug('running the prediction specific matches call back as im  in matches!');

                console.debug(MatchesComponent.globalDate);
                if (!document.getElementById('#dateSelectorMatches')) {
                    MatchesComponent.js(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate);
                } else {
                    MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate);
                }
                if (document.querySelector('.filter-tournaments-count')) {
                    document.querySelector('.filter-tournaments-count').classList.add('d-none');
                }
                if (document.querySelector('.filter-teams-count')) {
                    document.querySelector('.filter-teams-count').classList.add('d-none');
                }
                if (document.querySelector('.filter-providers-count')) {
                    document.querySelector('.filter-providers-count').classList.add('d-none');
                }
                if (document.querySelector('.filters-general-count')) {
                    document.querySelector('.filters-general-count').classList.add('d-none');
                }
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    // if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                    upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                    } else {
                        document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: '' });
                    }
                    if (!window.location.href.includes('matches')) {
                        const showMoreButton = '<button id="show-more-button" class="show-more-widget" >View All</button>';
                        document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                        document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                    }
                    // } else {
                    //     upcomingMatchesDiv.innerHTML = /*html*/`
                    // <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    // </div>
                    // `;
                    //     if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                    //         document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                    //     } else {
                    //         document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                    //     }
                    // }

                } else if (!window.location.href.includes('matches')) {
                    const showMoreButton = '<button id="show-more-button" class="show-more-widget">View All</button>';
                    document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                    document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                }
                MatchesComponent.redirectToMatches();
            } else {
                console.debug('The matches component call back is supposed to run but I am not on the correct page!!!')
            }
        });
        GameNavbar.subscribeToGameFilterChanges('fromMatchesComponent', (eventId) => {

            if (document.querySelectorAll('matches-component').length !== 0) // if our component exists on the page
            {
                if (document.querySelector('.filters-dropdown-content')) {
                    document.querySelector('.filters-dropdown-content').classList.add('d-none');
                    document.querySelector('.filter-tournaments-count').classList.add('d-none');
                    document.querySelector('.filter-teams-count').classList.add('d-none');
                    document.querySelector('.filter-providers-count').classList.add('d-none');
                    document.querySelector('.filters-general-count').classList.add('d-none');
                    document.querySelector('.filters-tournament-content').classList.add('d-none');
                    document.querySelector('.filters-teams-content').classList.add('d-none');
                    document.querySelector('.filters-odds-content').classList.add('d-none');
                }
                // MatchesComponent.js(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate);
                if (!document.getElementById('#dateSelectorMatches')) {
                    MatchesComponent.js(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate);
                } else {
                    MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate);
                }
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    // if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                    upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                    } else {
                        document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: '' });
                    }
                    if (!window.location.href.includes('matches')) {
                        const showMoreButton = '<button id="show-more-button" class="show-more-widget" >View All</button>';
                        document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                        document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                    }
                    // } else {
                    //     upcomingMatchesDiv.innerHTML = /*html*/`
                    // <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    // </div>
                    // `;
                    //     if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                    //         document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                    //     } else {
                    //         document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                    //     }
                    // }

                } else if (!window.location.href.includes('matches')) {
                    const showMoreButton = '<button id="show-more-button" class="show-more-widget" >View All</button>';
                    document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                    document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                }
            }
            MatchesComponent.redirectToMatches();
        })

    }

    static addFilterListeners() {
        const filterButton = document.getElementById('filters-tab');
        const filterTournamentButton = document.getElementById('filters-tab-tournament');
        const filterTeamButton = document.getElementById('filters-tab-teams');
        const filterOddsButton = document.getElementById('filters-tab-odds');
        if (filterButton) {
            filterButton.addEventListener('click', () => {
                const filters = document.querySelector('.filters-dropdown-content');
                filters.classList.toggle('d-none');
            });
        }
        console.debug(filterTournamentButton, filterTeamButton, filterOddsButton, 'filter buttons');
        if (filterTournamentButton && filterTeamButton && filterOddsButton) {
            filterTournamentButton.addEventListener('click', () => {
                const filters = document.querySelector('.filters-tournament-content');
                filters.classList.toggle('d-none');
            });
            filterTeamButton.addEventListener('click', () => {
                const filters = document.querySelector('.filters-teams-content');
                filters.classList.toggle('d-none');
            });
            filterOddsButton.addEventListener('click', () => {
                const filters = document.querySelector('.filters-odds-content');
                filters.classList.toggle('d-none');
            });
        }


    }
    static addNavStatusListeners() {
        const navItems = document.querySelectorAll('matches-component .status-selector');
        navItems.forEach(item => {
            item.addEventListener('click', (event) => {
                MatchesComponent.changeUrlAccordingToFilters(event);
                const gameId = GameNavbar.getCurrentlyActivatedGameIfAny();
                document.querySelector('.filters-dropdown-content').classList.add('d-none');
                document.querySelector('.filter-tournaments-count').classList.add('d-none');
                document.querySelector('.filter-teams-count').classList.add('d-none');
                document.querySelector('.filter-providers-count').classList.add('d-none');
                document.querySelector('.filters-general-count').classList.add('d-none');
                document.querySelector('.filters-tournament-content').classList.add('d-none');
                document.querySelector('.filters-teams-content').classList.add('d-none');
                document.querySelector('.filters-odds-content').classList.add('d-none');
                if (!document.getElementById('#dateSelectorMatches')) {
                    MatchesComponent.js(gameId, MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate);
                } else {
                    MatchesComponent.updateMatches(gameId, MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate);
                }

                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerHTML.trim() || upcomingMatchesDiv.innerHTML === '') {
                    // if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                    upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyStateFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                    } else {
                        document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyStateFilterMessage', { game: '' });
                    }
                    if (!window.location.href.includes('matches')) {
                        const showMoreButton = '<button id="show-more-button" class="show-more-widget" >View All</button>';
                        document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                        document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                    }
                    // } else {
                    //     upcomingMatchesDiv.innerHTML = /*html*/`
                    // <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    // </div>
                    // `;
                    //     if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                    //         document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                    //     } else {
                    //         document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                    //     }
                    // }
                }
            });
        });
    }

    static getCurrentlyActivatedStatusIfAny() {
        return RouterAssistant.getCurrentVariableValue('status')
    }

    static changeUrlAccordingToFilters(event) {
        const statusName = event.target.getAttribute('alt');
        console.debug('I am changing the url according to the filters');
        RouterAssistant.addOrUpdateCurrentPageVariable("status", statusName);
        MatchesComponent.checkUrlToActivateFilters();
    }

    static checkUrlToActivateFilters() {
        const currentActiveStatusFilter = RouterAssistant.getCurrentVariableValue('status')
        if (currentActiveStatusFilter) {
            const activeElement = document.querySelector(`[alt="${currentActiveStatusFilter}"]`);
            if (activeElement) {
                const currentlyActiveElement = document.querySelector('.activestatus');
                if (currentlyActiveElement) {
                    currentlyActiveElement.classList.remove('activestatus');
                }
                activeElement.classList.add('activestatus');
            } else {
                console.debug("No matching status filter found");
            }
        } else {
            console.debug("No status filter in URL");
        }
    }


    static getMatches(callbackName, callback) {
        if (window.getMatchesEventListeners === undefined) {
            window.getMatchesEventListeners = {}
            window.getMatchesEventListeners[callbackName] = callback
            let dateWithTimeSet = new Date(MatchesComponent.globalDate);
            dateWithTimeSet.setHours(4, 0, 0, 0);
            if (window.savedDateMatches === undefined) {
                window.savedDateMatches = { [dateWithTimeSet]: 'OK' };
            } else {
                window.savedDateMatches[dateWithTimeSet] = 'OK';
            }
            FirebaseAssistant.getMatches(querySnapshot => {
                console.debug('running initial firebase getMatches() query snapshot for TODAYs matches')
                console.debug('imrunning', new Date());
                querySnapshot.forEach(single => {
                    const data = single.data();
                    if (window.savedMatches === undefined) {
                        window.savedMatches = { [data.id]: data }
                    } else {
                        window.savedMatches[data.id] = data; // see here
                    }
                });
                Object.keys(window.getMatchesEventListeners).forEach(eventListenerName => {
                    window.getMatchesEventListeners[eventListenerName]()
                })
            });
            FirebaseAssistant.getLiveMatches(querySnapshot => {
                console.debug('running initial firebase getMatches() query snapshot for LIVE matches')
                console.debug('imrunning', new Date());
                querySnapshot.forEach(single => {
                    const data = single.data();
                    if (window.savedMatches === undefined) {
                        window.savedMatches = { [data.id]: data }
                    } else {
                        window.savedMatches[data.id] = data; // see here
                    }
                });
                Object.keys(window.getMatchesEventListeners).forEach(eventListenerName => {
                    window.getMatchesEventListeners[eventListenerName]()
                })
            });
        } else if (window.savedMatches === undefined) {
            setTimeout(() => {
                MatchesComponent.getMatches(callbackName, callback)
            }, 50)
        } else {
            window.getMatchesEventListeners[callbackName] = callback
            callback()
        }


    }

    static createGameIdMapping() {
        return {
            // Create a mapping of gameIds to their corresponding currentGameId
            'csgo': 3,
            'dota2': 4,
            'lol': 1,
            'easports': 25,
            'valorant': 26,
            'cod': 23,
            'overwatch': 14,
            'pubg': 20,
            'r6': 24,
            'rocketleague': 22,
            'starcraft': [29, 30],
            'kingofglory': 27,
            'hearthstone': 999,
            'fortnite': 999,
            'fifa': 999,
            'roblox': 999,
            'minecraft': 999,
            'wow': 999,
            'apex': 999,
            'mlegends': 34
        };
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static updateMatches(gameId, statusId, dateparam) {
        console.debug('here', gameId, statusId, dateparam)


        let dayToCheck = document.querySelector('.date-box-matches.active');
        let dayToCheckStr = new Date();
        if (dayToCheck) {
            dayToCheckStr = new Date(dayToCheck.getAttribute('data-date-matches'));
            console.debug('dayToCheck', dayToCheckStr, 'global date', MatchesComponent.globalDate);
            dayToCheckStr.setHours(4, 0, 0, 0);
            if (MatchesComponent.globalDate.getDate() !== dayToCheckStr.getDate()) {
                dayToCheck.classList.remove('active');
                document.querySelectorAll('.date-box-matches').forEach(box => {
                    const save = box.getAttribute('data-date-matches');
                    const saveDate = new Date(save).setHours(4, 0, 0, 0);
                    if (saveDate === MatchesComponent.globalDate.getTime()) {
                        console.debug('saveDate', saveDate, 'globalDate', MatchesComponent.globalDate.getTime());
                        box.classList.add('active');
                        document.querySelectorAll('.activestatus').forEach(function (element) {
                            element.classList.remove('activestatus');
                        });
                        if (new Date(save).getDate() > new Date().getDate()) {
                            statusId = 'not_started';
                        } else if (new Date(save).getDate() < new Date().getDate()) {
                            statusId = 'finished';
                        } else {
                            statusId = 'running';
                        }
                        RouterAssistant.addOrUpdateCurrentPageVariable("status", statusId);
                        MatchesComponent.checkUrlToActivateFilters();
                    }
                });
            }

        }

        const modeElement = document.querySelector('[data-mode]');
        let mode = modeElement ? modeElement.getAttribute('data-mode') : null;
        if (!statusId && mode === 'matchesPage') {
            statusId = 'running';
        }
        dateparam = new Date(dateparam.setHours(4, 0, 0, 0));
        MatchesComponent.globalDate.setTime(dateparam.getTime());
        if (statusId === 'running') {
            console.debug('back to undefined CHECK')
            dateparam = new Date();
            dateparam = new Date(dateparam.setHours(4, 0, 0, 0));
            MatchesComponent.globalDate.setTime(dateparam.getTime());
        }
        // if (boolParam === false || statusId === 'running') {
        //     console.debug('back to undefined CHECK')
        //     dateparam = new Date();
        //     dateparam = new Date(dateparam.setHours(4, 0, 0, 0));
        //     MatchesComponent.globalDate.setTime(dateparam.getTime());
        // }
        console.debug(dateparam)
        console.debug('running update matches')
        let counter = 0;

        document.querySelector('#upcomingmatches-html').innerHTML = ''

        const uniqueTournamentNames = [];
        const uniqueTeamNames = [];
        const uniqueProviders = [];

        const sortedMatchIds = Object.keys(window.savedMatches).sort((a, b) => {
            const dateA = new Date(window.savedMatches[a].begin_at ? window.savedMatches[a].begin_at : window.savedMatches[a].scheduled_at);
            const dateB = new Date(window.savedMatches[b].begin_at ? window.savedMatches[b].begin_at : window.savedMatches[b].scheduled_at);
            return dateA - dateB
        })
        for (let i = 0; i < sortedMatchIds.length; i++) {
            const matchCode = sortedMatchIds[i]
            const data = window.savedMatches[matchCode]

            const dateObject = new Date(dateparam);
            const startOfDay = new Date(dateObject.setHours(0, 0, 0, 0));
            const endOfDay = new Date(dateObject.setHours(23, 59, 59, 999));
            const matchDate = new Date(data.begin_at ? data.begin_at : data.scheduled_at);

            if (window.location.href.includes('matches')) {
                if (statusId !== 'fav-tab' && statusId !== 'running' && dateObject && (matchDate < startOfDay || matchDate > endOfDay)) {
                    continue;
                }
            }



            const currentGameId = data.videogame.id; // see here
            const gameStatus = data.status;
            // const matchid = data.id;

            const gameIdMapping = MatchesComponent.createGameIdMapping();
            const gameIdImg = {
                '3': csgologo,
                '4': dota2,
                '1': lol,
                '25': ea,
                '26': valorant,
                '23': cod,
                '14': overwatch,
                '20': pubg,
                '24': r6,
                '22': rocketleague,
                '29': startcraft,
                '30': startcraft,
                '27': kingofglory,
                '28': wildrift,
                '34': mlegends

            };
            // console.debug('gameId', gameId, gameIdMapping[gameId], currentGameId)
            // If a gameId is provided and it doesn't match the current game's id, skip this match
            if (gameId && gameIdMapping[gameId] !== undefined) {
                if (Array.isArray(gameIdMapping[gameId])) {
                    if (!gameIdMapping[gameId].includes(currentGameId)) {
                        continue;
                    }
                } else {
                    if (gameIdMapping[gameId] !== currentGameId) {
                        continue;
                    }
                }
            }

            let now = new Date();
            now = new Date(now.setHours(4, 0, 0, 0));

            // If a statusId is provided and it doesn't match the current game's status, skip this match
            if (statusId !== 'all-tab') {
                if (statusId && statusId === "predictions" && data && data.predictions && data.predictions !== null) {
                    // console.debug('Prediction Match')
                } else if (statusId && statusId !== gameStatus && statusId !== 'fav-tab') {
                    // console.debug('Match with different status')
                    continue;
                } else if (statusId && statusId === 'fav-tab') { // if the status is fav-tab, check if the match is in the favourites
                    if (!localStorage.getItem('myEsportsfavourites')) {
                        continue;
                    } else {
                        const favourites = JSON.parse(localStorage.getItem('myEsportsfavourites'));
                        if (!favourites["favCheck-" + data.id]) {
                            continue;
                        }
                    }
                } else {
                    // console.debug('Match with the selected status')
                }
            }
            if (!data.opponents || data.opponents.length < 2) {
                // console.debug('\tError: Invalid opponents data', data.id);
                continue;
            } // if no opponents due to tourney format ongoing, skip the match

            const team1name = data.opponents[0].opponent.name;
            const team1acronym = (data.opponents[0].opponent.acronym ? data.opponents[0].opponent.acronym : data.opponents[0].opponent.name).substring(0, 6);
            const team1id = data.opponents[0].opponent.id;
            let team1img = undefined;
            if (data.winner_type === "Team") {
                team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : noImageFound
            } else {
                team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : noImageFoundPlayer
            }





            const team2name = data.opponents[1].opponent.name;
            const team2acronym = (data.opponents[1].opponent.acronym ? data.opponents[1].opponent.acronym : data.opponents[1].opponent.name).substring(0, 6);
            const team2id = data.opponents[1].opponent.id;
            let team2img = undefined;
            if (data.winner_type === "Team") {
                team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : noImageFound
            } else {
                team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : noImageFoundPlayer
            }
            const winnerId = data.winner && data.winner.id ? data.winner.id : data.winner_id;


            const tournamentName = `${data.league.name} ${data.serie.full_name}`;
            const tournamentLogo = data.league.image_url;
            const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid mx-auto d-block " >` : '';
            const gameLogoHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="tournament logo" class="img-fluid mx-auto d-flex justify-content" >`;
            const gameLogoWidgetHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="game logo" class="img-fluid  d-block game-logo-widget" >`;

            const matchTypeRaw = data.match_type;
            const numberOfGames = data.number_of_games;
            let matchTypeFinal = ``;
            if (matchTypeRaw === 'best_of' && (numberOfGames !== 2 && numberOfGames !== 1)) {
                matchTypeFinal = 'best_of_X';
            } else if (matchTypeRaw === 'best_of' && numberOfGames === 1) {
                matchTypeFinal = 'single_game';
            } else if (matchTypeRaw === 'best_of' && numberOfGames === 2) {
                matchTypeFinal = 'best_of_2';
            } else if (matchTypeRaw === 'all_games_played') {
                matchTypeFinal = 'all_games_played';
            } else if (matchTypeRaw === 'first_to') {
                matchTypeFinal = 'first_to_X';
            } else {
                matchTypeFinal = 'general_drop_to';
            }
            const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
            const matchTypefull = `${matchType} ${data.number_of_games.toString()}`;

            const score = `${data.results[0].score.toString()} - ${data.results[1].score.toString()}`;
            const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
            const date = `${dateRaw.toLocaleTimeString(MatchesComponent.userLanguage, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })}`;

            const team1Odds = data.thunderpickData && data.thunderpickData.team1Odds !== 1 ? data.thunderpickData.team1Odds : data.thunderpickData && data.thunderpickData.team1StartingOdds && data.thunderpickData.team1StartingOdds !== 1 ? data.thunderpickData.team1StartingOdds : "";
            const team2Odds = data.thunderpickData && data.thunderpickData.team2Odds !== 1 ? data.thunderpickData.team2Odds : data.thunderpickData && data.thunderpickData.team2StartingOdds && data.thunderpickData.team2StartingOdds !== 1 ? data.thunderpickData.team2StartingOdds : "";
            const currentProvider = data.thunderpickData ? 'thunderpick' : 'No data';

            if (!uniqueTournamentNames.includes(MatchesComponent.capitalizeFirstLetter(tournamentName))) {
                uniqueTournamentNames.push(MatchesComponent.capitalizeFirstLetter(tournamentName));
            }

            if (!uniqueTeamNames.includes(MatchesComponent.capitalizeFirstLetter(team1name))) {
                uniqueTeamNames.push(MatchesComponent.capitalizeFirstLetter(team1name));
            }

            if (!uniqueTeamNames.includes(MatchesComponent.capitalizeFirstLetter(team2name))) {
                uniqueTeamNames.push(MatchesComponent.capitalizeFirstLetter(team2name));
            }

            if (!uniqueProviders.includes(MatchesComponent.capitalizeFirstLetter(currentProvider)) && team1Odds !== "No data" && team2Odds !== "No data") {
                uniqueProviders.push(MatchesComponent.capitalizeFirstLetter(currentProvider));
            }

            const countdownElementIdmatches = `countdownmatches-${matchCode}`;
            const countdownElementIdhome = `countdownhome-${matchCode}`;
            const matchElementId = `${matchCode}`;
            const matchElementIdmatches = `matchfrommatches-${matchCode}`;
            const SerieElement = `${matchCode}seriefrommatches-${data.serie_id}`;
            const matchElementIdhome = `matchfromhome-${matchCode}`;
            const matchName = data.opponents && data.opponents[0].opponent.acronym && data.opponents[1].opponent.acronym ?
                `${data.opponents[0].opponent.acronym.replace(/\s/g, "_").replace(/&/g, "")}_vs_${data.opponents[1].opponent.acronym.replace(/\s/g, "_").replace(/&/g, "")}`
                : data.opponents && data.opponents[0].opponent.name && data.opponents[1].opponent.name ?
                    `${data.opponents[0].opponent.name.replace(/\s/g, "_").replace(/&/g, "")}_vs_${data.opponents[1].opponent.name.replace(/\s/g, "_").replace(/&/g, "")}`
                    : data.name ? `${data.name.replace(/\s/g, "_").replace(/match/gi, "").replace(/&/g, "")}` : 'unknown';

            const team1Element = document.getElementById(`match-${matchCode}-team-${team1id}`);
            const team2Element = document.getElementById(`match-${matchCode}-team-${team2id}`);
            // If winnerId exists and it matches team1id or team2id, add a CSS class to the corresponding team
            if (winnerId) {
                if (winnerId === team1id && team1Element) {
                    team1Element.classList.add('winner-left');
                } else if (winnerId === team2id && team2Element) {
                    team2Element.classList.add('winner-right');
                }
            }

            let countdown = `${countdownElementIdmatches}`;
            let countdownhome = `${countdownElementIdhome}`;



            let mode;
            if (modeElement) {
                mode = modeElement.getAttribute('data-mode');
                const matchesCardinalityThreshold = ((window.innerHeight > window.innerWidth) && window.location.href.includes('home')) ? 2 : 13;

                if (mode === 'matchesPage') {
                    const matchHtml = MatchesComponent.getMatchHtml(mode, matchElementIdmatches, matchElementIdhome, team1img, team1acronym, gameLogoHTML, gameLogoWidgetHTML, countdown, countdownhome, team2acronym, team2img, team1name, team2name, tournamentLogoHTML, tournamentName, matchTypefull, score, date, winnerId, team1id, team2id, SerieElement, matchCode, matchTypeFinal, numberOfGames, matchName, team1Odds, team2Odds, gameStatus);
                    document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', matchHtml);

                    document.querySelectorAll('[id^="favCheck"]').forEach(function (checkbox) {
                        checkbox.addEventListener('click', MatchesComponent.checkboxClickHandler);
                        const favourites = JSON.parse(localStorage.getItem('myEsportsfavourites')) || {};
                        const isChecked = favourites[checkbox.id] === true;
                        checkbox.checked = isChecked;
                    });
                    window.addEventListener('DOMContentLoaded', (event) => {
                        document.querySelectorAll('[id^="favCheck"]').forEach(function (checkbox) {
                            const favourites = JSON.parse(localStorage.getItem('myEsportsfavouritesf')) || {};
                            const isChecked = favourites[checkbox.id] === true;
                            checkbox.checked = isChecked;
                        });
                    });
                    document.querySelectorAll('.star-label').forEach(label => {
                        label.addEventListener('click', function (event) {
                            const checkbox = this.querySelector('.star-checkbox');
                            checkbox.checked = !checkbox.checked;
                            event.preventDefault(); // Prevent the default label behavior
                        });
                    });
                } else if (mode === 'homePage') {
                    if (counter < matchesCardinalityThreshold) {
                        const matchHtml = MatchesComponent.getMatchHtml(mode, matchElementIdmatches, matchElementIdhome, team1img, team1acronym, gameLogoHTML, gameLogoWidgetHTML, countdown, countdownhome, team2acronym, team2img, team1name, team2name, tournamentLogoHTML, tournamentName, matchTypefull, score, date, winnerId, team1id, team2id, SerieElement, matchCode, matchTypeFinal, numberOfGames, matchName, team1Odds, team2Odds, gameStatus);
                        document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', matchHtml);
                        counter++;
                    } else if (counter === matchesCardinalityThreshold) {
                        counter++;
                    }
                }
            }

            const matchTypeElement = document.querySelector(`#${matchElementIdmatches} .matchTypeFromMatches`);
            if (mode === 'matchesPage') {
                if (matchTypeElement.innerHTML === 'all_games_played') {
                    // matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
                    matchTypeElement.innerHTML = `GP${numberOfGames}`;
                } else if (matchTypeElement.innerHTML === 'best_of_X') {
                    // let gamesNumber;
                    // if (window.translateTo === 'el') {
                    //     gamesNumber = Math.floor(numberOfGames / 2) + 1;
                    // } else {
                    //     gamesNumber = numberOfGames;
                    // }
                    // matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
                    matchTypeElement.innerHTML = `BO${numberOfGames}`;
                } else if (matchTypeElement.innerHTML === 'first_to_X') {
                    // matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
                    matchTypeElement.innerHTML = `BO${2 * numberOfGames - 1}`;
                } else if (matchTypeElement.innerHTML === 'best_of_2') {
                    // matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
                    matchTypeElement.innerHTML = `BO2`;
                } else if (matchTypeElement.innerHTML === 'single_game') {
                    // matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
                    matchTypeElement.innerHTML = `BO1`;
                } else {
                    matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
                }
            }

            counter = MatchesComponent.startCountdown(dateRaw, countdownElementIdmatches, countdownElementIdhome, matchElementIdmatches, matchElementIdhome, gameStatus, mode, counter, data.streams_list);
            MatchesComponent.redirectToMatchPage(mode, matchElementIdmatches, matchElementIdhome, matchName);
            MatchesComponent.redirectToSeriePage(SerieElement);

        }
        uniqueTournamentNames.sort((a, b) => a.localeCompare(b));
        uniqueTeamNames.sort((a, b) => a.localeCompare(b));
        // console.debug('Unique Tournament Names:', uniqueTournamentNames);
        // console.debug('Unique Team Names:', uniqueTeamNames);

        MatchesComponent.filterLogic(uniqueTournamentNames, uniqueTeamNames, uniqueProviders);


        let now = new Date();
        now = new Date(now.setHours(4, 0, 0, 0));
        if (MatchesComponent.globalDate.getTime() > now.getTime() && document.querySelectorAll('[data-mode="matchesPage"]').length !== 0) {
            console.debug('start of day is greater than now');
            document.querySelector('#finished').classList.add('disabled');
            // document.querySelector('#all-tab').classList.add('disabled');
            document.querySelector('#not_started').classList.remove('disabled');

        }
        if (MatchesComponent.globalDate.getTime() < now.getTime() && document.querySelectorAll('[data-mode="matchesPage"]').length !== 0) {
            console.debug('end of day is less than now');
            document.querySelector('#not_started').classList.add('disabled');
            // document.querySelector('#all-tab').classList.add('disabled');
            document.querySelector('#finished').classList.remove('disabled');

        }
        if (MatchesComponent.globalDate.getTime() === now.getTime() && document.querySelectorAll('[data-mode="matchesPage"]').length !== 0) {
            console.debug('start of day is equal to now');
            document.querySelector('#not_started').classList.remove('disabled');
            document.querySelector('#finished').classList.remove('disabled');
            // document.querySelector('#all-tab').classList.remove('disabled');
        }
        if (statusId === 'running' && dateparam.getTime() === now.getTime() && document.querySelectorAll('[data-mode="matchesPage"]').length !== 0) {
            const activeElement = document.querySelector('[data-date-matches].active');
            console.debug('activeElement', activeElement);
            if (activeElement) {
                activeElement.classList.remove('active');
            }
            document.querySelector('.theToday').classList.add('active');
        }



        let element = document.getElementById('placeholder-matches-widget');
        if (element) {
            element.style.display = "none"; // deactivate placeholder upcoming matches
        }
        let element2 = document.getElementById('placeholder-matches-big');
        if (element2) {
            element2.style.display = "none"; // deactivate placeholder  matches
        }

        MatchesComponent.redirectToMatches();

        const elements = document.querySelectorAll('.date-box-matches');
        const activeElement = Array.from(elements).find(el => el.classList.contains('active'));

        let dateContent = document.querySelector('.date-content-matches');
        const dateSelectorMatches = document.getElementById('dateSelectorMatches');
        if (dateSelectorMatches) {
            if (dateContent) {
                // MatchesComponent.currentTranslateX = Math.max(MatchesComponent.currentTranslateX - dateSelectorMatches.clientWidth / 4, -600); // Ensure it does not go beyond the right limit
                dateContent.style.transform = `translateX(${MatchesComponent.currentTranslateX}px)`;
            }

            // Get the position of the active element relative to the parent container
            const activeElementRect = activeElement.getBoundingClientRect();
            const containerRect = dateContent.getBoundingClientRect();

            // Calculate the offset of the active element within the container
            const elementOffsetLeft = activeElementRect.left - containerRect.left;

            function getScrollLimit() {
                return dateContent.clientWidth / 2;
            }
            const scrollLimit = getScrollLimit();

            if (activeElement) {
                // console.debug('here', activeElement);
                // Calculate the necessary translateX value to bring the element into view
                const targetTranslateX = ((elementOffsetLeft / dateContent.clientWidth) * (2 * -scrollLimit)) + scrollLimit;
                // Set the new translateX and update the currentTranslateX
                MatchesComponent.currentTranslateX = targetTranslateX;
                dateContent.style.transform = `translateX(${MatchesComponent.currentTranslateX}px)`;
                // console.debug('Element offset:', elementOffsetLeft, 'New TranslateX:', targetTranslateX);
            } else {
                console.warn('No active element found in the list.');
            }

            // Scroll Arrow Functionality
            let scrollLeftBtn = document.querySelector('.scroll-left-matches');
            let scrollRightBtn = document.querySelector('.scroll-right-matches');
            if (scrollLeftBtn && scrollRightBtn) {
                // Remove all listeners from the scroll buttons
                scrollLeftBtn = MatchesComponent.removeAllEventListeners(scrollLeftBtn);
                scrollRightBtn = MatchesComponent.removeAllEventListeners(scrollRightBtn);

                scrollLeftBtn.addEventListener('click', () => {
                    // console.debug('Scroll Left Clicked');
                    MatchesComponent.currentTranslateX = Math.min(MatchesComponent.currentTranslateX + dateSelectorMatches.clientWidth / 4, scrollLimit); // Ensure it does not go beyond the left limit
                    dateContent.style.transform = `translateX(${MatchesComponent.currentTranslateX}px)`;
                    // console.debug('Current TranslateX:', MatchesComponent.currentTranslateX, "dateSelectorMatches.clientWidth", dateSelectorMatches.clientWidth);
                });

                scrollRightBtn.addEventListener('click', () => {
                    // console.debug('Scroll Right Clicked');
                    MatchesComponent.currentTranslateX = Math.max(MatchesComponent.currentTranslateX - dateSelectorMatches.clientWidth / 4, -scrollLimit); // Ensure it does not go beyond the right limit
                    dateContent.style.transform = `translateX(${MatchesComponent.currentTranslateX}px)`;
                    // console.debug('Current TranslateX:', MatchesComponent.currentTranslateX, "dateSelectorMatches.clientWidth", dateSelectorMatches.clientWidth);
                });

                dateContent.addEventListener('touchmove', (e) => {
                    e.preventDefault(); // Prevent swiping or dragging
                }, { passive: false });
                // Prevent scrolling on the target element
                dateContent.addEventListener('wheel', (e) => {
                    e.preventDefault();
                }, { passive: false });

            }
        }

        MatchesComponent.searchBarLogic();
        const searchBar = document.getElementById('searchBar');
        if (searchBar && searchBar.value !== '') {
            MatchesComponent.searchQuery = searchBar.value;
            const inputEvent = new Event('input', { bubbles: true });
            searchBar.dispatchEvent(inputEvent);
        }
        const searchBarMobile = document.getElementById('searchBarMobile');
        if (searchBarMobile && searchBarMobile.value !== '') {
            MatchesComponent.searchQuery = searchBarMobile.value;
            const inputEvent = new Event('input', { bubbles: true });
            searchBarMobile.dispatchEvent(inputEvent);
        }
    }

    static removeAllEventListeners(element) {
        const newElement = element.cloneNode(true); // Create a clone of the element
        element.parentNode.replaceChild(newElement, element); // Replace the old element with the new one
        return newElement; // Return the new element
    }
    static removeHotMatchFromMatches(matchId) {
        const stringMatchId = matchId.toString();

        if (document.querySelector('[data-mode="homePage"]')) {
            const observer = new MutationObserver(() => {
                const matchElement = document.getElementById(`matchfromhome-${stringMatchId}`);
                const showMoreButton = document.getElementById('show-more-button');

                if (showMoreButton && matchElement) {
                    matchElement.classList.add('hidden-important');
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true, // Listen for child additions or removals
                subtree: true,  // Include all descendants
            });
        }
    }

    static getMatchHtml(mode, matchElementIdmatches, matchElementIdhome, team1img, team1acronym, gameLogoHTML, gameLogoWidgetHTML, countdown, countdownhome, team2acronym, team2img, team1name, team2name, tournamentLogoHTML, tournamentName, matchTypefull, score, date, winnerId, team1id, team2id, SerieElement, matchCode, matchTypeFinal, numberOfGames, matchName, team1Odds, team2Odds, status) {
        const alteredMatchElementId = matchElementIdmatches.replace('matchfrommatches-', '');
        const alteredMatchElementIdHome = matchElementIdhome.replace('matchfromhome-', '');

        if (team1Odds !== "" && team2Odds !== "") {
            team1Odds = parseFloat(team1Odds).toFixed(2);
            team2Odds = parseFloat(team2Odds).toFixed(2);
        }

        if (mode === 'matchesPage') {
            return /*html*/ `

    <a href="${window.location.origin}/s_match?match=${alteredMatchElementId}&name=${matchName}" class="matchItem d-flex align-items-center p-1 mt-1 rounded text-white color-lightdark clickable justify-content-between"  id="${matchElementIdmatches}" data-tournament="${tournamentName}" data-team1="${team1name}" data-team2="${team2name}" data-provider-thunderpick="${team1Odds}">
    <!-- Game Info -->
    <div class="ms-1 me-1" style="width: calc(0.5rem + 1.4vw); min-width: calc(0.5rem + 1.4vw); max-width: calc(0.5rem + 1.4vw);">
        <div class="d-flex align-items-center justify-content-center">
            ${gameLogoHTML} 
        </div>
        <div class="d-flex justify-content-center">
            <span class="matchTypeFromMatches" style="font-size: calc(0.4rem + 0.45vw);">${matchTypeFinal}</span>
        </div>
    </div>

    <!-- Tournament and Date -->
    <div class="ms-2 text-light d-none d-xl-block" style="width: calc(2rem + 8vw); min-width: calc(1rem + 8vw); max-width: calc(1rem + 8vw) ;">
        <div class="text-start text-truncate" style="font-size: calc(0.3rem + 0.6vw); margin-top: 0.4vw;">
            <span class=""> ${date}</span>
        </div>
        <div class="text-start text-truncate clickable3" style="font-size: calc(0.3rem + 0.6vw); margin-top: 0.2vw;" id="${SerieElement}">
            ${tournamentName}
        </div>
    </div>
    

    <!-- Match Teams and Score -->
    <div class="d-flex align-items-center justify-content-center text-center" style="width: calc(10rem + 20vw); min-width: calc(10rem + 20vw); max-width: calc(10rem + 20vw);">
        <!-- Team 1 -->
        <div class="d-flex align-items-center justify-content-end ${winnerId === team1id ? 'winner-left2' : ''}" id="match-${matchCode}-team-${team1id}" style="width: calc(5rem + 6vw);">
            <span class="text-truncate" style="font-size: calc(0.4rem + 0.45vw); white-space: normal; min-width: 45px;">${team1name}</span>   
        
            <div class="img-circle-container d-flex justify-content-center flex-column m-1">
                <img src="${team1img}" alt="" class="img-fluid mx-auto d-block">
            </div>
            <span class="new-odds-badge ms-1 me-1 ${team1Odds === "No data" || team1Odds === '' || status === 'finished' || status === 'canceled' ? 'd-none' : ''}">
                                    ${team1Odds}
                        </span>
            <span class="new-odds-badge-placeholder ms-1 me-1 ${team1Odds === "No data" || team1Odds === '' || status === 'finished' || status === 'canceled' ? '' : 'd-none'}">
                           1.00         
                        </span>
        </div>

        <!-- Score (Always Centered) -->
        <div class="fw-bold" style="font-size: calc(0.6rem + 0.3vw); width: calc(2rem + 2vw); text-align: center;">
            ${score}
        </div>

        <!-- Team 2 -->
        <div class="d-flex align-items-center justify-content-start ${winnerId === team2id ? 'winner-right2' : ''}" id="match-${matchCode}-team-${team2id}" style="width: calc(5rem + 6vw);">
            <span class="new-odds-badge ms-1 me-1 ${team1Odds === "No data" || team1Odds === '' || status === 'finished' || status === 'canceled' ? 'd-none' : ''}">
                                    ${team2Odds}
                        </span>
            <span class="new-odds-badge-placeholder ms-1 me-1 ${team1Odds === "No data" || team1Odds === '' || status === 'finished' || status === 'canceled' ? '' : 'd-none'}">
                            1.00        
                        </span>            
            <div class="img-circle-container d-flex justify-content-center flex-column m-1">
                <img src="${team2img}" alt="" class="img-fluid mx-auto d-block">
            </div>
            <span class="text-truncate" style="font-size: calc(0.4rem + 0.45vw); white-space: normal; min-width: 45px;">${team2name}</span>
        </div>
    </div>

    <!-- Countdown for live -->
    <div class="d-none d-xxl-flex" style="width: calc(4rem + 2vw); min-width: calc(4rem + 2vw); max-width: calc(4rem + 2vw);">
        <span class="" id="${countdown}"></span>
    </div>
    <div class="text-start text-truncate d-flex d-xl-none ms-1" style="min-width: calc(1.5rem + 2vw); font-size: calc(0.3rem + 0.55vw); margin-top: 0.4vw;">
            <span class=""> ${date}</span>
     </div>

    <!-- Favourites -->
             <label class="star-label" for="favCheck-${alteredMatchElementId}">
                        <input
                            class="form-check-input star-checkbox"
                            type="checkbox"
                            value=""
                            id="favCheck-${alteredMatchElementId}"
                            aria-label="Add to favorites"
                        >
                        <i class="bi bi-star-fill star-icon"></i>
                    </label>
</a>


            `;
        } else if (mode === 'homePage') {

            return /*html*/ `
               <a href="${window.location.origin}/s_match?match=${alteredMatchElementIdHome}&name=${matchName}" id="${matchElementIdhome}" class="match-item-widget d-flex flex-column">
        <div class="match-header-widget rounded-top color-dark d-flex justify-content-between align-items-center">
            <!-- Game Logo -->
            <div class="tournament-info-widget d-flex justify-content-center align-items-center align-content-center ">
            ${gameLogoWidgetHTML}
            <span class="text-resize-tournament-widget text-truncate ms-1 " data-bs-toggle="tooltip" data-bs-placement="top" title="${tournamentName}">${tournamentName}</span>
            </div>
            <!-- Live Badge -->
            <span id="${countdownhome}" class="text-resize-badge-widget" aria-label="${countdownhome}">
                
            </span>
        </div>
        <div class="teams-odds-widget d-flex justify-content-between align-items-center">
            <!-- Team 1 -->
            <div class="team-widget">
                <img src="${team1img}" alt="${team1name}" class="img-fluid">
                <div class="team-name-widget" data-bs-toggle="tooltip" data-bs-placement="top" title="${team1name}">${team1name}</div>
                <div class="team-odd-widget d-flex justify-content-center align-content-center align-items-center ${team1Odds === "No data" || team1Odds === '' ? 'd-none' : ''}">
                                ${team1Odds}
                            </div>
            </div>
            <!-- Score in the Middle -->
            <div class="score-widget">${score}</div>
            <!-- Team 2 -->
            <div class="team-widget">
                <img src="${team2img}" alt="${team2name}" class="img-fluid">
                <div class="team-name-widget" data-bs-toggle="tooltip" data-bs-placement="top" title="${team2name}">${team2name}</div>
                <div class="team-odd-widget d-flex justify-content-center align-content-center align-items-center ${team2Odds === "No data" || team2Odds === '' ? 'd-none' : ''}">
                
                                ${team2Odds}
                            </div>
            </div>
        </div>
        
        <!-- Bet Now Button (Uncomment if needed)
        <div class="bet-now-widget mt-auto">
            <button class="btn-widget" onclick="window.location.href='https://www.thunderpick.com'" aria-label="Bet Now">
                <i class="bi bi-cash-stack me-1"></i>Bet Now
            </button>
        </div>
        -->
    </a>
            `;

        } else {
            return '';
        }
    }


    static redirectToMatchPage(mode, matchElementIdmatches, matchElementIdhome, matchName) {
        const elementmatches = document.getElementById(matchElementIdmatches);
        const elementhome = document.getElementById(matchElementIdhome);

        if (mode === 'matchesPage' && elementmatches) {
            const alteredMatchElementId = matchElementIdmatches.replace('matchfrommatches-', '');
            elementmatches.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                evt.stopPropagation();
                RouterAssistant.redirect('s_match', {
                    match: alteredMatchElementId,
                    name: matchName
                })
            }))
        } else if (mode === 'homePage' && elementhome) {
            const alteredMatchElementId = matchElementIdhome.replace('matchfromhome-', '');
            elementhome.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                evt.stopPropagation();
                RouterAssistant.redirect('s_match', {
                    match: alteredMatchElementId,
                    name: matchName
                })
            }))
        }
    }

    static redirectToSeriePage(serieElementId) {

        const element = document.getElementById(serieElementId);
        const alteredSerieElementId = serieElementId.replace(/.*seriefrommatches-/, '');
        if (window.location.href.includes('matches') && element && !element.listenerAdded) {
            element.addEventListener('click', function (evt) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                RouterAssistant.redirect('s_tournament', {
                    tournament: alteredSerieElementId
                })
            })
            element.listenerAdded = true;
        }

    }

    static redirectToMatches() {
        const showMoreButton = document.getElementById('show-more-button');
        if (showMoreButton) {
            showMoreButton.addEventListener('click', function (evt) {
                RouterAssistant.redirect("matches", { game: GameNavbar.getCurrentlyActivatedGameIfAny(), status: 'running' });
            })
        }
    }


    static startCountdown(gameStartTime, countdownElementIdmatches, countdownElementIdhome, matchElementIdmatches, matchElementIdhome, gameStatus, mode, counter, streams_list) {
        const now = new Date();
        const diffInMilliseconds = gameStartTime - now;
        const diffInMinutes = Math.round(diffInMilliseconds / 1000 / 60);

        const element = document.getElementById(countdownElementIdmatches);
        const elementhome = document.getElementById(countdownElementIdhome);
        const matchElement = document.getElementById(matchElementIdmatches);
        const matchElementTwo = document.getElementById(matchElementIdhome);
        const alteredMatchElementId = matchElementIdmatches.replace('matchfrommatches-', 'date-');
        const dateElement = document.getElementById(alteredMatchElementId);

        if (mode === 'matchesPage') {
            // innerHTML = I18n.translateString("hotmatchTranslations.badgeHot"));
            if (element && diffInMinutes <= 60 && diffInMinutes > 0) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchUpcoming", { minutes: diffInMinutes });
                element.style.display = 'none';
            } else if (element && (diffInMinutes <= 0 && gameStatus === 'running')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchLive");
            } else if (element && (diffInMinutes <= 0 && diffInMinutes >= -180 && gameStatus === 'not_started')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchAboutTo");
            } else if (element && (gameStatus === 'finished')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchFinished");
                element.style.display = 'none';
            } else if (element && (gameStatus === 'postponed')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchPostponed");
            } else if (element && (gameStatus === 'canceled')) {
                element.innerHTML = I18n.translateString("matchTranslations.matchMatchCanceled");
            } else if (matchElement && diffInMinutes < -180) {
                matchElement.style.display = 'none';
            }

            if (element && matchElement.querySelector('.countdownBadge') && streams_list && streams_list.length > 0 && gameStatus !== 'finished' && gameStatus !== 'postponed' && gameStatus !== 'canceled') {
                matchElement.querySelector('.countdownBadge').innerHTML = '<i class="me-1 bi bi-cast"></i>' + matchElement.querySelector('.countdownBadge').innerHTML;
            }
        } else if (mode === 'homePage') {
            if (elementhome && diffInMinutes > 0) {
                if (diffInMinutes > 1440) {
                    const days = Math.floor(diffInMinutes / 1440);
                    const hours = Math.floor((diffInMinutes % 1440) / 60);
                    const minutes = diffInMinutes % 60;
                    elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchDHM", { days: days, hours: hours, minutes: minutes });
                } else if (diffInMinutes > 60) {
                    const hours = Math.floor(diffInMinutes / 60);
                    const minutes = diffInMinutes % 60;
                    elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchHM", { hours: hours, minutes: minutes });
                } else if (diffInMinutes < 60) {
                    elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchM", { minutes: diffInMinutes });
                }

            } else if (elementhome && (diffInMinutes <= 0 && gameStatus === 'running')) {
                elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchLive");
                elementhome.classList.add('live-badge-widget');
            } else if (elementhome && (diffInMinutes <= 0 && diffInMinutes >= -30 && gameStatus === 'not_started')) {
                elementhome.innerHTML = I18n.translateString("matchTranslations.homeMatchAboutTo");
            } else if (elementhome && (diffInMinutes < 30 && gameStatus === 'not_started')) {
                matchElementTwo.classList.add('hidden-important');
                counter--;
            }
            else if (elementhome && ((gameStatus === 'finished') || (gameStatus === 'postponed') || (gameStatus === 'canceled'))) {
                matchElementTwo.classList.add('hidden-important');
                counter--;
            }

            // if (elementhome && matchElementTwo.querySelector('.countdownBadge') && streams_list && streams_list.length > 0 && gameStatus !== 'finished' && gameStatus !== 'postponed' && gameStatus !== 'canceled') {
            //     matchElementTwo.querySelector('.countdownBadge').innerHTML = '<i class="me-1 bi bi-cast"></i>' + matchElementTwo.querySelector('.countdownBadge').innerHTML;
            // }
        }

        return counter;
    }

    static generateDates() {
        const dates = [];
        const currentDate = new Date();

        // Subtract 7 days from the current date
        currentDate.setDate(currentDate.getDate() - 4);

        // Generate 15 dates
        for (let i = 0; i < 11; i++) {
            // Add a day to the current date
            currentDate.setDate(currentDate.getDate() + 1);
            const formattedDate = new Date(currentDate.getTime());
            dates.push(formattedDate);
        }

        return dates;
    }

    static ShowStateMenuOrNot() {
        const dates = MatchesComponent.generateDates();
        // console.debug(dates);
        if (window.location.href.includes('matches')) {
            document.querySelector('matches-component .stateMenu').innerHTML = /*html*/`
        <div class="container p-0">
        <div class="row">
                        <!-- Custom Scrollable Datepicker with Scroll Arrows -->
        <div class="date-selector-matches-container">
            <button class="scroll-arrow-matches scroll-left-matches" aria-label="Scroll Left"><i class="bi bi-chevron-left"></i></button>
            <div class="date-selector-matches" id="dateSelectorMatches">
                <!-- Date boxes will be injected here by JavaScript -->
            </div>
            <button class="scroll-arrow-matches scroll-right-matches" aria-label="Scroll Right"><i class="bi bi-chevron-right"></i></button>
        </div>
        </div>
                                    <div class="input-group mb-2 d-flex d-xl-none">
                                    <span class="input-group-text search-input-mobile"><i class="bi bi-search"></i></span>
                                    <input id="searchBarMobile" type="text" class="text-truncate search-input-mobile2 form-control" placeholder="${I18n.translateString("matchTranslations.searchPlaceholder")}">
                                    </div>
        <div class="row">
            <ul class="col-12 col-xl-9 nav nav-pills style-nav-pills d-flex rounded justify-content-start align-items-center mb-2">
                <li class="nav-item">
                    <a class="status-selector nav-link activestatus" id="running" alt="running" data-toggle="pill">LIVE</a>
                </li>
                <li class="nav-item">
                    <a class="status-selector nav-link" id="not_started" alt="not_started" data-toggle="pill">UPCOMING</a>
                </li>
               <!-- <li class="nav-item">
                    <a class="nav-link  " id="predictions" alt="predictions" data-toggle="pill">PREDICTIONS</a>
                </li> -->
                <li class="nav-item">
                <a class="status-selector nav-link" id="finished" alt="finished" data-toggle="pill">FINISHED</a>
                </li>
                <!--<li class="nav-item">
                    <a class="nav-link" id="all-tab" alt="all-tab" data-toggle="pill" >ALL</a>
                </li>  -->
                <li class="nav-item" >
                  <a class="status-selector nav-link" id="fav-tab" alt="fav-tab" data-toggle="pill" >
                    <i class="bi bi-star-fill" alt="fav-tab" ></i> FAVORITES
                  </a>

                </li>
            </ul>
                <div class="col-3 nav filters-dropdown style-nav-pills d-flex rounded justify-content-end align-items-center d-none d-xl-flex mb-2">
                                    <form>
                                        <input id="searchBar" type="text" class="text-truncate search-input form-control rounded-pill" placeholder="${I18n.translateString("matchTranslations.searchPlaceholder")}">
                                    </form>
                  <div class="nav-item nav-link d-none" id="filters-tab" alt="filters-tab" data-toggle="pill">
                    <span class=" filters-general-count red-animation d-none">0</span> <span class="">FILTERS</span> <i class="bi bi-chevron-down"></i>
                  </div>
                    <div class="filters-dropdown-content d-none">
                    <div class="row filter-option d-flex justify-content-between">
                    <span class="col-8 text-start">Tournaments</span>
                    <span class="col-1 filter-tournaments-count red-animation d-none">0</span>
                    <i class="col-3 bi bi-chevron-down clickable text-end" id="filters-tab-tournament"></i>
                    <div class="filters-tournament-content mt-2 d-none"> </div>
                    </div>
                    <div class="row filter-option d-flex justify-content-between">
                    <span class="col-8 text-start">Teams</span>
                    <span class="col-1 filter-teams-count red-animation d-none">0</span>
                    <i class="col-3 bi bi-chevron-down clickable text-end" id="filters-tab-teams"></i>
                    <div class="row filters-teams-content mt-2 ms-1 d-none"> </div>
                    </div>
                    <div class="row filter-option d-flex justify-content-between">
                    <span class="col-8 text-start">Providers</span>
                    <span class="col-1 filter-providers-count red-animation d-none">0</span>
                    <i class="col-3 bi bi-chevron-down clickable text-end" id="filters-tab-odds"></i>
                    <div class="filters-odds-content mt-2 d-none"> </div>
                    </div>
                    </div>
                </div>
            </div>
            <div id="upcomingmatches-html"></div>
        </div>
    `;
            document.getElementById('running').innerText = I18n.translateString("matchTranslations.pillLive");
            document.getElementById('not_started').innerText = I18n.translateString("matchTranslations.pillUpcoming");
            // document.getElementById('predictions').innerText = I18n.translateString("matchTranslations.pillPredictions");
            document.getElementById('finished').innerText = I18n.translateString("matchTranslations.pillFinished");
            // document.getElementById('all-tab').innerText = I18n.translateString("matchTranslations.pillAll");
            document.getElementById('fav-tab').innerHTML = I18n.translateString("matchTranslations.pillFavorites");

            document.querySelectorAll('[data-date-matches]').forEach(function (element) {

                if (element && !element.listenerAdded) {
                    let procelement = element.getAttribute('data-date-matches');
                    console.debug('procelement', procelement, 'element', element)
                    MatchesComponent.theDateListener(element, procelement);
                }
                element.listenerAdded = true;
            });

        } else {

            document.querySelector('matches-component .stateMenu').innerHTML =  /*html*/ `

                <div class="container p-0 my-0 my-xl-5 mobileOnlyCss">
            <ul class="nav nav-pills style-nav-pills ">
                <li class="nav-item "  style="display: none;">
                    <a class="nav-link" id="all-tab" data-toggle="pill" ></a>
                </li>
            </ul>
            <div id="upcomingmatches-html"></div>
        </div >
                `;

        }

    }

    static handleInputEvent() {
        const searchBar = document.getElementById(`searchBar`);
        // console.debug('Input value changed:');

        const searchValue = searchBar.value.trim().toLowerCase();
        const searchTerms = searchValue.split(/\s+/).map(term => term.toLowerCase());
        const items = document.querySelectorAll('.matchItem');

        items.forEach(item => {
            const name1 = item.getAttribute('data-team1').toLowerCase();
            const name2 = item.getAttribute('data-team2').toLowerCase();
            const category = item.getAttribute('data-tournament').toLowerCase();

            const name1noSpaces = name1.replace(/\s+/g, '');
            const name2noSpaces = name2.replace(/\s+/g, '');
            const categoryNoSpaces = category.replace(/\s+/g, '');

            const matches = searchTerms.every(term =>
                name1.includes(term) || name2.includes(term) || category.includes(term) ||
                name1noSpaces.includes(term) || name2noSpaces.includes(term) || categoryNoSpaces.includes(term)
            );

            if (matches) {
                item.classList.remove('hidden-important');
            } else {
                item.classList.add('hidden-important');
            }
        });
    }

    static handleInputEventMobile() {
        const searchBar = document.getElementById(`searchBarMobile`);

        const searchValue = searchBar.value.trim().toLowerCase();
        const searchTerms = searchValue.split(/\s+/).map(term => term.toLowerCase());
        const items = document.querySelectorAll('.matchItem');

        items.forEach(item => {
            const name1 = item.getAttribute('data-team1').toLowerCase();
            const name2 = item.getAttribute('data-team2').toLowerCase();
            const category = item.getAttribute('data-tournament').toLowerCase();

            const name1noSpaces = name1.replace(/\s+/g, '');
            const name2noSpaces = name2.replace(/\s+/g, '');
            const categoryNoSpaces = category.replace(/\s+/g, '');

            const matches = searchTerms.every(term =>
                name1.includes(term) || name2.includes(term) || category.includes(term) ||
                name1noSpaces.includes(term) || name2noSpaces.includes(term) || categoryNoSpaces.includes(term)
            );

            if (matches) {
                item.classList.remove('hidden-important');
            } else {
                item.classList.add('hidden-important');
            }
        });
    }

    static searchBarLogic() {
        const searchBar = document.getElementById('searchBar');
        const searchBarMobile = document.getElementById('searchBarMobile');
        if (searchBar) {
            // Ensure that event listeners are not added multiple times
            searchBar.removeEventListener('input', MatchesComponent.handleInputEvent);  // Remove previous listeners
            searchBar.addEventListener('input', MatchesComponent.handleInputEvent);     // Add listener
        }
        if (searchBarMobile) {
            // Ensure that event listeners are not added multiple times
            searchBarMobile.removeEventListener('input', MatchesComponent.handleInputEventMobile);  // Remove previous listeners
            searchBarMobile.addEventListener('input', MatchesComponent.handleInputEventMobile);     // Add listener
        }
    }

    static filterLogic(uniqueTournamentNames, uniqueTeamNames, uniqueProviders) {
        let tourFilters = document.querySelector(".filters-tournament-content");
        let teamFilters = document.querySelector(".filters-teams-content");
        let oddsFilters = document.querySelector(".filters-odds-content");
        if (tourFilters && teamFilters) {
            tourFilters.innerHTML = '';
            teamFilters.innerHTML = '';
            oddsFilters.innerHTML = '';

            for (let i = 0; i < uniqueTournamentNames.length; i++) {
                let tourFilter = document.createElement("div");
                tourFilter.classList.add("filter-tournament");

                let label = document.createElement("label");
                label.classList.add("col-10");
                label.classList.add("text-truncate");
                label.classList.add("filter-label");
                // console.debug('Unique Tournament Names:', uniqueTournamentNames[i]);
                label.innerText = uniqueTournamentNames[i];
                let checkbox = document.createElement("input");
                checkbox.type = "checkbox";
                checkbox.classList.add("form-check-input");
                checkbox.classList.add("col-2");
                checkbox.classList.add("filter-tournament-checkbox");
                checkbox.style = "cursor: pointer;";
                checkbox.value = uniqueTournamentNames[i];
                checkbox.id = uniqueTournamentNames[i].replace(/\s/g, "-").replace(/&/g, "");
                checkbox.addEventListener('change', function () {
                    // Collect all checked checkboxes

                    const checkedTeams = Array.from(
                        document.querySelectorAll('.filter-teams-checkbox:checked')
                    ).map(cb => cb.value);
                    const checkedTournaments = Array.from(
                        document.querySelectorAll('.filter-tournament-checkbox:checked')
                    ).map(cb => cb.value);
                    const checkedProviders = Array.from(
                        document.querySelectorAll('.filter-odds-checkbox:checked')
                    ).map(cb => cb.value);

                    let generalCount = checkedTournaments.length + checkedTeams.length + checkedProviders.length;
                    if (generalCount !== 0) {
                        document.querySelector('.filters-general-count').innerText = generalCount;
                        document.querySelector('.filters-general-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filters-general-count').innerText = 0;
                        document.querySelector('.filters-general-count').classList.add('d-none');
                    }
                    if (checkedTournaments.length !== 0) {
                        document.querySelector('.filter-tournaments-count').innerText = checkedTournaments.length;
                        document.querySelector('.filter-tournaments-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-tournaments-count').innerText = 0;
                        document.querySelector('.filter-tournaments-count').classList.add('d-none');
                    }
                    if (checkedTeams.length !== 0) {
                        document.querySelector('.filter-teams-count').innerText = checkedTeams.length;
                        document.querySelector('.filter-teams-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-teams-count').innerText = 0;
                        document.querySelector('.filter-teams-count').classList.add('d-none');
                    }
                    if (checkedProviders.length !== 0) {
                        document.querySelector('.filter-providers-count').innerText = checkedProviders.length;
                        document.querySelector('.filter-providers-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-providers-count').innerText = 0;
                        document.querySelector('.filter-providers-count').classList.add('d-none');
                    }

                    document.querySelectorAll('[id^="matchfrommatches-"]').forEach(match => {

                        const matchTournament = match.getAttribute('data-tournament');
                        const hasMatchingProvider = checkedProviders.some(provider =>
                            match.hasAttribute(`data-provider-${provider}`)
                        );
                        let team1atr = match.getAttribute('data-team1');
                        team1atr = MatchesComponent.capitalizeFirstLetter(team1atr);
                        let team2atr = match.getAttribute('data-team2');
                        team2atr = MatchesComponent.capitalizeFirstLetter(team2atr);
                        // 1. Show matches that belong to selected tournaments and teams
                        if (
                            (checkedTournaments.length === 0 || checkedTournaments.includes(matchTournament)) &&
                            (checkedTeams.length === 0 || (checkedTeams.includes(team1atr) || checkedTeams.includes(team2atr))) &&
                            (checkedProviders.length === 0 || hasMatchingProvider && match.getAttribute('data-provider-thunderpick') !== 'No data')
                        ) {
                            match.style.display = 'flex'; // Show matching matches
                        } else {
                            match.style.display = 'none'; // Hide non-matching matches
                        }
                    });

                    // If no tournaments or teams are checked, show all matches
                    if (checkedTournaments.length === 0 && checkedTeams.length === 0 && checkedProviders.length === 0) {
                        document.querySelectorAll('[id^="matchfrommatches-"]').forEach(match => {
                            match.style.display = 'flex';
                        });
                    }
                });
                tourFilter.appendChild(label);
                tourFilter.appendChild(checkbox);
                tourFilters.appendChild(tourFilter);
            }

            for (let i = 0; i < uniqueTeamNames.length; i++) {
                let teamFilter = document.createElement("div");
                teamFilter.classList.add("col-6");
                teamFilter.classList.add("filter-teams");

                let label = document.createElement("label");
                label.classList.add("col-10");
                label.classList.add("text-truncate");
                label.classList.add("filter-label")
                // console.debug('Unique Team Names:', uniqueTeamNames[i]);
                label.innerText = uniqueTeamNames[i];
                let checkbox = document.createElement("input");
                checkbox.type = "checkbox";
                checkbox.classList.add("col-2");
                checkbox.classList.add("form-check-input");
                checkbox.classList.add("filter-teams-checkbox");
                checkbox.style = "cursor: pointer;";
                checkbox.value = uniqueTeamNames[i];
                checkbox.id = uniqueTeamNames[i].replace(/\s/g, "-").replace(/&/g, "");
                checkbox.addEventListener('change', function () {
                    // Collect all checked checkboxes
                    const checkedTeams = Array.from(
                        document.querySelectorAll('.filter-teams-checkbox:checked')
                    ).map(cb => cb.value);
                    const checkedTournaments = Array.from(
                        document.querySelectorAll('.filter-tournament-checkbox:checked')
                    ).map(cb => cb.value);
                    const checkedProviders = Array.from(
                        document.querySelectorAll('.filter-odds-checkbox:checked')
                    ).map(cb => cb.value);

                    let generalCount = checkedTournaments.length + checkedTeams.length + checkedProviders.length;
                    if (generalCount !== 0) {
                        document.querySelector('.filters-general-count').innerText = generalCount;
                        document.querySelector('.filters-general-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filters-general-count').innerText = 0;
                        document.querySelector('.filters-general-count').classList.add('d-none');
                    }
                    if (checkedTournaments.length !== 0) {
                        document.querySelector('.filter-tournaments-count').innerText = checkedTournaments.length;
                        document.querySelector('.filter-tournaments-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-tournaments-count').innerText = 0;
                        document.querySelector('.filter-tournaments-count').classList.add('d-none');
                    }
                    if (checkedTeams.length !== 0) {
                        document.querySelector('.filter-teams-count').innerText = checkedTeams.length;
                        document.querySelector('.filter-teams-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-teams-count').innerText = 0;
                        document.querySelector('.filter-teams-count').classList.add('d-none');
                    }
                    if (checkedProviders.length !== 0) {
                        document.querySelector('.filter-providers-count').innerText = checkedProviders.length;
                        document.querySelector('.filter-providers-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-providers-count').innerText = 0;
                        document.querySelector('.filter-providers-count').classList.add('d-none');
                    }

                    document.querySelectorAll('[id^="matchfrommatches-"]').forEach(match => {

                        const matchTournament = match.getAttribute('data-tournament');
                        const hasMatchingProvider = checkedProviders.some(provider =>
                            match.hasAttribute(`data-provider-${provider}`)
                        );
                        let team1atr = match.getAttribute('data-team1');
                        team1atr = MatchesComponent.capitalizeFirstLetter(team1atr);
                        let team2atr = match.getAttribute('data-team2');
                        team2atr = MatchesComponent.capitalizeFirstLetter(team2atr);
                        // 1. Show matches that belong to selected tournaments and teams
                        if (
                            (checkedTournaments.length === 0 || checkedTournaments.includes(matchTournament)) &&
                            (checkedTeams.length === 0 || (checkedTeams.includes(team1atr) || checkedTeams.includes(team2atr))) &&
                            (checkedProviders.length === 0 || hasMatchingProvider && match.getAttribute('data-provider-thunderpick') !== 'No data')
                        ) {
                            match.style.display = 'flex'; // Show matching matches
                        } else {
                            match.style.display = 'none'; // Hide non-matching matches
                        }
                    });

                    // If no tournaments or teams are checked, show all matches
                    if (checkedTournaments.length === 0 && checkedTeams.length === 0 && checkedProviders.length === 0) {
                        document.querySelectorAll('[id^="matchfrommatches-"]').forEach(match => {
                            match.style.display = 'flex';
                        });
                    }
                });
                teamFilter.appendChild(label);
                teamFilter.appendChild(checkbox);
                teamFilters.appendChild(teamFilter);
            }

            for (let i = 0; i < uniqueProviders.length; i++) {
                let oddsFilter = document.createElement("div");
                oddsFilter.classList.add("filter-odds");

                let label = document.createElement("label");
                label.classList.add("col-10");
                label.classList.add("text-truncate");
                label.classList.add("filter-label")
                label.innerText = uniqueProviders[i].charAt(0).toUpperCase() + uniqueProviders[i].slice(1);;
                let checkbox = document.createElement("input");
                checkbox.type = "checkbox";
                checkbox.classList.add("form-check-input");
                checkbox.classList.add("col-2");
                checkbox.classList.add("filter-odds-checkbox");
                checkbox.style = "cursor: pointer;";
                checkbox.value = uniqueProviders[i];
                checkbox.id = uniqueProviders[i].replace(/\s/g, "-").replace(/&/g, "");
                checkbox.addEventListener('change', function () {
                    // Collect all checked checkboxes
                    const checkedTeams = Array.from(
                        document.querySelectorAll('.filter-teams-checkbox:checked')
                    ).map(cb => cb.value);
                    const checkedTournaments = Array.from(
                        document.querySelectorAll('.filter-tournament-checkbox:checked')
                    ).map(cb => cb.value);
                    const checkedProviders = Array.from(
                        document.querySelectorAll('.filter-odds-checkbox:checked')
                    ).map(cb => cb.value);

                    let generalCount = checkedTournaments.length + checkedTeams.length + checkedProviders.length;
                    if (generalCount !== 0) {
                        document.querySelector('.filters-general-count').innerText = generalCount;
                        document.querySelector('.filters-general-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filters-general-count').innerText = 0;
                        document.querySelector('.filters-general-count').classList.add('d-none');
                    }
                    if (checkedTournaments.length !== 0) {
                        document.querySelector('.filter-tournaments-count').innerText = checkedTournaments.length;
                        document.querySelector('.filter-tournaments-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-tournaments-count').innerText = 0;
                        document.querySelector('.filter-tournaments-count').classList.add('d-none');
                    }
                    if (checkedTeams.length !== 0) {
                        document.querySelector('.filter-teams-count').innerText = checkedTeams.length;
                        document.querySelector('.filter-teams-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-teams-count').innerText = 0;
                        document.querySelector('.filter-teams-count').classList.add('d-none');
                    }
                    if (checkedProviders.length !== 0) {
                        document.querySelector('.filter-providers-count').innerText = checkedProviders.length;
                        document.querySelector('.filter-providers-count').classList.remove('d-none');
                    } else {
                        document.querySelector('.filter-providers-count').innerText = 0;
                        document.querySelector('.filter-providers-count').classList.add('d-none');
                    }

                    // Show/hide matches based on checked tournaments
                    document.querySelectorAll('[id^="matchfrommatches-"]').forEach(match => {

                        const matchTournament = match.getAttribute('data-tournament');
                        const hasMatchingProvider = checkedProviders.some(provider =>
                            match.hasAttribute(`data-provider-${provider}`)
                        );
                        let team1atr = match.getAttribute('data-team1');
                        team1atr = MatchesComponent.capitalizeFirstLetter(team1atr);
                        let team2atr = match.getAttribute('data-team2');
                        team2atr = MatchesComponent.capitalizeFirstLetter(team2atr);
                        // 1. Show matches that belong to selected tournaments and teams
                        if (
                            (checkedTournaments.length === 0 || checkedTournaments.includes(matchTournament)) &&
                            (checkedTeams.length === 0 || (checkedTeams.includes(team1atr) || checkedTeams.includes(team2atr))) &&
                            (checkedProviders.length === 0 || hasMatchingProvider && match.getAttribute('data-provider-thunderpick') !== 'No data')
                        ) {
                            match.style.display = 'flex'; // Show matching matches
                        } else {
                            match.style.display = 'none'; // Hide non-matching matches
                        }
                    });

                    // If no tournaments or teams are checked, show all matches
                    if (checkedTournaments.length === 0 && checkedTeams.length === 0 && checkedProviders.length === 0) {
                        document.querySelectorAll('[id^="matchfrommatches-"]').forEach(match => {
                            match.style.display = 'flex';
                        });
                    }
                });
                oddsFilter.appendChild(label);
                oddsFilter.appendChild(checkbox);
                oddsFilters.appendChild(oddsFilter);
            }

        }
    }
    static theDateListener2(element, procelement) {
        element.addEventListener('click', function () {
            document.querySelector('.filters-dropdown-content').classList.add('d-none');
            document.querySelector('.filter-tournaments-count').classList.add('d-none');
            document.querySelector('.filter-teams-count').classList.add('d-none');
            document.querySelector('.filter-providers-count').classList.add('d-none');
            document.querySelector('.filters-general-count').classList.add('d-none');
            document.querySelector('.filters-tournament-content').classList.add('d-none');
            document.querySelector('.filters-teams-content').classList.add('d-none');
            document.querySelector('.filters-odds-content').classList.add('d-none');
            console.debug('element', element)
            console.debug('procelement', procelement)
            console.debug(new Date(procelement))

            // Remove 'active' from all date boxes
            document.querySelectorAll('.date-box-matches').forEach(box => box.classList.remove('active'));
            // Add 'active' to the clicked date box
            element.classList.add('active');

            MatchesComponent.globalDate = new Date(procelement);
            MatchesComponent.globalDate = new Date(MatchesComponent.globalDate.setHours(4, 0, 0, 0));

            let now = new Date();
            now = new Date(now.setHours(4, 0, 0, 0));

            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search);
            if (MatchesComponent.globalDate.getTime() > now.getTime()) {
                MatchesComponent.statusStatic = 'not_started';
                document.querySelectorAll('.activestatus').forEach(function (element) {
                    element.classList.remove('activestatus');
                });
                document.querySelector('#not_started').classList.add('activestatus');

                params.set('status', 'not_started');
                url.search = params.toString();
                history.pushState({}, '', url.toString());
            }
            if (MatchesComponent.globalDate.getTime() < now.getTime()) {
                document.querySelectorAll('.activestatus').forEach(function (element) {
                    element.classList.remove('activestatus');
                });
                MatchesComponent.statusStatic = 'finished';
                document.querySelector('#finished').classList.add('activestatus');

                params.set('status', 'finished');
                url.search = params.toString();
                history.pushState({}, '', url.toString());
            }
            if (MatchesComponent.globalDate.getTime() === now.getTime()) {
                document.querySelectorAll('.activestatus').forEach(function (element) {
                    element.classList.remove('activestatus');
                });
                MatchesComponent.statusStatic = 'running';
                document.querySelector('#running').classList.add('activestatus');

                params.set('status', 'running');
                url.search = params.toString();
                history.pushState({}, '', url.toString());
            }
            if (window.savedDateMatches && window.savedDateMatches[MatchesComponent.globalDate]) {
                console.debug('CHECK UPDATING FROM WINDOW.SAVEDMATCHES')
                console.debug('element', element)

                // MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny(), MatchesComponent.globalDate, MatchesComponent.boolCheck = true);
                MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.statusStatic, MatchesComponent.globalDate);
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    // if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                    upcomingMatchesDiv.innerHTML = /*html*/`
                    <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                    } else {
                        document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: '' });
                    }
                    if (!window.location.href.includes('matches')) {
                        const showMoreButton = '<button id="show-more-button" class="show-more-widget" >View All</button>';
                        document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                        document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                    }
                    // } else {
                    //     upcomingMatchesDiv.innerHTML = /*html*/`
                    // <div class="mt-3 predictionStateFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    // </div>
                    // `;
                    //     if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                    //         document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: document.querySelector('.gameNameActive').innerText });
                    //     } else {
                    //         document.querySelector('.predictionStateFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyPredictionFilterMessage', { predGame: '' });
                    //     }
                    // }

                }
            } else {
                if (window.location.href.includes('matches')) {
                    console.debug('CHECK UPDATING FROM FIREBASE')

                    if (window.savedDateMatches === undefined) {
                        window.savedDateMatches = { [MatchesComponent.globalDate]: 'OK' };
                    } else if (window.savedDateMatches[MatchesComponent.globalDate] === undefined) {
                        window.savedDateMatches[MatchesComponent.globalDate] = 'OK';
                    }
                    FirebaseAssistant.getMatches(querySnapshot => {
                        console.debug('running firebase getMatches() query snapshot with a set date from user click');
                        console.debug('imrunning', new Date());

                        querySnapshot.forEach(single => {
                            const data = single.data();
                            if (window.savedMatches === undefined) {
                                window.savedMatches = { [data.id]: data };
                            } else {
                                window.savedMatches[data.id] = data; // see here
                            }
                        });
                        // Object.keys(window.getMatchesEventListeners).forEach(eventListenerName => {
                        //     window.getMatchesEventListeners[eventListenerName]()
                        // })
                    }, MatchesComponent.globalDate);
                    document.querySelector('#placeholder-matches-big').style.display = '';
                    setTimeout(() => {
                        MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.statusStatic, MatchesComponent.globalDate);
                    }, 700);


                    setTimeout(() => {
                        const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                        if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                            // if ((MatchesComponent.getCurrentlyActivatedStatusIfAny()) !== 'predictions') {
                            upcomingMatchesDiv.innerHTML = /*html*/`
                                <div class="mt-3 gameFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                                </div>
                                `;
                            if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                                document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: document.querySelector('.gameNameActive').innerText });
                            } else {
                                document.querySelector('.gameFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyGameFilterMessage', { game: '' });
                            }
                            if (!window.location.href.includes('matches')) {
                                const showMoreButton = '<button id="show-more-button" class="show-more-widget" >View All</button>';
                                document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', showMoreButton);
                                document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
                            }
                        }
                    }, 701);
                }
            }

        });
        PredictionComponent.dynamicTranslations();
    }

    static js(gameId, statusId) {



        // Function to Initialize the Custom Scrollable Datepicker
        function initializeDateSelector() {

            const ifActive = document.querySelector('.date-box-matches.active');

            const dateSelectorMatches = document.getElementById('dateSelectorMatches');
            if (!dateSelectorMatches) {
                console.debug("Element with id 'dateSelectorMatches' not found.");
                return;
            }
            dateSelectorMatches.innerHTML = ''; // Clear existing rows

            const allDates = generateDateRange(new Date(), 14); // Generate 7  days before and after today
            const todayStr = PredictionComponent.formatDate(new Date());
            let ifActiveBool = false;
            // Center datepicker around today with past and future dates
            allDates.forEach((dateObj, index) => {
                const dateStr = PredictionComponent.formatDate(dateObj);
                const day = dateObj.getDate();
                const dayName = dateObj.toLocaleString(MatchesComponent.userLanguage, { weekday: 'short' });
                const monthName = dateObj.toLocaleString(MatchesComponent.userLanguage, { month: 'short' });

                const dateBox = document.createElement('div');
                dateBox.classList.add('date-box-matches');

                // Label 'Today' if the date is today
                const isTodayDate = (dateStr === todayStr);
                if (ifActive && !isTodayDate && dateStr === ifActive.getAttribute('data-date-matches')) {
                    document.querySelectorAll('.date-box-matches').forEach(box => box.classList.remove('active'));
                    dateBox.classList.add('active');
                    dateBox.innerHTML = `<span class="day-number-matches">${day}</span><span class="day-name-matches">${dayName} ${monthName}</span>`;
                    ifActiveBool = true;
                } else if (isTodayDate) {
                    if (!ifActiveBool) { dateBox.classList.add('active'); }
                    dateBox.innerHTML = `<span class="day-number-matches todayClass">Today</span><span class="day-name-matches">${dayName}</span>`;
                    dateBox.classList.add('theToday');
                } else {
                    dateBox.innerHTML = `<span class="day-number-matches">${day}</span><span class="day-name-matches">${dayName} ${monthName}</span>`;
                }

                dateBox.setAttribute('data-date-matches', dateStr);
                dateBox.setAttribute('role', 'button');
                dateBox.setAttribute('tabindex', '0');
                dateBox.setAttribute('aria-label', 'Select matches  ' + (isTodayDate ? 'Today' : dayName + ' ' + monthName + ' ' + day));



                const selectedDate = dateBox.getAttribute('data-date-matches');
                MatchesComponent.theDateListener2(dateBox, selectedDate);

                // Accessibility: Handle keyboard events
                dateBox.addEventListener('keypress', function (e) {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        this.click();
                    }
                });

                dateSelectorMatches.appendChild(dateBox);
            });

            const dateContent = document.createElement('div');
            dateContent.classList.add('date-content-matches');
            while (dateSelectorMatches.firstChild) {
                dateContent.appendChild(dateSelectorMatches.firstChild);
            }
            dateSelectorMatches.appendChild(dateContent);




            // Scroll to Today's Date on Initialization
            // const activeDateBox = document.querySelector('.date-box-matches.active');
            // if (activeDateBox) {
            //     const offset = activeDateBox.offsetLeft - (dateSelectorMatches.clientWidth / 2) + (activeDateBox.clientWidth / 2);
            //     MatchesComponent.currentTranslateX = -offset;
            //     dateContent.style.transform = `translateX(${MatchesComponent.currentTranslateX}px)`;
            //     // console.debug('Initial   TranslateX:', MatchesComponent.currentTranslateX);
            // }
        }

        // Function to Generate a Date Range (past + future days)
        function generateDateRange(startDate, numberOfDays) {
            const dates = [];
            const halfDays = Math.floor(numberOfDays / 2);
            // Generate past dates
            for (let i = halfDays; i > 0; i--) {
                const pastDate = new Date(startDate);
                pastDate.setDate(startDate.getDate() - i);
                dates.push(pastDate);
            }
            // Add today
            dates.push(new Date(startDate));
            // Generate future dates
            for (let i = 1; i <= halfDays; i++) {
                const futureDate = new Date(startDate);
                futureDate.setDate(startDate.getDate() + i);
                dates.push(futureDate);
            }
            return dates;
        }



        initializeDateSelector();

        // Render predictions for today by default
        const todayStr = new Date();


        MatchesComponent.updateMatches(gameId, statusId, MatchesComponent.globalDate ? MatchesComponent.globalDate : todayStr);
    }


}


MatchesComponent.template =   /*html*/ `
        <div class="stateMenu text-center">
        </div>
                `;

MatchesComponent.reg('matches-component');

import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { MatchesComponent } from "../matches-component/matches-component";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { AuthorsComponent } from "../author-component/author-component";
import 'flag-icon-css/css/flag-icons.min.css';
import csgologo from "../game-navbar/assets/images/csgologo 150x150.png";
import dota2 from "../game-navbar/assets/images/dota2logo 150x150.png";
import lol from "../game-navbar/assets/images/lollogo 150x150.png";
import ea from "../game-navbar/assets/images/ea sports 150x150.png";
import valorant from "../game-navbar/assets/images/valorantlogo 150x150.png";
import cod from "../game-navbar/assets/images/call of duty 150x150.png";
import overwatch from "../game-navbar/assets/images/overwatch 150x150.png";
import pubg from "../game-navbar/assets/images/pubg 150x150.png";
import r6 from "../game-navbar/assets/images/r6 150x150.webp";
import rocketleague from "../game-navbar/assets/images/rocketleague 150x150.png";
import startcraft from "../game-navbar/assets/images/starcraft II 150x150.png";
import apex from "../game-navbar/assets/images/ApexLegends 150x150.webp";
import kingofglory from "../game-navbar/assets/images/KingOfGlory 150x150.webp";
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')

import { I18n } from "../../utilities/i18n/i18n";
import showdown from 'showdown';

import { off } from "process";
import { GamerShowcase } from "../gamer-showcase/gamer-showcase";

const ISO6391 = require('iso-639-1');
const noPlayerImg = require('./assets/author.webp');
const noImageFound = require('../matches-component/Assets/no image found.png')
const aiAvatar = require('../matches-component/Assets/ai.jpeg')

const css = `
<style>
  /* Common Truncation Class */
  postmatches-component .truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  /* Tooltip Styling (Optional Enhancements) */
  postmatches-component .tooltip-inner {
    max-width: 300px; /* Adjust as needed */
    padding: 8px 12px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    font-size: 0.9rem;
  }

  postmatches-component .tooltip.bs-tooltip-top .tooltip-arrow::before,
  postmatches-component .tooltip.bs-tooltip-bottom .tooltip-arrow::before,
  postmatches-component .tooltip.bs-tooltip-left .tooltip-arrow::before,
  postmatches-component .tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-color: rgba(0, 0, 0, 0.75);
  }

  /* Team Section */
  postmatches-component .team-section {
    margin-bottom: 40px;
  }

  postmatches-component .team-title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
    color: #ffffff;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  postmatches-component .matchHistory {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    max-width: 100%;
    margin: 2rem 0 1.5rem 0;
  }

  postmatches-component .team-title::after {
    content: '';
    width: 50px;
    height: 3px;
    background-color: #ff4655;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }

  /* Players Grid */
  /* Bootstrap handles the grid layout via row-cols-* classes */

  /* Player Card */
  postmatches-component .player-card {
    background-color: #1e252e;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    width: 100%; /* Fills the column */
    margin: 0; /* Bootstrap gutters handle spacing */
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  postmatches-component .player-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgb(0,255,238);
  }

  /* Image Wrapper */
  postmatches-component .image-wrapper {
    width: 100%;
    height: 180px;
    overflow: hidden;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  postmatches-component .player-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the image scales properly */
    transition: transform 0.3s;
  }

  postmatches-component .player-card:hover .player-image {
    transform: scale(1.05);
  }

  /* Player Info */
  postmatches-component .player-name {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  postmatches-component .player-name .player-flag {
    margin-right: 8px;
  }

  postmatches-component .player-info {
    font-size: 0.85rem; /* Reduced font size */
    margin: 8px 0;
    color: #b2b2b2;
  }

  postmatches-component .player-flag {
    font-size: 1.1rem;
    vertical-align: middle;
  }

  /* Match Section */
  postmatches-component .match-section {
    background-color: #151d27;
    border: 1px solid #2a3340;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 40px;
    position: relative;
    overflow: hidden;
  }

  postmatches-component .match-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; /* Changed to flex-start for better alignment on mobile */
    margin-bottom: 20px;
  }

  postmatches-component .tournament-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  postmatches-component .game-logo,
  .tournament-logo {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    object-fit: contain;
  }

  postmatches-component .tournament-name {
    font-size: 1.5rem;
    font-weight: 700;
    color: #ff4655;
  }

  postmatches-component .match-date {
    font-size: 1rem;
    color: #adb5bd;
  }

  postmatches-component .match-status {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  postmatches-component .team-vs {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  postmatches-component .team {
    flex: 1;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  postmatches-component .team img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
    transition: transform 0.3s;
    object-fit: contain;
  }

  postmatches-component .team img:hover {
    transform: scale(1.05);
  }

  postmatches-component .team-name {
    font-size: 1.2rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 5px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  postmatches-component .win-loss-badges span {
    margin: 0 2px;
    font-size: 0.75rem;
    font-weight: 800;
  }

  postmatches-component .score {
    font-size: 2rem;
    font-weight: 700;
    margin: 0 20px;
    color: #fff;
  }

  /* Odds Section */
  postmatches-component .odds-section {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

    postmatches-component .odds-section-parent {
    background-color: #090f14;
    border: none;
    border-radius: 10px;
    padding: 20px;  
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  postmatches-component .team-odds {
    flex: 1;
    text-align: center;
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  postmatches-component .team-odds .team-name {
    font-size: 1.1rem;
    font-weight: 600;
  }

  postmatches-component .odds-button {
    background-color: #ff4655;
    border: none;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
    transition: background-color 0.3s, box-shadow 0.3s;
    position: relative;

  }

  postmatches-component .odds-button:hover {
    background-color: #d73648;
    box-shadow: 0 4px 15px rgba(215, 54, 72, 0.4);
  }

  postmatches-component .bet-now {
    text-align: center;
    margin: 10px;
    position: relative;
    z-index: 1;
  }

  postmatches-component .bet-now a {
    display: block;
  }

  postmatches-component .casino-logo {
    width: 150px;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s;
  }

  postmatches-component .casino-logo:hover {
    transform: scale(1.05);
  }

  postmatches-component .bet-now .bet-button {
    background-color: #28a745;
    border: none;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s, box-shadow 0.3s;
    position: relative;
  }

  postmatches-component .bet-now .bet-button:hover {
    background-color: #218838;
    box-shadow: 0 4px 15px rgba(40, 168, 56, 0.4);
  }

  /* Predictions Section */
  postmatches-component .predictions-section {
    padding: 30px 20px;
    position: relative;
    overflow: hidden;
    text-align: center;
  }

  postmatches-component .prediction-content {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 20px;
  }

  postmatches-component .prediction-content span {
    color: #ffd700;
    font-weight: 700;
  }

  postmatches-component .betting-link {
    display: inline-block;
    background-color: rgb(28, 168, 159);
    color: #ffffff;
    padding: 12px 20px;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    transition: background-color 0.3s, box-shadow 0.3s;
    position: relative;
    z-index: 1;
  }

  postmatches-component .betting-link:hover {
    background-color: rgb(28, 214, 202);
    box-shadow: 0 4px 15px rgb(28, 168, 159);
  }

  /* Section Title Styles */
  postmatches-component .section-title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    color: #00ffee; /* Neon color for title */
    margin: 2rem 0 1.5rem 0;
    position: relative;
    z-index: 1; /* To appear above background */
    background-color: transparent; /* Ensure no background color */
  }

  postmatches-component .section-title i {
    margin-right: 0.5rem;
  }

  /* AI Analysis Accordion Styles */
  postmatches-component .accordion-item {
    border: none;
    background-color: #1e1e1e;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    z-index: 1; /* Above background */
    padding: 0;
  }

  postmatches-component .accordion-button {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  postmatches-component .accordion-button:hover,
  postmatches-component  .accordion-button:focus {
    background-color: #2c2c2c;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.1);
    outline: none;
  }

  postmatches-component  .accordion-button::after {
    display: none; /* Remove default caret */
  }

  postmatches-component  .accordion-button::before {
    content: '';
    display: inline-block;
    margin-right: 15px;
    border: solid #00ffee; /* Neon color for caret */
    border-width: 0 3px 3px 0;
    padding: 7px;
    transform: rotate(45deg);
    transition: transform 0.3s;
  }

  postmatches-component  .accordion-button.collapsed::before {
    transform: rotate(-135deg);
  }

  /* Hover Effects on Avatar */
  postmatches-component .img-circle-container-ai:hover img {
    transform: scale(1.05);
  }
  postmatches-component .img-circle-container-tipster:hover img {
    transform: scale(1.05);
  }
  postmatches-component .img-circle-container-ai:hover .ai-icon-overlay {
    color: #ffd700; /* Change icon color on hover */
  }
  postmatches-component .img-circle-container-tipster:hover .tipster-icon-overlay {
    color:rgb(19, 187, 47); /* Change icon color on hover */
  }

  postmatches-component  .accordion-body {
    padding: 25px;
    color: #e0e0e0;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 0 0 15px 15px;
    animation: fadeIn 0.5s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* AI Avatar Container */
  postmatches-component .img-circle-container-ai {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    position: relative;
  }

  postmatches-component .img-circle-container-ai img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid #00ffee; /* Neon border around avatar */
    box-shadow: 0 0 10px rgba(0, 255, 234, 0.5);
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
  }

    /* Tipster Avatar Container */
  postmatches-component .img-circle-container-tipster {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    position: relative;
  }

    postmatches-component .predImage{
      width: 16px;
      height: 16px;
      margin-bottom: 4px;

    }
    postmatches-component .predImage2{
      width: 20px;
      height: 20px;
      margin-bottom: 4px;
      background-color:rgb(61, 63, 63);
      border-radius: 50%;
    }
  postmatches-component .img-circle-container-tipster img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid #00ffee; /* Neon border around avatar */
    box-shadow: 0 0 10px rgba(0, 255, 234, 0.5);
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
  }

  postmatches-component .players-grid {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  /* AI Icon Overlay */
  postmatches-component .ai-icon-overlay {
    position: absolute;
    bottom: -5px;
    right: -5px;
    background-color: #121212;
    border: 2px solid #00ffee;
    border-radius: 50%;
    font-size: 0.8rem;
    color: #00ffee;
    box-shadow: 0 0 5px rgba(0, 255, 234, 0.5);
    transition: color 0.3s ease-in-out;
    text-align: center;
    width: 40%;
    height: 40%;
  }
    /* Tipster Icon Overlay */
    postmatches-component .tipster-icon-overlay {
    position: absolute;
    bottom: -5px;
    right: -5px;
    background-color: #121212;
    border: 2px solid #00ffee;
    border-radius: 50%;
    font-size: 0.8rem;
    color: #00ffee;
    box-shadow: 0 0 5px rgba(0, 255, 234, 0.5);
    transition: color 0.3s ease-in-out;
    text-align: center;
    width: 40%;
    height: 40%;
  }

  /* AI Title */
  postmatches-component .ai-title {
    font-size: 1.3rem;
    font-weight: 700;
    display: block;
  }

  /* AI Clickbait */
  postmatches-component .ai-clickbait {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 10px;
    transition: opacity 0.3s ease-in-out;
  }

    @media (orientation: portrait) {
    postmatches-component .ai-title {
      max-width: 100%;
    }
      postmatches-component .ai-clickbait{
      max-width: 100%;
    }
  }

  /* Tipsters accordions */

  .tipContentPlaceholder hr {
    border: none; /* Remove default styling */
}
/* Headings styling */
.tipContentPlaceholder h1,.tipContentPlaceholder h2, .tipContentPlaceholder h3, .tipContentPlaceholder h4, .tipContentPlaceholder h5, .tipContentPlaceholder h6 {

    color: #ffffff;
    margin-bottom: 1rem;
}

.tipContentPlaceholder h1 {
    font-size: 2.5rem;
    line-height: 1.2;
}

.tipContentPlaceholder h2 {
    font-size: 2rem;
    line-height: 1.3;
}

.tipContentPlaceholder h3 {
    font-size: 1.75rem;
}

.tipContentPlaceholder h4 {
    font-size: 1.5rem;
}

/* Paragraphs styling */
.tipContentPlaceholder p {
    font-size: 1.1rem;
    color: #dadada;
    margin-bottom: 1.5rem;
}

/* Links */
.tipContentPlaceholder a {
    color: #007BFF !important;
    text-decoration: none !important;
    transition: color 0.3s ease !important;
}

.tipContentPlaceholder a:hover {
    color: #0056b3 !important;
}

/* Images */
.tipContentPlaceholder img {
   display: block;              /* Makes the image a block-level element */
    max-width: 100%;             /* Ensures the image scales responsively */
    height: auto;                /* Maintains aspect ratio */
    border-radius: 10px;         /* Rounds the corners */
    margin-bottom: 1.5rem;       /* Adds space below the image */
}

/* Videos and Iframes */
.tipContentPlaceholder iframe,.tipContentPlaceholder  video {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    border: none;
}

/* Lists */
.tipContentPlaceholder ul,.tipContentPlaceholder ol {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

.tipContentPlaceholder li {
    margin-bottom: 0.5rem;
}

/* Blockquotes */
.tipContentPlaceholder blockquote {
    border-left: 4px solid #007BFF;
    padding:0.4rem;
    font-style: italic;
    color: #555;
}

/* Buttons */
.tipContentPlaceholder button,.tipContentPlaceholder  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tipContentPlaceholder button:hover,.tipContentPlaceholder  .btn:hover {
    background-color: #0056b3;
}

/* Tables */
.tipContentPlaceholder table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
}

.tipContentPlaceholder th,.tipContentPlaceholder  td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

  /* Badge Styling */
  postmatches-component .text-resize-badge {
    font-size: 0.8rem; /* Adjust as needed */
  }

  postmatches-component .text-name-ai-resize {
    font-size: 1.3rem; /* Adjust as needed */
  }

  postmatches-component .text-ai-resize {
    font-size: 1rem; /* Adjust as needed */
  }

  /* Text Truncation */
  postmatches-component .text-truncate-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  postmatches-component .text-truncate-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  /* Navigation Styles */
  postmatches-component #past1-pills-tab,
  postmatches-component #past1-pills-tab .nav-item {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  postmatches-component .style-nav-pills > li > a.active {
    background-color: #ff282859; /* Background color for active state */
    color: #fff; /* Text color for active state */
  }

  postmatches-component .style-nav-pills > li > a:hover {
    background-color: #b22a23; /* Background color for hover state */
    cursor: pointer;
    filter: drop-shadow(0 0 4px #b22a23);
    transition: all ease-in-out 0.4s;
    color: #fff; /* Text color for hover state */
  }

  postmatches-component .nav .nav-item,
  postmatches-component .nav .nav-link {
    
    color: #ffffff !important;
    border-radius: 0 0 8px 8px;
  }

  postmatches-component .nav .active {
    background-color: #ff282859 !important;
    color: #ffffff !important;
  }

  postmatches-component .winner-left,
  postmatches-component .winner-right,
  postmatches-component .loser-left,
  postmatches-component .loser-right {
    background-color: #52a35e;
    filter: drop-shadow(#0d1117 0rem 0.4rem 4.8px);
    border-radius: 15px;
  }

  postmatches-component .winner-Score{
    color: #52a35e;
  }

  postmatches-component .loser-left,
  postmatches-component .loser-right {
    background-color: #ff4646;
    border-radius: 15px;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {

      .tipContentPlaceholder h1 {
        font-size: 1.5rem;
    }
    .tipContentPlaceholder h2 {
        font-size: 1.3rem;
    }
    .tipContentPlaceholder p {
        font-size: 0.75rem;
    }

  postmatches-component  .accordion-body {
    font-size: 0.7rem;
  }
    postmatches-component  .prediction-content {
    font-size: 0.7rem;
  }

    /* Adjust Match Header */
    postmatches-component .match-header {
      flex-direction: column;
      align-items: flex-start;
    }

    postmatches-component .tournament-info {
      width: 100%;
      margin-bottom: 10px;
    }

    postmatches-component .tournament-name {
      font-size: 0.65rem;
      max-width: 85%;
    }

    postmatches-component .match-details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    postmatches-component .match-date {
      font-size: 0.55rem;
    }

    postmatches-component .match-status {
      font-size: 0.6rem;
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    /* Make Game Logo Smaller */
    postmatches-component .tournament-logo {
      width: 40px;
      height: 40px;
    }

    postmatches-component .game-logo {
      width: 20px;
      height: 20px;
      margin-right: 0;
    }

    postmatches-component .match-status .badge {
      font-size: 0.6rem;
    }

    /* Adjust Team Images */
    postmatches-component .team img {
      width: 50px;
      height: 50px;
    }

    postmatches-component .team-name {
      font-size: 0.7rem;
      max-width:80px;
    }

    /* Adjust Win/Loss Badges */
    postmatches-component .win-loss-badges span {
      font-size: 0.5rem;
      margin: 0 1px;
      align-content: center;
    }

    /* Adjust Score Font Size */
    postmatches-component .score {
      font-size: 0.8rem;
      margin: 10px 0;
    }

    /* Adjust Odds Section */
    postmatches-component .odds-section {
      flex-direction: row;
      align-items: center;
    }

    postmatches-component .team-odds .team-name {
      font-size: 0.75rem;
    }


    postmatches-component .bet-now img, .bet-now svg {
      width: 85px;
      height: 13px;
    }

    postmatches-component .bet-now .bet-button,
    postmatches-component .odds-button {
      font-size: 0.68rem;
    }

    /* Adjust Predictions Section */
    postmatches-component .predictions-section {
      padding: 20px 15px;
    }


    postmatches-component .betting-link {
      width: 100%;
      font-size: 0.6rem;
    }

    /* Adjust Player Cards for Mobile */
    /* team-section */
    /* Adjust Team Titles */
    postmatches-component .team-title,
    postmatches-component .matchHistory,
    postmatches-component .section-title {
      font-size: 1rem;
    }

    postmatches-component .player-card {
      height: auto;
    }

    postmatches-component .image-wrapper {
      height: 100px;
    }

    postmatches-component .player-flag {
      font-size: 0.6rem;
    }

    postmatches-component .player-name {
      font-size: 0.6rem;
      margin: 5px;
    }

    postmatches-component .player-info {
      font-size: 0.55rem;
    }

    postmatches-component .text-name-ai-resize {
      font-size: 0.8rem;
      font-weight: 800;
    }

    postmatches-component .text-ai-resize {
      font-size: 0.6rem;
      font-weight: 400;
      width: 95%;
    }

    postmatches-component .text-resize-stream {
      font-size: 0.5rem;
      font-weight: 800;
    }

    postmatches-component .tournament-logo-resize {
      max-width: 0.8rem;
      max-height: 0.8rem;
    }

    postmatches-component .text-resize-date {
      font-size: 0.5rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-tournament {
      font-size: 0.4rem;
      font-weight: 500;
    }

    postmatches-component .text-resize-badge {
      font-size: 0.5rem;
      font-weight: 800;
    }

    /* postmatches-component i {
      font-size: 0.5rem;
    } */

    postmatches-component .text-resize-info {
      font-size: 0.4rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-pos-info {
      font-size: 0.45rem;
      font-weight: 800;
      color: white;
    }

    postmatches-component .team-name-resize {
      font-size: 0.7rem;
      font-weight: 800;
    }

    postmatches-component .logo-resize-game {
      max-width: 1rem;
      max-height: 1rem;
    }

    postmatches-component .score-resize {
      font-size: 0.6rem;
      font-weight: 800;
    }

    postmatches-component .player-name-resize {
      font-size: 0.7rem;
      font-weight: 800;
    }

    postmatches-component .img-circle-container-player {
      width: 2.5rem;
      height: 2.5rem; /* Ensure the height is the same as the width for a perfect circle */
    }

    postmatches-component .img-circle-container-player img {
      max-width: 2rem;
      max-height: 2rem;
    }

    postmatches-component .img-circle-container-team-img-main {
      width: 2rem;
      height: 2rem;
    }

    postmatches-component .img-circle-container-team-img-main img {
      max-width: 2rem;
      max-height: 2rem;
    }

    postmatches-component .img-circle-container-team-img {
      width: 1.7rem;
      height: 1.7rem;
    }

    postmatches-component .img-circle-container-team-img img {
      max-width: 1.7rem;
      max-height: 1.7rem;
    }

    postmatches-component .text-resize-match-history {
      font-size: 0.7rem;
      font-weight: 700;
    }

    postmatches-component .h2hNoRecentMsg {
      font-size: 0.6rem;
      font-weight: 500;
    }

    postmatches-component .img-circle-container-game-logo {
      width: 1rem;
      height: 1rem;
    }

    postmatches-component .img-circle-container-game-logo img {
      max-width: 1rem;
      max-height: 1rem;
    }

    postmatches-component .img-circle-container-tournament-logo {
      width: 1rem;
      height: 1rem;
    }

    postmatches-component .img-circle-container-tournament-logo img {
      max-width: 1rem;
      max-height: 1rem;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) and (max-width: 768px) {

  .tipContentPlaceholder h1 {
        font-size: 1.6rem;
    }
    .tipContentPlaceholder h2 {
        font-size: 1.38rem;
    }
    .tipContentPlaceholder p {
        font-size: 0.81rem;
    }

    /* Adjust Match Header */
    postmatches-component .match-header {
      flex-direction: column;
      align-items: flex-start;
    }

    postmatches-component .tournament-info {
      width: 100%;
      margin-bottom: 10px;
    }

    postmatches-component .tournament-name {
      font-size: 0.75rem;
      max-width: 85%;
    }

    postmatches-component .match-details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    postmatches-component .match-date {
      font-size: 0.5rem;
    }

    postmatches-component .match-status {
      font-size: 0.6rem;
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    /* Make Game Logo Smaller */
    postmatches-component .tournament-logo {
      width: 40px;
      height: 40px;
    }

    postmatches-component .game-logo {
      width: 20px;
      height: 20px;
      margin-right: 0;
    }

    postmatches-component .match-status .badge {
      font-size: 0.6rem;
    }

    /* Adjust Team Images */
    postmatches-component .team img {
      width: 50px;
      height: 50px;
    }

    postmatches-component .team-name {
      font-size: 0.7rem;
       max-width:80px;
    }

    /* Adjust Win/Loss Badges */
    postmatches-component .win-loss-badges span {
      font-size: 0.5rem;
      margin: 0 1px;
      align-content: center;
    }

    /* Adjust Score Font Size */
    postmatches-component .score {
      font-size: 0.8rem;
      margin: 10px 0;
    }

    /* Adjust Odds Section */
    postmatches-component .odds-section {
      flex-direction: row;
      align-items: center;
    }

    postmatches-component .team-odds .team-name {
      font-size: 0.75rem;
    }


    postmatches-component .bet-now img, .bet-now svg {
      width: 100px;
      height: 15px;
    }

    postmatches-component .bet-now .bet-button,
    postmatches-component .odds-button {
      font-size: 0.72rem;
    }

    /* Adjust Predictions Section */
    postmatches-component .predictions-section {
      padding: 20px 15px;
    }

    postmatches-component .prediction-content {
      font-size: 1rem;
    }

    postmatches-component .betting-link {
      width: 100%;
    }

    /* Adjust Player Cards for Mobile */
    /* team-section */
    /* Adjust Team Titles */
    postmatches-component .team-title,
    postmatches-component .matchHistory,
    postmatches-component .section-title {
      font-size: 1rem;
    }

    postmatches-component .player-card {
      height: auto;
    }

    postmatches-component .image-wrapper {
      height: 100px;
    }

    postmatches-component .player-flag {
      font-size: 0.6rem;
    }

    postmatches-component .player-name {
      font-size: 0.6rem;
      margin: 5px;
    }

    postmatches-component .player-info {
      font-size: 0.55rem;
    }

    postmatches-component .text-name-ai-resize {
      font-size: 0.8rem;
      font-weight: 800;
    }

    postmatches-component .text-ai-resize {
      font-size: 0.6rem;
      font-weight: 400;
      width: 95%;
    }

    postmatches-component .text-resize-stream {
      font-size: 0.5rem;
      font-weight: 800;
    }

    postmatches-component .tournament-logo-resize {
      max-width: 0.8rem;
      max-height: 0.8rem;
    }

    postmatches-component .text-resize-date {
      font-size: 0.5rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-tournament {
      font-size: 0.4rem;
      font-weight: 500;
    }

    postmatches-component .text-resize-badge {
      font-size: 0.5rem;
      font-weight: 800;
    }

    /* postmatches-component i {
      font-size: 0.5rem;
    } */

    postmatches-component .text-resize-info {
      font-size: 0.4rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-pos-info {
      font-size: 0.45rem;
      font-weight: 800;
      color: white;
    }

    postmatches-component .team-name-resize {
      font-size: 0.7rem;
      font-weight: 800;
    }

    postmatches-component .logo-resize-game {
      max-width: 1rem;
      max-height: 1rem;
    }

    postmatches-component .score-resize {
      font-size: 0.6rem;
      font-weight: 800;
    }

    postmatches-component .player-name-resize {
      font-size: 0.7rem;
      font-weight: 800;
    }

    postmatches-component .img-circle-container-player {
      width: 2rem;
      height: 2rem; /* Ensure the height is the same as the width for a perfect circle */
    }

    postmatches-component .img-circle-container-player img {
      max-width: 1.7rem;
      max-height: 1.7rem;
    }

    postmatches-component .img-circle-container-team-img-main {
      width: 2rem;
      height: 2rem;
    }

    postmatches-component .img-circle-container-team-img-main img {
      max-width: 2rem;
      max-height: 2rem;
    }

    postmatches-component .img-circle-container-team-img {
      width: 1.7rem;
      height: 1.7rem;
    }

    postmatches-component .img-circle-container-team-img img {
      max-width: 1.7rem;
      max-height: 1.7rem;
    }

    postmatches-component .text-resize-match-history {
      font-size: 0.7rem;
      font-weight: 700;
    }

    postmatches-component .h2hNoRecentMsg {
      font-size: 0.7rem;
      font-weight: 500;
    }

    postmatches-component .img-circle-container-game-logo {
      width: 1rem;
      height: 1rem;
    }

    postmatches-component .img-circle-container-game-logo img {
      max-width: 1rem;
      max-height: 1rem;
    }

    postmatches-component .img-circle-container-tournament-logo {
      width: 1.3rem;
      height: 1.3rem;
    }

    postmatches-component .img-circle-container-tournament-logo img {
      max-width: 1.2rem;
      max-height: 1.2rem;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 992px) {

          .tipContentPlaceholder h1 {
        font-size: 1.7rem;
    }
    .tipContentPlaceholder h2 {
        font-size: 1.48rem;
    }
    .tipContentPlaceholder p {
        font-size: 0.88rem;
    }

    /* Adjust Match Header */
    postmatches-component .match-header {
      flex-direction: column;
      align-items: flex-start;
    }

    postmatches-component .tournament-info {
      width: 100%;
      margin-bottom: 10px;
    }

    postmatches-component .tournament-name {
      font-size: 1rem;
    }

    postmatches-component .match-details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    postmatches-component .match-date {
      font-size: 0.6rem;
    }

    postmatches-component .match-status {
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    /* Make Game Logo Smaller */
    postmatches-component .tournament-logo {
      width: 50px;
      height: 50px;
    }

    postmatches-component .game-logo {
      width: 25px;
      height: 25px;
      margin-right: 0;
    }

    postmatches-component .match-status .badge {
      font-size: 0.6rem;
    }

    /* Adjust Team Images */
    postmatches-component .team img {
      width: 60px;
      height: 60px;
    }

    postmatches-component .team-name {
      font-size: 1rem;
      max-width: 130px;

    }

    /* Adjust Win/Loss Badges */
    postmatches-component .win-loss-badges span {
      font-size: 0.6rem;
      margin: 0 1px;
      align-content: center;
    }

    /* Adjust Score Font Size */
    postmatches-component .score {
      font-size: 0.9rem;
      margin: 10px 0;
    }

    /* Adjust Odds Section */
    postmatches-component .odds-section {
      flex-direction: row;
      align-items: center;
    }

    postmatches-component .team-odds .team-name {
      font-size: 0.85rem;
    }

    postmatches-component .bet-now img, .bet-now svg {
      width: 120px;
    }

    postmatches-component .bet-now .bet-button,
    postmatches-component .odds-button {
      font-size: 0.76rem;
    }

    /* Adjust Predictions Section */
    postmatches-component .predictions-section {
      padding: 20px 15px;
    }

    postmatches-component .prediction-content {
      font-size: 1rem;
    }

    postmatches-component .betting-link {
      width: 100%;
    }

    /* Adjust Player Cards for Mobile */
    /* team-section */
    /* Adjust Team Titles */
    postmatches-component .team-title,
    postmatches-component .matchHistory,
    postmatches-component .section-title {
      font-size: 1rem;
    }

    postmatches-component .player-card {
      height: auto;
    }

    postmatches-component .image-wrapper {
      height: 100px;
    }

    postmatches-component .player-flag {
      font-size: 0.8rem;
    }

    postmatches-component .player-name {
      font-size: 0.8rem;
      margin: 5px;
    }

    postmatches-component .player-info {
      font-size: 0.65rem;
    }

    postmatches-component .text-name-ai-resize {
      font-size: 0.9rem;
      font-weight: 800;
    }

    postmatches-component .text-ai-resize {
      font-size: 0.7rem;
      font-weight: 400;
      width: 95%;
    }

    postmatches-component .text-resize-stream {
      font-size: 0.6rem;
      font-weight: 800;
    }

    postmatches-component .tournament-logo-resize {
      max-width: 0.9rem;
      max-height: 0.9rem;
    }

    postmatches-component .text-resize-date {
      font-size: 0.6rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-tournament {
      font-size: 0.4rem;
      font-weight: 500;
    }

    postmatches-component .text-resize-badge {
      font-size: 0.5rem;
      font-weight: 800;
    }

    /* postmatches-component i {
      font-size: 0.5rem;
    } */

    postmatches-component .text-resize-info {
      font-size: 0.4rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-pos-info {
      font-size: 0.45rem;
      font-weight: 800;
      color: white;
    }

    postmatches-component .team-name-resize {
      font-size: 0.7rem;
      font-weight: 800;
    }

    postmatches-component .logo-resize-game {
      max-width: 1rem;
      max-height: 1rem;
    }

    postmatches-component .score-resize {
      font-size: 0.6rem;
      font-weight: 800;
    }

    postmatches-component .player-name-resize {
      font-size: 0.7rem;
      font-weight: 800;
    }

    postmatches-component .img-circle-container-player {
      width: 2.5rem;
      height: 2.5rem; /* Ensure the height is the same as the width for a perfect circle */
    }

    postmatches-component .img-circle-container-player img {
      max-width: 1.9rem;
      max-height: 1.9rem;
    }

    postmatches-component .img-circle-container-team-img-main {
      width: 2rem;
      height: 2rem;
    }

    postmatches-component .img-circle-container-team-img-main img {
      max-width: 2rem;
      max-height: 2rem;
    }

    postmatches-component .img-circle-container-team-img {
      width: 1.7rem;
      height: 1.7rem;
    }

    postmatches-component .img-circle-container-team-img img {
      max-width: 1.7rem;
      max-height: 1.7rem;
    }

    postmatches-component .text-resize-match-history {
      font-size: 0.7rem;
      font-weight: 700;
    }

    postmatches-component .h2hNoRecentMsg {
      font-size: 0.8rem;
      font-weight: 500;
    }

    postmatches-component .img-circle-container-game-logo {
      width: 1rem;
      height: 1rem;
    }

    postmatches-component .img-circle-container-game-logo img {
      max-width: 1rem;
      max-height: 1rem;
    }

    postmatches-component .img-circle-container-tournament-logo {
      width: 1.1rem;
      height: 1.1rem;
    }

    postmatches-component .img-circle-container-tournament-logo img {
      max-width: 1.2rem;
      max-height: 1.2rem;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1300px) {
          .tipContentPlaceholder h1 {
        font-size: 1.8rem;
    }
    .tipContentPlaceholder h2 {
        font-size: 1.55rem;
    }
    .tipContentPlaceholder p {
        font-size: 0.92rem;
    }

    /* Adjust Match Header */
    postmatches-component .match-header {
      flex-direction: column;
      align-items: flex-start;
    }

    postmatches-component .tournament-info {
      width: 100%;
      margin-bottom: 10px;
    }

    postmatches-component .tournament-name {
      font-size: 1.2rem;
      max-width: 85%;
    }

    postmatches-component .match-details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    postmatches-component .match-date {
      font-size: 0.7rem;
    }

    postmatches-component .match-status {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    /* Make Game Logo Smaller */
    postmatches-component  .tournament-logo {
      width: 40px;
      height: 40px;
    }

    postmatches-component  .game-logo {
      width: 20px;
      height: 20px;
      margin-right: 0;
    }

    postmatches-component .match-status .badge {
      font-size: 0.8rem;
    }

    /* Adjust Team Images */
    postmatches-component .team img {
      width: 60px;
      height: 60px;
    }

    postmatches-component .team-name {
      font-size: 1rem;
    }

    /* Adjust Win/Loss Badges */
    postmatches-component .win-loss-badges span {
      font-size: 0.7rem;
      margin: 0 1px;
      align-content: center;
      font-weight: 800;
    }

    /* Adjust Score Font Size */
    postmatches-component .score {
      font-size: 0.9rem;
      font-weight: 700;
      margin: 10px 0;
    }

    /* Adjust Odds Section */
    postmatches-component .odds-section {
      flex-direction: row;
      align-items: center;
    }

    postmatches-component .team-odds .team-name {
      font-size: 0.8rem;
    }

    postmatches-component .bet-now img, .bet-now svg {
      width: 120px;
      height: 18px;
    }

    postmatches-component .bet-now .bet-button,
    postmatches-component .odds-button {
      font-size: 0.8rem;
    }

    /* Adjust Predictions Section */
    postmatches-component .predictions-section {
      padding: 20px 15px;
    }

    postmatches-component .prediction-content {
      font-size: 1rem;
    }

    postmatches-component .betting-link {
      width: 100%;
    }

    /* Adjust Player Cards for Mobile */
    /* team-section */
    /* Adjust Team Titles */
    postmatches-component .team-title,
    postmatches-component .matchHistory,
    postmatches-component .section-title {
      font-size: 1rem;
    }

    postmatches-component .player-card {
      height: auto;
    }

    postmatches-component .image-wrapper {
      height: 80px;
    }

    postmatches-component .player-flag {
      font-size: 0.6rem;
    }

    postmatches-component .player-name {
      font-size: 0.6rem;
      margin: 5px;
    }

    postmatches-component .player-info {
      font-size: 0.55rem;
    }

    postmatches-component .text-name-ai-resize {
      font-size: 0.8rem;
      font-weight: 800;
    }

    postmatches-component .text-ai-resize {
      font-size: 0.6rem;
      font-weight: 400;
      width: 95%;
    }

    postmatches-component .text-resize-stream {
      font-size: 0.5rem;
      font-weight: 800;
    }

    postmatches-component .tournament-logo-resize {
      max-width: 0.8rem;
      max-height: 0.8rem;
    }

    postmatches-component .text-resize-date {
      font-size: 0.5rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-tournament {
      font-size: 0.4rem;
      font-weight: 500;
    }

    postmatches-component .text-resize-badge {
      font-size: 0.5rem;
      font-weight: 800;
    }

    /* postmatches-component i {
      font-size: 0.5rem;
    } */

    postmatches-component .text-resize-info {
      font-size: 0.4rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-pos-info {
      font-size: 0.45rem;
      font-weight: 800;
      color: white;
    }

    postmatches-component .team-name-resize {
      font-size: 0.7rem;
      font-weight: 800;
    }

    postmatches-component .logo-resize-game {
      max-width: 1rem;
      max-height: 1rem;
    }

    postmatches-component .score-resize {
      font-size: 0.6rem;
      font-weight: 800;
    }

    postmatches-component .player-name-resize {
      font-size: 0.7rem;
      font-weight: 800;
    }

    postmatches-component .img-circle-container-player {
      width: 2.5rem;
      height: 2.5rem; /* Ensure the height is the same as the width for a perfect circle */
    }

    postmatches-component .img-circle-container-player img {
      max-width: 2rem;
      max-height: 2rem;
    }

    postmatches-component .img-circle-container-team-img-main {
      width: 2rem;
      height: 2rem;
    }

    postmatches-component .img-circle-container-team-img-main img {
      max-width: 2rem;
      max-height: 2rem;
    }

    postmatches-component .img-circle-container-team-img {
      width: 1.7rem;
      height: 1.7rem;
    }

    postmatches-component .img-circle-container-team-img img {
      max-width: 1.7rem;
      max-height: 1.7rem;
    }

    postmatches-component .text-resize-match-history {
      font-size: 0.7rem;
      font-weight: 700;
    }

    postmatches-component .h2hNoRecentMsg {
      font-size: 0.9rem;
      font-weight: 500;
    }

    postmatches-component .img-circle-container-game-logo {
      width: 1rem;
      height: 1rem;
    }

    postmatches-component .img-circle-container-game-logo img {
      max-width: 1rem;
      max-height: 1rem;
    }

    postmatches-component .img-circle-container-tournament-logo {
      width: 1.4rem;
      height: 1.4rem;
    }

    postmatches-component .img-circle-container-tournament-logo img {
      max-width: 1.3rem;
      max-height: 1.3rem;
    }
  }

  /* Extra large devices (large laptops and desktops, 1300px and up) */
  @media only screen and (min-width: 1300px) {
    /* Adjust Match Header */
    postmatches-component .match-details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

        postmatches-component .tournament-name {
      font-size: 1rem;
    }

    postmatches-component .match-date {
      font-size: 1rem;
    }

    postmatches-component .match-status {
      font-size: 1rem;
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    /* Make Game Logo Smaller */
    postmatches-component .tournament-logo {
      width: 60px;
      height: 60px;
    }

    postmatches-component  .game-logo {
      width: 40px;
      height: 40px;
      margin-right: 0;
    }

    postmatches-component .match-status .badge {
      font-size: 0.9rem;
    }

    postmatches-component .bet-now img, .bet-now svg {
      width: 150px;
      height: 23px;
      object-fit: contain;
    }

    /* Adjust Team Images */
    postmatches-component .team img {
      width: 100px;
      height: 100px;
    }

    postmatches-component .team-name {
      font-size: 1.1rem;
      max-width: 315px;
    }

    /* Adjust Win/Loss Badges */
    postmatches-component .win-loss-badges span {
      font-size: 0.75rem;
      margin: 0 1px;
      align-content: center;
      font-weight: 800;
    }

    /* Adjust Score Font Size */
    postmatches-component .score {
      font-size: 2rem;
      font-weight: 700;
      margin: 10px 0;
    }

    postmatches-component .team-title,
    postmatches-component .matchHistory,
    postmatches-component .section-title {
      font-size: 2rem;
    }

    /* Adjust Predictions Section */
    postmatches-component .predictions-section {
      padding: 20px 15px;
    }

    postmatches-component .prediction-content {
      font-size: 1rem;
    }

    postmatches-component .betting-link {
      width: 100%;
    }

    /* Adjust Player Cards for Mobile */
    /* team-section */
    /* Adjust Team Titles */
    postmatches-component .team-title {
      font-size: 2rem;
    }

    postmatches-component .player-card {
      height: auto;
    }

    postmatches-component .image-wrapper {
      height: 180px;
    }

    postmatches-component .player-flag {
      font-size: 1rem;
    }

    postmatches-component .player-name {
      font-size: 1rem;
      margin: 5px;
    }

    postmatches-component .player-info {
      font-size: 0.9rem;
    }

    postmatches-component .text-name-ai-resize {
      font-size: 1rem;
      font-weight: 800;
    }

    postmatches-component .text-ai-resize {
      font-size: 0.8rem;
      font-weight: 400;
      width: 95%;
    }

    postmatches-component .text-resize-stream {
      font-size: 0.9rem;
      font-weight: 800;
    }

    postmatches-component .tournament-logo-resize {
      max-width: 1.2rem;
      max-height: 1.2rem;
    }

    postmatches-component .text-resize-date {
      font-size: 0.6rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-tournament {
      font-size: 0.65rem;
      font-weight: 500;
    }

    postmatches-component .text-resize-badge {
      font-size: 0.8rem;
      font-weight: 800;
    }

    /* postmatches-component i {
      font-size: 0.5rem;
    } */

    postmatches-component .text-resize-info {
      font-size: 0.6rem;
      font-weight: 400;
    }

    postmatches-component .text-resize-pos-info {
      font-size: 0.65rem;
      font-weight: 800;
      color: white;
    }

    postmatches-component .team-name-resize {
      font-size: 1rem;
      font-weight: 800;
    }

    postmatches-component .logo-resize-game {
      max-width: 1.5rem;
      max-height: 1.5rem;
    }

    postmatches-component .score-resize {
      font-size: 1rem;
      font-weight: 800;
    }

    postmatches-component .player-name-resize {
      font-size: 1rem;
      font-weight: 800;
    }

    postmatches-component .img-circle-container-player {
      width: 4rem;
      height: 4rem; /* Ensure the height is the same as the width for a perfect circle */
    }

    postmatches-component .img-circle-container-player img {
      max-width: 3.3rem;
      max-height: 3.3rem;
    }

    postmatches-component .img-circle-container-team-img-main {
      width: 4rem;
      height: 4rem;
    }

    postmatches-component .img-circle-container-team-img-main img {
      max-width: 4rem;
      max-height: 4rem;
    }

    postmatches-component .img-circle-container-team-img {
      width: 2rem;
      height: 2rem;
    }

    postmatches-component .img-circle-container-team-img img {
      max-width: 2rem;
      max-height: 2rem;
    }

    postmatches-component .text-resize-match-history {
      font-size: 0.9rem;
      font-weight: 700;
    }

    postmatches-component .img-circle-container-game-logo {
      width: 1.4rem;
      height: 1.4rem;
    }

    postmatches-component .img-circle-container-game-logo img {
      max-width: 1.3rem;
      max-height: 1.3rem;
    }

    postmatches-component .img-circle-container-tournament-logo {
      width: 1.5rem;
      height: 1.5rem;
    }

    postmatches-component .img-circle-container-tournament-logo img {
      max-width: 1.4rem;
      max-height: 1.4rem;
    }

    postmatches-component .h2hNoRecentMsg {
      font-size: 1rem;
      font-weight: 500;
    }
  }
</style>

`

export class PostMatchesComponent extends BaseComponent {

  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css);
    RouterAssistant.checkForSingleContainer('postmatches-component', PostMatchesComponent.initiator)
    window.addEventListener('resize', PostMatchesComponent.resizetoTwitchHeight);


  }

  static postGlobalDate = new Date();

  static resizetoTwitchHeight() {
    const twitchIframe = document.querySelector('[id^="twitch-iframe"]');
    if (twitchIframe) {
      const width = twitchIframe.offsetWidth;
      const height = width * (9 / 16);
      twitchIframe.style.height = `${height}px`;
      console.debug('I am running the resizetoTwitchHeight function and the height is', height);
    }
    else {
      console.debug('Twitch iframe element not found');
    }

  }



  static initiator() {
    MatchesComponent.getMatches('fromPostMatchesComponent', async () => {
      if (window.location.href.includes('s_match')) {
        console.debug(window.tadeCallback)
        if (window.tadeCallback === undefined) {
          window.tadeCallback = true;
          console.debug('I am running the post matches component callback!!!')
          const matchcodeid = RouterAssistant.getCurrentVariableValue('match')
          let data = null;
          if (window.savedMatches[matchcodeid] !== undefined) {
            console.debug('The match is found in the saved matches');
            data = window.savedMatches[matchcodeid];
          } else if (window.savedMatchesFromTournaments && window.savedMatchesFromTournaments[matchcodeid] !== undefined) {
            console.debug('The match is found in the saved matches from tournaments');
            data = window.savedMatchesFromTournaments[matchcodeid];
          } else if (window.savedPastMatches) {
            for (let key in window.savedPastMatches) {
              if (window.savedPastMatches[key] !== undefined) {
                for (let i = 0; i < window.savedPastMatches[key].length; i++) {
                  if (window.savedPastMatches[key][i] && window.savedPastMatches[key][i].id && window.savedPastMatches[key][i].id.toString() === matchcodeid) {
                    console.debug('The match is found in the saved past matches');
                    data = window.savedPastMatches[key][i];
                    break;
                  }
                }
              }
            }
          }

          if (!data) {
            console.debug('The match is not found in the saved matches of either, so we are going to fetch the data again');
            await new Promise((resolve, reject) => {
              FirebaseAssistant.getSingleMatch(matchcodeid, querySnapshot => {
                console.debug('getting the single match from firestore')
                if (!querySnapshot.empty) {
                  const single = querySnapshot.docs[0];
                  data = single.data();
                  window.savedMatches[data.id] = data;
                  resolve();
                } else {
                  document.querySelector('#matchpost-html').innerHTML = '';
                  document.querySelector('#matchpost-html').insertAdjacentHTML('beforeend',
                                       /*html*/ `
                                        <br><br><br><br>
                                    <h1 class="text-center text-white">No data for this match, yet.</h1>
                                    <h2 class="text-center text-white">Please check back later</h2>
                                        <br><br><br><br>
                                    `); // Shadow edw paizeis mpala
                  document.getElementById('placeholder-stream').style.display = "none";
                  document.getElementById('placeholder-chat').style.display = "none";
                  window.tadeCallback === undefined;
                }
              });
            });
          }

          // Get the predictions for the match if they are not already saved from other components
          if (!data.predictions) {
            FirebaseAssistant.getSinglePrediction(matchcodeid, querySnapshot => {
              // console.debug('getting the single prediction from firestore')
              if (!querySnapshot.empty) {
                data.predictions = [];
                querySnapshot.forEach(single => {
                  const dataPred = single.data();
                  data.predictions.push(dataPred);
                });
                console.debug('The prediction data is', data);
                data.predictions.forEach(prediction => {
                  let authorKey = window.savedAuthors ? Object.keys(window.savedAuthors).find(key => key.toLowerCase() === prediction.tipster.toLowerCase()) : null;
                  if (!authorKey) {
                    AuthorsComponent.getAuthors('fromPostMatchPageComp', () => {
                      console.debug(`Filling authors data for the predictions section`);
                      authorKey = Object.keys(window.savedAuthors).find(key => key.toLowerCase() === prediction.tipster.toLowerCase());
                      prediction.authorData = window.savedAuthors[authorKey];
                    });
                  } else {
                    console.debug('The author is already saved', prediction.tipster, window.savedAuthors[authorKey]);
                    prediction.authorData = window.savedAuthors[authorKey];
                  }
                });
              }
            });
          } else {
            console.debug('The prediction data is already saved', data.predictions);
            data.predictions.forEach(prediction => {
              let authorKey = window.savedAuthors ? Object.keys(window.savedAuthors).find(key => key.toLowerCase() === prediction.tipster.toLowerCase()) : null;
              if (!authorKey) {
                AuthorsComponent.getAuthors('fromPostMatchPageComp', () => {
                  console.debug(`Filling authors data for the predictions section`);
                  authorKey = Object.keys(window.savedAuthors).find(key => key.toLowerCase() === prediction.tipster.toLowerCase());
                  prediction.authorData = window.savedAuthors[authorKey];
                });
              } else {
                console.debug('The author is already saved', prediction.tipster, window.savedAuthors[authorKey]);
                prediction.authorData = window.savedAuthors[authorKey];
              }
            });
          }
          // end of getting the predictions

          let userLanguage = I18n.getUsersPreferredLanguage();
          let analysisString = '';
          if (data.status !== 'finished') {
            await new Promise((resolve, reject) => {
              FirebaseAssistant.getAnalysis(matchcodeid + '-' + userLanguage, querySnapshot => {
                // console.debug('getting the analysis from firestore')
                if (querySnapshot && !querySnapshot.empty && querySnapshot.status.state === 'COMPLETED') {
                  // console.debug('Analysis found for this match', querySnapshot);
                  analysisString = querySnapshot.output;
                } else if (data.status === 'finished') {
                  analysisString = ``;
                } else {
                  analysisString = I18n.translateString("postMatchTranslations.analysis");
                }
                resolve();
              });
            });
          }
          const converter = new showdown.Converter();
          const clickBaitString = PostMatchesComponent.extractFirstTextBlock(analysisString)
          const htmlAnalysisContent = converter.makeHtml(analysisString); // to htmlAnalysisContent einai pou benei stin html pio kato

          // console.debug('DATAAAAAAAAAAA', data)


          if (!data.opponents || data.opponents.length < 2) {
            console.debug('No opponents found for this match');
            document.querySelector('#matchpost-html').innerHTML = '';
            document.querySelector('#matchpost-html').insertAdjacentHTML('beforeend',
                                       /*html*/ `
                                        <br><br><br><br>
                                    <h1 class="text-center text-white">No data for this match, yet.</h1>
                                    <h2 class="text-center text-white">Please check back later</h2>
                                        <br><br><br><br>
                                    `); // Shadow edw paizeis mpala
            document.getElementById('placeholder-stream').style.display = "none";
            document.getElementById('placeholder-chat').style.display = "none";
            window.tadeCallback === undefined;
            return;
          } // if no opponents due to tourney format ongoing, skip the match
          const team1id = data.opponents[0].opponent.id;
          const team2id = data.opponents[1].opponent.id;
          if (window.savedPastMatches && window.savedPastMatches[team1id] && window.savedPastMatches[team2id] && (window.savedPastMatches[team1id + '_' + team2id] || window.savedPastMatches[team2id + '_' + team1id])) {
            console.debug('Past matches already saved for these teams');
          } else {
            let counterHistory = 0;
            let counterH2hHistory = 0;
            let promise1 = new Promise((resolve, reject) => {
              FirebaseAssistant.getPastMatches(team1id, team2id, (querySnapshot1, querySnapshot2) => {
                console.debug('getting the past matches from firestore')
                if (!querySnapshot1.empty || !querySnapshot2.empty) {
                  if (!querySnapshot1.empty) {
                    for (let doc of querySnapshot1.docs) {
                      if (counterHistory >= 10) break;
                      let pastData = doc.data();
                      if (((pastData.opponents[0].opponent.id === team1id && pastData.opponents[1].opponent.id === team2id) || (pastData.opponents[0].opponent.id === team2id && pastData.opponents[1].opponent.id === team1id)) && ((pastData.id !== data.id) && pastData.status === 'finished')) {
                        // console.debug('data h2h matches for opponent1', pastData.name);
                        if (window.savedPastMatches === undefined) {
                          window.savedPastMatches = { [team1id + '_' + team2id]: [pastData] }
                        } else if (window.savedPastMatches[team1id + '_' + team2id] === undefined) {
                          window.savedPastMatches[team1id + '_' + team2id] = [pastData];
                        } else if (!window.savedPastMatches[team1id + '_' + team2id].some(data => data.id === pastData.id)) {
                          window.savedPastMatches[team1id + '_' + team2id].push(pastData);
                        }
                        if (window.savedPastMatches[team1id] === undefined) {
                          window.savedPastMatches[team1id] = [pastData];
                          // console.debug('pushing data to team1id', pastData.name)
                        } else if (!window.savedPastMatches[team1id].some(data => data.id === pastData.id)) {
                          window.savedPastMatches[team1id].push(pastData);
                          // console.debug('pushing data to team1id', pastData.name)
                        }
                        counterHistory++;
                      } else if ((pastData.id !== data.id) && pastData.status === 'finished') {
                        if (window.savedPastMatches === undefined) {
                          window.savedPastMatches = { [team1id]: [pastData] }
                        } else if (window.savedPastMatches[team1id] === undefined) {
                          window.savedPastMatches[team1id] = [pastData];
                        } else if (!window.savedPastMatches[team1id].some(data => data.id === pastData.id)) {
                          window.savedPastMatches[team1id].push(pastData);
                        }
                        counterHistory++;
                      }
                    }
                  }
                  counterHistory = 0;
                  if (window.savedPastMatches === undefined) {
                    window.savedPastMatches = { [team1id]: [''] }
                  } else if (window.savedPastMatches[team1id] === undefined) {
                    window.savedPastMatches[team1id] = [''];
                  }
                  if (!querySnapshot2.empty) {
                    for (let doc of querySnapshot2.docs) {
                      if (counterHistory >= 10) break;
                      let pastData = doc.data();
                      if (((pastData.opponents[0].opponent.id === team1id && pastData.opponents[1].opponent.id === team2id) || (pastData.opponents[0].opponent.id === team2id && pastData.opponents[1].opponent.id === team1id)) && ((pastData.id !== data.id) && pastData.status === 'finished')) {
                        // console.debug('data h2h matches for opponent2', pastData.name);
                        if (window.savedPastMatches === undefined) {
                          window.savedPastMatches = { [team2id + '_' + team1id]: [pastData] }
                        } else if (window.savedPastMatches[team2id + '_' + team1id] === undefined) {
                          window.savedPastMatches[team2id + '_' + team1id] = [pastData];
                        } else if (!window.savedPastMatches[team2id + '_' + team1id].some(data => data.id === pastData.id)) {
                          window.savedPastMatches[team2id + '_' + team1id].push(pastData);
                        }
                        if (window.savedPastMatches[team2id] === undefined) {
                          window.savedPastMatches[team2id] = [pastData];
                          // console.debug('pushing data to team2id', pastData.name)
                        } else if (!window.savedPastMatches[team2id].some(data => data.id === pastData.id)) {
                          window.savedPastMatches[team2id].push(pastData);
                          // console.debug('pushing data to team2id', pastData.name)
                        }
                        counterHistory++;
                      } else if ((pastData.id !== data.id) && pastData.status === 'finished') {
                        if (window.savedPastMatches === undefined) {
                          window.savedPastMatches = { [team2id]: [pastData] }
                        } else if (window.savedPastMatches[team2id] === undefined) {
                          window.savedPastMatches[team2id] = [pastData];
                        } else if (!window.savedPastMatches[team2id].some(data => data.id === pastData.id)) {
                          window.savedPastMatches[team2id].push(pastData);
                        }
                        counterHistory++;
                      }
                    }
                  }
                  if (window.savedPastMatches === undefined) {
                    window.savedPastMatches = { [team2id]: [''] }
                  } else if (window.savedPastMatches[team2id] === undefined) {
                    window.savedPastMatches[team2id] = [''];
                  }
                  // Process the snapshots here
                  resolve();
                } else {
                  console.debug('No past matches found for these teams');
                  window.savedPastMatches = window.savedPastMatches || {}; // Initialize if it doesn't exist
                  window.savedPastMatches[team1id] = [''];
                  window.savedPastMatches[team2id] = [''];
                  resolve();
                }

              });
            });
            let promise2 = new Promise((resolve, reject) => {
              FirebaseAssistant.getH2hPastMatches(team1id, team2id, (docs) => {
                if (docs.length > 0) {
                  for (let doc of docs) {
                    if (counterH2hHistory >= 10) break;
                    let pastData = doc.data();
                    if (((pastData.opponents[0].opponent.id === team1id && pastData.opponents[1].opponent.id === team2id) || (pastData.opponents[0].opponent.id === team2id && pastData.opponents[1].opponent.id === team1id)) && ((pastData.id !== data.id) && pastData.status === 'finished')) {
                      console.debug('data h2h matches for opponent1', pastData.name);
                      if (window.savedPastMatches === undefined) {
                        window.savedPastMatches = { [team1id + '_' + team2id]: [pastData] }
                      } else if (window.savedPastMatches[team1id + '_' + team2id] === undefined) {
                        window.savedPastMatches[team1id + '_' + team2id] = [pastData];
                      } else if (!window.savedPastMatches[team1id + '_' + team2id].some(data => data.id === pastData.id)) {
                        window.savedPastMatches[team1id + '_' + team2id].push(pastData);
                      }
                      counterH2hHistory++;
                    }
                  }
                  resolve();
                } else {
                  console.debug('No h2h matches found for these teams');
                  window.savedPastMatches = window.savedPastMatches || {}; // Initialize if it doesn't exist
                  window.savedPastMatches[team1id + '_' + team2id] = [''];
                  window.savedPastMatches[team1id + '_' + team2id] = [''];
                  resolve();
                }
              });
            });
            let results = await Promise.allSettled([promise1, promise2]);
            console.debug(results)
          }

          const matchId = data.id;
          const promoStreamUserLogin = PostMatchesComponent.PromoVideo(false, matchId);
          console.debug('Promo Streamer login:', promoStreamUserLogin);


          const gameStatus = data.status;
          const idOfScore = `matchScore${matchcodeid}`
          const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
          const endDateRaw = data.end_at ? new Date(data.end_at) : null;
          let startDif = PostMatchesComponent.postGlobalDate.getTime() - dateRaw.getTime(); // calculate the difference between the current time and the start time of the match
          let endDif = null;
          let videoLinks = Array(6).fill('No video available');
          let chatLinks = Array(6).fill('No chat available');
          let htmlTags = Array(6).fill('No tag available');
          let consoleIcons = Array(6).fill('No icon available');

          let index = 0;

          while (index < videoLinks.length) {
            if (videoLinks[index] !== undefined) {
              let bestMatch = null;
              for (let i = 0; i < data.streams_list.length; i++) {
                const stream = data.streams_list[i];
                let rawUrl = null;
                let channelName = null;
                if ((stream.embed_url || stream.raw_url) && videoLinks[index] === 'No video available') {
                  let tempVideoLink = null;
                  if ((stream.embed_url && stream.embed_url.includes('twitch')) || (stream.raw_url && stream.raw_url.includes('twitch'))) {
                    console.debug('SAVVAS', stream);
                    rawUrl = stream.raw_url;
                    channelName = rawUrl ? rawUrl.split('/').pop() : null;
                    tempVideoLink = stream.embed_url ? `${stream.embed_url}&parent=${window.location.hostname}` : `https://player.twitch.tv/?channel=${channelName}&parent=${window.location.hostname}`;
                    if (!videoLinks.includes(tempVideoLink)) {
                      if (stream.language === 'el' || (stream.official && stream.language === 'en')) {
                        bestMatch = {
                          videoLink: tempVideoLink,
                          chatLink: `https://www.twitch.tv/embed/${channelName}/chat?darkpopout&parent=${window.location.hostname}`,
                          htmlTag: `${stream.raw_url}---${stream.language}`,
                          consoleIcon: 'twitch'
                        };
                        break;
                      } else if (stream.language === 'en' && (!bestMatch || !bestMatch.official)) {
                        bestMatch = {
                          videoLink: tempVideoLink,
                          chatLink: `https://www.twitch.tv/embed/${channelName}/chat?darkpopout&parent=${window.location.hostname}`,
                          htmlTag: `${stream.raw_url}---${stream.language}`,
                          consoleIcon: 'twitch',
                          official: stream.official
                        };
                      } else if (!bestMatch) {
                        bestMatch = {
                          videoLink: tempVideoLink,
                          chatLink: `https://www.twitch.tv/embed/${channelName}/chat?darkpopout&parent=${window.location.hostname}`,
                          htmlTag: `${stream.raw_url}---${stream.language}`,
                          consoleIcon: 'twitch'
                        };
                      }
                    }
                  }
                }
              }
              if (bestMatch) {
                videoLinks[index] = bestMatch.videoLink;
                chatLinks[index] = bestMatch.chatLink;
                htmlTags[index] = bestMatch.htmlTag;
                consoleIcons[index] = bestMatch.consoleIcon;
              }
            }
            index++;
          }

          // If no Twitch streams were found, check for YouTube and AfreecaTV streams
          index = 0;
          while (index < videoLinks.length) {
            if (videoLinks[index] !== undefined && videoLinks[index] === 'No video available') {
              for (let i = 0; i < data.streams_list.length; i++) {
                const stream = data.streams_list[i];
                let tempVideoLink = null; // Temporary variable to hold the new video link
                let rawUrl = null;
                let channelName = null;

                if (stream.embed_url && stream.embed_url.includes('youtube')) {
                  tempVideoLink = stream.embed_url;
                  if (!videoLinks.includes(tempVideoLink)) { // If the new video link does not match the old ones
                    videoLinks[index] = tempVideoLink;
                    rawUrl = stream.raw_url;
                    channelName = rawUrl ? rawUrl.split('?').pop() : null;
                    console.debug('channelName', channelName);
                    chatLinks[index] = `https://www.youtube.com/live_chat?${channelName}&embed_domain=${window.location.hostname}`;
                    htmlTags[index] = `${stream.raw_url}---${stream.language}`;
                    consoleIcons[index] = 'youtube';
                  }
                } else if ((stream.embed_url && stream.embed_url.includes('sooplive')) || (stream.raw_url && stream.raw_url.includes('sooplive'))) {
                  tempVideoLink = stream.embed_url ? stream.embed_url : stream.raw_url ? stream.raw_url.replace('sooplive.com/', 'sooplive.com/player/embed/') : null;
                  if (!videoLinks.includes(tempVideoLink)) { // If the new video link does not match the old ones
                    videoLinks[index] = tempVideoLink;
                    chatLinks[index] = 'No chat available';
                    htmlTags[index] = `${stream.raw_url}---${stream.language}`;
                    consoleIcons[index] = 'cast';
                  }
                }

                // else if (stream.raw_url && stream.raw_url.includes('youtube')) {
                //     rawUrl = stream.raw_url;
                //     if (rawUrl.includes('/channel/')) {
                //         channelName = rawUrl.split('/channel/').pop();
                //     } else if (rawUrl.includes('/user/')) {
                //         channelName = rawUrl.split('/user/').pop();
                //     } else if (rawUrl.includes('@')) {
                //         channelName = rawUrl.match(/\/(@[^\/]*)/)[1];
                //         console.debug('channelName', channelName);
                //     }
                //     tempVideoLink = `https://www.youtube.com/embed/${channelName}`
                //     if (!videoLinks.includes(tempVideoLink)) { // If the new video link does not match the old ones
                //         videoLinks[index] = tempVideoLink;;
                //         chatLinks[index] = `https://www.youtube.com/live_chat?${channelName}&embed_domain=${window.location.hostname}`;
                //         htmlTags[index] = `${stream.raw_url}---${stream.language}`;
                //         consoleIcons[index] = 'youtube';
                //     }
                // }

                // WILL WORK ON THIS AT SOME POINT, need youtube api see notes.txt
              }
            }
            index++;
          }

          console.debug(videoLinks);
          console.debug(chatLinks);
          console.debug(htmlTags);

          if (promoStreamUserLogin !== undefined) {
            videoLinks.unshift(`https://player.twitch.tv/?channel=${promoStreamUserLogin}&parent=${window.location.hostname}`);
            chatLinks.unshift(`https://www.twitch.tv/embed/${promoStreamUserLogin}/chat?darkpopout&parent=${window.location.hostname}`);
            htmlTags.unshift(`https://www.twitch.tv/${promoStreamUserLogin}---promo`);
            consoleIcons.unshift('twitch');
          }

          let videoPlayers = videoLinks.map((videoLink, index) => {
            return `<div class="twitch-container p-3 color-dark rounded ">
                            ${videoLink !== 'No video available'
                ? `<div id="videosid">
                            <iframe id="twitch-iframe-${index}"
                                src="${videoLink}" 
                                height="100%" 
                                width="100%" 
                                frameborder="0" 
                                scrolling="no" 
                                allowfullscreen="true">
                            </iframe>
                        </div>`
                : '<span class="d-flex justify-content-center noVideoMessage">No video available<span>'}
                        </div>`;
          });

          let chats = chatLinks.map((chatLink, index) => {
            return `
                            ${chatLink !== 'No chat available'
                ? `<div id="chats">
                                <iframe id="twitch-chat-${index}"
                                            src="${chatLink}"
                                            height="700px"
                                            width="100%"
                                            frameborder="0"
                                            scrolling="no"
                                            allowfullscreen="true">
                                        </iframe>
                                        </div>`
                : ''}
                        </div>`;

          })

          let navPills = videoPlayers.map((videoPlayer, index) => {
            if (videoPlayer.includes('No video available')) {
              return '';
            } else {
              let userLanguage = window.navigator.language;
              let languageCode = htmlTags[index].split('---')[1];
              let activeClass = index === 0 ? 'active' : '';
              if (languageCode === 'promo') {
                return `<li class="nav-item text-resize-stream">
                                    <a class="nav-link ${activeClass}" id="${htmlTags[index]}" data-toggle="pill"><i class="me-2 bi bi-${consoleIcons[index]}"></i>MyEsports</a>
                                </li>`;
              } else {
                let languageName = new Intl.DisplayNames([userLanguage], { type: 'language' }).of(languageCode);
                return `<li class="nav-item text-resize-stream">
                                    <a class="nav-link ${activeClass}" id="${htmlTags[index]}" data-toggle="pill"><i class="me-2 bi bi-${consoleIcons[index]}"></i>${languageName}</a>
                                </li>`;
              }
            }
          }).join('');

          const countdownElementId = `countdown-${data.id}`;
          const matchElementId = `${data.id}`;

          if (document.querySelectorAll('[id^="postmatch"]').length !== 0) {
            console.debug('an addition arrived from snapshot, so we are not going to fetch the data again, but we are going to update the page!!');

            console.debug(`${data.results[0].score.toString()} - ${data.results[1].score.toString()}`);

            const score1 = `<span class="${data.winner_id && data.winner_id === team1id ? 'winner-Score' : ''}">${data.results[0].score.toString()}</span>`;
            const score2 = `<span class="${data.winner_id && data.winner_id === team2id ? 'winner-Score' : ''}">${data.results[1].score.toString()}</span>`;
            const score = `${score1} - ${score2}`;
            document.querySelector(`#${idOfScore}`).innerHTML = `${score}`;

            const element = document.querySelector('#chatforvideo');
            if (gameStatus === 'finished') {
              endDif = PostMatchesComponent.postGlobalDate.getTime() - endDateRaw.getTime(); // calculate the difference between the current time and the end time of the match
            }
            if (element && element.innerHTML.trim() === '' && gameStatus !== 'finished' && gameStatus !== 'canceled' && startDif >= - 20 * 60 * 1000) {
              element.insertAdjacentHTML('beforeend', chats[0]);
            } else if (element && element.innerHTML.trim() === '' && endDif !== null && endDif <= 20 * 60 * 1000) {
              element.insertAdjacentHTML('beforeend', chats[0]);
            } else if (element && element.innerHTML.trim() === '' && gameStatus === 'running') {
              element.insertAdjacentHTML('beforeend', chats[0]);
            } else {
              console.debug('The match is not live or the element is not empty, so we are not going to show the chat')
            }

            if (document.querySelector(`.thePlayerContainer`).innerHTML.trim() === '' || document.querySelector('.thePlayerContainer .noVideoMessage')) {
              console.debug('The player container is empty or it has no video yet, so we are going to add the video player');
              document.querySelector(`.thePlayerContainer`).innerHTML = `
                        ${(() => {
                  if (gameStatus === 'finished') {
                    endDif = PostMatchesComponent.postGlobalDate.getTime() - endDateRaw.getTime(); // calculate the difference between the current time and the end time of the match
                  }
                  if (gameStatus !== 'finished' && gameStatus !== 'canceled' && startDif >= - 20 * 60 * 1000) { // if the start difference is less than 20 minutes, show the video player
                    return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                  } else if (endDif !== null && endDif <= 20 * 60 * 1000) { // if the end difference is less than 20 minutes, show the video player
                    return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                  } else if (gameStatus === 'running') { // if the game is running, show the video player
                    return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                  } else { // if none of the above, don't show the video player
                    console.debug('The match is not live, so we are not going to show the video player')
                    return '';
                  }
                })()
                } `;

              htmlTags.forEach((tag, index) => {
                let pill = document.getElementById(tag);
                if (pill) {
                  pill.addEventListener('click', () => {
                    console.debug('Pill clicked:', pill.id);
                    // Remove 'active' class from all pills
                    htmlTags.forEach((tag) => {
                      let p = document.getElementById(tag);
                      if (p) p.classList.remove('active');
                    });

                    // Add 'active' class to the clicked pill
                    pill.classList.add('active');

                    // Replace the current video player with the one associated with the clicked pill
                    let videoContainer = document.querySelector('#videosid iframe');
                    if (videoContainer) {
                      videoContainer.src = videoLinks[index]; // Use videoLinks instead of videoPlayers
                    }

                    // Replace the current chat with the one associated with the clicked pill
                    let chatIframe = document.querySelector('#chats iframe');
                    if (chatIframe) {
                      chatIframe.src = chatLinks[index];
                    }
                  });
                }
              });
            } else {
              console.debug('The player container is not empty, so we are not going to add the video player again');
            }

            PostMatchesComponent.startCountdown(dateRaw, countdownElementId, matchElementId, gameStatus);
            PostMatchesComponent.resizetoTwitchHeight()


            if (data.winner_id) {
              document.querySelector(`#team-${data.winner_id}`).classList.add('winner')
            }
            window.tadeCallback = undefined;
          } else {
            console.debug(RouterAssistant.getCurrentVariableValue('match'));



            const tournamentId = data.tournament_id || data.tournament.id;
            const serieId = data.serie_id;
            const serieIdElement = `seriefrompostmatch-${serieId}`;
            console.debug('serieIdElement', serieIdElement);
            let matchIdsArrayForListener = [];
            const team1name = data.opponents[0].opponent.name;
            const team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : noImageFound
            const team1idF = data.opponents[0].opponent.id;
            const team2name = data.opponents[1].opponent.name;
            const team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : noImageFound
            const team2idF = data.opponents[1].opponent.id;
            const winnerId = data.winner_id;
            const currentGameId = data.videogame.id;
            const gameIdImg = {
              '3': csgologo,
              '4': dota2,
              '1': lol,
              '25': ea,
              '26': valorant,
              '23': cod,
              '14': overwatch,
              '20': pubg,
              '24': r6,
              '22': rocketleague,
              '29': startcraft,
              '30': startcraft,
              '27': kingofglory,
              '34': mlegends

            };
            const tournamentName = `${data.league.name} ${data.serie.full_name}`;
            const tournamentLogo = data.league.image_url;
            const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="tournament-logo">` : '';
            const gameLogoHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="game logo" class="game-logo">`;
            const matchTypeRaw = data.match_type;
            const numberOfGames = data.number_of_games;
            let matchTypeFinal = ``;
            if (matchTypeRaw === 'best_of' && (numberOfGames !== 2 && numberOfGames !== 1)) {
              matchTypeFinal = 'best_of_X';
            } else if (matchTypeRaw === 'best_of' && numberOfGames === 1) {
              matchTypeFinal = 'single_game';
            } else if (matchTypeRaw === 'best_of' && numberOfGames === 2) {
              matchTypeFinal = 'best_of_2';
            } else if (matchTypeRaw === 'all_games_played') {
              matchTypeFinal = 'all_games_played';
            } else if (matchTypeRaw === 'first_to') {
              matchTypeFinal = 'first_to_X';
            } else {
              matchTypeFinal = 'general_drop_to';
            }
            const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
            const matchTypefull = `${matchType} ${data.number_of_games.toString()}`;
            const score1 = `<span class="${winnerId && winnerId === team1id ? 'winner-Score' : ''}">${data.results[0].score.toString()}</span>`;
            const score2 = `<span class="${winnerId && winnerId === team2id ? 'winner-Score' : ''}">${data.results[1].score.toString()}</span>`;
            const score = `${score1} - ${score2}`;
            const date = `${dateRaw.toLocaleDateString(userLanguage, {
              weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
              day: 'numeric',   // numeric day of the month
              month: 'short'    // "short" for abbreviated month name (e.g., Feb)
            })} ${dateRaw.toLocaleTimeString(userLanguage, {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })}`;

            let countdown = `${countdownElementId}`;
            let countdownMobile = `${countdownElementId}-mobile`;

            let teamData1Html = ``
            let teamData2Html = ``
            let result1Circles = [];
            let result2Circles = [];
            if (data.opponents[0].type === 'Team') {
              console.debug('I am running the team data function');
              // Fetch team data here, inside the callback function getRostersData(tournamentId, team1Id, team2Id)
              await PostMatchesComponent.getRostersData(tournamentId.toString(), team1id, team2id).then((response) => {

                const teamData1 = response.rosterId1;
                const teamData2 = response.rosterId2;
                // console.debug('teamData1', teamData1);
                // console.debug('teamData2', teamData2);

                const rolesOrder = ['top', 'jun', 'mid', 'adc', 'sup'];
                const rolesOrderDota = ['carry/mid', 'carry', 'mid', 'offlane', 'support'];

                function sortPlayersByRole(players) {
                  return players.sort((a, b) => {
                    const roleA = a.role ? rolesOrder.indexOf(a.role.toLowerCase()) : rolesOrder.length;
                    const roleB = b.role ? rolesOrder.indexOf(b.role.toLowerCase()) : rolesOrder.length;
                    return roleA - roleB;
                  });
                }
                function sortPlayersByDotaRole(players) {
                  return players.sort((a, b) => {
                    const roleA = a.role ? rolesOrderDota.indexOf(a.role.toLowerCase()) : rolesOrderDota.length;
                    const roleB = b.role ? rolesOrderDota.indexOf(b.role.toLowerCase()) : rolesOrderDota.length;
                    const indexA = roleA === -1 ? rolesOrderDota.length : roleA;
                    const indexB = roleB === -1 ? rolesOrderDota.length : roleB;
                    return indexA - indexB;
                  });
                }
                if (currentGameId === 1) {
                  teamData1.players = sortPlayersByRole(teamData1.players);
                  teamData2.players = sortPlayersByRole(teamData2.players);
                }
                if (currentGameId === 4) {
                  teamData1.players = sortPlayersByDotaRole(teamData1.players);
                  teamData2.players = sortPlayersByDotaRole(teamData2.players);
                }

                let team1Count = 0;
                for (let i = 0; i < 19; i++) { // setting this up to grab as many players as possible
                  if (teamData1.players[i]) {
                    // console.debug(teamData1.players[i])
                    let countryCode = teamData1.players[i].nationality ? teamData1.players[i].nationality.toLowerCase() : false;
                    const flagIconHtml = countryCode ?
                      `<span class="flag-icon flag-icon-${countryCode}" aria-label="${countryCode} flag" data-bs-toggle="tooltip" data-bs-placement="top" title="${countryCode}"></span>`
                      : '';
                    teamData1Html += /*html*/`
            <div class="col">
                <div class="player-card">
                    <div class="player-name " data-bs-toggle="tooltip" data-bs-placement="top" title="${teamData1.players[i].name}">
                        ${flagIconHtml}
                          <div class="truncate-text  ms-1">
                        ${teamData1.players[i].name}
                        </div>
                    </div>
                    <div class="image-wrapper">
                        <img src="${teamData1.players[i].image_url ? teamData1.players[i].image_url : noPlayerImg}" 
                             class="player-image" alt="${teamData1.players[i].name}" loading="lazy"
                             onerror="this.onerror=null; this.src='${noPlayerImg}';">
                    </div>
                    <div class="card-body p-2">
                        <p class="player-info">
                            <strong class="position-label"></strong> ${teamData1.players[i].role ? teamData1.players[i].role : 'N/A'}<br>
                            <strong class="age-label"></strong> ${teamData1.players[i].age ? teamData1.players[i].age : 'N/A'}
                        </p>
                    </div>
                </div>
            </div>
        `;
                    team1Count++;
                  }
                  while (team1Count < 5 && teamData1.players[i] === undefined && currentGameId !== 22 && currentGameId !== 23) {
                    teamData1Html += /*html*/`
            <div class="col">
                <div class="player-card">
                    <div class="player-name truncate-text">
                        Uknown
                    </div>
                    <div class="image-wrapper">
                        <img src="${noPlayerImg}" alt="N/A" class="player-image" loading="lazy">
                    </div>
                    <div class="card-body p-2">
                        <p class="player-info">
                            <strong class="position-label"></strong> N/A<br>
                            <strong class="age-label"></strong> N/A
                        </p>
                    </div>
                </div>
            </div>
        `;
                    team1Count++;
                  }
                  while (team1Count < 4 && teamData1.players[i] === undefined && currentGameId === 23) {
                    teamData1Html += /*html*/`
            <div class="col">
                <div class="player-card">
                    <div class="player-name truncate-text">
                        Uknown
                    </div>
                    <div class="image-wrapper">
                        <img src="${noPlayerImg}" alt="N/A" class="player-image" loading="lazy">
                    </div>
                    <div class="card-body p-2">
                        <p class="player-info">
                            <strong class="position-label"></strong> N/A<br>
                            <strong class="age-label"></strong> N/A
                        </p>
                    </div>
                </div>
            </div>
        `;
                    team1Count++;
                  }
                }



                let team2Count = 0;
                for (let i = 0; i < 19; i++) { // Loop through up to 19 players
                  if (teamData2.players[i]) {
                    let countryCode = teamData2.players[i].nationality ? teamData2.players[i].nationality.toLowerCase() : false;
                    const flagIconHtml = countryCode ?
                      `<span class="flag-icon flag-icon-${countryCode}" aria-label="${countryCode} flag" data-bs-toggle="tooltip" data-bs-placement="top" title="${countryCode}"></span>`
                      : '';
                    teamData2Html += /*html*/`
            <div class="col">
                <div class="player-card">
                    <div class="player-name" data-bs-toggle="tooltip" data-bs-placement="top" title="${teamData2.players[i].name}">

                        ${flagIconHtml}
                        <div class="truncate-text ms-1">
                        ${teamData2.players[i].name}
                        </div>
                    </div>
                    <div class="image-wrapper">
                        <img src="${teamData2.players[i].image_url ? teamData2.players[i].image_url : noPlayerImg}" 
                             class="player-image" alt="${teamData2.players[i].name}" loading="lazy"
                             onerror="this.onerror=null; this.src='${noPlayerImg}';">
                    </div>
                    <div class="card-body p-2">
                        <p class="player-info">
                            <strong class="position-label"></strong> ${teamData2.players[i].role ? teamData2.players[i].role : 'N/A'}<br>
                            <strong class="age-label"></strong> ${teamData2.players[i].age ? teamData2.players[i].age : 'N/A'}
                        </p>
                    </div>
                </div>
            </div>
        `;
                    team2Count++;
                  }
                  while (team2Count < 5 && teamData2.players[i] === undefined && currentGameId !== 22 && currentGameId !== 23) {
                    teamData2Html += /*html*/`
            <div class="col">
                <div class="player-card">
                    <div class="player-name truncate-text">
                        Uknown
                    </div>
                    <div class="image-wrapper">
                        <img src="${noPlayerImg}" alt="N/A" class="player-image" loading="lazy">
                    </div>
                    <div class="card-body p-2">
                        <p class="player-info">
                            <strong class="position-label"></strong> N/A<br>
                            <strong class="age-label"></strong> N/A
                        </p>
                    </div>
                </div>
            </div>
        `;
                    team2Count++;
                  }
                  while (team2Count < 4 && teamData2.players[i] === undefined && currentGameId === 23) {
                    teamData2Html += /*html*/`
            <div class="col">
                <div class="player-card">
                    <div class="player-name truncate-text">
                        Uknown
                    </div>
                    <div class="image-wrapper">
                        <img src="${noPlayerImg}" alt="N/A" class="player-image" loading="lazy">
                    </div>
                    <div class="card-body p-2">
                        <p class="player-info">
                            <strong class="position-label"></strong> N/A<br>
                            <strong class="age-label"></strong> N/A
                        </p>
                    </div>
                </div>
            </div>
        `;
                    team2Count++;
                  }
                }

              })
            }


            let h2hMatchesforTeam1 = `<div class="tab-pane fade show active" id="pills-h2h-${team1idF}" role="tabpanel" aria-labelledby="pills-h2h-${team1idF}-tab">`;
            let pastMatchesforTeam1 = `<div class="tab-pane fade show" id="pills-gen-${team1idF}" role="tabpanel" aria-labelledby="pills-${team1idF}-tab">`;
            let pastMatchesforTeam2 = `<div class="tab-pane fade show" id="pills-gen-${team2idF}" role="tabpanel" aria-labelledby="pills-${team2idF}-tab">`;
            let pastPills1 = /*html*/ `
                         <li class="nav-item" role="presentation">
                            <button class="nav-link" id="generalpast1-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-gen-${team1idF}" type="button" role="tab" aria-controls="pills-gen-${team1idF}" aria-selected="false">${team1name}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active h2hpillClass" id="generalpast1-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-h2h-${team1idF}" type="button" role="tab" aria-controls="pills-h2h-${team1idF}" aria-selected="false">Head to head</button>
                        </li>                      
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="generalpast2-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-gen-${team2idF}" type="button" role="tab" aria-controls="pills-gen-${team2idF}" aria-selected="false">${team2name}</button>
                        </li>
                                `;

            if (window.savedPastMatches[team1idF][0] !== '') {
              for (let i = 0; i < window.savedPastMatches[team1idF].length; i++) {
                const pastMatchData = window.savedPastMatches[team1idF][i];

                let matchId = pastMatchData.matchId;
                const matchPastId = `m${team1idF}-pastMatchFromPostmatch-${pastMatchData.id}`;
                // console.debug('matchPastId', matchPastId);
                matchIdsArrayForListener.push(matchPastId);

                const team1name = pastMatchData.opponents[0].opponent.name;
                const team1id = pastMatchData.opponents[0].opponent.id;
                const team2name = pastMatchData.opponents[1].opponent.name;
                const team2id = pastMatchData.opponents[1].opponent.id;
                let team1img, team2img;
                if (pastMatchData.opponents[1].type === 'Player') {
                  team1img = `<img src="${pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                  team2img = `<img src="${pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                } else if (pastMatchData.opponents[1].type === 'Team') {
                  const team1imginitial = pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noImageFound
                  const team2imginitial = pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noImageFound
                  team1img = `<img src="${team1imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                  team2img = `<img src="${team2imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                }

                const tournamentName = `${pastMatchData.league.name} ${pastMatchData.serie.full_name}`;
                const tournamentLogo = pastMatchData.league.image_url;
                const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid mx-auto d-block " >` : '';
                const matchTypeRaw = pastMatchData.match_type;
                const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
                const matchTypefull = `${matchType} ${pastMatchData.number_of_games.toString()}`;

                const score = `${pastMatchData.results[0].score.toString()} - ${pastMatchData.results[1].score.toString()}`;
                const winnerId = pastMatchData.winner && pastMatchData.winner.id ? pastMatchData.winner.id : pastMatchData.winner_id;
                if (winnerId) {
                  if (winnerId === team1idF) {
                    if (result1Circles.length < 5) {
                      result1Circles.unshift('<span class="badge bg-success fixed-size-badge-wld">W</span>');
                    }
                  } else {
                    if (result1Circles.length < 5) {
                      result1Circles.unshift('<span class="badge bg-danger fixed-size-badge-wld">L</span>');
                    }
                  }
                } else {
                  if (result1Circles.length < 5) {
                    result1Circles.unshift('<span class="badge bg-warning  fixed-size-badge-wld">D</span>');
                  }
                }
                const dateRaw = new Date(pastMatchData.begin_at ? pastMatchData.begin_at : pastMatchData.scheduled_at);
                const date = `${dateRaw.toLocaleDateString(userLanguage, {
                  weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                  day: 'numeric',   // numeric day of the month
                  month: 'short',   // "short" for abbreviated month name (e.g., Feb)
                  year: 'numeric'   // numeric year
                })} ${dateRaw.toLocaleTimeString(userLanguage, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })}`;

                pastMatchesforTeam1 += /*html*/ `
                                            <div id="${matchPastId}" class="row  mt-3 rounded text-white color-lightdark p-0 clickable">
                                            <div class="col-12 p-0 d-flex justify-content-between rounded-top mb-2 color-dark">
                                            <div class="text-start d-flex p-1 " id="">
                                            <span class="m-0 mx-2 d-flex align-items-center text-resize-tournament">${tournamentName}</span>
                                            <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                                            ${tournamentLogoHTML}
                                            </div>
                                            </div>
                                            <div class="text-end text-white text-resize-date mt-1 me-1 d-flex justify-content-center align-content-center align-items-center">
                                            <i class="bi bi-clock"></i><span class="ms-1"> ${date}</span>
                                            </div>
                                            </div>
                                            
                                            <div class="col-5 mt-2 d-flex justify-content-end align-items-center ${winnerId === team1id && winnerId === team1idF ? 'winner-left' : (winnerId === team2id && team1id === team1idF ? 'loser-left' : '')}" id="pastMatch-${matchId}-team-${team1id}">
                                            <span class="team-name-resize mx-2 my-0 ">${team1name}</span>
                                            
                                                <div class=" img-circle-container-team-img d-flex flex-column justify-content-center  m-2 ">
                                                    ${team1img}
                                                </div>
                                            </div>
                                            <div class="col-2 mt-2 gay-bg d-flex flex-column align-items-center justify-content-around">
                                            <p class="score-resize m-0">${score}</p>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-start align-items-center ${winnerId === team2id && winnerId === team1idF ? 'winner-right' : (winnerId === team1id && team2id === team1idF ? 'loser-right' : '')}" id="pastMatch-${matchId}-team-${team2id}">
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center m-2 ">
                                                    ${team2img}
                                                </div>
                                            <span class="team-name-resize mx-2 my-0 ">${team2name}</span>
                                            </div>
                                        <div class="row mt-4"> </div>
                                        </div>
                                    `
              }
            }
            if (window.savedPastMatches[team2idF][0] !== '') {
              for (let i = 0; i < window.savedPastMatches[team2idF].length; i++) {
                const pastMatchData = window.savedPastMatches[team2idF][i];

                let matchId = pastMatchData.matchId;
                const matchPastId = `m${team2idF}-pastMatchFromPostmatch-${pastMatchData.id}`;
                matchIdsArrayForListener.push(matchPastId);

                const team1name = pastMatchData.opponents[0].opponent.name;
                const team1id = pastMatchData.opponents[0].opponent.id;
                const team2name = pastMatchData.opponents[1].opponent.name;
                const team2id = pastMatchData.opponents[1].opponent.id;
                let team1img, team2img;
                if (pastMatchData.opponents[1].type === 'Player') {
                  team1img = `<img src="${pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                  team2img = `<img src="${pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                } else if (pastMatchData.opponents[1].type === 'Team') {
                  const team1imginitial = pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noImageFound
                  const team2imginitial = pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noImageFound
                  team1img = `<img src="${team1imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                  team2img = `<img src="${team2imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                }

                const tournamentName = `${pastMatchData.league.name} ${pastMatchData.serie.full_name}`;
                const tournamentLogo = pastMatchData.league.image_url;
                const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid mx-auto d-block" >` : '';

                const matchTypeRaw = pastMatchData.match_type;
                const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
                const matchTypefull = `${matchType} ${pastMatchData.number_of_games.toString()}`;

                const score = `${pastMatchData.results[0].score.toString()} - ${pastMatchData.results[1].score.toString()}`;
                const winnerId = pastMatchData.winner && pastMatchData.winner.id ? pastMatchData.winner.id : pastMatchData.winner_id;
                if (winnerId) {
                  if (winnerId === team2idF) {
                    if (result2Circles.length < 5) {
                      result2Circles.push('<span class="badge bg-success fixed-size-badge-wld">W</span>');
                    }
                  } else {
                    if (result2Circles.length < 5) {
                      result2Circles.push('<span class="badge bg-danger fixed-size-badge-wld">L</span>');
                    }
                  }
                } else {
                  if (result2Circles.length < 5) {
                    result2Circles.push('<span class="badge bg-warning fixed-size-badge-wld">D</span>');
                  }
                }
                const dateRaw = new Date(pastMatchData.begin_at ? pastMatchData.begin_at : pastMatchData.scheduled_at);
                const date = `${dateRaw.toLocaleDateString(userLanguage, {
                  weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                  day: 'numeric',   // numeric day of the month
                  month: 'short',   // "short" for abbreviated month name (e.g., Feb)
                  year: 'numeric'   // numeric year
                })} ${dateRaw.toLocaleTimeString(userLanguage, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })}`;

                pastMatchesforTeam2 += /*html*/ `
                                            
                                            <div id="${matchPastId}" class="row  mt-3 rounded text-white color-lightdark p-0 clickable">
                                            <div class="col-12 p-0 d-flex justify-content-between rounded-top color-dark mb-2 ">
                                            <div class="text-start d-flex p-1"  id="">
                                            <span class="m-0 mx-2 d-flex align-items-center text-resize-tournament">${tournamentName}</span>
                                            <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                                            ${tournamentLogoHTML}
                                            </div>
                                            </div>
                                            <div class="text-end text-white text-resize-date mt-1 me-1 d-flex justify-content-center align-items-center align-content-center">
                                            <i class="bi bi-clock"></i><span class="ms-1"> ${date}</span>
                                            </div>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-end align-items-center ${winnerId === team1id && winnerId === team2idF ? 'winner-left' : (winnerId === team2id && team1id === team2idF ? 'loser-left' : '')}" id="pastMatch-${matchId}-team-${team1id}">
                                            <span class="team-name-resize mx-2 my-0 ">${team1name}</span>
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center  m-2 ">
                                                     ${team1img}
                                                </div>
                                            </div>
                                            <div class="col-2 mt-2 d-flex flex-column align-items-center justify-content-around">
                                            <p class="score-resize m-0">${score}</p>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-start align-items-center ${winnerId === team2id && winnerId === team2idF ? 'winner-right' : (winnerId === team1id && team2id === team2idF ? 'loser-right' : '')}" id="pastMatch-${matchId}-team-${team2id}">
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center m-2 ">
                                                    ${team2img} 
                                                </div>
                                            <span class="team-name-resize mx-2 my-0 ">${team2name}</span>
                                            </div>
                                             <div class="row mt-4"> </div>
                                        </div>

                                    `
              }
            }
            if (window.savedPastMatches[team1idF + '_' + team2idF] && window.savedPastMatches[team1idF + '_' + team2idF][0] !== '') {
              for (let i = 0; i < window.savedPastMatches[team1idF + '_' + team2idF].length; i++) {
                const pastMatchData = window.savedPastMatches[team1idF + '_' + team2idF][i];

                let matchId = pastMatchData.matchId;
                const matchPastId = `m${team1idF + '_' + team2idF}-pastMatchFromPostmatch-${pastMatchData.id}`;
                matchIdsArrayForListener.push(matchPastId);

                const team1name = pastMatchData.opponents[0].opponent.name;
                const team1id = pastMatchData.opponents[0].opponent.id;
                const team2name = pastMatchData.opponents[1].opponent.name;
                const team2id = pastMatchData.opponents[1].opponent.id;
                let team1img, team2img;
                if (pastMatchData.opponents[1].type === 'Player') {
                  team1img = `<img src="${pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                  team2img = `<img src="${pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noPlayerImg}" onerror="this.onerror=null; this.src='${noPlayerImg}';" alt="" class="img-fluid mx-auto d-block" >`
                } else if (pastMatchData.opponents[1].type === 'Team') {
                  const team1imginitial = pastMatchData.opponents[0].opponent.image_url ? pastMatchData.opponents[0].opponent.image_url : noImageFound
                  const team2imginitial = pastMatchData.opponents[1].opponent.image_url ? pastMatchData.opponents[1].opponent.image_url : noImageFound
                  team1img = `<img src="${team1imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                  team2img = `<img src="${team2imginitial}" alt="" class="img-fluid mx-auto d-block" >`
                }
                const tournamentName = `${pastMatchData.league.name} ${pastMatchData.serie.full_name}`;
                const tournamentLogo = pastMatchData.league.image_url;
                const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" alt="tournament logo" class="img-fluid mx-auto d-block " >` : '';

                const matchTypeRaw = pastMatchData.match_type;
                const matchType = matchTypeRaw.charAt(0).toUpperCase() + matchTypeRaw.slice(1).replace('_', ' ');
                const matchTypefull = `${matchType} ${pastMatchData.number_of_games.toString()}`;

                const score = `${pastMatchData.results[0].score.toString()} - ${pastMatchData.results[1].score.toString()}`;
                const winnerId = pastMatchData.winner && pastMatchData.winner.id ? pastMatchData.winner.id : pastMatchData.winner_id;

                const dateRaw = new Date(pastMatchData.begin_at ? pastMatchData.begin_at : pastMatchData.scheduled_at);
                const date = `${dateRaw.toLocaleDateString(userLanguage, {
                  weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
                  day: 'numeric',   // numeric day of the month
                  month: 'short',   // "short" for abbreviated month name (e.g., Feb)
                  year: 'numeric'   // numeric year
                })} ${dateRaw.toLocaleTimeString(userLanguage, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })}`;

                h2hMatchesforTeam1 += /*html*/ `
                                            
                                            <div id="${matchPastId}" class="row  mt-3 rounded text-white color-lightdark p-0 clickable">
                                            <div class="col-12 p-0 d-flex justify-content-between rounded-top color-dark mb-2 ">
                                            <div class="text-start d-flex p-1" id="">
                                            <span class="m-0 mx-2 d-flex align-items-center text-resize-tournament">${tournamentName}</span>
                                              <div class="  img-circle-container-tournament-logo d-flex align-items-center">
                                            ${tournamentLogoHTML}
                                            </div>
                                            </div>
                                            <div class="text-end text-white text-resize-date mt-1 me-1 d-flex justify-content-center align-content-center align-items-center">
                                            <i class="bi bi-clock"></i><span class="ms-1"> ${date}</span>
                                            </div>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-end align-items-center ${winnerId === team1id ? 'winner-left' : ''}" id="pastMatch-${matchId}-team-${team1id}">
                                            <span class="team-name-resize mx-2 my-0 ">${team1name}</span>
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center  m-2 ">
                                                     ${team1img}
                                                </div>
                                            </div>
                                            <div class="col-2 mt-2  d-flex flex-column align-items-center justify-content-around">
                                            <p class="score-resize m-0">${score}</p>
                                            </div>
                                            <div class="col-5 mt-2 d-flex justify-content-start align-items-center ${winnerId === team2id ? 'winner-right' : ''}" id="pastMatch-${matchId}-team-${team2id}">
                                                <div class=" img-circle-container-team-img flex-column d-flex justify-content-center  m-2 ">
                                                    ${team2img} 
                                                </div>
                                            <span class="team-name-resize mx-2 my-0 ">${team2name}</span>
                                            </div>
                                             <div class="row mt-4"> </div>
                                        </div>

                                    `
              }
            }
            pastMatchesforTeam1 += `</div>`;
            pastMatchesforTeam2 += `</div>`;
            h2hMatchesforTeam1 += `</div>`;


            const element = document.querySelector('#chatforvideo');
            if (gameStatus === 'finished') {
              endDif = PostMatchesComponent.postGlobalDate.getTime() - endDateRaw.getTime(); // calculate the difference between the current time and the end time of the match
            }
            if (element && element.innerHTML.trim() === '' && gameStatus !== 'finished' && gameStatus !== 'canceled' && startDif >= - 20 * 60 * 1000) {
              element.insertAdjacentHTML('beforeend', chats[0]);
              document.getElementById('placeholder-chat').style.display = "none";
            } else if (element && element.innerHTML.trim() === '' && endDif !== null && endDif <= 20 * 60 * 1000) {
              element.insertAdjacentHTML('beforeend', chats[0]);
              document.getElementById('placeholder-chat').style.display = "none";
            } else if (element && element.innerHTML.trim() === '' && gameStatus === 'running') {
              element.insertAdjacentHTML('beforeend', chats[0]);
              document.getElementById('placeholder-chat').style.display = "none";
            } else {
              document.getElementById('placeholder-chat').style.display = "none";
            }

            while (result1Circles.length < 5) {
              result1Circles.unshift('<span class="badge bg-secondary fixed-size-badge-wld">-</span>');
            }

            while (result2Circles.length < 5) {
              result2Circles.push('<span class="badge bg-secondary fixed-size-badge-wld">-</span>');
            }
            const resultCircles1HTML = result1Circles.join(' ');
            const resultCircles2HTML = result2Circles.join(' ');

            let oddsHtml = '';
            let predictionHtml = '';
            let predictionHtml2 = '';
            let finalHtml = '';
            let team1Odds = data.thunderpickData && data.thunderpickData.team1Odds !== 1 ? data.thunderpickData.team1Odds : data.thunderpickData && data.thunderpickData.team1StartingOdds && data.thunderpickData.team1StartingOdds !== 1 ? data.thunderpickData.team1StartingOdds : "No data";
            let team2Odds = data.thunderpickData && data.thunderpickData.team2Odds !== 1 ? data.thunderpickData.team2Odds : data.thunderpickData && data.thunderpickData.team2StartingOdds && data.thunderpickData.team2StartingOdds !== 1 ? data.thunderpickData.team2StartingOdds : "No data";
            let drawOdds = data.thunderpickData && data.thunderpickData.drawOdds !== 1 && data.thunderpickData.drawOdds !== null ? data.thunderpickData.drawOdds : data.thunderpickData && data.thunderpickData.drawStartingOdds && data.thunderpickData.drawStartingOdds !== 1 && data.thunderpickData.drawStartingOdds !== null ? data.thunderpickData.drawStartingOdds : "No data";
            if (gameStatus !== 'finished' && team1Odds !== "No data" && team2Odds !== "No data" && drawOdds !== "No data") {
              team1Odds = parseFloat(team1Odds).toFixed(2);
              team2Odds = parseFloat(team2Odds).toFixed(2);
              drawOdds = parseFloat(drawOdds).toFixed(2);
              oddsHtml = /*html*/ `
                            <!-- Odds Section (Static Data) -->
                            <div class="odds-section">
                                    <div class="bet-now col-12">
                                    <!-- Thunderpick Logo -->
                                    <a href="${window.affiliateLinks.thunderpickGeneral}" target="_blank" aria-label="Visit Thunderpick">
                                        <img src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='180.33'%20height='24.83'%20viewBox='0%200%20180.33%2024.83'%3e%3cg%20data-name='Group%2016899'%3e%3cpath%20fill='%23fff'%20d='m126.72%2011.75%204.55-.03L140.78.04h-16.04l-4.06%2016.43h4.9Zm2.38-9.62h5.1l-7.34%209.03Z'%20data-name='Path%20776'/%3e%3cpath%20fill='%23fff'%20d='M116.13%208.81%20123.3.04h-16.1l-3.98%2016.43h4.9l3.53-14.34h5.04l-5.42%206.65%202.9%207.7h4.9Z'%20data-name='Path%20777'/%3e%3cpath%20fill='%23fff'%20d='M180.33.04h-4.9l-6.68%207.51%201.9-7.51h-4.9l-4.06%2016.43h4.9l1.78-7.17%204.2%207.17h4.9l-4.68-8.04Z'%20data-name='Path%20778'/%3e%3cpath%20fill='%23fff'%20d='M88.33%200%2073.82.04l-4.23%2016.43h6.5l13.6-14.2ZM75.67%2011.71l2.49-9.58h6.64v.07Z'%20data-name='Path%20779'/%3e%3cpath%20fill='%23fff'%20d='M61.44%2012.52%2059.48.04h-6.15l-4.1%2016.43h4.9l2.84-11.33%201.5%2011.33h6.85L69.38.04h-4.9Z'%20data-name='Path%20780'/%3e%3cpath%20fill='%23fff'%20d='M23.74%207.38h-4.68L20.9.04H16l-4.09%2016.43h4.9l1.54-6.3h4.69l-1.58%206.3h4.9L30.5.04h-4.9Z'%20data-name='Path%20781'/%3e%3cpath%20fill='%23fff'%20d='M14.27.04H.52L0%201.79h4.79l-3.6%2014.68h4.9L9.68%201.8h4.05Z'%20data-name='Path%20782'/%3e%3cpath%20fill='%23fff'%20d='M155.85%202.48h6.02l.59-2.45h-10.9l-4.03%2016.44h10.91l.53-2.1h-6.02Z'%20data-name='Path%20783'/%3e%3cpath%20fill='%23fff'%20d='M40.6%209.51h-4.2L39.79.04h-4.9l-3.46%2013.88%201.5%202.48.14.07H44.8v-.07L48.92.04h-4.9Z'%20data-name='Path%20784'/%3e%3cpath%20fill='%23fff'%20d='M147.15.04h-4.96V0c-1.4%205.46-4.02%2016.47-4.02%2016.47h4.9Z'%20data-name='Path%20785'/%3e%3cpath%20fill='%2316bdf9'%20d='m101.9%209.51.38-1.5h-8.6l10.6-7.97h-6.36L85.4%2010.6l.21.42%207.94-.03-9.34%205.03.17.35%207.94.03-13.39%208.01.25.42%2021.12-8.88.66-2.62h-6.08Z'%20data-name='Path%20786'/%3e%3c/g%3e%3c/svg%3e" alt="Thunderpick Logo">
                                    </a>
                                </div>
                                <!-- Team 1 Odds -->
                                <div class="team-odds">
                                    <div class="team-name truncate-text" data-bs-toggle="tooltip" data-bs-placement="top" title="${data.thunderpickData.team1}">${data.thunderpickData.team1}</div>
                                    <button class="odds-button" onclick="window.open('${data.thunderpickData.matchUrl}${window.affiliateLinks.thunderpickCode}', '_blank')" aria-label="Bet on ${data.thunderpickData.team1} at odds ${data.thunderpickData.team1Odds}">
                                        <i class="bi bi-cash-stack me-2  d-none d-lg-inline"></i>${team1Odds}
                                    </button>
                                </div>
                                    <!-- DRAW -->
                                <div class="team-odds">
                                    <div class="team-name truncate-text translate-draw" data-bs-toggle="tooltip" data-bs-placement="top" title="draw">Draw</div>
                                    <button class="odds-button" onclick="window.open('${data.thunderpickData.matchUrl}${window.affiliateLinks.thunderpickCode}', '_blank')" aria-label="Bet on draw at odds ${data.thunderpickData.team1Odds}">
                                        <i class="bi bi-cash-stack me-2  d-none d-lg-inline"></i>${drawOdds}
                                    </button>
                                </div>
                                <!-- Team 2 Odds -->
                                <div class="team-odds">
                                    <div class="team-name truncate-text" data-bs-toggle="tooltip" data-bs-placement="top" title="${data.thunderpickData.team2}">${data.thunderpickData.team2}</div>
                                    <button class="odds-button" onclick="window.open('${data.thunderpickData.matchUrl}${window.affiliateLinks.thunderpickCode}', '_blank')" aria-label="Bet on ${data.thunderpickData.team2} at odds ${data.thunderpickData.team2Odds}">
                                        <i class="bi bi-cash-stack me-2 d-none d-lg-inline"></i>${team2Odds}
                                    </button>
                                </div>
                            </div>
                        `;
            } else if (gameStatus !== 'finished' && team1Odds !== "No data" && team2Odds !== "No data") {
              team1Odds = parseFloat(team1Odds).toFixed(2);
              team2Odds = parseFloat(team2Odds).toFixed(2);

              const getRandomOdds = (odds) => {
                let fluctuation = parseFloat(odds) * 0.03;
                let randomFactor = Math.random() * fluctuation;
                return (parseFloat(odds) + randomFactor).toFixed(2);
              };

              let boomerang1Odds = getRandomOdds(team1Odds);
              let boomerang2Odds = getRandomOdds(team2Odds);
              oddsHtml = /*html*/ `
                            <!-- Odds Section (Static Data) -->
                            <div class="odds-section-parent">

                            <div class="odds-section">
                                <div class="bet-now">
                                    <!-- Placeholder Logo -->
                                  <svg xmlns="http://www.w3.org/2000/svg" width="180.33" height="24.83" viewBox="0 0 180.33 24.83">
                                    <rect width="180.33" height="24.83" fill="none"/>
                                  </svg>
                                </div>                            
                                <!-- Team 1 Name -->
                                <div class="team-odds">
                                    <div class="team-name truncate-text" data-bs-toggle="tooltip" data-bs-placement="top" title="${data.thunderpickData.team1}">${data.thunderpickData.team1}</div>
                                </div>
                                <!-- Team 2 Name -->
                                <div class="team-odds">
                                    <div class="team-name truncate-text" data-bs-toggle="tooltip" data-bs-placement="top" title="${data.thunderpickData.team2}">${data.thunderpickData.team2}</div>

                                </div>
                              </div>

                                <!-- BOOMERANG -->
                              <div class="odds-section">
                                <div class="bet-now">
                                    <!-- Logo -->
                                    <a href="${window.affiliateLinks.boomerangGeneral}" target="_blank" aria-label="Visit Thunderpick">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/myesports-65c4d.appspot.com/o/bookmakers%2Fbp_logo_white.webp?alt=media&token=81d367a8-fba0-4167-8cd3-07ef74c45343" alt="Thunderpick Logo">
                                    </a>
                                </div>                            
                                <!-- Team 1 Odds -->
                                <div class="team-odds">
                                    
                                    <button class="odds-button" onclick="window.open('${window.affiliateLinks.boomerangOddsGeneral}', '_blank')" aria-label="Bet on ${data.thunderpickData.team1} at odds ${data.thunderpickData.team1Odds}">
                                        <i class="bi bi-cash-stack me-2  d-none d-lg-inline"></i>${boomerang1Odds}
                                    </button>
                                </div>
                                <!-- Team 2 Odds -->
                                <div class="team-odds">

                                <button class="odds-button" onclick="window.open('${window.affiliateLinks.boomerangOddsGeneral}', '_blank')" aria-label="Bet on ${data.thunderpickData.team2} at odds ${data.thunderpickData.team2Odds}">
                                        <i class="bi bi-cash-stack me-2 d-none d-lg-inline"></i>${boomerang2Odds}
                                    </button>
                                </div>
                              </div>

                               <!-- THUNDERPICK -->
                              <div class="odds-section">
                                <div class="bet-now">
                                    <!--Logo -->
                                    <a href="${window.affiliateLinks.thunderpickGeneral}" target="_blank" aria-label="Visit Thunderpick">
                                        <img src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='180.33'%20height='24.83'%20viewBox='0%200%20180.33%2024.83'%3e%3cg%20data-name='Group%2016899'%3e%3cpath%20fill='%23fff'%20d='m126.72%2011.75%204.55-.03L140.78.04h-16.04l-4.06%2016.43h4.9Zm2.38-9.62h5.1l-7.34%209.03Z'%20data-name='Path%20776'/%3e%3cpath%20fill='%23fff'%20d='M116.13%208.81%20123.3.04h-16.1l-3.98%2016.43h4.9l3.53-14.34h5.04l-5.42%206.65%202.9%207.7h4.9Z'%20data-name='Path%20777'/%3e%3cpath%20fill='%23fff'%20d='M180.33.04h-4.9l-6.68%207.51%201.9-7.51h-4.9l-4.06%2016.43h4.9l1.78-7.17%204.2%207.17h4.9l-4.68-8.04Z'%20data-name='Path%20778'/%3e%3cpath%20fill='%23fff'%20d='M88.33%200%2073.82.04l-4.23%2016.43h6.5l13.6-14.2ZM75.67%2011.71l2.49-9.58h6.64v.07Z'%20data-name='Path%20779'/%3e%3cpath%20fill='%23fff'%20d='M61.44%2012.52%2059.48.04h-6.15l-4.1%2016.43h4.9l2.84-11.33%201.5%2011.33h6.85L69.38.04h-4.9Z'%20data-name='Path%20780'/%3e%3cpath%20fill='%23fff'%20d='M23.74%207.38h-4.68L20.9.04H16l-4.09%2016.43h4.9l1.54-6.3h4.69l-1.58%206.3h4.9L30.5.04h-4.9Z'%20data-name='Path%20781'/%3e%3cpath%20fill='%23fff'%20d='M14.27.04H.52L0%201.79h4.79l-3.6%2014.68h4.9L9.68%201.8h4.05Z'%20data-name='Path%20782'/%3e%3cpath%20fill='%23fff'%20d='M155.85%202.48h6.02l.59-2.45h-10.9l-4.03%2016.44h10.91l.53-2.1h-6.02Z'%20data-name='Path%20783'/%3e%3cpath%20fill='%23fff'%20d='M40.6%209.51h-4.2L39.79.04h-4.9l-3.46%2013.88%201.5%202.48.14.07H44.8v-.07L48.92.04h-4.9Z'%20data-name='Path%20784'/%3e%3cpath%20fill='%23fff'%20d='M147.15.04h-4.96V0c-1.4%205.46-4.02%2016.47-4.02%2016.47h4.9Z'%20data-name='Path%20785'/%3e%3cpath%20fill='%2316bdf9'%20d='m101.9%209.51.38-1.5h-8.6l10.6-7.97h-6.36L85.4%2010.6l.21.42%207.94-.03-9.34%205.03.17.35%207.94.03-13.39%208.01.25.42%2021.12-8.88.66-2.62h-6.08Z'%20data-name='Path%20786'/%3e%3c/g%3e%3c/svg%3e" alt="Thunderpick Logo">
                                    </a>
                                </div>                            
                                <!-- Team 1 Odds -->
                                <div class="team-odds">
                                    <button class="odds-button" onclick="window.open('${data.thunderpickData.matchUrl}${window.affiliateLinks.thunderpickCode}', '_blank')" aria-label="Bet on ${data.thunderpickData.team1} at odds ${data.thunderpickData.team1Odds}">
                                        <i class="bi bi-cash-stack me-2  d-none d-lg-inline"></i>${team1Odds}
                                    </button>
                                </div>
                                <!-- Team 2 Odds -->
                                <div class="team-odds">
                                    <button class="odds-button" onclick="window.open('${data.thunderpickData.matchUrl}${window.affiliateLinks.thunderpickCode}', '_blank')" aria-label="Bet on ${data.thunderpickData.team2} at odds ${data.thunderpickData.team2Odds}">
                                        <i class="bi bi-cash-stack me-2 d-none d-lg-inline"></i>${team2Odds}
                                    </button>
                                </div>
                              </div>

                            </div>
                        `;
            }

            if (data.predictions && data.predictions.length > 0) {

              // dynamically generate the betting link based on the provider
              const providerMapping = {
                'Thunderpick': {
                  betLink: data.thunderpickMatchUrl ? data.thunderpickMatchUrl : data.thunderpickData && data.thunderpickData.matchUrl ?
                    `${data.thunderpickData.matchUrl}${window.affiliateLinks.thunderpickCode}` : `${window.affiliateLinks.thunderpickGeneral}`,
                },
                'Sportaza': { betLink: `${window.affiliateLinks.sportazaGeneral}` },
                'Boomerang': { betLink: `${window.affiliateLinks.boomerangOddsGeneral}` },
              };

              const getLocalizedField = (field) => {
                if (field && typeof field === 'object') {
                  return field[userLanguage] || field['en'] || '';
                }
                return field || '';
              };
              data.predictions.forEach((prediction, index) => {
                let predictionOdd = prediction.predictionValue === "1" ?
                  data.thunderpickData && data.thunderpickData.team1StartingOdds && data.thunderpickData.team1StartingOdds !== 1 ? parseFloat(data.thunderpickData.team1StartingOdds).toFixed(2) : prediction.oddValue :
                  prediction.predictionValue === "2" ?
                    data.thunderpickData && data.thunderpickData.team2StartingOdds && data.thunderpickData.team2StartingOdds !== 1 ? parseFloat(data.thunderpickData.team2StartingOdds).toFixed(2) : prediction.oddValue :
                    prediction.predictionValue === "X" ?
                      data.thunderpickData && data.thunderpickData.drawStartingOdds && data.thunderpickData.drawStartingOdds !== 1 ? parseFloat(data.thunderpickData.drawStartingOdds).toFixed(2) : prediction.oddValue :
                      prediction.oddValue;
                const preMarkdownPred = (getLocalizedField(prediction.thePrediction));
                const converter = new showdown.Converter({
                  extensions: [
                    () => ({
                      type: 'output',
                      filter: (html, converter) => {
                        return html.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ');
                      }
                    })
                  ]
                });
                const convertedMarkdownContent = converter.makeHtml(preMarkdownPred);

                const thePrediction = converter.makeHtml(convertedMarkdownContent);

                let predictionChoice = prediction.predictionValue === "1" ?
                  `${team1name && team1img ? `<img src="${team1img}" class="img-fluid mx-auto predImage"> ${team1name} ${I18n.translateString("postMatchTranslations.predictToWin")}</img>` : ''}` :
                  prediction.predictionValue === "2" ?
                    `${team2name && team2img ? `<img src="${team2img}" class="img-fluid mx-auto predImage"> ${team2name} ${I18n.translateString("postMatchTranslations.predictToWin")}</img>` : ''}` :
                    prediction.predictionValue === "X" ?
                      `<svg class="predImage2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M488 192H336v56c0 39.7-32.3 72-72 72s-72-32.3-72-72V126.4l-64.9 39C107.8 176.9 96 197.8 96 220.2v47.3l-80 46.2C.7 322.5-4.6 342.1 4.3 357.4l80 138.6c8.8 15.3 28.4 20.5 43.7 11.7L231.4 448H368c35.3 0 64-28.7 64-64h16c17.7 0 32-14.3 32-32v-64h8c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24zm147.7-37.4L555.7 16C546.9 .7 527.3-4.5 512 4.3L408.6 64H306.4c-12 0-23.7 3.4-33.9 9.7L239 94.6c-9.4 5.8-15 16.1-15 27.1V248c0 22.1 17.9 40 40 40s40-17.9 40-40v-88h184c30.9 0 56 25.1 56 56v28.5l80-46.2c15.3-8.9 20.5-28.4 11.7-43.7z"/></svg> ${I18n.translateString("postMatchTranslations.predictDraw")}` :
                      /^\d+-\d+$/.test(prediction.predictionValue) ?
                        `<i class="bi bi-hand-thumbs-up-fill"></i> ${prediction.predictionValue} ${I18n.translateString("postMatchTranslations.predictExactScore")}` :
                        /^Over\d+\.\d+$/.test(prediction.predictionValue) ?
                          `<i class="bi bi-chevron-double-up"></i> Over ${prediction.predictionValue.match(/^Over(\d+\.\d+)/)[1]} ${I18n.translateString("postMatchTranslations.predictOverUnder")}` :
                          /^Under\d+\.\d+$/.test(prediction.predictionValue) ?
                            `<i class="bi bi-chevron-double-down"></i> Under ${prediction.predictionValue.match(/^Under(\d+\.\d+)/)[1]} ${I18n.translateString("postMatchTranslations.predictOverUnder")}` :
                            prediction.predictionValue;

                let placeBetHtml = '';
                if (!prediction.oddBoolean) {
                  placeBetHtml = /*html*/ `
                                    <a href="${providerMapping[prediction.provider].betLink}" target="_blank" class="betting-link blue-animation">
                                        <span class="translatePredictionButton"></span>${prediction.provider}!
                                    </a>
                                `
                } else {
                  placeBetHtml = /*html*/ `
                                    <a href="${providerMapping[prediction.provider].betLink}" target="_blank" class="betting-link blue-animation">
                                        <span class="translatePredictionButton"></span>${prediction.provider} <span class="translateWithOdd"></span>${predictionOdd}!
                                    </a>
                `;
                }
                predictionHtml = /*html*/ `
                                <!-- Predictions Section (Static or Dynamic as Needed) -->
                                <div class="predictions-section">
                                    <div class="prediction-content tipContentPlaceholder">
                                    ${thePrediction}
                                    </div>
                                  <span class="${data.status === 'finished' ? 'd-none' : ''}"> ${placeBetHtml}</span>
                                </div>
                                `;

                const authorImage = prediction.authorData.photo ? prediction.authorData.photo : noImageFound;
                const authorName = prediction.authorData.name ? prediction.authorData.name : `Team's`;
                const tipClickBaitString = PostMatchesComponent.getFirstWords(thePrediction, 10);
                predictionHtml2 += /*html*/ `
            <!-- TIP Accordion -->
            <div class="accordion accordion-flush my-3 row" id="accordionFlushExample${index}">                              
              <div class="accordion-item">
                <div class="accordion-header" id="flush-heading${index}">
                  <button class="accordion-button collapsed rounded-top color-lightdark clickable" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse${index}" aria-expanded="false" aria-controls="flush-collapse${index}">
                  
                  <div class="col-auto mb-3">
                    <div class="img-circle-container-tipster d-flex justify-content-center flex-column">
                      <img src="${authorImage}" alt="${authorName}'s tip on esports match" class="img-fluid mx-auto d-block">
                      <i class="bi bi-coin tipster-icon-overlay" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="col-8 col-md-9 col-lg-10 d-inline-flex flex-column justify-content-center align-content-start ms-3">
                    <span id="mainTipTitle${index}" class="text-name-ai-resize text-white ai-title"><span class="translateAuthorPrediction"></span>${authorName}: <span class="d-none d-lg-inline">${predictionChoice}</span></span>
                    <span class="text-name-ai-resize text-white ai-title d-sm-inline d-lg-none">${predictionChoice}</span>
                    <span id="tipClickBaitSpan${index}" class="text-ai-resize mt-3 text-truncate-3 text-white ai-clickbait"><em>"${tipClickBaitString}"</em></span>
                  </div>

                </button>
              </div>
              <div id="flush-collapse${index}" class="accordion-collapse collapse" aria-labelledby="flush-heading${index}" data-bs-parent="#accordionFlushExample${index}">
                <div class="accordion-body color-dark rounded-bottom">
                  <div id="tipFullBodySpan${index}" class="">${predictionHtml}</div>
                </div>
              </div>
            </div>
          </div>
            `;
                finalHtml = /*html*/ `
                        <!-- TIP Section Title -->
            <div class="container mt-5">
            <h2 class="section-title tipTranslationTitle"></h2>
            </div>
            ${predictionHtml2}
            `;
              });

            }

            const matchHtml = /*html*/ `
                            <div class="row  mb-3 rounded thePlayerContainer">
                                ${(() => {
                if (gameStatus === 'finished') {
                  endDif = PostMatchesComponent.postGlobalDate.getTime() - endDateRaw.getTime(); // calculate the difference between the current time and the end time of the match
                }
                console.debug("HERE", startDif)
                if (gameStatus !== 'finished' && gameStatus !== 'canceled' && startDif >= - 20 * 60 * 1000) { // if the start difference is less than 20 minutes, show the video player
                  return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                } else if (gameStatus !== 'canceled' && endDif !== null && endDif <= 20 * 60 * 1000) { // if the end difference is less than 20 minutes, show the video player
                  return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                } else if (gameStatus === 'running') { // if the game is running, show the video player
                  return `${videoPlayers[0]}
                                        <ul class="nav nav-pills style-nav-pills ">
                                            ${navPills}
                                        </ul>
                                    </div>`;
                } else { // if none of the above, don't show the video player
                  console.debug('the match is not live, so no video player')
                  return '';
                }
              })()
              }   
                            </div>

             <!-- Main Content Area -->
            <div class="col-md-12">
                <!-- Match Section -->
                <div id="postmatch-${matchElementId}" class="match-section">
                    <!-- Match Header -->
                    <div class="match-header">
                        <div class="tournament-info d-flex align-items-center mb-3 mb-md-0 w-100">
                            <!-- Tournament Logo -->
                            ${tournamentLogoHTML}
                            <div class="ms-2 w-100">
                            <div class="d-flex justify-content-between align-items-center w-100">
                               <div class="tournament-name truncate-text clickable" data-bs-toggle="tooltip" data-bs-placement="top" title="${tournamentName}" id="${serieIdElement}">${tournamentName}</div>
                                <div class="match-status d-flex align-items-center d-none d-xxl-flex">
                    <span class="badge bg-secondary matchTypeFromPostMatch me-2">${matchTypeFinal}</span>
                    <span id="${countdown}" class="me-2"></span>
                     ${gameLogoHTML}
                </div>
                </div>
                 <div class="d-flex align-items-center justify-content-between mt-2 mt-sm-0">
                                <div class="match-date"><i class="bi bi-clock"></i> ${date}</div>
                                 <!-- Match Status on Mobile -->
                <div class="match-status d-flex align-items-center ms-3 d-xxl-none">
                    
                    <span id="${countdownMobile}" class="me-2"></span>
                    ${gameLogoHTML}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    <!-- Team vs Score Section -->
                    <div class="team-vs">
                        <!-- Team 1 -->
                        <div class="team">
                            <div class="d-flex flex-column justify-content-center align-content-center align-items-center" id="team-${team1id}">
                            <img src="${team1img}" alt="${team1name}" class="img-fluid mx-auto d-block" loading="lazy">
                            <div class="team-name truncate-text"  data-bs-toggle="tooltip" data-bs-placement="top" title="${team1name}" >${team1name}</div>
                            
                            <!-- Win/Loss Badges -->
                            <div class="win-loss-badges">
                                ${resultCircles1HTML}
                            </div>
                            </div>
                        </div>
                        <!-- Score -->
                        <div class="score" id="${idOfScore}">${score}</div>
                        <!-- Team 2 -->
                        <div class="team">
                            <div class="d-flex flex-column justify-content-center align-content-center align-items-center" id="team-${team2id}">
                            <img src="${team2img}" alt="${team2name}" class="img-fluid mx-auto d-block" loading="lazy">
                            <div class="team-name truncate-text"  data-bs-toggle="tooltip" data-bs-placement="top" title="${team2name}">${team2name}</div>
                            <!-- Win/Loss Badges -->
                            <div class="win-loss-badges">
                                ${resultCircles2HTML}
                            </div>
                            </div>
                        </div>
                    </div>
                     <div class="match-status d-flex mb-3 align-items-center justify-content-center d-xxl-none">
                            <span class="badge bg-secondary matchTypeFromPostMatch me-2">${matchTypeFinal}</span>
                          </div>
                    <!-- Odds Section -->
                    ${oddsHtml}
                </div>

                <!-- Predictions Section -->
                ${finalHtml}


                <!-- AI Analysis Section Title -->
  <div class="container mt-5 ${data.status === 'finished' ? 'd-none' : ''}  ">
    <h2 class="section-title aiTranslationTitle"></h2>
  </div>

          <!-- AI Analysis Accordion -->
          <div class="accordion accordion-flush my-3 row ${data.status === 'finished' ? 'd-none' : ''}" id="accordionFlushExample">                              
            <div class="accordion-item">
              <div class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed rounded-top color-lightdark clickable" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  
                  <div class="col-auto mb-3">
                    <div class="img-circle-container-ai d-flex justify-content-center flex-column">
                      <img src="${aiAvatar}" alt="AI Avatar analyzing esports match" class="img-fluid mx-auto d-block">
                      <i class="bi bi-activity ai-icon-overlay" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="col-8 col-md-9 col-lg-10 d-inline-flex flex-column justify-content-center align-content-start ms-3">
                    <span id="mainAiTitle" class="text-name-ai-resize text-white ai-title">${I18n.translateString("postMatchTranslations.whatDoesAiThink")}</span>
                    <span id="clickBaitSpan" class="text-ai-resize mt-3 text-truncate-3 text-white ai-clickbait"><em>"${clickBaitString}"</em></span>
                  </div>
        <!--          <span class="my-2 me-2 badge bg-success text-resize-badge text-ai">${I18n.translateString("postMatchTranslations.aiAnalysis")}</span>-->
                  
                </button>
              </div>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body color-dark rounded-bottom">
                  <div id="analysisFullBodySpan" class="">${htmlAnalysisContent}</div>
                </div>
              </div>
            </div>
          </div>



                <!-- Teams and Player Cards -->
                <div class="team-players-container row my-3 p-0 rounded">
                    <div class="col-12">
                        <div class="team-section">
                            <div class="team-title">${team1name} <span class="team1players"></span></div>
                            <div class="scrollbar players-grid row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-3">
                                ${teamData1Html}
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="team-section">
                            <div class="team-title">${team2name} <span class="team2players"></span></div>
                            <div class="scrollbar players-grid row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-3">
                                ${teamData2Html}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                             
                        
                        <div class="row my-3 p-0 rounded">
                        <h2 class="matchHistory" style="justify-content: center; text-align: center;"></h2>
                        <ul class="nav nav-pills mb-1 text-resize-match-history p-0" id="past1-pills-tab" role="tablist">
                            ${pastPills1}
                            </ul>
                        <div class="col-12">  
                            <div class="tab-content" id="past1-pills-tabContent">
                            ${h2hMatchesforTeam1}
                            ${pastMatchesforTeam1}
                            ${pastMatchesforTeam2}                         
                            </div>
                            </div>
                        `;
            document.querySelector('#matchpost-html').insertAdjacentHTML('beforeend', matchHtml);
            if (data.opponents[0].type !== 'Team') {
              document.querySelector('.team-players-container').style.display = 'none';
            }
            const myCollapsible = document.getElementById('flush-collapseOne')
            // Check if the collapsible element exists
            if (myCollapsible) {
              // Add event listeners for collapse events
              myCollapsible.addEventListener('hidden.bs.collapse', event => {
                const clickBaitSpan = document.getElementById('clickBaitSpan');
                if (clickBaitSpan) {
                  clickBaitSpan.style.display = '';
                  clickBaitSpan.style.opacity = '1';
                }
              });

              myCollapsible.addEventListener('show.bs.collapse', event => {
                const clickBaitSpan = document.getElementById('clickBaitSpan');
                clickBaitSpan.style.opacity = '0';
                setTimeout(() => {
                  clickBaitSpan.style.display = '';
                }, 300); // Match the CSS transition duration
              });



              // Handle the analysisString length
              if (analysisString.length < 200) {
                // Remove the collapsible content
                myCollapsible.remove();

                // Hide the clickBaitSpan
                const clickBaitSpan = document.getElementById('clickBaitSpan');
                if (clickBaitSpan) {
                  clickBaitSpan.style.display = 'none';
                }

                // Update the mainAiTitle
                const mainAiTitle = document.getElementById('mainAiTitle');
                if (mainAiTitle) {
                  mainAiTitle.innerText = analysisString;
                }
              }
            }

            document.querySelectorAll('.accordion-collapse').forEach((collapsible, index) => {
              collapsible.addEventListener('hidden.bs.collapse', event => {
                const clickBaitSpan = document.querySelector(`.tipClickBaitSpan[data-index="${index}"]`);
                if (clickBaitSpan) {
                  clickBaitSpan.style.display = '';
                  clickBaitSpan.style.opacity = '1';
                }
              });

              collapsible.addEventListener('show.bs.collapse', event => {
                const clickBaitSpan = document.querySelector(`.tipClickBaitSpan[data-index="${index}"]`);
                if (clickBaitSpan) {
                  clickBaitSpan.style.opacity = '0';
                  setTimeout(() => {
                    clickBaitSpan.style.display = '';
                  }, 300); // Match the CSS transition duration
                }
              });
            });

            document.getElementById('placeholder-stream').style.display = "none"; //VGAZW TO PLACEHOLDER
            const matchTypeElements = document.querySelectorAll('.matchTypeFromPostMatch');
            matchTypeElements.forEach(matchTypeElement => {
              if (matchTypeElement.innerHTML === 'all_games_played') {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
              } else if (matchTypeElement.innerHTML === 'best_of_X') {
                let gamesNumber;
                if (window.translateTo === 'el') {
                  gamesNumber = Math.floor(numberOfGames / 2) + 1;
                } else {
                  gamesNumber = numberOfGames;
                }
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: gamesNumber });
              } else if (matchTypeElement.innerHTML === 'best_of_2') {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
              } else if (matchTypeElement.innerHTML === 'first_to_X') {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
              } else if (matchTypeElement.innerHTML === 'single_game') {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
              } else {
                matchTypeElement.innerHTML = I18n.translateString("matchTranslations.generalDrop", { matchType: matchTypefull });
              }
            });
            document.querySelectorAll('.fixed-size-badge-wld').forEach(function (el) {
              if (el.innerHTML === 'W') {
                el.innerHTML = I18n.translateString("postMatchTranslations.badgeWon");
              } else if (el.innerHTML === 'L') {
                el.innerHTML = I18n.translateString("postMatchTranslations.badgeLost");
              } else if (el.innerHTML === 'D') {
                el.innerHTML = I18n.translateString("postMatchTranslations.badgeDraw");
              }
            });
            document.querySelectorAll('.age-label').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.ageLabel"));
            document.querySelectorAll('.position-label').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.positionLabel"));
            document.querySelectorAll('.team1players').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.team1players"));
            document.querySelectorAll('.team2players').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.team2players"));
            document.querySelectorAll('.aiTranslationTitle').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.aiTranslationTitle"));
            document.querySelectorAll('.tipTranslationTitle').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.tipTranslationTitle"));
            document.querySelectorAll('.matchHistory').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.matchHistory"));
            document.querySelectorAll('.h2hpillClass').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.h2hPill"));
            document.querySelectorAll('.noVideoMessage').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.noVideoMessage"));
            document.querySelectorAll('.translateAuthorPrediction').forEach(el => el.innerHTML = I18n.translateString("predictionsTranslations.authorsPrediction"));
            document.querySelectorAll('.translatePredictionButton').forEach(el => el.innerHTML = I18n.translateString("predictionsTranslations.betButton1"));
            document.querySelectorAll('.translateWithOdd').forEach(el => el.innerHTML = I18n.translateString("predictionsTranslations.odd"));
            if (document.querySelector('.bet-now-button')) {
              document.querySelector('.bet-now-button').innerHTML = I18n.translateString("postMatchTranslations.betNow");
            }
            if (document.querySelector('.translate-draw')) {
              document.querySelector('.translate-draw').innerHTML = I18n.translateString("postMatchTranslations.oddsDraw");
            }
            htmlTags.forEach((tag, index) => {
              let pill = document.getElementById(tag);
              if (pill) {
                pill.addEventListener('click', () => {
                  console.debug('Pill clicked:', pill.id);
                  // Remove 'active' class from all pills
                  htmlTags.forEach((tag) => {
                    let p = document.getElementById(tag);
                    if (p) p.classList.remove('active');
                  });

                  // Add 'active' class to the clicked pill
                  pill.classList.add('active');

                  // Replace the current video player with the one associated with the clicked pill
                  let videoContainer = document.querySelector('#videosid iframe');
                  if (videoContainer) {
                    videoContainer.src = videoLinks[index]; // Use videoLinks instead of videoPlayers
                  }

                  // Replace the current chat with the one associated with the clicked pill
                  let chatIframe = document.querySelector('#chats iframe');
                  if (chatIframe) {
                    chatIframe.src = chatLinks[index];
                  }
                });
              }
            });

            let pillElements = document.querySelectorAll('#past1-pills-tab .nav-link');
            for (let i = 0; i < pillElements.length; i++) {
              new bootstrap.Tab(pillElements[i]);
            }

            const tabPanes = document.querySelectorAll('#past1-pills-tabContent .tab-pane');
            for (let i = 0; i < tabPanes.length; i++) {
              if (tabPanes[i].innerHTML.trim() === '') {
                tabPanes[i].innerHTML = /*html*/ `
                                                                            <div class="row rounded text-white color-dark">
                                <span class="mt-4 mb-4 h2hNoRecentMsg" style="justify-content: center; text-align: center;"></span>
                                </div>
                                `;
              }
            }
            document.querySelectorAll('.h2hNoRecentMsg').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.h2hNoRecentMsg"));
            PostMatchesComponent.redirectToMatchFromPastMatches(matchIdsArrayForListener);
            PostMatchesComponent.redirectToSeriePage(serieIdElement);
            PostMatchesComponent.startCountdown(dateRaw, countdownElementId, matchElementId, gameStatus);
            // Fetch team data here, inside the callback function
            PostMatchesComponent.resizetoTwitchHeight()
            document.querySelectorAll('postmatches-component .card').forEach(el => el.addEventListener('click', PostMatchesComponent.showPlayerData))
            window.tadeCallback = undefined;

          }
        }
      } else {
        console.debug('The post matches component callback is supposed to run but we are not on the right page!!!!')
      }
      window.tadeCallback = undefined;
    })

  }

  static showPlayerData(evt) {
    // GamerShowcase.showGamer(evt.target.closest('.card').getAttribute('data-lookupid'))
    console.debug('I am supposedly opening the player modal here')
  }

  static async getTeamsData(team1idss, team2idss) {
    return new Promise(async (resolve, reject) => {
      FirebaseAssistant.callHelloWorldFunction({ team1: team1idss, team2: team2idss }, (result) => {
        console.debug(result)
        resolve(result)
      })
    })
  }

  static async getRostersData(tournamentId, team1Id, team2Id) {
    return new Promise(async (resolve, reject) => {
      try {
        FirebaseAssistant.callGetRostersFunctions({ tournamentId: tournamentId, rosterId1: team1Id, rosterId2: team2Id }, (result) => {
          // console.debug(result)
          resolve(result);
        });
      } catch (error) {
        console.error('Error fetching rosters data:', error);
        reject(error);
      }
    });
  }

  static redirectToSeriePage(serieElementId) {

    const element = document.getElementById(serieElementId);
    const alteredSerieElementId = serieElementId.replace(/.*seriefrompostmatch-/, '');
    if (window.location.href.includes('s_match') && element && !element.listenerAdded) {
      element.addEventListener('click', function (evt) {
        evt.stopPropagation();
        RouterAssistant.redirect('s_tournament', {
          tournament: alteredSerieElementId
        })
      })
      element.listenerAdded = true;
    }

  }

  static redirectToMatchFromPastMatches(matchIdsArrayForListener) {
    matchIdsArrayForListener.forEach(pastmatchId => {
      const element = document.getElementById(pastmatchId);
      const alteredpastmatchId = pastmatchId.replace(/.*pastMatchFromPostmatch-/, '');
      if (window.location.href.includes('s_match') && element && !element.listenerAdded) {
        element.addEventListener('click', function (evt) {
          evt.stopPropagation();
          RouterAssistant.redirect('s_match', {
            match: alteredpastmatchId
          })
        })
        element.listenerAdded = true;
      }
    });
  }

  static extractFirstTextBlock(markup) {
    try {
      // Split the string into lines
      const lines = markup.split('\n');

      // Initialize an array to hold the text block lines
      let textBlock = [];
      let blockStarted = false;

      // Iterate over each line
      for (let line of lines) {
        // Check if the line does not start with special characters and is not empty
        if (!line.startsWith('##') && !line.startsWith('#') && !line.startsWith('**') && !line.startsWith('* **') && !line.startsWith('*') && !line.startsWith('-') && line.trim() !== '') {
          textBlock.push(line.trim());
          blockStarted = true;
        } else if (blockStarted) {
          // If the block has started and we encounter a line with special characters or an empty line, break the loop
          break;
        }
      }
      const textToBeReturned = textBlock.join(' ').replace('<p>', "").replace('</p>', "");
      return (textToBeReturned.length === 0) ? "You won't believe it" : textToBeReturned;
    } catch (e) {
      console.log('ERROR')
      return ''
    }

  }

  static getFirstWords(str, numWords) {
    const words = str.split(' ');
    const theString = words.length > numWords ? words.slice(0, numWords).join(' ') + '...' : str;
    let refactoredString = theString.replace(/<[^>]*>?/gm, '');
    return refactoredString;
  }

  static startCountdown(gameStartTime, elementId, matchElementId, gameStatus) {
    const now = new Date();
    const diffInMilliseconds = gameStartTime - now;
    const diffInMinutes = Math.round(diffInMilliseconds / 1000 / 60);

    const element = document.getElementById(elementId);
    const element2 = document.getElementById(elementId + '-mobile');
    const matchElement = document.getElementById(`postmatch-${matchElementId}`);

    if (element && diffInMinutes <= 60 && diffInMinutes > 0) {
      element.innerHTML = I18n.translateString("matchTranslations.matchMatchUpcoming", { minutes: diffInMinutes });
      if (element2) {
        element2.innerHTML = I18n.translateString("matchTranslations.matchMatchUpcoming", { minutes: diffInMinutes });
      }
    } else if (element && (diffInMinutes <= 0 && gameStatus === 'running')) {
      element.innerHTML = I18n.translateString("matchTranslations.matchMatchLive");
      if (element2) {
        element2.innerHTML = I18n.translateString("matchTranslations.matchMatchLive");
      }
    } else if (element && (diffInMinutes <= 0 && diffInMinutes >= -180 && gameStatus === 'not_started')) {
      element.innerHTML = I18n.translateString("matchTranslations.matchMatchAboutTo");
      if (element2) {
        element2.innerHTML = I18n.translateString("matchTranslations.matchMatchAboutTo");
      }
    } else if (element && (gameStatus === 'finished')) {
      element.innerHTML = I18n.translateString("matchTranslations.matchMatchFinished");
      if (element2) {
        element2.innerHTML = I18n.translateString("matchTranslations.matchMatchFinished");
      }
    } else if (element && (gameStatus === 'postponed')) {
      element.innerHTML = I18n.translateString("matchTranslations.matchMatchPostponed");
      if (element2) {
        element2.innerHTML = I18n.translateString("matchTranslations.matchMatchPostponed");
      }
    } else if (element && (gameStatus === 'canceled')) {
      element.innerHTML = I18n.translateString("matchTranslations.matchMatchCanceled");
      if (element2) {
        element2.innerHTML = I18n.translateString("matchTranslations.matchMatchCanceled");
      }
    }
  }

  static PromoVideo(fromHotmatch, promomatchid) {
    if (window.savedStreamers) {
      for (const [key, streamer] of Object.entries(window.savedStreamers)) {
        // console.debug("here", key, streamer);

        if (streamer.promo && streamer.promo.check && streamer.promo.promoMatchId && streamer.promo.check === true && streamer.promo.promoMatchId === promomatchid && streamer.status === 'Online' && fromHotmatch === false) {
          // console.debug('Promo streamer found with corresponding matchid:', streamer);
          return streamer.userLogin;
        } else if (streamer.promo && streamer.promo.check && streamer.promo.promoMatchId && streamer.promo.check === true && streamer.status === 'Online' && fromHotmatch === true) {
          // console.debug('Promo streamer found but for the hotmatches:', streamer);
          return {
            promoMatchId: streamer.promo.promoMatchId,
            userLogin: streamer.userLogin,
            promoViewers: streamer.viewerCount
          };
        } else {
          // console.debug('Promo streamer not found:', streamer);
        }
      }
    } else {
      console.debug('window.savedStreamers is not defined');
    }
  }


}

document.addEventListener('DOMContentLoaded', function () {
  // Initialize all tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, {
      delay: { "show": 500, "hide": 100 }, // Example delay
      animation: true,
      html: true,
      trigger: 'hover focus click' // Handle hover and click for mobile
    });
  });
});



PostMatchesComponent.template = /*html*/ `
<div class="twitch-container p-3 bg-dark text-white rounded placeholder-glow" id="placeholder-stream">
<div class="ratio ratio-16x9 placeholder">
</div>
  <div class="placeholder-glow">
    <span class="placeholder col-12"></span>
       </div>
</div>


<div id="matchpost-html"></div>
<gamer-showcase></gamer-showcase>
`;

PostMatchesComponent.reg('postmatches-component');

import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { PostAuthorComponent } from "../postauthor-component/postauthor-component";



const css = `

`

export class PostAuthorContainer extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters()
        document.head.insertAdjacentHTML("beforeend", css)

    }
}

PostAuthorContainer.template = /*html*/`
    <h1 id="authorPostContainerHeader" style="font-size: 1px;color: rgb(15, 18, 23)"></h1>


    <div class="main-content col-lg-8 col-md-12 col-sm-12 col-xs-12 g-0 mt-2 ">
         <div class="row">
                    <postauthor-component></postauthor-component>
        </div>
    </div>





 <div class="sidebar col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5 ">
                    <div class="sidebar-predictions home-components row m-2 ">
                        <predictions-homecomponent></predictions-homecomponent>
                    </div>
                    <div class="sidebar-discord home-components row m-2 " >
                        <div class="home-discord-template">
                            <discord-template></discord-template>
                        </div>
                    </div>
                    <div class="sidebar-bonus home-components row m-2 ">
                        <div class="home-widget-bookmakers">
                            <widget-bookmakers></widget-bookmakers>
                            <widget-bookmakers-skin></widget-bookmakers-skin>
                        </div>
                    </div>
                </div>
`;

PostAuthorContainer.reg('postauthor-container');
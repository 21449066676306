export const englishTranslation =
{
    "metaTags": {
        "description": "Unlock the Ultimate eSports Experience! Livestreams, Articles, Predictions, & More! Join the Discord Fun Now!"
    },
    "homePage": {
        "generalMessage": "Suck my dick from within the Home Page",
        "desktopMessage": "Desktop!",
        "mobileMessage": "Mobile!",
        "variablesAre": "The page variables are",
        "redirectButton": "Go to Posts",
        "latestAnalyses": 'LATEST ΑΙ ANALYSES'
    },
    "postPage": {
        "generalMessage": "Suck my dick from within the Post Page",
        "desktopMessage": "Desktop!",
        "mobileMessage": "Mobile!",
        "variablesAre": "The page variables are",
        "redirectButton": "Go to Home",
    },
    "gamerShowcase": {
        "showGamer": "Show Gamer's profile",
        "modalHeader": "Profile of",
    },
    "networkGuard": {
        "title": "Oh Dear!",
        "description": "Slow or no internet connection.",
    },
    "navMainMenu": {
        "Home": "Home",
        "Tournaments": "Tournaments",
        "Matches": "Matches",
        "Giveaways": "Giveaways",
        "Casinos": "Bookmakers",
        "AboutUs": "About us",
        "Streamers": "Streamers",
        "News": "News",
        "navDesktopTitle": "MyEsports",
    },
    "hotmatchTranslations": {
        "playerNationalities": "Players from {{countries}} compete",
        "playerAges": "The youngest player is {{Age}} years old",
        "viewers": "{{maxviewers}} watching now!",
        "alternativeTimeLineHours": "Game {{RGN}} ongoing. Started {{hourz}} hour(s) and {{minutez}} minute(s) ago",
        "alternativeTimeLineMinutes": "Game {{RGN}} ongoing. Started {{minutez}} minute(s) ago",
        "badgeHot": `<i class="me-2 bi bi-fire"></i>HOT`,
        "noLiveMsg": `<h2>Hang tight, action is just around the corner!</h2><p>Looks like the {{hotGame}} action is taking a breather.</p>`,
    },
    "matchTranslations": {
        "matchMatchUpcoming": `<span class="my-2 badge bg-primary text-resize-badge countdownBadge">Starts in {{minutes}} mins approx</span>`,
        "matchMatchLive": `<span class="my-2 badge bg-danger text-resize-badge live-animation countdownBadge"><i class="me-2 bi bi-broadcast"></i>LIVE</span>`,
        "matchMatchFinished": `<span class="my-2 badge bg-secondary text-resize-badge countdownBadge">Finished</span>`,
        "matchMatchPostponed": `<span class="my-2 badge bg-warning text-resize-badge countdownBadge">Postponed</span>`,
        "matchMatchCanceled": `<span class="my-2 badge bg-warning text-resize-badge countdownBadge">Canceled</span>`,
        "matchMatchAboutTo": `<span class="my-2 badge bg-primary text-resize-badge countdownBadge">About to start</span>`,
        "homeMatchDHM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{days}}d {{hours}}h {{minutes}}m</span>`,
        "homeMatchHM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{hours}}h {{minutes}}m</span>`,
        "homeMatchM": `<span class="m-0 badge bg-secondary text-resize-badge countdownBadge">{{minutes}}m</span>`,
        "homeMatchLive": `<span class="m-0 badge bg-danger text-resize-badge countdownBadge"><i class="me-2 bi bi-broadcast"></i>LIVE</span>`,
        "homeMatchAboutTo": `<span class="m-0 badge bg-secondary text-resize-badge">Starting</span>`,
        "pillLive": "LIVE",
        "pillUpcoming": "UPCOMING",
        "pillFinished": "FINISHED",
        "pillPredictions": "PREDICTIONS",
        "pillAll": "ALL",
        "bestofX": "Best of {{gamesNumber}}",
        "bestof1": "Best of 1",
        "bestof2": "Best of 2",
        "allGamesPlayed": "{{gamesNumber}} games",
        "firstToX": "First to {{gamesNumber}}",
        "generalDrop": "{{matchType}}",
        "showMoreMatches": "View All",
        "emptyGameFilterMessage": "Νo available {{game}} matches at the moment.",
        "emptyStateFilterMessage": "Νo available {{game}} matches with the selected filters.",
        "emptyPredictionFilterMessage": "No available {{predGame}} predictions for the selected date.",
    },
    "postMatchTranslations": {
        "ageLabel": "Age: ",
        "positionLabel": "Position: ",
        "h2hNoRecentMsg": "No recent matches between these teams",
        "matchHistory": "Match History",
        "h2hPill": "Head to Head",
        "badgeWon": "W",
        "badgeLost": "L",
        "badgeDraw": "D",
        "noVideoMessage": "No video available for this match",
        "analysis": "Our 'PlayPulse' AI is currently analysing the data of this match.  Come back in approximately 30 minutes to read the analysis!",
        "whatDoesAiThink": "What does \"PlayPulse\", our eSports AI think about this match?",
        "aiAnalysis": "AI ANALYSIS",
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>No Recent Articles</h2>
      <p>Unfortunately, there are no {{newsGame}} recent articles.</p>`,
        "newsTitle": "ARTICLES",
        "writtenAt": "Written at",
        "footerArticle": "🔥 Enjoyed the Article? 🔥",
        "footerArticleFollow": "Don't miss out! Follow us on social media for more exciting updates!",
        "newsSeeAll": "See All",
        "seeMore": "See More...",
        "goBack": "Go back to News",
        "tournamentNews": "Tournaments",
        "rosterNews": "Rosters",
        "gameNews": "Gameplay",
    },
    "footer": {
        "about": "Welcome to MyEsports, your comprehensive destination for all eSports enthusiasts. Born from a deep love and passion for the gaming world, we've created a space where the eSports community can find everything: from live broadcasts of matches and events to predictions, analyses, and articles for all kinds of games.",
        "followUs": "Follow Us",
        "usefulLinks": "Useful Links",
        "contactUs": "Contact Us",
        "aboutUs": "About Us",
        "eula": "Terms of Use",
        "privacy": "Privacy Policy",
        "faq": "Frequently Asked Questions",
        "Authors": "Authors",
    },
    "tournamentsTranslations": {
        "noDateMsg": "Ending date TBD",
        "tier": "Tier",
        "emptyTournamentFilterMessage": "No available {{tourGame}} tournaments with the selected status filters.",
        "pillOngoing": "ONGOING",
        "pillUpcoming": "UPCOMING",
        "pillFinished": "FINISHED",
        "pillAll": "ALL",
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>Ongoing</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">Finished</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{days}} days</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{days}} day</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{hours}}h {{minutes}}m</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{minutes}}m</span>`,
        "noPrizePoolAvailable": "N/A",
        "euroPrizePool": "€",
        "usdPrizePool": "$",
        "teamsCompete": "Τeams compete",
        "hotTournamentsTitle": "HOT TOURNAMENTS",
        "hotTournamentFixedLabel": `ONGOING`,
        "noHotTournamentsMsg": `<h2>No Live Tournaments Currently</h2><p>Unfortunately, there are no {{hTgame}} live tournaments currently.</p>`,
        "noHotTournamentsButton": "View All",
        "noHotTournamentsButton2": "See All",
        "clickHotTournaments": "Click to view more information.",
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Teams Compete: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `Players Compete: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `Prizepool: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Prizepool: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Prizepool: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": "Tier:",
        "winnerPost": "Tournament Winner:",
        "startPost": "Start Date:",
        "endPost": "End Date:",
        "noDateMsgPost": "Ending date TBD",
        "ladderWins": "Wins",
        "ladderLosses": "Losses",
        "ladderTies": "Ties",
        "ladderGamesWon": "Games Won",
        "ladderGamesLost": "Games Lost",
        "ladderTieBreaker": "Tiebreakers",
        "upperBracket": "Upper Bracket",
        "lowerBracket": "Lower Bracket",
        "grandFinal": "Grand Final",
        "semiFinal": "Semi Final",
        "quarterFinal": "Quarter Final",
        "roundBrackets": "Round",
        "matchBrackets": "Match",
        "laddersMatchesTitle": "Matches",
        "week": "Week",
        "liveWeek": "LIVE",

    },
    "predictionsTranslations": {
        "predTitle": "PREDICTIONS",
        "comingSoon": "Coming soon",
    },
    "banners": {
        "desktopLeft": 'en_desktop_left',
        "desktopRight": 'en_desktop_right',
    },
    "streamerTranslations": {
        "cardFollowers": "Followers",
        "cardInfo": `{{user}} is {{status}} at the moment.`,
        "cardViewers": "Viewers!",
        "cardFollowers": "Followers",
        "detailsInformation": "Streamer Information",
        "detailsName": "Full Name",
        "detailsNationality": "Nationality",
        "detailsAge": "Age",
        "detailsGame": "Games",
        "detailsRole": "Role",
        "detailsTeam": "Team",
        "detailsSignature": "Signature Hero",
        "accordionHowTo": "<strong>-How can I become a streamer on MyEsports?</strong>",
        "accordionHowToReply": `To become a streamer on MyEsports, you can apply by joining our <a href="https://discord.gg/myesports" target="_blank" rel="noopener noreferrer">Discord server</a> and contacting our team or fill this <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">form</a>.`,
        "accordionEarn": `<strong>-What do I earn by becoming a streamer on MyEsports?</strong>`,
        "accordionEarnReply": `Being presented on MyEsports will give you the opportunity to increase your audience and grow your channel. You will also have the chance to be featured in our articles and social media.`,
        "accordionGraphics": `<strong>-Will I receive graphics for my channel?</strong>`,
        "accordionGraphicsReply": `Yes, we will provide you with graphics for your channel, as well as support and guidance on how to use them and improve your streaming experience.`,
        "accordionHowToStream": `<strong>-I am new to streaming, can I still join? How do i start?</strong>`,
        "accordionHowToStreamReply": `Of course! We welcome streamers of all levels. You can start by joining our <a href="https://discord.gg/myesports" target="_blank" rel="noopener noreferrer">Discord server</a> and contacting our team for more information.`,
        "accordionViewers": `<strong>-How many viewers do I need to have to join?</strong>`,
        "accordionViewersReply": `We welcome streamers of all sizes. Whether you have 1 or 10,000 viewers, you are welcome to join MyEsports!`,
    },
    "authorsTranslations": {
        "title": 'Discover the MyEsports Author Team',
    },
}

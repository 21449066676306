import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";

import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";

import { I18n } from "../../utilities/i18n/i18n";


const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fifa = require('../game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/rainbow six 150x150.png')
const roblox = require('../game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../game-navbar/assets/images/KingOfGlory 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')


const css = `
    <style>
tournaments-component .nav .nav-item , .nav .nav-link {
background=red !important;
color:#ffffff !important;
}
 tournaments-component .nav .active{
background-color: #ff282859 !important;
color:#ffffff !important;
}
 tournaments-component .nav .disabled{
color:#707070 !important;
}  
tournaments-component .text-resize-name {
    font-weight: 400;
    color: #ffffff;
}

tournaments-component .text-resize-date {
    font-weight: 200;
}
 tournaments-component .text-resize-info {
        font-weight: 500;
        color: white;
    }

tournaments-component .img-circle-container {
     overflow: hidden;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
tournaments-component .text-resize-name {
    font-size: 0.5rem;
}
    tournaments-component .text-resize-badge {
        font-size: 0.3rem;
        font-weight:700
    }

    tournaments-component .text-resize-date {
        font-size: 0.4rem;
    }
     tournaments-component .text-resize-info {
        font-size: 0.4rem;
    }

    tournaments-component .img-circle-container {
        width: 2rem;
        height: 2rem;
    }
    tournaments-component .img-circle-container img {
        max-width: 1.5rem;
        max-height: 1.5rem;
    }
    tournaments-component .nav .nav-item {
    font-size: 0.55rem;
    font-weight: 700;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
tournaments-component .text-resize-name {
    font-size: 0.5rem;
}
    tournaments-component .text-resize-badge{
        font-size: 0.4rem;
        font-weight: 700;
    }

    tournaments-component .text-resize-date {
        font-size: 0.4rem;
    }
    tournaments-component .text-resize-info {
        font-size: 0.45rem;
    }


    tournaments-component .img-circle-container {
        width: 2rem;
        height: 2rem;
    }
    tournaments-component .img-circle-container img {
        max-width: 1.5rem;
        max-height: 1.5rem;
    }
    tournaments-component .nav .nav-item {
    font-size: 0.6rem;
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
tournaments-component .text-resize-name {
    font-size: 0.6rem;
}
    tournaments-component .text-resize-badge {
        font-size: 0.5rem;
        font-weight:500;
    }

    tournaments-component .img-circle-container {
        width: 2.5rem;
        height: 2.5rem;
    }
    tournaments-component .img-circle-container img {
        max-width: 2rem;
        max-height: 2rem;
    }
       tournaments-component .text-resize-date {
        font-size: 0.45rem;
    }
     tournaments-component .text-resize-info {
        font-size: 0.5rem;
    }
    tournaments-component .nav .nav-item {
    font-size: 0.6rem;
    font-weight: 700;
}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
tournaments-component .text-resize-name {
    font-size: 0.65rem;
}
    tournaments-component .text-resize-badge {
        font-size: 0.5rem;
        font-weight: 700;
    }

    tournaments-component .img-circle-container {
        width: 2.5rem;
           height: 2.5rem;
    }
    tournaments-component .img-circle-container img {
        max-width: 2rem;
        max-height: 2rem;
    }
       tournaments-component .text-resize-date {
        font-size: 0.5rem;
    }
     tournaments-component .text-resize-info {
        font-size: 0.55rem;
    }
    tournaments-component .nav .nav-item {
     font-size: 0.65rem;
    font-weight: 600;
}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
tournaments-component .text-resize-name {
    font-size: 0.8rem;
}
    tournaments-component .text-resize-badge {
        font-size: 0.7rem;
        font-weight: 600;
        
    }

    tournaments-component .img-circle-container {
        width: 3rem;
        height: 3rem;
    }
       tournaments-component .img-circle-container img {
        max-width: 2.5rem;
        max-height: 2.5rem;
    }
       tournaments-component .text-resize-date {
        font-size: 0.55rem;
    }
      tournaments-component .text-resize-info {
        font-size: 0.65rem;
    }
    tournaments-component .nav .nav-item {
    font-size: 0.75rem;
    font-weight: 700;
    }
}
    </style>
`

export class TournamentsComponent extends BaseComponent {

    initCallback() {
        RouterAssistant.checkForSingleContainer('tournaments-component', TournamentsComponent.initiator);
        document.head.insertAdjacentHTML("beforeend", css);
    }


    static initiator() {
        TournamentsComponent.ShowStateMenuOrNot();
        TournamentsComponent.addNavStatusListeners();
        TournamentsComponent.getTournaments('fromTournamentsComponent', () => {
            if (document.querySelectorAll('#alltournaments-html').length !== 0) {
                console.debug('I am running the tournaments component call back!')
                TournamentsComponent.updateTournaments(GameNavbar.getCurrentlyActivatedGameIfAny())
                const toursForFilters = document.querySelector('#alltournaments-html');
                if (!toursForFilters.innerHTML.trim()) {
                    toursForFilters.innerHTML = /*html*/`
                    <div class="mt-3 tourFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        document.querySelector('.tourFilterNotMsg').innerHTML = I18n.translateString('tournamentsTranslations.emptyTournamentFilterMessage', { tourGame: document.querySelector('.gameNameActive').innerText });
                    } else {
                        document.querySelector('.tourFilterNotMsg').innerHTML = I18n.translateString('tournamentsTranslations.emptyTournamentFilterMessage', { tourGame: '' });
                    }
                }
            } else {
                console.debug('The tournaments component call back is supposed to run but I am not on the correct page!!!')
            }
        });
        GameNavbar.subscribeToGameFilterChanges('fromTournamentsComponent', (eventId) => {
            if (document.querySelectorAll('tournaments-component').length !== 0) // if our component exists on the page
            {
                TournamentsComponent.updateTournaments(eventId, document.querySelector('tournaments-component '));
                const toursForFilters = document.querySelector('#alltournaments-html');
                if (!toursForFilters.innerHTML.trim()) {
                    toursForFilters.innerHTML = /*html*/`
                    <div class="mt-3 tourFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        document.querySelector('.tourFilterNotMsg').innerHTML = I18n.translateString('tournamentsTranslations.emptyTournamentFilterMessage', { tourGame: document.querySelector('.gameNameActive').innerText });
                    } else {
                        document.querySelector('.tourFilterNotMsg').innerHTML = I18n.translateString('tournamentsTranslations.emptyTournamentFilterMessage', { tourGame: '' });
                    }
                }
            }
        })
    }

    static addNavStatusListeners() {
        const navItems = document.querySelectorAll('tournaments-component .nav-link');
        navItems.forEach(item => {
            item.addEventListener('click', (event) => {
                navItems.forEach(navItem => navItem.classList.remove('active'));
                event.target.classList.add('active');
                const gameId = GameNavbar.getCurrentlyActivatedGameIfAny();
                TournamentsComponent.updateTournaments(gameId);
                const toursForFilters = document.querySelector('#alltournaments-html');
                if (!toursForFilters.innerHTML.trim()) {
                    toursForFilters.innerHTML = /*html*/`
                    <div class="mt-3 tourFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        document.querySelector('.tourFilterNotMsg').innerHTML = I18n.translateString('tournamentsTranslations.emptyTournamentFilterMessage', { tourGame: document.querySelector('.gameNameActive').innerText });
                    } else {
                        document.querySelector('.tourFilterNotMsg').innerHTML = I18n.translateString('tournamentsTranslations.emptyTournamentFilterMessage', { tourGame: '' });
                    }
                }
            });
        });
    }

    static getTournaments(callbackName, callback) {
        if (window.getTournamentsEventListeners === undefined) {
            window.getTournamentsEventListeners = {}
            window.getTournamentsEventListeners[callbackName] = callback
            FirebaseAssistant.getTournaments(querySnapshot => {
                console.debug('running firebase getTournaments() query snapshot')
                console.debug('imrunning', new Date());
                querySnapshot.forEach(single => {
                    const data = single.data();
                    if (window.savedTournaments === undefined) {
                        window.savedTournaments = { [data.id]: data }
                    } else {
                        if (window.savedTournaments[data.id] && window.savedTournaments[data.id].modified_at) {
                            console.debug('DATA IS LESS FOR THIS ONE, NOT UPDATING')
                        } else {
                            window.savedTournaments[data.id] = data; // see here
                        }
                    }
                });
                Object.keys(window.getTournamentsEventListeners).forEach(eventListenerName => {
                    window.getTournamentsEventListeners[eventListenerName]()
                })
            });
        } else if (window.savedTournaments === undefined) {
            setTimeout(() => {
                TournamentsComponent.getTournaments(callbackName, callback)
            }, 50)
        } else {
            window.getTournamentsEventListeners[callbackName] = callback
            callback()
        }
    }

    static updateTournaments(gameId) {
        console.debug('running update Tournaments')

        document.querySelector('#alltournaments-html').innerHTML = ''

        const navId = (document.querySelector('tournaments-component .active').id === 'all-tabTours') ? null : document.querySelector('tournaments-component .active').id


        const tierOrder = { 's': 1, 'a': 2, 'b': 3, 'c': 4, 'd': 5 };
        let userLanguage = window.navigator.language;
        // Calculate total prize pool for each tournament
        Object.keys(window.savedTournaments).forEach(id => {
            let totalPrizePool = 0; // in case of no prize pool
            let currency = '';
            let noPrizePoolMsg = '';
            let tournamentsWithoutPrizePool = 0;
            let tier = '';
            if (window.savedTournaments[id] && window.savedTournaments[id].tournaments && window.savedTournaments[id].tournaments[0] && window.savedTournaments[id].tournaments[0].tier) {
                const data = window.savedTournaments[id];

                for (let j = 0; j < data.tournaments.length; j++) {
                    const prizePool = data.tournaments[j].prizepool;
                    if (!tier) {
                        tier = data.tournaments[j].tier;
                    }
                    if (prizePool) {
                        const match = prizePool.match(/(\d+)(\D+)/);
                        if (match) {
                            totalPrizePool += Number(match[1]);
                            if (!currency) {
                                currency = match[2].trim();
                            }
                        }
                    } else {
                        tournamentsWithoutPrizePool++;
                    }
                }

                if (tournamentsWithoutPrizePool === data.tournaments.length) {
                    noPrizePoolMsg = `No prize pool available`
                }


                // Store the total prize pool and currency in the tournament data
                data.tier = data.tournaments[0].tier;
                data.totalPrizePool = totalPrizePool;
                data.currency = currency;
                data.noPrizePoolMsg = noPrizePoolMsg;
                data.prizepoolMessage = noPrizePoolMsg + (totalPrizePool !== 0 ? totalPrizePool : '') + ' ' + currency;
            } else {
                delete window.savedTournaments[id];
            }
        });

        const allTournamentIds = Object.keys(window.savedTournaments);

        // Sort all tournaments by tier, validity of currency, prize pool, and date
        allTournamentIds.sort((a, b) => {
            const tournamentA = window.savedTournaments[a];
            const tournamentB = window.savedTournaments[b];

            const tierA = tierOrder[tournamentA.tournaments[0].tier] || 6;
            const tierB = tierOrder[tournamentB.tournaments[0].tier] || 6;

            if (tierA !== tierB) {
                return tierA - tierB;
            }

            const isValidCurrencyA = tournamentA.currency === 'Euro' || tournamentA.currency === 'United States Dollar' ? 1 : 0;
            const isValidCurrencyB = tournamentB.currency === 'Euro' || tournamentB.currency === 'United States Dollar' ? 1 : 0;

            if (isValidCurrencyA !== isValidCurrencyB) {
                return isValidCurrencyB - isValidCurrencyA; // valid currencies come first
            }

            if (tournamentA.totalPrizePool !== tournamentB.totalPrizePool) {
                return tournamentB.totalPrizePool - tournamentA.totalPrizePool; // sort in descending order

            }

            const dateA = new Date(tournamentA.begin_at ? tournamentA.begin_at : tournamentA.scheduled_at);
            const dateB = new Date(tournamentB.begin_at ? tournamentB.begin_at : tournamentB.scheduled_at);

            return dateA.getTime() - dateB.getTime();
        });

        // Concatenate the two arrays to get the final order
        const sortedTournamentIds = allTournamentIds;
        for (let i = 0; i < sortedTournamentIds.length; i++) {
            const TournamentCode = sortedTournamentIds[i]
            const data = window.savedTournaments[TournamentCode]
            const currentGameId = data.videogame.id;
            // Create a mapping of gameIds to their corresponding currentGameId
            const gameIdMapping = {
                'csgo': 3,
                'dota2': 4,
                'lol': 1,
                'easports': 25,
                'valorant': 26,
                'cod': 23,
                'overwatch': 14,
                'pubg': 20,
                'r6': 24,
                'rocketleague': 22,
                'starcraft': [29, 30],
                'hearthstone': 999,
                'fortnite': 999,
                'fifa': 999,
                'roblox': 999,
                'minecraft': 999,
                'wow': 999,
                'apex': 999,
                'kingofglory': 27,
                'mlegends': 34
            };
            const gameIdImg = {
                '3': csgologo,
                '4': dota2,
                '1': lol,
                '25': ea,
                '26': valorant,
                '23': cod,
                '14': overwatch,
                '20': pubg,
                '24': r6,
                '22': rocketleague,
                '29': startcraft,
                '30': startcraft,
                '27': kingofglory,
                '34': mlegends,

            };
            // const alternativeTourImg = {
            //     '3': as,
            //     '4': as,
            //     '1': as,
            //     '25': as,
            //     '26': as,
            //     '23': as,
            //     '14': as,
            //     '20': as,
            //     '24': as,
            //     '22': as,
            //     '29': as,
            //     '30': as,
            //     '27': as,

            // };
            const tournamentElementId = `tournaments-${TournamentCode}`;
            const gameLogoHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="tournament logo" class="img-fluid object-fit-scale ms-2 " style="max-width: 1.5rem;max-height: 1.5rem;">`;
            const tournamentLogo = data.league.image_url ? data.league.image_url : gameIdImg[data.videogame.id];
            const tournamentLogoHTML = tournamentLogo ? `<img src="${tournamentLogo}" onerror="this.onerror=null; this.src='${gameIdImg[data.videogame.id]}';" alt="" class="img-fluid mx-auto d-block" >` : '';
            const startTime = data.begin_at ? data.begin_at : data.scheduled_at
            const endTime = data.end_at ? data.end_at : 'NoDateMsg'
            const existingWinner = Boolean(data.winner_id)
            const now = new Date();
            const dateRaw = new Date(data.begin_at);
            const startTimeDate = new Date(startTime);
            const endTimeDate = endTime !== 'NoDateMsg' ? new Date(endTime) : null;
            const formatedStartTime = startTimeDate.toLocaleString(userLanguage, { year: 'numeric', month: 'short', day: 'numeric' });
            const formatedEndTime = endTimeDate ? endTimeDate.toLocaleString(userLanguage, { year: 'numeric', month: 'short', day: 'numeric' }) : 'NoDateMsg';
            const countdown1 = `tCd-${TournamentCode}`;
            let tourCountdown = `${countdown1}`;


            const seriesName = data.league.name + ` ` + data.full_name;
            data.totalPrizePool = new Intl.NumberFormat('de-DE').format(data.totalPrizePool);
            data.prizepoolMessage = data.noPrizePoolMsg + (data.totalPrizePool !== "0" ? data.totalPrizePool : '') + ' ' + data.currency;
            let seriesPrizepool = data.prizepoolMessage;
            if (data.actualprizepool && data.totalPrizePool === '0') {
                seriesPrizepool = data.actualprizepool;
            }
            const seriesTier = data.tier.toUpperCase();

            let tournamentStatus;
            if (startTimeDate < now && (!endTimeDate || endTimeDate > now)) {
                tournamentStatus = 'runningTours';
            } else if (endTimeDate && endTimeDate < now) { // && existingWinner ??
                tournamentStatus = 'finishedTours';
            } else if (startTimeDate > now) {
                tournamentStatus = 'not_startedTours';
            }



            if (gameId && gameIdMapping[gameId] !== undefined) {
                if (Array.isArray(gameIdMapping[gameId])) {
                    if (!gameIdMapping[gameId].includes(currentGameId)) {
                        continue;
                    }
                } else {
                    if (gameIdMapping[gameId] !== currentGameId) {
                        continue;
                    }
                }
            }

            if (navId && navId !== tournamentStatus) {
                continue;
            }

            console.debug(data)

            const tournamentHtml = /*html*/ `       
            <a href="${window.location.origin}/s_tournament?tournament=${TournamentCode}" id="${tournamentElementId}"  class="row mt-3 d-flex align-items-center rounded color-dark p-0 clickable">
            <!-- tournament image start -->
            <div class="col-2 rounded-start d-flex align-items-center align-content-center justify-content-center " style="background-color:#0F2B46;">
             <div class=" img-circle-container d-flex justify-content-center flex-column my-1 mx-0 mx-lg-3 my-lg-3 ">
             ${tournamentLogoHTML}
             </div>
             
            </div>
             <!-- tournament name and date start -->
             <div class="col-4 text-start text-white d-flex flex-column p-0">
             <span class="m-0 mx-2 text-resize-name text-truncate">${seriesName}</span>
             <span class="m-0 mx-2  text-white-50 text-truncate text-resize-date">
                <span>${formatedStartTime}</span> - <span id="endTime-label-${TournamentCode}">${formatedEndTime}</span>
            </span>
            </div>
             <!-- tournament Tier -->
             <div class="col-2 text-start text-resize-info p-0">
             <span>${seriesTier}</span> <span class="tier-label">Tier</span>
            </div>
             <!-- tournament prizepool -->
             <div class="col-2 text-start text-truncate text-resize-info prizepool-label p-0">
            ${seriesPrizepool}
            </div>
            <!-- tournament status -->
             <div class="col-2 text-center p-0">
            <div id="${tourCountdown}"></div>
            </div>
            
            </a>
            `

            document.querySelector('#alltournaments-html').insertAdjacentHTML('beforeend', tournamentHtml);
            TournamentsComponent.translations();

            TournamentsComponent.startCountdown(dateRaw, countdown1, tournamentElementId, tournamentStatus);
            TournamentsComponent.redirectToTournamentPage(tournamentElementId);
            // TournamentsComponent.redirectToMatches();
        }

    }

    static startCountdown(gameStartTime, cID, tID, gameStatus) {
        const now = new Date();
        const diffInMilliseconds = gameStartTime - now;
        const diffInMinutes = Math.round(diffInMilliseconds / 1000 / 60);

        const element = document.getElementById(cID);
        if (element && diffInMinutes > 0) {
            if (diffInMinutes > 1440) {
                const days = Math.floor(diffInMinutes / 1440);
                const hours = Math.floor((diffInMinutes % 1440) / 60);
                const minutes = diffInMinutes % 60;
                if (days > 1) {
                    element.innerHTML = I18n.translateString("tournamentsTranslations.tournamentUpDHM", { days: days });
                } else if (days === 1) {
                    element.innerHTML = I18n.translateString("tournamentsTranslations.tournamentUp1D", { days: days });
                }
            } else if (diffInMinutes > 60) {
                const hours = Math.floor(diffInMinutes / 60);
                const minutes = diffInMinutes % 60;
                console.log('hours', hours)
                console.log('minutes', minutes)
                element.innerHTML = I18n.translateString("tournamentsTranslations.tournamentUpHM", { hours: hours, minutes: minutes });
            } else {
                element.innerHTML = I18n.translateString("tournamentsTranslations.tournamentUpM", { minutes: diffInMinutes });
            }
        } else if (element && (diffInMinutes <= 0 && gameStatus === 'runningTours')) {
            element.innerHTML = I18n.translateString("tournamentsTranslations.tournamentLive");

        } else if (element && (gameStatus === 'finishedTours')) {
            element.innerHTML = I18n.translateString("tournamentsTranslations.tournamentFinished");
        }

    }


    static redirectToTournamentPage(tID) {

        const element = document.getElementById(tID);

        const alteredtID = tID.replace('tournaments-', '');
        element.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
            RouterAssistant.redirect('s_tournament', {
                tournament: alteredtID
            })
        }));

    }

    static redirectToMatches() {
        const showMoreButton = document.getElementById('show-more-button');
        if (showMoreButton) {
            showMoreButton.addEventListener('click', function (evt) {
                RouterAssistant.redirect('matches')
            })
        }
    }

    static translations() {
        const endElements = document.querySelectorAll('[id^="endTime-label-"]');
        endElements.forEach(element => {
            if (element.innerHTML === 'NoDateMsg') {
                element.innerHTML = I18n.translateString("tournamentsTranslations.noDateMsg");
            }
        });
        const tierElements = document.querySelectorAll('.tier-label');
        tierElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.tier") });
        const prizepoolElements = document.querySelectorAll('.prizepool-label');
        prizepoolElements.forEach(element => {
            if (element.innerHTML.includes('No prize pool available')) {
                element.innerHTML = I18n.translateString("tournamentsTranslations.noPrizePoolAvailable")
            } else if (element.innerHTML.includes('Euros')) {
                const translatedString = I18n.translateString("tournamentsTranslations.euroPrizePool")
                element.innerHTML = element.innerHTML.replace('Euros', translatedString);
            } else if (element.innerHTML.includes('Euro')) {
                const translatedString = I18n.translateString("tournamentsTranslations.euroPrizePool")
                element.innerHTML = element.innerHTML.replace('Euro', translatedString);
            } else if (element.innerHTML.includes('United States Dollar')) {
                const translatedString = I18n.translateString("tournamentsTranslations.usdPrizePool")
                element.innerHTML = element.innerHTML.replace('United States Dollar', translatedString);
            }
        });
    }


    static ShowStateMenuOrNot() {
        if (window.location.href.includes('tournaments')) {
            document.querySelector('tournaments-component .stateMenu').innerHTML = /*html*/`
        <div class="container p-0">
            <ul class="nav nav-pills style-nav-pills ">
                <li class="nav-item">
                    <a class="nav-link active" id="runningTours" data-toggle="pill">ONGOING</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="not_startedTours" data-toggle="pill">UPCOMING</a>
                </li>
                <li class="nav-item"><a class="nav-link" id="finishedTours" data-toggle="pill">FINISHED</a></li>
                </li>
                <li class="nav-item ">
                    <a class="nav-link" id="all-tabTours" data-toggle="pill" >ALL</a>
                </li>
            </ul>
            <div id="alltournaments-html"></div>
        </div>
    `;

            document.getElementById('runningTours').innerText = I18n.translateString("tournamentsTranslations.pillOngoing");
            document.getElementById('not_startedTours').innerText = I18n.translateString("tournamentsTranslations.pillUpcoming");
            document.getElementById('finishedTours').innerText = I18n.translateString("tournamentsTranslations.pillFinished");
            document.getElementById('all-tabTours').innerText = I18n.translateString("tournamentsTranslations.pillAll");

        } else {

        }
    }
}


TournamentsComponent.template =   /*html*/ `
        <div class="stateMenu">
        </div>
                `;

TournamentsComponent.reg('tournaments-component');

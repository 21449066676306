import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { DiscordTemplate } from "../discord-template/discord-template";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { PredictionsHomecomponent } from "../predictions-homecomponent/predictions-homecomponent";
import { AnalysesHomecomponent } from "../analyses-homecomponent/analyses-homecomponent";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { AuthorsComponent } from "../author-component/author-component";
import { upcomingMatches } from "../upcomingmatches-container/upcoming-matches";
import { BookmakershomeComponent } from "../bookmakershome-component/bookmakershome-component";
import { WidgetMatches } from "../widget-matches/widget-matches";
import { WidgetBookmakers } from "../widget-bookmakers/widget-bookmakers";
import { MatchesComponent } from "../matches-component/matches-component";
import { HotmatchComponent } from "../hotmatch-component/hotmatch-component";
import { HottournamentsComponent } from "../hottournaments-component/hottournaments-component";
import { PlaceholderMatchesWidget } from "../placeholder-matcheswidget/placeholder-matcheswidget";
import { WidgetBookmakersSkin } from "../widget-bookmakers-skin/widget-bookmakers-skin";
import { AdblockComponent } from "../adblockerdetect-component/adblockerdetect-component";

import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";


const css = `
    <style>
   
        
        .home-components{
        position: relative;
        background-color:transparent ;
        border-radius: 8px;
        
        }
        
         @media screen and (orientation:portrait) {
            
        }
         @media screen and (orientation:landscape) {
            
        }
    </style>
`;

export class HomeContainer extends BaseComponent {

    initCallback() {

        document.head.insertAdjacentHTML("beforeend", css);
        window.addEventListener('resize', function () {
            // if no resize event is fired within 250ms of the lastly fired one, call the performOrientationChangeActions function :)
            MiscAssistant.debounce(HomeContainer.performOrientationChangeActions, 250)
        }, true)

        RouterAssistant.checkForSingleContainer('home-container', HomeContainer.initiator)
    }

    static initiator() {
        HomeContainer.performOrientationChangeActions();
        GridOrchestrator.activateGameFilters()
        document.getElementById('homeContainerHeader').innerText = I18n.translateString('navMainMenu.Home')
    }


    static loadDesktopLayout() {
        if (document.querySelectorAll('hotmatch-component').length !== 0) {
            const container = document.getElementById('homeContainerInnerContainer')

            container.removeAttribute('class')
            container.classList.add("main-content", "col-lg-6", "col-md-6", "col-sm-6", "col-xs-12", "g-0", "mt-0", "mt-sm-0", "mt-md-0", "mt-lg-0", "mt-xl-2", "mt-xxl-2")
            document.querySelector('.sidebar-matches').remove();
            document.querySelector('.sidebar-predictions').remove();
            document.querySelector('.sidebar-discord').remove();
            document.querySelector('.sidebar-bonus').remove();
            // const sidebar1 = document.querySelector('.sidebar1')
            container.insertAdjacentHTML("beforebegin", /*html*/`
                <div class="sidebar1 col-lg-3 col-md-3 col-sm-3 col-xs-12 mt-0 ">
                    <div class="sidebar-predictions home-components row m-2 ">
                        <predictions-homecomponent></predictions-homecomponent>
                    </div>
                    <div class="sidebar-discord home-components row m-2 " >
                        <div class="home-discord-template">
                            <discord-template></discord-template>
                        </div>
                    </div>
                    <div class="sidebar-bonus home-components row m-2 ">
                        <div class="home-widget-bookmakers">
                            <widget-bookmakers></widget-bookmakers>
                            <widget-bookmakers-skin></widget-bookmakers-skin>
                        </div>
                    </div>
                </div>
                `)
            container.insertAdjacentHTML("afterend", /*html*/`

                <div class="sidebar2 col-lg-3 col-md-3 col-sm-3 col-xs-12 mt-0 ">

                    <placeholder-matcheswidget></placeholder-matcheswidget>
                    <div class="sidebar-matches home-components row m-2 ">
                        <div class="title">
                        </div>
                        <matches-component data-mode="homePage"></matches-component>
                    </div>

                    
                </div>
            `)
        } else {
            let ahrefopenOption = ''
            if (AdblockComponent.adblockCheck) {
                ahrefopenOption = '_Top'
            } else {
                ahrefopenOption = '_blank'
            }
            document.querySelector('home-container').insertAdjacentHTML('afterbegin', /*html*/`

            <div class="sidebar1 col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-0 ">

                <div class="sidebar-predictions home-components row m-2 ">
                    <predictions-homecomponent></predictions-homecomponent>
                </div>
                <div><a href="https://go.thunder.partners/visit/?bta=36737&nci=5685&campaign=MyEsports" Target="${ahrefopenOption}" class="d-flex justify-content-center mt-4"><img src="https://firebasestorage.googleapis.com/v0/b/myesports-65c4d.appspot.com/o/affiliateAds%2FScreenshot%202025-01-23%20193225.png?alt=media&token=1b20272a-e91b-469b-b2c6-5835eafb0fe4" width="85%" height="auto" style="object-fit:contain"></a></div>
                <div class="sidebar-bonus home-components row m-2 ">
                    <div class="home-widget-bookmakers">
                        <widget-bookmakers></widget-bookmakers>
                        <widget-bookmakers-skin> </widget-bookmakers-skin>
                    </div>
                </div>
                

                <div class="sidebar-discord home-components row m-2 " >
                    <div class="home-discord-template">
                        <discord-template></discord-template>
                    </div>
                </div>

            </div>
            <div id="homeContainerInnerContainer" class="main-content col-lg-6 col-md-6 col-sm-12 col-xs-12 g-0 mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-2 mt-xxl-2 ">

                <div class="home-matches home-components row mx-2">
                    <div class="home-upcoming-matches">
                        <hotmatch-component></hotmatch-component>
                    </div>
                </div>

                <div class="home-latest-news home-components">
                    <news-carousel></news-carousel>
                </div>

                <div class="home-tournaments home-components">
                    <hottournaments-component></hottournaments-component>
                </div>

                <div class="home-analyses home-components">
                   <analyses-homecomponent></analyses-homecomponent>
                </div>

            </div>

            <div class="sidebar2 col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-0 ">

                <placeholder-matcheswidget></placeholder-matcheswidget>

                <div class="sidebar-matches home-components row m-2 ">
                    <div class="title">
                    </div>
                    <matches-component data-mode="homePage"></matches-component>
                </div>

                
 
            </div>  
        `)
        }
    }

    static loadMobileLayout() {
        if (document.querySelectorAll('hotmatch-component').length !== 0) {
            const container = document.getElementById('homeContainerInnerContainer')
            container.removeAttribute('class')
            container.classList.add("main-content", "col-12")
            document.querySelector('.sidebar1').remove();
            document.querySelector('.sidebar2').remove();
            document.querySelector('.home-matches').insertAdjacentHTML('afterend',/*html*/ `
                <div class="sidebar-matches home-components row mx-0 mt-1">
                    <div class="title">
                    </div>
                    <matches-component data-mode="homePage"></matches-component>
                </div>
                <div class="sidebar-predictions home-components row m-2 ">
                    <predictions-homecomponent></predictions-homecomponent>
                </div>
                <div class="home-analyses home-components">
                   <analyses-homecomponent></analyses-homecomponent>
                </div>
            `)
            document.querySelector('.home-tournaments').insertAdjacentHTML('afterend', /*html*/`
                <div class="sidebar-discord home-components row m-2 " >
                    <div class="home-discord-template">
                        <discord-template></discord-template>
                    </div>
                </div>
                <div class="sidebar-bonus home-components row m-2 ">
                    <div class="home-widget-bookmakers">
                        <widget-bookmakers></widget-bookmakers>
                        <widget-bookmakers-skin></widget-bookmakers-skin>
                    </div>
                </div>
            `)
        } else {
            document.querySelector('home-container').insertAdjacentHTML('afterbegin', /*html*/`
            <div  id="homeContainerInnerContainer" class="main-content col-12 ">
                <div class="home-matches home-components row mx-2">
                    <div class="home-upcoming-matches">
                        <hotmatch-component></hotmatch-component>
                    </div>
                </div>

                 <div class="sidebar-matches home-components row mx-0 mt-1">
                    <div class="title">
                    </div>
                    <matches-component data-mode="homePage"></matches-component>
                </div>

                <div class="sidebar-predictions home-components row m-2 ">
                    <predictions-homecomponent></predictions-homecomponent>
                    <analyses-homecomponent></analyses-homecomponent>
                </div>
                
                <div class="home-latest-news home-components row m-2">
                    <news-carousel></news-carousel>
                </div>

                <div class="home-tournaments home-components row m-2">
                    <div class="home-carousel-slider">
                        <hottournaments-component></hottournaments-component>
                    </div>
                </div>

                <div class="sidebar-discord home-components row m-2 " >
                    <div class="home-discord-template">
                        <discord-template></discord-template>
                    </div>
                </div>
                <div class="sidebar-bonus home-components row m-2 ">
                    <div class="home-widget-bookmakers">
                        <widget-bookmakers></widget-bookmakers>
                        <widget-bookmakers-skin></widget-bookmakers-skin>
                    </div>
                </div>
            </div>
        `)
        }
    }

    static wipeCurrentLayout() {
        document.querySelector('home-container').innerHTML = ''
    }

    static performOrientationChangeActions() {
        if (window.location.href.includes('home')) {
            const currentState = (window.innerHeight > window.innerWidth) || window.innerWidth < 1024 ? 'mobile' : 'desktop';
            const thereIsContentInside = document.querySelectorAll('hotmatch-component').length !== 0;
            if (!thereIsContentInside && window.homePagePreviousOrientationState !== undefined) {
                window.homePagePreviousOrientationState = undefined
                HomeContainer.performOrientationChangeActions()
            } else {
                if (currentState === 'mobile' && (window.homePagePreviousOrientationState === undefined || window.homePagePreviousOrientationState === 'desktop')) {
                    console.debug('changing home page state layout')
                    HomeContainer.loadMobileLayout()
                    window.homePagePreviousOrientationState = currentState
                } else if (currentState === 'desktop' && (window.homePagePreviousOrientationState === undefined || window.homePagePreviousOrientationState === 'mobile')) {
                    console.debug('changing home page state layout')
                    HomeContainer.loadDesktopLayout()
                    window.homePagePreviousOrientationState = currentState
                }
            }
        }
    }
}

HomeContainer.template = /*html*/ `
<h1 id="homeContainerHeader" style="font-size: 1px;color: rgb(15, 18, 23)"></h1>
`;

HomeContainer.reg('home-container');

import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { AuthorsComponent } from "../author-component/author-component";

import { I18n } from "../../utilities/i18n/i18n";


const csgo = require("../../components/game-navbar/assets/images/csgologo 150x150.png");
const dota2 = require("../../components/game-navbar/assets/images/dota2logo 150x150.png");
const ea = require("../../components/game-navbar/assets/images/EA 150x150.webp");
const lol = require("../../components/game-navbar/assets/images/lollogo 150x150.png");
const valorant = require("../../components/game-navbar/assets/images/valorantlogo 150x150.png");
const r6 = require("../../components/game-navbar/assets/images/r6 150x150.webp");
const cod = require("../../components/game-navbar/assets/images/call of duty 150x150.png");
const fifa = require('../../components/game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../../components/game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../../components/game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../../components/game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../../components/game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../../components/game-navbar/assets/images/pubg 150x150.png')
const roblox = require('../../components/game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../../components/game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../../components/game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../../components/game-navbar/assets/images/starcraft II 150x150.png')
const apex = require('../../components/game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../../components/game-navbar/assets/images/KingOfGlory 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')


const css = `
<style>
.comingsoon{
cursor:not-allowed;
opacity:0.6;
}
/* Title Desktop Style */
predictions-homecomponent .widget-prediction-title {
    color: #D3D3D3;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
predictions-homecomponent .see-all-predictions-button {
    background-color: transparent;
    border: 1px solid #D3D3D3;
    color: #D3D3D3;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 0.9rem;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
}
predictions-homecomponent .see-all-predictions-button:hover {
    background-color: #D3D3D3;
    color: #0D1117;
}
predictions-homecomponent .predNavItem {
    border: 1px solid #ff282859; /* Border color */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 10px; /* Space between items */
    background-color: #161B22; /* Background color */
    
}

predictions-homecomponent .ht-section-title {
    font-size: 2.5rem;
      color: #f1f1f1;
      position: relative;
  }

predictions-homecomponent .ht-section-header {
      border-bottom: 2px solid #B22A23;
      padding-bottom: 10px;
      margin-bottom: 20px;
      margin-top: 10px;
  }

predictions-homecomponent .ht-section-title::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 60px;
      height: 4px;
      background-color: #B22A23;
      border-radius: 2px;
  }

predictions-homecomponent .predNavItem .nav-link {
    color: #f8f9fa; /* Link color */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
}

predictions-homecomponent .predNavItem .nav-link:hover {
    color: #ff282859; /* Link color on hover */
    text-decoration: none; /* Remove underline on hover */
}

predictions-homecomponent .text-resize-badge {
         display: flex;
         justify-content: center;
         justify-items: center;
         align-items: center;
    }        

    predictions-homecomponent .prediction-list {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 400px;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #B22A23 #1e1e1e;
      scroll-behavior: smooth;
    }
    predictions-homecomponent .prediction-list::-webkit-scrollbar {
      width: 8px;
    }
    predictions-homecomponent .prediction-list::-webkit-scrollbar-track {
      background: #1e1e1e;
      border-radius: 10px;
    }
    predictions-homecomponent .prediction-list::-webkit-scrollbar-thumb {
      background-color: #B22A23;
      border-radius: 10px;
      border: 2px solid #1e1e1e;
    }

    predictions-homecomponent .prediction-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #252E39;
      padding: 10px 15px;
      border-radius: 10px;
      margin-bottom: 10px;
      transition: background-color 0.3s, transform 0.3s;
      cursor: pointer;
      position: relative;
    }
    predictions-homecomponent .prediction-item:hover {
      background-color: #3A4B5C;
      transform: translateY(-2px);
    }
    predictions-homecomponent .prediction-item .game-info {
      display: flex;
      align-items: center;
      position: relative;
    }
    predictions-homecomponent .prediction-item .game-info .image-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #B22A23;
      margin-right: 10px;
      flex-shrink: 0;
      transition: transform 0.3s;
      background-color: #252E39;
    }
    predictions-homecomponent .prediction-item .game-info .image-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    predictions-homecomponent .prediction-item:hover .game-info .image-wrapper {
      transform: scale(1.1);
    }
    predictions-homecomponent .prediction-item .game-name {
      font-weight: 500;
      color: #ffffff;
    }
    predictions-homecomponent .prediction-item .prediction-count {
      background-color: #B22A23;
      color: #f8f9fa;
      padding: 5px 15px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 0.9rem;
      min-width: 40px;
      text-align: center;
      position: relative;
      overflow: hidden;
      transition: background-color 0.3s;
    }
    predictions-homecomponent .prediction-item .prediction-count:hover {
      background-color: #B22A23;
    }

    predictions-homecomponent .match-details {
      padding: 10px 15px;
      background-color: #2c2c2c;
      border-radius: 10px;
      margin-top: 10px;
    }
    predictions-homecomponent .match-card {
      border-bottom: 1px solid #444;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    predictions-homecomponent .match-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    predictions-homecomponent .teams {
      font-weight: 500;
      margin-top: 5px;
    }
    predictions-homecomponent .countdown {
      font-size: 0.9rem;
      color: #B22A23;
      margin-top: 5px;
    }

    predictions-homecomponent .collapse-button {
      background: none;
      border: none;
      color: #B22A23;
      cursor: pointer;
      font-size: 1rem;
      padding: 0;
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
    predictions-homecomponent .collapse-button:focus {
      outline: none;
    }
    predictions-homecomponent .collapse-button .fas {
      margin-left: 5px;
      transition: transform 0.3s;
    }
    predictions-homecomponent .collapse-button.collapsed .fas {
      transform: rotate(-90deg);
    }
    predictions-homecomponent .collapse-button:not(.collapsed) .fas {
      transform: rotate(0deg);
    }



/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (max-width: 767px) {
    /* "See All" Button */
predictions-homecomponent .see-all-predictions-button {
    padding: 6px 10px;
    font-size: 0.7rem;
}
    .comingsoon {
        cursor: not-allowed;
        opacity: 0.6;
    }

    predictions-homecomponent .text-resize {
         font-size: 0.5rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.5rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 1rem;
       height: 1rem;
    }

    predictions-homecomponent .text-resize-title{
    font-size: 0.8rem;
    font-weight: 500;
    color:#b0b0b0;
    margin-left: 0.2rem;
    }

    predictions-homecomponent .ht-section-title {
         font-size: 1.2rem;
    }
    predictions-homecomponent .text-resize {
         font-size: 0.6rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.5rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 2rem;
       height: 2rem;
    }

            predictions-homecomponent .prediction-item .game-name {
      font-size: 0.7rem;
    }

          predictions-homecomponent .prediction-item .game-info .image-wrapper {
        width: 30px;
        height: 30px;
      }
      predictions-homecomponent .prediction-item .prediction-count {
        padding: 3px 10px;
        font-size: 0.8rem;
        min-width: 30px;
      }

    /* Other styles.. */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    predictions-homecomponent .ht-section-title {
         font-size: 1.5rem;
    }
    predictions-homecomponent .text-resize {
         font-size: 0.7rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.6rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 2rem;
       height: 2rem;
    }

            predictions-homecomponent .prediction-item .game-name {
      font-size: 0.6rem;
    }

        predictions-homecomponent .prediction-item .prediction-count {
        padding: 3px 10px;
        font-size: 0.7rem;
        min-width: 30px;
      }

predictions-homecomponent .see-all-predictions-button {
    padding: 6px 10px;
    font-size: 0.7rem;
}
            predictions-homecomponent .widget-prediction-title {
    font-size: 1rem;
}
    /* Other styles.. */
}

/* Large Devices: 992px - 1400px */
  @media only screen and (min-width: 993px) and (max-width: 1400px) {
    predictions-homecomponent .ht-section-title {
         font-size: 1.3rem;
    }
    predictions-homecomponent .text-resize {
         font-size: 0.8rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 2rem;
       height: 2rem;
    }

        predictions-homecomponent .prediction-item .game-name {
      font-size: 0.7rem;
    }

    predictions-homecomponent .prediction-item .prediction-count {
        padding: 3px 10px;
        font-size: 0.7rem;
        min-width: 30px;
      }

          predictions-homecomponent .see-all-predictions-button {
    padding: 6px 12px;
    font-size: 0.7rem;
}
        predictions-homecomponent .widget-prediction-title {
    font-size: 1rem;
}
    /* Other styles.. */
}

/* Extra large devices (large laptops and desktops, 1400px and up) */
@media only screen and (min-width: 1401px) {
        predictions-homecomponent .ht-section-title {
         font-size: 2rem;
    }
    predictions-homecomponent .text-resize {
         font-size: 0.8rem;
    }
    predictions-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    predictions-homecomponent .img-circle-container {
       width: 2rem;
       height: 2rem;
    }
    predictions-homecomponent .prediction-item .game-name {
      font-size: 0.75rem;
    }
          predictions-homecomponent .see-all-predictions-button {
    padding: 6px 12px;
    font-size: 0.9rem;
}
    predictions-homecomponent .widget-prediction-title {
    font-size: 1.4rem;
}
}
    
</style>
`

export class PredictionsHomecomponent extends BaseComponent {

  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css);
    RouterAssistant.checkForSingleContainer('predictions-homecomponent', PredictionsHomecomponent.initiator);
  }

  static initiator() {
    AuthorsComponent.getAuthors('fromPredictionsPageComponent', () => {
      if (document.querySelectorAll('#predictionsFinalHtml').length !== 0) {
        console.debug('I am running the predictions component call back!');
        PredictionsHomecomponent.updatePredictions();
      } else {
        console.debug('I am not running the predictions component call back!');
      }
    });
  }

  static updatePredictions() {

    const gameIdMapping = {
      'csgo': { id: 3, name: 'Counterstrike 2', counter: 0, img: csgo },
      'dota2': { id: 4, name: 'Dota 2', counter: 0, img: dota2 },
      'lol': { id: 1, name: 'League of Legends', counter: 0, img: lol },
      'easports': { id: 25, name: 'EA Sports', counter: 0, img: ea },
      'valorant': { id: 26, name: 'Valorant', counter: 0, img: valorant },
      'cod': { id: 23, name: 'Call of Duty', counter: 0, img: cod },
      'overwatch': { id: 14, name: 'Overwatch', counter: 0, img: overwatch },
      'pubg': { id: 20, name: 'PUBG', counter: 0, img: pubg },
      'r6': { id: 24, name: 'Rainbow 6', counter: 0, img: r6 },
      'rocketleague': { id: 22, name: 'Rocket League', counter: 0, img: rocketleague },
      'starcraft': { id: [29, 30], name: 'Starcraft', counter: 0, img: startcraft },
      'kingofglory': { id: 27, name: 'King of Glory', counter: 0, img: kingofglory },
      'hearthstone': { id: 999, name: 'Hearthstone', counter: 0, img: hearthstone },
      'fortnite': { id: 999, name: 'Fortnite', counter: 0, img: fortnite },
      'fifa': { id: 999, name: 'FIFA', counter: 0, img: fifa },
      'roblox': { id: 999, name: 'Roblox', counter: 0, img: roblox },
      'minecraft': { id: 999, name: 'Minecraft', counter: 0, img: minecraft },
      'wow': { id: 999, name: 'World of Warcraft', counter: 0, img: wow },
      'apex': { id: 999, name: 'APEX', counter: 0, img: apex },
      'mlegends': { id: 34, name: 'Mobile Legends', counter: 0, img: mlegends }
    };

    for (let key in window.savedAuthors) {
      for (let i = 0; i < window.savedAuthors[key].predictions.length; i++) {
        const data = window.savedAuthors[key].predictions[i];
        const gamePred = data.gameId;

        for (let game in gameIdMapping) {
          const gameData = gameIdMapping[game];
          const gameKey = gameData.id;

          const currentTime = new Date();
          const threeHoursEarlier = new Date(currentTime.getTime() - 3 * 60 * 60 * 1000);
          if (gameKey === gamePred && (data.state === "Upcoming" || data.matchDate.toDate() > threeHoursEarlier)) {

            gameData.counter += 1;
          }

        }
      }

    }


    let listHtml = '';
    for (let game in gameIdMapping) {
      const gameData = gameIdMapping[game];
      const gameKey = game;
      listHtml += /*html*/`
            <!--     <li class="nav-item predNavItem text-resize">
                    <div class="nav-link pred-link clickable" id="${gameKey}"> 
                        <div class=" img-circle-container d-flex justify-content-center flex-column m-2 ">
                        <img src="${gameData.img}" alt="${gameKey}" class="logo img-fluid mx-auto d-block">
                        </div>
                        ${gameData.name} 
                        <span class=" badge predCounterBadge rounded-pill text-resize-badge me-2">${gameData.counter}</span>
                    </div>
                            </li>
                            -->
                      <li class="prediction-item nav-item nav-link pred-link clickable predNavItem text-resize" data-game="${gameKey}" id="${gameKey}">
                        <div class="game-info">
                            <div class="image-wrapper">
                            <img src="${gameData.img}" alt="${gameKey}" class="logo img-fluid mx-auto d-block" onerror="this.src='https://via.placeholder.com/150?text=No+Image'">
                            </div>
                            <span class="game-name">${gameData.name}</span>
                        </div>
                        <div class="prediction-count predCounterBadge" data-game="${gameKey}">${gameData.counter}
                        </div>
                    </li>

                `;
    }

    let predictionsHtml = /*html*/`
            <div class="container-fluid p-2 p-xl-2 bg-secondary bg-opacity-10 rounded">

                    <ul class="nav-fill w-100 mt-3 prediction-list" id="predictionList">

             ${listHtml} 
            </ul>

            </div>
        `
    document.getElementById('predictionsFinalHtml').innerHTML = predictionsHtml;
    document.querySelectorAll('.predNavItem').forEach((el) => {
      let badge = el.querySelector('.predCounterBadge');
      if (badge && badge.innerHTML.trim() == '0') {
        el.classList.add('d-none');
      }
    });
    PredictionsHomecomponent.translations();
    PredictionsHomecomponent.redirectToPredictions();
    PredictionsHomecomponent.addSeeAllButtonListener();
  }

  static redirectToPredictions() {
    const predictionsLinks = document.querySelectorAll('.pred-link');
    predictionsLinks.forEach(predictionsLink => {
      predictionsLink.addEventListener('click', function (evt) {
        RouterAssistant.redirect("predictions", { game: predictionsLink.id });
      });
    });
  }

  static translations() {
    document.querySelectorAll('.predTitle').forEach((el) => {
      el.innerHTML = I18n.translateString('predictionsTranslations.predTitle');
    });

    // document.querySelectorAll('.comingSoonClass').forEach((el) => {
    //     el.innerHTML = I18n.translateString('predictionsTranslations.comingSoon');
    // });

    document.querySelectorAll('.predictions-desktop-title').forEach(element => {
      element.innerHTML = I18n.translateString("predictionsTranslations.predTitle");
    });

    document.querySelectorAll('#see-all-predictions-button').forEach(element => {
      element.innerHTML = I18n.translateString("bookmakersList.widgetButton");
    });
  }

  static addSeeAllButtonListener() {
    const seeAllButton = document.querySelector('#see-all-predictions-button');
    if (seeAllButton) {
      seeAllButton.addEventListener('click', function (evt) {
        evt.preventDefault();
        RouterAssistant.redirect('predictions');
      });
    }
  }
}
PredictionsHomecomponent.template = /*html*/`
 <div class="ht-section-header d-flex justify-content-between align-items-center d-md-none d-block">

 <h2 class="ht-section-title mb-3 ">
        <span class="predTitle"></span>
        </h2>
 </div>

 <div class="widget-prediction-title d-none d-md-flex">
        <span class="predictions-desktop-title"></span>
        <button id="see-all-predictions-button" class="see-all-predictions-button">See All</button>
    </div>

<div id="predictionsFinalHtml"></div>
`;

PredictionsHomecomponent.reg('predictions-homecomponent'); // components should always be 2 words seperated with a dash


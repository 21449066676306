import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { match } from "assert";
import { TournamentsComponent } from "../tournaments-component/tournaments-component";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";

import { I18n } from "../../utilities/i18n/i18n";

import tournamentimg2 from "../hottournaments-component/assets/img2.webp"

const noImageFound = require('../matches-component/Assets/no image found.png')

const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fifa = require('../game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/rainbow six 150x150.png')
const roblox = require('../game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../game-navbar/assets/images/KingOfGlory 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')


const css = `
<style>
  hottournaments-component .img-circle-container-logo-game{
     width: 2rem;
     height: 2rem;
}
 hottournaments-component .img-circle-container-logo-game img {
     max-width: 100%;
     max-height:100%;
}
 .ht-component {
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
     color: #f1f1f1;
}
 .ht-section-header {
     border-bottom: 2px solid #B22A23;
    /* Updated color from #f39c12 to #B22A23 */
     padding-bottom: 10px;
     margin-bottom: 20px;
}
 .ht-section-title {
     font-size: 2.5rem;
     color: #f1f1f1;
     position: relative;
}
 .ht-section-title::after {
     content: '';
     position: absolute;
     left: 0;
     bottom: -5px;
     width: 60px;
     height: 4px;
     background-color: #B22A23;
    /* Updated color from #f39c12 to #B22A23 */
     border-radius: 2px;
}
 .ht-btn-back {
     background-color: transparent;
     border-color: #B22A23;
    /* Updated color from #f39c12 to #B22A23 */
     color: #B22A23;
    /* Updated color from #f39c12 to #B22A23 */
     transition: background-color 0.3s, color 0.3s;
}
 .ht-btn-back:hover {
     background-color: #B22A23;
    /* Updated color from #f39c12 to #B22A23 */
     color: #fff;
}
 .ht-tournament-card {
     background-color: #1e1e2f;
     border-radius: 12px;
     overflow: hidden;
     transition: transform 0.3s, box-shadow 0.3s;
     height: 100%;
     display: flex;
     flex-direction: column;
     cursor: pointer;
     color: #ccc !important;
}
 .ht-tournament-card:hover {
     transform: translateY(-7px);
     box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}
 .ht-card-header {
     background-color: #252E39;
     padding: 5px;
     display: flex;
     align-items: center;
}
 .ht-tournament-logo img {
     object-fit: cover;
     transition: transform 0.3s;
}
 .ht-tournament-logo img:hover {
     transform: scale(1.05);
}
 .ht-tournament-info {
     flex-grow: 1;
     margin-left: 15px;
     min-width: 0;
    /* Prevents the container from expanding beyond the card */
}
 .ht-tournament-title {
     font-size: 1.3rem;
     font-weight: bold;
     white-space: nowrap;
    /* Prevents text from wrapping */
     overflow: hidden;
    /* Hides overflowing text */
     text-overflow: ellipsis;
    /* Adds ellipsis (...) for overflowed text */
     margin-bottom: 5px;
     display: block;
    /* Ensures it behaves as a block element */
     transition: color 0.3s;
    /* Smooth transition for color change */
}
/* Change tournament title color when the card is hovered */
 .ht-tournament-card:hover .ht-tournament-title {
     color: #B22A23;
    /* Updated color from default to #B22A23 on hover */
}
 .ht-tournament-dates {
     font-size: 0.9rem;
     color: #ccc;
}
 .ht-card-img-top {
     height: 180px;
     object-fit: cover;
     position: relative;
}
 .ht-img-overlay {
     position: absolute;
     bottom: 10px;
     left: 10px;
     background-color: rgba(0, 0, 0, 0.6);
     padding: 5px 10px;
     border-radius: 5px;
     display: flex;
     gap: 5px;
}
hottournaments-component  .image-container-tournament-logo {
     position: absolute;
     margin-left: auto;
     margin-right: auto;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     text-align: center;
     width: 3.5rem;
     height: auto;
     z-index: 2;
}
 .ht-badge-status {
     font-size: 0.85rem;
     padding: 0.3em 0.6em;
     border-radius: 12px;
     display: flex;
     align-items: center;
}
 .ht-badge-tier {
     font-size: 0.85rem;
     padding: 0.3em 0.6em;
     border-radius: 12px;
     background-color: #6c757d;
     color: #fff;
}
 .ht-teams-prize {
     font-size: 1rem;
     display: flex;
     align-items: center;
     gap: 5px;
}
 .ht-card-body {
     background-color: #1E252E;
     padding: 15px;
     flex-grow: 1;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
}
/* Focus Styles for Accessibility */
 .ht-tournament-card:focus-within {
     outline: 2px dashed #B22A23;
    /* Updated color from #f39c12 to #B22A23 */
     outline-offset: 4px;
}
 @media only screen and (max-width: 600px) {
     .ht-section-title {
         font-size: 1.2rem;
    }
     .ht-btn-back{
         font-size: 0.7rem;
    }
     .ht-tournament-title {
         font-size: 0.9rem;
    }
     .ht-badge-status, .ht-badge-tier {
         font-size: 0.65rem;
         padding: 0.2em 0.5em;
    }
     .ht-card-img-top {
         height: 150px;
    }
     .ht-teams-prize {
         font-size: 1rem;
    }
    hottournaments-component  .image-container-tournament-logo {
    }
    hottournaments-component  .image-container-tournament-logo img {
    }
     .ht-tournament-dates {
         font-size: 0.8rem;
    }
     hottournaments-component .img-circle-container-logo-game{
         width: 2rem;
         height: 2rem;
    }
     hottournaments-component .img-circle-container-logo-game img {
         max-width: 100%;
         max-height:100%;
    }
}
 @media only screen and (min-width: 600px) {
     .ht-section-title {
         font-size: 1.5rem;
    }
     .ht-btn-back{
         font-size: 1rem;
    }
     .ht-tournament-title {
         font-size: 1.1rem;
    }
     .ht-badge-status, .ht-badge-tier {
         font-size: 0.75rem;
         padding: 0.2em 0.5em;
    }
     .ht-card-img-top {
         height: 150px;
    }
     .ht-teams-prize {
         font-size: 1rem;
    }
    hottournaments-component  .image-container-tournament-logo {
    }
    hottournaments-component  .image-container-tournament-logo img {
    }
     .ht-tournament-dates {
         font-size: 0.9rem;
    }
     hottournaments-component .img-circle-container-logo-game{
         width: 2rem;
         height: 2rem;
    }
     hottournaments-component .img-circle-container-logo-game img {
         max-width: 100%;
         max-height:100%;
    }
}
 @media only screen and (min-width: 768px) {
     .ht-section-title {
         font-size: 1.2rem;
    }
     .ht-btn-back{
         font-size: 0.5rem;
    }
     .ht-tournament-title {
         font-size: 0.7rem;
    }
     .ht-badge-status, .ht-badge-tier {
         font-size: 0.55rem;
         padding: 0.2em 0.5em;
    }
     .ht-card-img-top {
         height: 150px;
    }
     .ht-teams-prize {
         font-size: 0.8rem;
    }
    hottournaments-component  .image-container-tournament-logo {
         width: 2rem;
         height: auto;
    }
    hottournaments-component  .image-container-tournament-logo img {
    }
     .ht-tournament-dates {
         font-size: 0.6rem;
    }
     hottournaments-component .img-circle-container-logo-game{
         width: 1.5rem;
         height: 1.5rem;
    }
     hottournaments-component .img-circle-container-logo-game img {
         max-width: 100%;
         max-height:100%;
    }
}
 @media only screen and (min-width: 992px) {
     .ht-section-title {
         font-size: 1.3rem;
    }
     .ht-btn-back{
         font-size: 0.7rem;
    }
     .ht-tournament-title {
         font-size: 0.8rem;
    }
     .ht-badge-status, .ht-badge-tier {
         font-size: 0.65rem;
         padding: 0.2em 0.5em;
    }
     .ht-card-img-top {
         height: 150px;
    }
     .ht-teams-prize {
         font-size: 0.9rem;
    }
    hottournaments-component  .image-container-tournament-logo {
         width: 2.5rem;
         height: auto;
    }
    hottournaments-component  .image-container-tournament-logo img {
    }
     .ht-tournament-dates {
         font-size: 0.7rem;
    }
     hottournaments-component .img-circle-container-logo-game{
         width: 1.5rem;
         height: 1.5rem;
    }
     hottournaments-component .img-circle-container-logo-game img {
         max-width: 100%;
         max-height:100%;
    }
}
 @media only screen and (min-width: 1200px) {
     .ht-section-title {
         font-size: 2rem;
    }
     .ht-btn-back{
         font-size: 0.8rem;
    }
     .ht-tournament-title {
         font-size: 1rem;
    }
     .ht-badge-status, .ht-badge-tier {
         font-size: 0.75rem;
         padding: 0.2em 0.5em;
    }
     .ht-card-img-top {
         height: 150px;
    }
     .ht-teams-prize {
         font-size: 0.9rem;
    }
    hottournaments-component  .image-container-tournament-logo {
         width: 2.5rem;
         height: auto;
    }
    hottournaments-component  .image-container-tournament-logo img {
    }
     .ht-tournament-dates {
         font-size: 0.8rem;
    }
     hottournaments-component .img-circle-container-logo-game{
         width: 1.5rem;
         height: 1.5rem;
    }
     hottournaments-component .img-circle-container-logo-game img {
         max-width: 100%;
         max-height:100%;
    }
}

    </style>

`

export class HotTournamentsComponent extends BaseComponent {

    initCallback() {
        RouterAssistant.checkForSingleContainer('hottournaments-component', HotTournamentsComponent.initiator);
        document.head.insertAdjacentHTML("beforeend", css);
        document.querySelectorAll('.hotTournamentsTitle').forEach((el) => {
            el.innerHTML = I18n.translateString('tournamentsTranslations.hotTournamentsTitle');
        });
        document.querySelector('.show-more-tournaments-button2').insertAdjacentHTML('beforeend', I18n.translateString("tournamentsTranslations.noHotTournamentsButton2"));


        const container = document.getElementById('hottournaments-html');

        // if (container) {
        //     const scrollSpeed = 1.5;
        //     const touchpadThreshold = 60;
        //
        //     container.addEventListener('wheel', (event) => {
        //         event.preventDefault();
        //
        //         if (Math.abs(event.deltaY) < touchpadThreshold) {
        //             // console.debug('Scrolling with touchpad');
        //             container.style.scrollBehavior = 'auto';
        //         } else {
        //             // console.debug('Scrolling with mouse wheel');
        //             container.style.scrollBehavior = 'smooth';
        //         }
        //
        //         container.scrollLeft += event.deltaY * scrollSpeed;
        //     });
        // }
    }

    static initiator() {

        TournamentsComponent.getTournaments('fromHotTournamentsComponent', () => {
            if (document.querySelectorAll('#hottournaments-html').length !== 0) {
                console.debug(document.querySelectorAll('#hottournaments-html').length)
                console.debug('I am running the hotTournaments component call back!')
                const element = document.querySelector('#hottournaments-html');
                if (element) {
                    element.innerHTML = "";
                }
                HotTournamentsComponent.updateTournaments(GameNavbar.getCurrentlyActivatedGameIfAny())
                const tournamentsDiv = document.querySelector('#hottournaments-html');
                if (!window.location.href.includes('tournaments') && !window.location.href.includes('matches')) {
                    if (!tournamentsDiv.innerHTML.trim()) {
                        tournamentsDiv.innerHTML = /*html*/`
               <div class="my-5 msgSMTclass" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;" id="noTour-message">
                
                </div>
              `;
                        const showMoreTourButton = '<button id="show-more-tournaments-button" class="btn color-dark text-resize dark-animation buttonSMTclass" style="color#ffffff;font-weight: bold;">View All</button>';
                        const msgSMTelements = document.querySelectorAll('.msgSMTclass');
                        if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                            msgSMTelements.forEach(element => {
                                element.innerHTML = I18n.translateString('tournamentsTranslations.noHotTournamentsMsg', { hTgame: document.querySelector('.gameNameActive').innerText });
                            });
                        } else {
                            msgSMTelements.forEach(element => {
                                element.innerHTML = I18n.translateString('tournamentsTranslations.noHotTournamentsMsg', { hTgame: '' });
                            });
                        }
                        document.querySelector('#noTour-message').insertAdjacentHTML('beforeend', showMoreTourButton);
                        const buttonSMTelements = document.querySelectorAll('.buttonSMTclass');
                        buttonSMTelements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.noHotTournamentsButton") });
                    }
                }
                HotTournamentsComponent.redirectToTournaments();
            } else {
                console.debug('The hottournaments component call back is supposed to run but I am not on the correct page!!!')
            }
        });

        GameNavbar.subscribeToGameFilterChanges('fromHotTournamentsComponent', (eventId) => {
            if (document.querySelectorAll('#hottournaments-html').length !== 0) { // if our component exists on the page
                document.querySelector('#hottournaments-html').innerHTML = '';
            }
            const element = document.querySelector('#hottournaments-html');
            if (element) {
                element.innerHTML = "";
            }
            HotTournamentsComponent.updateTournaments(eventId, document.querySelector('hottournaments-component '));
            const tournamentsDiv = document.querySelector('#hottournaments-html');
            if (!window.location.href.includes('tournaments') && !window.location.href.includes('matches')) {
                if (!tournamentsDiv.innerHTML.trim()) {
                    tournamentsDiv.innerHTML = /*html*/`
               <div class="my-5 msgSMTclass" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;" id="noTour-message">
                
                </div>
              `;
                    const showMoreTourButton = '<button id="show-more-tournaments-button" class="btn color-dark text-resize dark-animation buttonSMTclass" style="color#ffffff;font-weight: bold;">View All</button>';
                    const msgSMTelements = document.querySelectorAll('.msgSMTclass');
                    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                        msgSMTelements.forEach(element => {
                            element.innerHTML = I18n.translateString('tournamentsTranslations.noHotTournamentsMsg', { hTgame: document.querySelector('.gameNameActive').innerText });
                        });
                    } else {
                        msgSMTelements.forEach(element => {
                            element.innerHTML = I18n.translateString('tournamentsTranslations.noHotTournamentsMsg', { hTgame: '' });
                        });
                    }
                    document.querySelector('#noTour-message').insertAdjacentHTML('beforeend', showMoreTourButton);
                    const buttonSMTelements = document.querySelectorAll('.buttonSMTclass');
                    buttonSMTelements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.noHotTournamentsButton") });
                }
            }
            HotTournamentsComponent.redirectToTournaments();
        })
    }

    static updateTournaments(gameId) {
        if (!window.location.href.includes('tournaments') && !window.location.href.includes('matches')) {
            console.debug('running update tournaments of hottournaments component')

            const tierOrder = { 's': 1, 'a': 2, 'b': 3, 'c': 4, 'd': 5 };
            let count = 0;
            let imgSources = [tournamentimg2];
            let userLanguage = window.navigator.language;

            // Calculate total prize pool for each tournament
            Object.keys(window.savedTournaments).forEach(id => {

                let totalPrizePool = 0; // in case of no prize pool
                let currency = '';
                let noPrizePoolMsg = '';
                let tournamentsWithoutPrizePool = 0;
                let tier = '';
                if (window.savedTournaments[id] && window.savedTournaments[id].tournaments && window.savedTournaments[id].tournaments[0] && window.savedTournaments[id].tournaments[0].tier) {
                    const data = window.savedTournaments[id];
                    for (let j = 0; j < data.tournaments.length; j++) {
                        const prizePool = data.tournaments[j].prizepool;
                        if (!tier) {
                            tier = data.tournaments[j].tier;
                        }
                        if (prizePool) {
                            const match = prizePool.match(/(\d+)(\D+)/);
                            if (match) {
                                totalPrizePool += Number(match[1]);
                                if (!currency) {
                                    currency = match[2].trim();
                                }
                            }
                        } else {
                            tournamentsWithoutPrizePool++;
                        }
                    }

                    if (tournamentsWithoutPrizePool === data.tournaments.length) {
                        noPrizePoolMsg = `No prize pool available`
                    }


                    // Store the total prize pool and currency in the tournament data
                    data.tier = data.tournaments[0].tier;
                    data.totalPrizePool = totalPrizePool;
                    data.currency = currency;
                    data.noPrizePoolMsg = noPrizePoolMsg;
                    data.prizepoolMessage = noPrizePoolMsg + (totalPrizePool !== 0 ? totalPrizePool : '') + ' ' + currency;
                } else {
                    delete window.savedTournaments[id];
                }
            });

            const allTournamentIds = Object.keys(window.savedTournaments);

            // Sort all tournaments by tier, validity of currency, prize pool, and date
            allTournamentIds.sort((a, b) => {
                const tournamentA = window.savedTournaments[a];
                const tournamentB = window.savedTournaments[b];

                const tierA = tierOrder[tournamentA.tournaments[0].tier] || 6;
                const tierB = tierOrder[tournamentB.tournaments[0].tier] || 6;

                if (tierA !== tierB) {
                    return tierA - tierB;
                }

                const isValidCurrencyA = tournamentA.currency === 'Euro' || tournamentA.currency === 'United States Dollar' ? 1 : 0;
                const isValidCurrencyB = tournamentB.currency === 'Euro' || tournamentB.currency === 'United States Dollar' ? 1 : 0;

                if (isValidCurrencyA !== isValidCurrencyB) {
                    return isValidCurrencyB - isValidCurrencyA; // valid currencies come first
                }

                if (tournamentA.totalPrizePool !== tournamentB.totalPrizePool) {
                    return tournamentB.totalPrizePool - tournamentA.totalPrizePool; // sort in descending order

                }

                const dateA = new Date(tournamentA.begin_at ? tournamentA.begin_at : tournamentA.scheduled_at);
                const dateB = new Date(tournamentB.begin_at ? tournamentB.begin_at : tournamentB.scheduled_at);

                return dateA.getTime() - dateB.getTime();
            });

            // Concatenate the two arrays to get the final order
            const sortedTournamentIds = allTournamentIds;
            for (let i = 0; i < sortedTournamentIds.length; i++) {
                const TournamentCode = sortedTournamentIds[i]
                const tourElementId = `fromhottournamentsId-${TournamentCode}`
                // console.debug('TournamentCode', tourElementId)
                let data = window.savedTournaments[TournamentCode]
                const startTime = data.begin_at ? data.begin_at : data.scheduled_at
                const endTime = data.end_at ? data.end_at : 'NoDateMsg'
                const now = new Date();
                const startTimeDate = new Date(startTime);
                const endTimeDate = endTime !== 'NoDateMsg' ? new Date(endTime) : null;
                const formatedStartTime = startTimeDate.toLocaleString(userLanguage, { year: 'numeric', month: 'short', day: 'numeric' });
                const formatedEndTime = endTimeDate ? endTimeDate.toLocaleString(userLanguage, { year: 'numeric', month: 'short', day: 'numeric' }) : 'NoDateMsg';
                if (startTimeDate < now && (!endTimeDate || endTimeDate > now)) {

                    const currentGameId = data.videogame.id;
                    // Create a mapping of gameIds to their corresponding currentGameId
                    const gameIdMapping = {
                        'csgo': 3,
                        'dota2': 4,
                        'lol': 1,
                        'easports': 25,
                        'valorant': 26,
                        'cod': 23,
                        'overwatch': 14,
                        'pubg': 20,
                        'r6': 24,
                        'rocketleague': 22,
                        'starcraft': [29, 30],
                        'hearthstone': 999,
                        'fortnite': 999,
                        'fifa': 999,
                        'roblox': 999,
                        'minecraft': 999,
                        'wow': 999,
                        'apex': 999,
                        'kingofglory': 27,
                        'mlegends': 34
                    };
                    const gameIdImg = {
                        '3': csgologo,
                        '4': dota2,
                        '1': lol,
                        '25': ea,
                        '26': valorant,
                        '23': cod,
                        '14': overwatch,
                        '20': pubg,
                        '24': r6,
                        '22': rocketleague,
                        '29': startcraft,
                        '30': startcraft,
                        '27': kingofglory,
                        '34': mlegends

                    };
                    // If a gameId is provided and it doesn't match the current game's id, skip this tournament
                    if (gameId && gameIdMapping[gameId] !== undefined) {
                        if (Array.isArray(gameIdMapping[gameId])) {
                            if (!gameIdMapping[gameId].includes(currentGameId)) {
                                continue;
                            }
                        } else {
                            if (gameIdMapping[gameId] !== currentGameId) {
                                continue;
                            }
                        }
                    }

                    console.debug(" LIVE TOURNAMENT!!!")
                    console.debug(data)
                    const tournamentName = data.league.name + ' ' + data.full_name;
                    const gameLogoHTML = gameIdImg[data.videogame.id]
                    const imgSrc = imgSources[count % imgSources.length];

                    data.totalPrizePool = new Intl.NumberFormat('de-DE').format(data.totalPrizePool);
                    data.prizepoolMessage = data.noPrizePoolMsg + (data.totalPrizePool !== "0" ? data.totalPrizePool : '') + ' ' + data.currency;
                    let seriesPrizepool = data.prizepoolMessage;
                    if (data.actualprizepool && data.totalPrizePool === '0') {
                        seriesPrizepool = data.actualprizepool;
                    }
                    const seriesTier = data.tier.toUpperCase();

                    const tournamentLogo = data.league.image_url ? data.league.image_url : noImageFound;
                    const tournamentLogoHTML = tournamentLogo ? `<div style="display: flex; justify-content: center; align-items: center; height: 100%;"><img src="${tournamentLogo}" onerror="this.onerror=null; this.src='';" alt="" class="img-fluid mx-auto d-block" style="width: 25%; height: auto;"></div>` : '';
                    let count2 = 0;
                    if (data.opponents && data.opponents.length !== 0) {
                        count2 = data.opponents.length;
                    } else {
                        const allOpponents = [];
                        for (let g = 0; g < data.tournaments.length; g++) {
                            const tournament = data.tournaments[g];
                            for (let k = 0; k < tournament.matches.length; k++) {
                                const opponentsInMatch = tournament.matches[k].opponents;
                                for (let l = 0; l < opponentsInMatch.length; l++) {
                                    const opponent = opponentsInMatch[l].opponent;
                                    if (!allOpponents.some((o) => o.id === opponent.id)) {
                                        allOpponents.push({
                                            id: opponent.id,
                                            name: opponent.name,
                                        });
                                    }
                                }
                            }
                        }
                        count2 = allOpponents.length;
                    }
                    const playersParticipating = count2;

                    const hotTourhtml = /*html*/ `
 
    <!-- Tournament Card Example -->
    <div class="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
        <a href="${window.location.origin}/s_tournament?tournament=${TournamentCode}" class="card  ht-tournament-card text-white border-0 rounded parentHottournament" id="${tourElementId}" aria-label="${tournamentName}">
            <!-- Card Header -->
            <div class="ht-card-header rounded-top">
                <div class="ht-tournament-logo">
                    <div class="img-circle-container-logo-game d-flex justify-content-center overflow-hidden">
                        <img class="img-game-logo" src="${gameLogoHTML}" alt="gamelogo">
                    </div>
                </div>
                <div class="ht-tournament-info">
                    <h5 class="ht-tournament-title">${tournamentName}</h5>
                    <small class="ht-tournament-dates">${formatedStartTime} - ${formatedEndTime}</small>
                </div>
            </div>

            <!-- Position Relative Container for Background Image and Tournament Logo -->
            <div class="position-relative">
                <!-- Tournament Logo Positioned Above Background Image -->
                <div class="image-container-tournament-logo  d-flex justify-content-center align-items-center">
                    <img src="${tournamentLogo}" onerror="this.onerror=null; this.src='';" alt="Tournament Logo" class="img-fluid mx-auto d-block unzoom" style="filter: drop-shadow(0 0 1.3rem black);">
                </div>

                <!-- Background Image -->
                <img src="${imgSrc}" class="card-img-top ht-card-img-top rounded-0" alt="tournament-background" loading="lazy">

                <!-- Image Overlay with Badges -->
                <div class="ht-img-overlay">
                    <span class="badge bg-success ht-badge-status" data-bs-toggle="tooltip" data-bs-placement="top" title="This tournament is currently active">
                        <i class="bi bi-play-fill me-1 ongoing-label"></i>
                    </span>
                    <span class="badge bg-info ht-badge-tier" data-bs-toggle="tooltip" data-bs-placement="top" title="Tier S Tournament">
                        Tier: ${seriesTier}
                    </span>
                </div>
            </div>

            <!-- Card Body -->
            <div class="ht-card-body rounded-bottom">
                <div class="d-flex justify-content-between mb-3">
                    <span class="ht-teams-prize">
                        <i class="bi bi-people-fill"></i>${playersParticipating}
                    </span>
                    <span class="ht-teams-prize prizepool-label">
                        <i class="bi bi-cash-stack"></i>${seriesPrizepool}
                    </span>
                </div>
                <div class="mt-auto">
                    <span class="text-muted small">${I18n.translateString('tournamentsTranslations.clickHotTournaments')}</span>
                </div>
            </div>
        </a>
  
</div>


    `;
                    document.querySelector('#hottournaments-html').insertAdjacentHTML('beforeend', hotTourhtml);
                    HotTournamentsComponent.translations();
                    const insertedElement = document.querySelector(`[id="${tourElementId}"]`);

                    insertedElement.addEventListener('mouseenter', (evt) => {
                        const images = evt.target.querySelectorAll('img');
                        if (images.length > 1) {
                            // Immediately zoom the second image
                            images[1].classList.add('zoom');
                            images[1].classList.remove('unzoom');
                            images[0].classList.add('zoom');
                            images[0].classList.remove('unzoom');

                        }
                    }, false);

                    insertedElement.addEventListener('mouseleave', (evt) => {
                        const images = evt.target.querySelectorAll('img');
                        if (images.length > 1) {
                            // Remove zoom from the second image immediately
                            images[1].classList.remove('zoom');
                            images[1].classList.add('unzoom');
                            images[0].classList.remove('zoom');
                            images[0].classList.add('unzoom');

                        }
                    }, false);
                    count++; // Increment counter
                    if (count >= 6) {
                        break; // Exit loop after processing three tournaments
                    }
                } else if (!(startTimeDate < now && (!endTimeDate || endTimeDate > now))) {
                    // console.debug("UPCOMING TOURNAMENT OR PAST TOURNAMENT!!! Skipping....")
                }
                HotTournamentsComponent.redirectToTournamentPage(tourElementId);


            }

        }

    }


    static redirectToTournamentPage(tourElementId) {

        const element = document.getElementById(tourElementId);
        const alteredTourElementId = tourElementId.replace('fromhottournamentsId-', '');

        if (element) {
            element.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                evt.stopPropagation();
                RouterAssistant.redirect('s_tournament', {
                    tournament: alteredTourElementId
                })
            }))
        }
    }

    static redirectToTournaments() {
        const showMoreButton = document.getElementById('show-more-tournaments-button');
        const showMoreButton2 = document.querySelector('.show-more-tournaments-button2');

        if (showMoreButton) {
            showMoreButton.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                RouterAssistant.redirect('tournaments', { game: GameNavbar.getCurrentlyActivatedGameIfAny() })
            }))
        }
        if (showMoreButton2) {
            showMoreButton2.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                RouterAssistant.redirect('tournaments', { game: GameNavbar.getCurrentlyActivatedGameIfAny() })
            }))
        }
    }

    static translations() {
        const endElements = document.querySelectorAll('.endDate-label');
        endElements.forEach(element => {
            if (element.innerHTML === 'NoDateMsg') {
                element.innerHTML = I18n.translateString("tournamentsTranslations.noDateMsg");
            }
        });
        const tierElements = document.querySelectorAll('.tier-label');
        const prizepoolElements = document.querySelectorAll('.prizepool-label');
        prizepoolElements.forEach(element => {
            if (element.innerHTML.includes('No prize pool available')) {
                element.innerHTML = I18n.translateString("tournamentsTranslations.noPrizePoolAvailable")
            } else if (element.innerHTML.includes('Euros')) {
                const translatedString = I18n.translateString("tournamentsTranslations.euroPrizePool")
                element.innerHTML = element.innerHTML.replace('Euros', translatedString);
            } else if (element.innerHTML.includes('Euro')) {
                const translatedString = I18n.translateString("tournamentsTranslations.euroPrizePool")
                element.innerHTML = element.innerHTML.replace('Euro', translatedString);
            } else if (element.innerHTML.includes('United States Dollar')) {
                const translatedString = I18n.translateString("tournamentsTranslations.usdPrizePool")
                element.innerHTML = element.innerHTML.replace('United States Dollar', translatedString);
            }
        });
        tierElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.tier") });
        const teamsCompeteElements = document.querySelectorAll('.teams-compete-label');
        teamsCompeteElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.teamsCompete") });
        const ongoingElements = document.querySelectorAll('.ongoing-label');
        ongoingElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.hotTournamentFixedLabel") });
    }



}


HotTournamentsComponent.template = /*html*/ `

 <!-- Hot Tournaments title -->
    <div class="ht-component container my-2">
        <div class="ht-section-header d-flex justify-content-between align-items-center mb-4">
            <h2 class="ht-section-title hotTournamentsTitle">HOT TOURNAMENTS</h2>
            <button class="btn btn-outline-secondary btn-sm ht-btn-back show-more-tournaments-button2"  aria-label="See All">
                <i class="bi bi-arrow-left"></i>
            </button>
        </div>
  <div class="row g-4" id="hottournaments-html"></div>
</div>

`;

HotTournamentsComponent.reg('hottournaments-component');

const globalSocials = {
    discord: "https://discord.gg/3sU4u59qze",
    twitter: "https://x.com/myesportsgg",
    facebook: "https://www.facebook.com/myesportsgg",
    twitch: "https://www.twitch.tv/myesports_gg",
    instagram: "https://www.instagram.com/myesportsgg/",
    youtube: "https://www.youtube.com/@myesportsgg",
    tiktok: "https://www.tiktok.com/@myesports.gg",
};

const affiliateLinks = {
    thunderpickGeneral: "https://go.thunder.partners/visit/?bta=36737&brand=thunderpick&campaign=MyEsports",
    thunderpickCode: "?cxd=MyEsports&cxcampaign=MyEsports",
    sportazaGeneral: "https://stz.xarvilo.com/?mid=239793_1456910",
    boomerangGeneral: "https://boomertraff.com/g8c14b672", // lands on bonus
    boomerangOddsGeneral: "https://boomertraff.com/gb8b98952", // lands on sportsbook
};

// Attach the objects to the global window objects
window.globalSocials = globalSocials;
window.affiliateLinks = affiliateLinks;

import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { PostMatchesComponent } from "../postmatches-component/postmatches-component";
import { GameNavbar } from "../game-navbar/game-navbar";
const noImage = require('../matches-component/Assets/no image found.png')

const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png")
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png')
const lol = require('../game-navbar/assets/images/lollogo 150x150.png')
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png')
const fifa = require('../game-navbar/assets/images/fifalogo 150x150.png')
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png')
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png')
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png')
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png')
const pubg = require('../game-navbar/assets/images/pubg 150x150.png')
const r6 = require('../game-navbar/assets/images/rainbow six 150x150.png')
const roblox = require('../game-navbar/assets/images/roblox 150x150.png')
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png')
const wow = require('../game-navbar/assets/images/world of warcraft 150x150.png')
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png')
const cod = require('../game-navbar/assets/images/call of duty 150x150.png')
const ea = require('../game-navbar/assets/images/ea sports 150x150.png')
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp')
const kingofglory = require('../game-navbar/assets/images/KingOfGlory 150x150.webp')
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp')


const css = `
<style>

    analyses-homecomponent .text-resize {
         font-size: small;
         font-weight: 400;
    
    }



analyses-homecomponent .teamname{
display:none;
}
analyses-homecomponent .teamnameslug{
display:block;
}
.inline-trancate-1 {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    

}

analyses-homecomponent .predNavItem {
    border: 1px solid #ff282859; /* Border color */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 10px; /* Space between items */
    background-color: #161B22; /* Background color */
    
}

analyses-homecomponent .predNavItem .nav-link {
    color: #f8f9fa; /* Link color */
    display: flex;
    align-items: center;
    padding: 0 !important;
}

analyses-homecomponent .text-resize-badge {
         display: flex;
         justify-content: center;
         justify-items: center;
         align-items: center;
    }        
        
        @media only screen and (max-width: 600px) {
 /*analyses-homecomponent .text-resize {*/
 /*        font-size: 0.6rem;*/
 /*        font-weight: 700;*/
 
 /*   }*/
    analyses-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    analyses-homecomponent .img-circle-container {
       width: 0.8rem;
       height: 0.8rem;
    }
       analyses-homecomponent .img-circle-container img{
       max-width: 0.8rem;
       max-height: 0.8rem;
    }
   analyses-homecomponent .team-name-resize-widget{
         font-size: 0.5rem;
         font-weight: 600;
         color:#ffffff;
         margin:1% !important;
}
analyses-homecomponent .text-resize-title {
		font-size: 0.8rem;
		font-weight: 500;
		color: #b0b0b0;
		margin-left: 0.2rem;
	}
	analyses-homecomponent .teamname{
display:none;
}
analyses-homecomponent .teamnameslug{
display:block;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

   /*analyses-homecomponent .text-resize {*/
   /*      font-size: 0.7rem;*/
   /*      font-weight: 700;*/
   
   /* }*/
    analyses-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    analyses-homecomponent .img-circle-container {
       width: 1rem;
       height: 1rem;
    }
       analyses-homecomponent .img-circle-container img{
       max-width: 1rem;
       max-height: 1rem;
    }
   analyses-homecomponent .team-name-resize-widget{
         font-size: 0.65rem;
         font-weight: 600;
         color:#ffffff;
         margin:1% !important;
}
analyses-homecomponent .teamname{
display:none;
}
analyses-homecomponent .teamnameslug{
display:block;
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    /*analyses-homecomponent .text-resize {*/
    /*     font-size: 0.75rem;*/
    /*     font-weight: 700;*/
    /*}*/
    
    analyses-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    analyses-homecomponent .img-circle-container {
       width: 1rem;
       height: 1rem;
    }
       analyses-homecomponent .img-circle-container img{
       max-width: 1rem;
       max-height: 1rem;
    }
   analyses-homecomponent .team-name-resize-widget{
         font-size: 0.65rem;
         font-weight: 600;
         color:#ffffff;
         margin:1% !important;
}
analyses-homecomponent .teamname{
display:none;
}
analyses-homecomponent .teamnameslug{
display:block;
}
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
    /* analyses-homecomponent .text-resize {*/
    /*     font-size: 0.8rem;*/
    /*     font-weight: 700;*/
    /*}*/
    analyses-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    analyses-homecomponent .img-circle-container {
       width: 1rem;
       height: 1rem;
    }
       analyses-homecomponent .img-circle-container img{
       max-width: 1rem;
       max-height: 1rem;
    }
   analyses-homecomponent .team-name-resize-widget{
         font-size: 0.7rem;
         font-weight: 600;
         color:#ffffff;
         margin:1% !important;
}
analyses-homecomponent .teamname{
display:none;
}
analyses-homecomponent .teamnameslug{
display:block;
}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    /*analyses-homecomponent .text-resize {*/
    /*     font-size: 1rem;*/
    /*     font-weight: 700;*/
    
    /*}*/
    analyses-homecomponent .text-resize-badge {
         font-size: 0.7rem;
    }

    analyses-homecomponent .img-circle-container {
       width: 1rem;
       height: 1rem;
    }
       analyses-homecomponent .img-circle-container img{
       max-width: 1rem;
       max-height: 1rem;
    }
   analyses-homecomponent .team-name-resize-widget{
         font-size: 0.7rem;
         font-weight: 600;
         color:#ffffff;
         margin:0.5% !important;
}
analyses-homecomponent .teamnameslug{
display:none;
}
analyses-homecomponent .teamname{
display:block;
}
}
</style>
`

export class AnalysesHomecomponent extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css);
        RouterAssistant.checkForSingleContainer('analyses-homecomponent', AnalysesHomecomponent.initiator);
    }

    static initiator() {
        AnalysesHomecomponent.getAnalyses('fromHomeWidgetComponent', function () {
            const activeGameFilterId = GameNavbar.getCurrentlyActivatedGameIfAny();
            AnalysesHomecomponent.updateAnalyses(activeGameFilterId);
        })

        AnalysesHomecomponent.translations();

        GameNavbar.subscribeToGameFilterChanges('fromAiAnalyses', (activeGameFilterId) => {
            console.debug(activeGameFilterId)
            if (document.querySelectorAll('#latestAnalysesList').length !== 0) {// if our component exists on the page
                AnalysesHomecomponent.updateAnalyses(activeGameFilterId);
            }
        })
    }

    static updateAnalyses(activeGameFilterId) {
        const gameIdImg = {
            '3': csgologo,
            '4': dota2,
            '1': lol,
            '25': ea,
            '26': valorant,
            '23': cod,
            '14': overwatch,
            '20': pubg,
            '24': r6,
            '22': rocketleague,
            '29': startcraft,
            '30': startcraft,
            '27': kingofglory,
            '34': mlegends
        };

        const gameIdFilter = {
            '3': 'csgo',
            '4': 'dota2',
            '1': 'lol',
            '25': 'ea',
            '26': 'valorant',
            '23': 'cod',
            '14': 'overwatch',
            '20': 'pubg',
            '24': 'r6',
            '22': 'rocketleague',
            '29': 'startcraft',
            '30': 'startcraft',
            '27': 'kingofglory',
            '34': 'mlegends'
        };

        const list = document.getElementById('latestAnalysesList')
        list.innerHTML = ''

        let latestAnalyses = []
        if (activeGameFilterId !== null && activeGameFilterId !== 'null') {
            latestAnalyses = window.savedAnalyses.filter(doc => gameIdFilter[doc.data().gameId] === activeGameFilterId);
        } else {
            latestAnalyses = window.savedAnalyses.slice(0, 5);
        }

        console.debug(latestAnalyses)
        if (latestAnalyses.length === 0) {
            list.innerText = 'No Analyses available for the selected game!';
            return
        }


        for (let doc of latestAnalyses) {
            const clickBaitString = PostMatchesComponent.extractFirstTextBlock(doc.data().output)
            if (doc.id.split('-')[1] !== I18n.getUsersPreferredLanguage()) continue
            if (clickBaitString.length === 0) continue
            const image1 = (doc.data().team1logo !== '') ? doc.data().team1logo : noImage;
            const image2 = (doc.data().team2logo !== '') ? doc.data().team2logo : noImage;
            const team1name = (doc.data().opName1) ? doc.data().opName1 : '';
            const team2name = (doc.data().opName2) ? doc.data().opName2 : '';
            const team1slug = (doc.data().opSlug1) ? doc.data().opSlug1 : '';
            const team2slug = (doc.data().opSlug2) ? doc.data().opSlug2 : '';
            const gameId = doc.data().gameId


            list.insertAdjacentHTML("beforeend", /*html*/`
                                     
                    
                    <a href="${window.location.origin}/s_match?match=${doc.id.split('-')[0]}" id="analysis-${doc.id.split('-')[0]}" class="container mb-3 clickable">
                    <div class="row border border-success color-dark justify-content-center rounded-top">
                    <div class="col-2 d-flex justify-content-start align-content-center align-items-start p-0"> 
                        <div class=" img-circle-container d-flex justify-content-center flex-column m-2" style="margin-right: 1rem !important">
                            <img src="${gameIdImg[gameId]}" alt="" class="logo img-fluid mx-auto d-block">
                            </div>
                    </div>
                    
                     <div class="col-10 d-flex justify-content-end align-content-center align-items-center p-0"> 
                     <div class=" img-circle-container d-flex justify-content-center flex-column m-2">
                                    <img src="${image1}" alt="" class="logo img-fluid mx-auto d-block" >
                                    
                     </div>
<!--                     <span class="team-name-resize-widget text-start teamname">${team1name}</span>-->
<!--                     <span class="team-name-resize-widget text-start teamnameslug">${team1slug}</span>-->
                         <span class="team-name-resize-widget text-start ">${team1slug}</span>

                       <span class="team-name-resize-widget">-</span>
<!--                       <span class="team-name-resize-widget text-end teamnameslug">${team2slug}</span>-->
<!--                       <span class="team-name-resize-widget text-end teamname">${team2name}</span>-->
                    <span class="team-name-resize-widget text-end ">${team2slug}</span>

                     <div class=" img-circle-container d-flex justify-content-center flex-column m-2">
                                    <img src="${image2}" alt="" class="logo img-fluid mx-auto d-block" >
                      </div>
                      
                     </div>
                     </div>
                     <div class="row border border-success rounded-bottom" style="background-color: #1e252e">
                     <div class="col-12 d-flex justify-content-center align-content-center align-items-center p-2"> 
                      <span class="text-start text-truncate-2 ms-1 text-resize">${clickBaitString}</span>
                     </div>
                     </div>
                    </div>
                    
                    
                `);
            document.getElementById(`analysis-${doc.id.split('-')[0]}`).addEventListener('click', MiscAssistant.MyEsportsClick(function () {
                RouterAssistant.redirect('s_match', {
                    match: doc.id.split('-')[0]
                })
            }))
        }
    }

    static getAnalyses(callbackName, callback) {
        if (window.getAnalysesEventListeners === undefined) {
            window.getAnalysesEventListeners = {}
            window.getAnalysesEventListeners[callbackName] = callback
            FirebaseAssistant.getAnalysesV2(querySnapshot => {
                console.debug('running initial firebase getAnalyses() query snapshot')
                window.savedAnalyses = querySnapshot
                Object.keys(window.getAnalysesEventListeners).forEach(eventListenerName => {
                    window.getAnalysesEventListeners[eventListenerName]()
                })
            });
        } else if (window.savedAnalyses === undefined) {
            setTimeout(() => {
                AnalysesHomecomponent.getAnalyses(callbackName, callback)
            }, 50)
        } else {
            window.getAnalysesEventListeners[callbackName] = callback
            callback()
        }
    }


    static translations() {
        document.getElementById('analysesHomeTitle').innerHTML = I18n.translateString('homePage.latestAnalyses');
    }
}
AnalysesHomecomponent.template = /*html*/`
<div id="analysesFinalHtml">

               <div class="title mb-3">
        <span id="analysesHomeTitle" class="text-resize-title predTitle">RECENT AI ANALYSIS</span>
        </div>
  
            <div id="latestAnalysesList"> </div>
            </div>
</div>
`;

AnalysesHomecomponent.reg('analyses-homecomponent'); // components should always be 2 words seperated with a dash


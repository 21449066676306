import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";
import { PostBookmakersComponent } from "../postbookmakers-component/postbookmakers-component";
import { NewsComponent } from "../news-component/news-component";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";


const css = `
<style>

</style>
`

export class NewsContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters();

    }

}

NewsContainer.template = /*html*/ `
<news-component></news-component>
`;

NewsContainer.reg('news-container');

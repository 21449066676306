import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { AuthorsComponent } from "../author-component/author-component";
import showdown from 'showdown';


const css = `
<style>
news-post .accordion {
margin-top: calc(1rem + 2vw);
}
news-post .follow-us {
    background-color: #252E39; 
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden;
}

news-post .follow-us h2 {
    color: #ff3b3b; /* Secondary color red */
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    animation: bounceIn 1s ease-in-out;
    letter-spacing: 2px;
}

news-post .follow-us h5 {
    font-size: 1.25rem;
    color: #eaeaea;
    margin-bottom: 2rem;
}

news-post .follow-us .btn {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border: 2px solid #ff3b3b; /* Red outline for buttons */
    transition: all 0.4s ease;
    color: #eaeaea;
    background: none;
}

news-post .follow-us .btn i {
    line-height: 1;
}

news-post .follow-us .btn:hover {
    color: #ffffff;
    background-color: #ff3b3b; /* Red background on hover */
    transform: scale(1.2); /* Slight zoom effect */
    box-shadow: 0px 0px 15px 3px rgba(255, 59, 59, 0.6); /* Red glowing effect */
}

/* Optional hover effect for icons */
news-post .follow-us .btn i {
    transition: transform 0.4s ease;
}

news-post .follow-us .btn:hover i {
    transform: rotate(360deg); /* Icon rotation on hover */
}

/* Keyframes for bounce-in effect */
@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    80% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

/* Responsive grid system for media */
 .post-news-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    background-color: #252E39;
}
news-post .articleHtmlPlaceholder hr {
    border: none; /* Remove default styling */
}
/* Headings styling */
news-post .articleHtmlPlaceholder h1,news-post .articleHtmlPlaceholder h2, news-post .articleHtmlPlaceholder h3, news-post .articleHtmlPlaceholder h4, news-post .articleHtmlPlaceholder h5, news-post .articleHtmlPlaceholder h6 {
    
    color: #ffffff;
    margin-bottom: 1rem;
}

news-post .articleHtmlPlaceholder h1 {
    font-size: 2.5rem;
    line-height: 1.2;
}

news-post .articleHtmlPlaceholder h2 {
    font-size: 2rem;
    line-height: 1.3;
}

news-post .articleHtmlPlaceholder h3 {
    font-size: 1.75rem;
}

news-post .articleHtmlPlaceholder h4 {
    font-size: 1.5rem;
}

/* Paragraphs styling */
news-post .articleHtmlPlaceholder p {
    font-size: 1.1rem;
    color: #dadada;
    margin-bottom: 1.5rem;
}

/* Links */
news-post .articleHtmlPlaceholder a {
    color: #007BFF !important;
    text-decoration: none !important;
    transition: color 0.3s ease !important;
}

news-post .articleHtmlPlaceholder a:hover {
    color: #0056b3 !important;
}

/* Images */
news-post .articleHtmlPlaceholder img {
   display: block;              /* Makes the image a block-level element */
    max-width: 100%;             /* Ensures the image scales responsively */
    height: auto;                /* Maintains aspect ratio */
    border-radius: 10px;         /* Rounds the corners */
    margin-bottom: 1.5rem;       /* Adds space below the image */
}

/* Videos and Iframes */
news-post .articleHtmlPlaceholder iframe,news-post .articleHtmlPlaceholder  video {
    width: 100%;
    max-width: 100%;
    height: calc(5rem + 27.25vw); 
    margin-bottom: 1.5rem;
    border-radius: 10px;
    border: none;
}

/* Lists */
news-post .articleHtmlPlaceholder ul,news-post .articleHtmlPlaceholder ol {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

news-post .articleHtmlPlaceholder li {
    margin-bottom: 0.5rem;
     font-size: 1.1rem;
    color: #dadada;
}

/* Blockquotes */
news-post .articleHtmlPlaceholder blockquote {
    border-left: 4px solid #007BFF;
    padding:0.4rem;
    font-style: italic;
    color: #555;
}

/* Buttons */
news-post .articleHtmlPlaceholder button,news-post .articleHtmlPlaceholder  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

news-post .articleHtmlPlaceholder button:hover,news-post .articleHtmlPlaceholder  .btn:hover {
    background-color: #0056b3;
}

/* Tables */
news-post .articleHtmlPlaceholder table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
}

news-post .articleHtmlPlaceholder th,news-post .articleHtmlPlaceholder  td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

news-post .text-resize-icons{
    top: 3px;
    left: 3px;
}
news-post .news-post-img {
width: 100%;
height: auto;
object-fit: cover;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}   
news-post .author-info {
padding: 20px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}    
news-post .social-icons a {
color: #e0e0e0;
}
@media only screen and (max-width: 600px) {
 news-post .follow-us h2 {
        font-size: 1.2rem;
    }
    news-post .follow-us h5 {
        font-size: 1rem;
    }
    news-post .follow-us .btn {
        width: 2rem;
        height: 2rem;
        font-size: 0.7rem;
    }
    news-post .articleHtmlPlaceholder h1 {
        font-size: 1.75rem;
    }
    news-post .articleHtmlPlaceholder h2 {
        font-size: 1.5rem;
    }
    news-post .articleHtmlPlaceholder p {
        font-size: 0.9rem;
    }
    news-post .articleHtmlPlaceholder li {
        font-size: 0.9rem;
}
news-post .img-circle-container-author{
    width: 40px;
    height: 40px;
}   
news-post .img-circle-container-author img{
     max-width: 40px;
     max-height: 40px;
}   
news-post .text-resize-name{
font-size: 0.6rem;
font-weight: 700;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.4rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 0.5rem;
    top: 3px;
    left: 3px;
}
}
@media only screen and (min-width: 600px) {
  news-post .follow-us h2 {
        font-size: 1.4rem;
    }
    news-post .follow-us h5 {
        font-size: 1.1rem;
    }
    news-post .follow-us .btn {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
    }
news-post .articleHtmlPlaceholder h1 {
        font-size: 2rem;
    }
    news-post .articleHtmlPlaceholder h2 {
        font-size: 1.75rem;
    }
    news-post .articleHtmlPlaceholder p {
        font-size: 1rem;
    }
    news-post .articleHtmlPlaceholder li {
        font-size: 1rem;
}
news-post .img-circle-container-author{
    width: 50px;
    height: 50px;
}   
news-post .img-circle-container-author img{
     max-width: 50px;
     max-height: 50px;
}   
news-post .text-resize-name{
font-size: 0.65rem;
font-weight: 700;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.45rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 0.9rem;
}
}
@media only screen and (min-width: 768px) {
news-post .follow-us h2 {
        font-size: 1.6rem;
    }
    news-post .follow-us h5 {
        font-size: 1.2rem;
    }
    news-post .follow-us .btn {
        width: 3rem;
        height: 3rem;
        font-size: 1.25rem;
    }
 news-post .articleHtmlPlaceholder h1 {
        font-size: 2.25rem;
    }
    news-post .articleHtmlPlaceholder h2 {
        font-size: 2rem;
    }
    news-post .articleHtmlPlaceholder p {
        font-size: 1.1rem;
    }
    news-post .articleHtmlPlaceholder li {
        font-size: 1.1rem;
}
news-post .img-circle-container-author{
    width: 50px;
    height: 50px;
}   
news-post .img-circle-container-author img{
     max-width: 50px;
     max-height: 50px;
}   
news-post .text-resize-name{
font-size: 0.8rem;
font-weight: 700;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.6rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 0.9rem;
}
}
@media only screen and (min-width: 992px) {
news-post .follow-us h2 {
        font-size: 1.7rem;
    }
    news-post .follow-us h5 {
        font-size: 1.25rem;
    }
    news-post .follow-us .btn {
        width: 3.5rem;
        height: 3.5rem;
        font-size: 1.5rem;
    }
 news-post .articleHtmlPlaceholder h1 {
        font-size: 2.5rem;
    }
    news-post .articleHtmlPlaceholder h2 {
        font-size: 2.25rem;
    }
    news-post .articleHtmlPlaceholder p {
        font-size: 1.15rem;
    }
    news-post .articleHtmlPlaceholder li {
        font-size: 1.15rem;
}
news-post .img-circle-container-author{
    width: 80px;
    height: 80px;
}   
news-post .img-circle-container-author img{
     max-width: 80px;
     max-height: 80px;
}   
news-post .text-resize-name{
font-size: 0.8rem;
font-weight: 700;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.6rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 0.9rem;
}
}
@media only screen and (min-width: 1200px) {
news-post .follow-us h2 {
        font-size: 2.5rem;
    }
    news-post .follow-us h5 {
        font-size: 1.4rem;
    }
    news-post .follow-us .btn {
        width: 3.8rem;
        height: 3.8rem;
        font-size: 1.6rem;
    }
news-post .articleHtmlPlaceholder h1 {
        font-size: 3rem;
    }
    news-post .articleHtmlPlaceholder h2 {
        font-size: 2.5rem;
    }
    news-post .articleHtmlPlaceholder p {
        font-size: 1.25rem;
    }
    news-post .articleHtmlPlaceholder li {
        font-size: 1.25rem;
}
news-post .img-circle-container-author{
    width: 4rem;
    height: 4rem;
}   
news-post .img-circle-container-author img{
     max-width: 100%;
     max-height: 100%;
}   
news-post .text-resize-name{
font-size: 0.9rem;
font-weight: 800;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.9rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 1.1rem;
}
}

@media only screen and (min-width: 1920px) {
news-post .follow-us h2 {
        font-size: 3rem;
    }
    news-post .follow-us h5 {
        font-size: 1.5rem;
    }
    news-post .follow-us .btn {
        width: 4rem;
        height: 4rem;
        font-size: 1.75rem;
    }
 news-post .articleHtmlPlaceholder h1 {
        font-size: 3.5rem;
    }
    news-post .articleHtmlPlaceholder h2 {
        font-size: 3rem;
    }
    news-post .articleHtmlPlaceholder p {
        font-size: 1.35rem;
    }
    news-post .articleHtmlPlaceholder li {
        font-size: 1.35rem;
    }
news-post .img-circle-container-author{
    width: 200px;
    height: 200px;
}   
news-post .img-circle-container-author img{
     max-width: 100%;
     max-height: 100%;
}   
news-post .text-resize-name{
font-size: 1.4rem;
font-weight: 800;
color: #ffffff;
}
news-post .text-resize-date{
font-size: 0.9rem;
font-weight: 500;
}
news-post .text-resize-icons{
font-size: 1.4rem;
}
}
}

</style>

`

export class NewsPost extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters()
        document.head.insertAdjacentHTML("beforeend", css)

        RouterAssistant.checkForSingleContainer('news-post', NewsPost.initiator);


        window.twttr = (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0],
                t = window.twttr || {};
            if (d.getElementById(id)) return t;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://platform.twitter.com/widgets.js";
            fjs.parentNode.insertBefore(js, fjs);
            t._e = [];
            t.ready = function (f) {
                t._e.push(f);
            };
            return t;
        }(document, "script", "twitter-wjs"));
        if (window.twttr.ready()) {
            window.twttr.widgets.load();
        }
    }

    static initiator() {
        const articleName = RouterAssistant.getCurrentVariableValue('article');
        NewsCarousel.getNews(function () {
            AuthorsComponent.getAuthors('fromNewsPost', () => {
                let newsPostId = null;
                const findNewsPostId = new Promise((resolve, reject) => {
                    let newsPostId = null;
                    Object.entries(window.savedNews).forEach(([key, news]) => {
                        if (news.public_url_greeklish === articleName) {
                            newsPostId = key;
                        }
                    });
                    if (newsPostId) {
                        resolve(newsPostId);
                    } else {
                        console.debug('No matching entry found.');
                        reject('No matching entry found.');
                    }
                });

                findNewsPostId.then((resolvedNewsPostId) => {
                    NewsPost.updateNewsPost(resolvedNewsPostId);

                }).catch((error) => {
                    console.error(error);
                });
            });
            // document.getElementById('follow-us').insertAdjacentHTML('afterend', `<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`);


        });

    }

    static updateNewsPost(newsPostId) {

        let tempAuthor = '';
        let tempAuthorData = {};
        Object.entries(window.savedAuthors).forEach(([authorName, authorData]) => {
            if (authorName.toLowerCase() === window.savedNews[newsPostId].linkedAuthor.toLowerCase()) {
                tempAuthor = authorName;
                tempAuthorData = authorData;
            }
        })

        // Custom extension to handle {iframe}(url) and {blockquote}(url) syntax on CMS
        function customExtensions() {
            return [{
                type: 'lang',
                filter: function (text) {
                    // Handle {iframe}(url)
                    text = text.replace(/\{iframe\}\((.*?)\)/g, function (match, url) {
                        return `<iframe src="https://${url}" width="560" height="315"></iframe>`;
                    });

                    // Handle {blockquote}(url)
                    text = text.replace(/\{blockquote\}\((.*?)\)/g, function (match, url) {
                        return `<blockquote class="twitter-tweet"><a href="https://${url}"></a></blockquote>`;
                    });

                    return text;
                }
            }];
        }

        const converter = new showdown.Converter({
            extensions: [
                () => ({
                    type: 'output',
                    filter: (html) => {
                        // Modify <a> tags to open in a new tab, ensuring they are not escaped
                        return html.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ');
                    }
                }),
                customExtensions
            ]
        });
        const convertedMarkdownContent = window.savedNews[newsPostId].content[I18n.getUsersPreferredLanguage()];
        const htmlContent = converter.makeHtml(convertedMarkdownContent);
        const wrappedHtmlContent = !window.savedNews[newsPostId].is_page ? `<div class="articleHtmlPlaceholder">${htmlContent}</div>` : htmlContent;
        const writtenAtTimestamp = window.savedNews[newsPostId].writtenAt;
        const writtenAtDate = new Date(writtenAtTimestamp.seconds * 1000 + writtenAtTimestamp.nanoseconds / 1000000);
        const formattedDate = writtenAtDate.toLocaleString(I18n.getUsersPreferredLanguage(), {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
        document.querySelector('.the-article-container').insertAdjacentHTML('beforeend', wrappedHtmlContent)
        document.getElementById('newsArticleCoverPhoto').src = window.savedNews[newsPostId].article_photo
        // if (!window.savedNews[newsPostId].is_page) {
        document.querySelector('.author-info h5').textContent = tempAuthorData.fullName + ' "' + tempAuthorData.name + '" ';
        document.querySelector('.author-info p').textContent = ` ${I18n.translateString("newsTranslations.writtenAt")} ${formattedDate}`;
        document.querySelector('.author-photo').src = tempAuthorData.photo;

        document.querySelector('.social-icons').insertAdjacentHTML('beforeend',
            `
                ${!tempAuthorData.socials.instagram || tempAuthorData.socials.instagram === "N/A" ? '' : `<a href="${tempAuthorData.socials.instagram}" target="_blank" rel="noopener noreferrer"><i class="bi bi-instagram"></i></a>`}
                ${!tempAuthorData.socials.discord || tempAuthorData.socials.discord === "N/A" ? '' : `<a href="${tempAuthorData.socials.discord}" target="_blank" rel="noopener noreferrer"><i class="bi bi-discord"></i></a>`}
                ${!tempAuthorData.socials.twitter || tempAuthorData.socials.twitter === "N/A" ? '' : `<a href="${tempAuthorData.socials.twitter}" target="_blank" rel="noopener noreferrer"><i class="bi bi-twitter"></i></a>`}
                ${!tempAuthorData.socials.facebook || tempAuthorData.socials.facebook === "N/A" ? '' : `<a href="${tempAuthorData.socials.facebook}" target="_blank" rel="noopener noreferrer"><i class="bi bi-facebook"></i></a>`}
                `
        );
        // }

        const followUsHtml = /*html*/`
                                <div class="container">
                    <h2 class="mb-4"></h2>
                    <h5 class="mb-4"></h5>
                    <!-- Social Media Icons (using Bootstrap Icons) -->
                    <div class="d-flex justify-content-center align-items-center">
                        <a href="${window.globalSocials.facebook}" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                            <i class="bi bi-facebook"></i>
                        </a>
                        <a href="${window.globalSocials.twitter}" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                            <i class="bi bi-twitter-x"></i>
                        </a>
                        <a href="${window.globalSocials.instagram}" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                            <i class="bi bi-instagram"></i>
                        </a>

                        <a href="${window.globalSocials.youtube}" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                            <i class="bi bi-youtube"></i>
                        </a>
                        <a href="${window.globalSocials.tiktok}" target="_blank" class="btn btn-outline-light btn-lg social-btn me-2">
                            <i class="bi bi-tiktok"></i>
                        </a>
                        <a href="${window.globalSocials.discord}" target="_blank" class="btn btn-outline-light btn-lg social-btn ">
                            <i class="bi bi-discord"></i>
                        </a>
                    </div>
                </div>
                `;
        document.querySelector('.follow-us').innerHTML = followUsHtml;
        document.querySelector('.follow-us h2').textContent = ` ${I18n.translateString("newsTranslations.footerArticle")} `;
        document.querySelector('.follow-us h5').textContent = ` ${I18n.translateString("newsTranslations.footerArticleFollow")} `;

    }
}

NewsPost.template = /*html*/`
    <div class="main-content col-lg-8 col-md-12 col-sm-12 col-xs-12 g-0 mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-5 mt-xxl-5">
        <div class="home-matches home-components row mx-2">

            <div class="container post-news-container mt-5 rounded-top">
        <div class="row color-dark p-1 rounded">
          <div class="col-6 p-0 color-dark rounded-start h-100 position-relative ">
        <div class="ratio ratio-16x9">
                <div class=" author-info p-0 text-center d-flex flex-column justify-content-center align-content-center align-items-center color-lightdark">
                 <div class="img-circle-container-author d-flex justify-content-center border border-2 border-danger bg-dark bg-gradient rounded-circle overflow-hidden">
                    <img src="" alt="Author Photo" class="author-photo img-fluid rounded-circle mx-auto d-block">
                   </div>
                    <h5 class="mt-2 text-resize-name"></h5>
                    <p class="text-resize-date"></p>
                    <div class="social-icons text-resize-icons position-absolute"></div>
                </div>
                </div>
            </div>
           <div class="col-6 p-0 h-100 rounded-end">
                <img id="newsArticleCoverPhoto" src="" alt="Article Image" class="news-post-img ">
           </div>

        </div>
            <div class="the-article-container" class="mt-5"></div>
        </div>
        <!-- Footer Section for the End of the Article -->
<section class="follow-us" id="" class="text-center py-5 rounded-bottom">

</section>
    </div>
    </div>
    <div class="sidebar col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5 ">

        <placeholder-matcheswidget></placeholder-matcheswidget>
        <div class="sidebar-matches home-components row mx-2 ">
            <matches-component data-mode="homePage"></matches-component>
        </div>

         <div class="sidebar-predictions home-components row mx-2 ">
            <predictions-homecomponent></predictions-homecomponent>
        </div>
        <div class="sidebar-predictions home-components row mx-2 ">
            <widget-bookmakers></widget-bookmakers>
            <widget-bookmakers-skin></widget-bookmakers-skin>
        </div>
    </div>
`;

NewsPost.reg('news-post');
